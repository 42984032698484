/* eslint-disable */
import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  inject,
  CUSTOM_ELEMENTS_SCHEMA,
  OnInit,
  OnDestroy
} from '@angular/core';
import { IonContent, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

//Services
import { SportService } from '../../../providers/SportService';
import { TicketService } from '../../../providers/TicketService';
import { SportBookService } from '../../../providers/SportBookService';
import { UrlsService } from '../../../providers/UrlsService';
import { PlayerPropsService } from '../../../services/sports/PlayerPropsService';
import { MenuSportService } from '../../../providers/menuSportService';
import { BetSenseService } from '../../../services/sports/BetSenseService';
import { StyleService } from '../../../providers/StyleService';
import { ScriptService } from '../../../providers/ScriptService';
import { NewBaseService } from '../../../providers/newBase.service';
import { DeviceService } from '../../../services/device.service';
import { EventsService } from '../../../providers/EventsService';
import { IonLoaderService } from '../../../../../shared/src/services/loader-feature/ion-loader.service';

//Models
import { C_CategoryInfo } from '../../../models/C_CategoryInfo';
import { C_PlayerPropsIframeData } from '../../../models/sports/C_PlayerPropsIframeData';
import { ScoreBoard } from '../../../models/sportbook/scoreboard.model';
import { BetSenseData } from '../../../models/sports/BetSenseData';

//Others
import { Utils } from '../../../utils/Utils';
import { ExtractDatePipe } from '../../../pipes/extractDate.pipe';
import { SmartLineItem } from '../../../models/C_SmartLineItem';
import { BetbuilderComponent } from '../../../components/sports/sb-betbuilder/sb-betbuilder';
import { SbPrematchScoreboardComponent } from '../../../components/sports/sb-prematch-scoreboard/sb-prematch-scoreboard';

import { SbDropdownComponent } from '../../../components/sports/sb-dropdown/sb-dropdown';
import { SbButtonComponent } from '../../../components/sports/sb-button/sb-button';
import { SbGridContentSingleComponent } from '../../../components/sports/sb-grid-content-single/sb-grid-content-single';
import { SbGridHeaderComponent } from '../../../components/sports/sb-grid-header/sb-grid-header';
import { TrackingInfo, BasePage } from '../../base/BasePage';
import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { SbFilterComponent } from '../../../components/sports/sb-filter/sb-filter';
import { SbGridItemComponent } from '../../../components/sports/sb-grid-item/sb-grid-item';
import { NewTicketFacade } from '../../../models/sports/store/facades/ticket.facade';
import { SbNavbarComponent } from '../../../components/common/sb-navbar/sb-navbar';
import { SmartMarketInput } from '../../../models/sports/smartMarket.model';
import { C_Event } from '../../../models/C_Event';
import { BetDisplayDirective } from '@directives/bet-display/bet-display';
import { DropdownMode } from '../../../models/sportbook';
import { Store } from '@ngrx/store';
import { MobileBehaviorService } from '../../../services/sports/mobileBehavior.service';
import * as ticketSelector from '../../../../src/models/sports/store/selectors/ticket.selectors';
import { ticketState } from '../../../../src/models/sports/store/reducers/ticket.reducers';
import { NowLiveDetailPage } from '../../../../src/pages/sports/live-market/live-market';
import { NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { StaticStoreService } from '../../../providers/static-store.service';
import { SPORTS_PATHS } from '../../../lib/constants/routes';
import { Constants } from '../../../utils/constants';
import { SportHandleList } from '../../../utils/sportHandleList';

declare const SIR;

@Component({
  selector: 'page-market',
  templateUrl: './market.html',
  styleUrls: ['./market.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    SbNavbarComponent,
    SbPrematchScoreboardComponent,
    SbFilterComponent,
    SbDropdownComponent,
    SbGridHeaderComponent,
    SbGridItemComponent,
    SbButtonComponent,
    SbGridContentSingleComponent,
    CodereFooterComponent,
    BetbuilderComponent,
    BetDisplayDirective,
    NowLiveDetailPage
  ]
})
export class MercadosPage extends BasePage implements OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('standardWidgetSIR') standardWidgetSIR: ElementRef;
  @ViewChild('stickyWidgetSIR') stickyWidgetSIR: ElementRef;
  @ViewChild('standardWidgetSIR2') standardWidgetSIR2: ElementRef;
  @ViewChild('stickyWidgetSIR2') stickyWidgetSIR2: ElementRef;
  market: any;
  markets$: Observable<any>;
  currentCategory$: BehaviorSubject<C_CategoryInfo> = new BehaviorSubject(null);
  categories: any[] = [];
  scoreboard: ScoreBoard;
  offset: number = 0;
  nameLeague: string;
  playerPropsIframeData: C_PlayerPropsIframeData;
  playerPropsEventHandler: any;
  betSenseData: BetSenseData;
  eventListener: any;
  widgetActive: string;
  //GameType property used for identify "Resultados Finales"
  public gametypes: number[] = [284, 114, 37, 82, 153, 192];

  betbuilderSelection$: Observable<SmartLineItem[]>;
  betbuilderSelection: any[] = [];
  smartMarketRequest: SmartMarketInput;
  betbuilderCuotaText: string = '';
  betbuilderMessageText: string = 'Añadir al menos X selecciones.';
  betbuilderErrorMessage: string = '';

  isDesktop: boolean = false;
  isMobile: boolean = false;

  globalVars: GlobalVarsModel;
  extractDate = inject(ExtractDatePipe);
  sportBookService = inject(SportBookService);
  newTicketFacade = inject(NewTicketFacade);
  sportService = inject(SportService);
  ticketService = inject(TicketService);
  translateService = inject(TranslateService);
  playerPropsService = inject(PlayerPropsService);
  menuSportService = inject(MenuSportService);
  urlsService = inject(UrlsService);
  betSenseService = inject(BetSenseService);
  styleService = inject(StyleService);
  scriptService = inject(ScriptService);
  renderer = inject(Renderer2);
  ngZone = inject(NgZone);
  utils = inject(Utils);
  newBaseService = inject(NewBaseService);
  ionLoaderService = inject(IonLoaderService);
  staticStoreService = inject(StaticStoreService);

  dropdownMode = DropdownMode;
  deviceService = inject(DeviceService);
  eventService = inject(EventsService);
  mobileBehavior = inject(MobileBehaviorService);
  store = inject(Store<ticketState>);
  router = inject(Router);
  private fromLeftMenuAZ: boolean = false;
  mySubscription: Subscription;
  betbuilderSelectionSub: Subscription;
  betbuilderCatId = Constants.betbuilderCatId;

  constructor() {
    super();

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.betSenseData = new BetSenseData();
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globalVars = data;
          this.betSenseData.globals = data;
          return of(null);
        })
      )
      .subscribe();
    const params = history.state.params;
    this.fromLeftMenuAZ = !!history.state.isLeftMenuAZ;
    this.market = C_Event.parse(params);

    this.nameLeague = this.globalVars.SportHandleSelected;

    this.betbuilderSelection$ = this.store.select(ticketSelector.getBetbuilderSelection);
    this.betbuilderSelectionSub = this.betbuilderSelection$.subscribe((betbuilderSelection: any) => {
      this.betbuilderSelection = betbuilderSelection;
      this.checkBetbuilderLength();
    });
  }

  override ngOnInit() {
    this.getData();
  }

  private getData() {
    const trackingInfo: TrackingInfo = this.getTrackingInfo();
    if (trackingInfo != null) {
      this.trackPageView(trackingInfo);
    }
    this.scoreboard = new ScoreBoard(
      this.market.teamHome,
      this.market.teamAway,
      this.market.StartDate,
      this.market.pitcherHome ? this.market.pitcherHome : null,
      this.market.pitcherAway ? this.market.pitcherAway : null
    );

    this.betSenseData.eventId = this.market.NodeId;
    this.betSenseData.iframeEnabled = this.globalVars.FEATURES.betsenseNonLiveEnabled;
    this.betSenseData.iframeVisible = false;
    this.betSenseData.iframeUrl = this.urlsService.getBetsenseUrl(this.market.NodeId);
    this.betSenseData.ticketService = this.ticketService;
    this.betSenseData.newTicketFacade = this.newTicketFacade;

    this.betSenseData.events = this.eventService;

    this.betSenseData.trackingService = this.trackingService;
    this.eventListener = this.betSenseService.listenEvents.bind(this.betSenseData, this.market);
    this.getMarketsAndCategories();
  }

  ionViewWillEnter() {
    this.staticStoreService.callPolling = false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationSkipped) {
        this.router.navigated = false;
        this.sportService.showSportradar$.next(false);
        if (history.state.params) {
          const params = history.state.params;
          this.fromLeftMenuAZ = !!history.state.isLeftMenuAZ;
          this.market = C_Event.parse(params);

          this.nameLeague = this.globalVars.SportHandleSelected;

          this.getData();
        }
      }
    });
    if (history.state.params) {
      const params = history.state.params;
      this.fromLeftMenuAZ = !!history.state.isLeftMenuAZ;
      this.market = C_Event.parse(params);

      this.nameLeague = this.globalVars.SportHandleSelected;

      if (this.fromLeftMenuAZ) {
        this.getData();
      }
    }

    this.globalVars.hideBetFooterBar = false;

    this.trackingService.trackEvent([
      'SelectMarket',
      this.market.NodeId,
      this.market.SportHandle,
      this.market.Name,
      'pageView'
    ]);
    window.addEventListener('message', this.eventListener);
  }

  ionViewDidLoad() {
    this.staticStoreService.callPolling = false;
    this.playerPropsIframeData = {
      iframeVisible: false,
      playerPropsLoader: false,
      iframeUrl: this.urlsService.getPlayerPropsWidgetUrl(this.market.NodeId, ''),
      iframeHeight: 100,
      widgetId: '',
      playerPropsService: this.playerPropsService
    };
    this.playerPropsEventHandler = this.playerPropsService.handleEvents.bind(this.playerPropsIframeData);
  }

  ionViewDidEnter() {
    this.staticStoreService.callPolling = false;
    if (this.showSportRadar()) {
      Promise.all([this.scriptService.load('sportradar'), this.styleService.load('sportradar')]).then(() => {
        this.sportService.showSportradar$.next(true);
        this.sportService.eventStatisticsId$.next(this.market.StatisticsId);
        this.sportService.isFormula1$.next(false);
        if (this.isMobile) {
          this.widgetActive = 'standard';
          this.setSportRadarWidget();
        }
      });
    }
    if (this.globalVars.FEATURES.SPO_LMTFormula1 && this.showFormula1()) {
      Promise.all([this.scriptService.load('sportradarF1'), this.styleService.load('sportradar')]).then(() => {
        this.sportService.showSportradar$.next(true);
        this.sportService.eventStatisticsId$.next(this.market.StatisticsId);
        this.sportService.isFormula1$.next(true);
        if (this.isMobile) {
          this.widgetActive = 'standard';
          this.setSportRadarWidgetF1();
        }
      });
    }

    window.addEventListener('message', this.playerPropsEventHandler);
    this.content.scrollToTop();
  }

  ionViewDidLeave() {
    this.ngOnDestroy();
  }

  onScroll(event) {
    if ((!this.showSportRadar() && !this.showFormula1()) || !event) return;
    if (event.detail.scrollTop > 0) {
      this.widgetActive = 'sticky';
      if (this.standardWidgetSIR != undefined) this.renderer.addClass(this.standardWidgetSIR.nativeElement, 'd-none');
      if (this.stickyWidgetSIR != undefined) this.renderer.removeClass(this.stickyWidgetSIR.nativeElement, 'd-none');
      if (this.standardWidgetSIR2 != undefined) this.renderer.addClass(this.standardWidgetSIR2.nativeElement, 'd-none');
      if (this.stickyWidgetSIR2 != undefined) this.renderer.removeClass(this.stickyWidgetSIR2.nativeElement, 'd-none');
    } else {
      this.widgetActive = 'standard';
      if (this.standardWidgetSIR != undefined)
        this.renderer.removeClass(this.standardWidgetSIR.nativeElement, 'd-none');
      if (this.stickyWidgetSIR != undefined) this.renderer.addClass(this.stickyWidgetSIR.nativeElement, 'd-none');
      if (this.standardWidgetSIR2 != undefined)
        this.renderer.removeClass(this.standardWidgetSIR2.nativeElement, 'd-none');
      if (this.stickyWidgetSIR2 != undefined) this.renderer.addClass(this.stickyWidgetSIR2.nativeElement, 'd-none');
    }
    this.mobileBehavior.scrolling$.next(true);
  }

  setSportRadarWidget() {
    this.renderer.addClass(this.stickyWidgetSIR.nativeElement, 'd-none');
    SIR(
      'addWidget',
      '.sr-widget-1',
      'match.lmtSticky',
      {
        layout: 'topdown',
        scoreboard: 'disable',
        detailedScoreboard: 'disable',
        tabsPosition: 'top',
        matchId: this.market.StatisticsId
      },
      () =>
        setTimeout(() => {
          this.content.scrollToTop();
        }, 500)
    );
    SIR(
      'addWidget',
      '.sr-widget-2',
      'match.lmtPlus',
      {
        layout: 'single',
        scoreboard: 'disable',
        detailedScoreboard: 'disable',
        tabsPosition: 'top',
        pitchLogo: './assets/global/img/codere-apuestas-mx.svg',
        goalBannerImage: './assets/global/img/codere_green.svg',
        logo: ['./assets/global/img/codere_grey.svg'],
        matchId: this.market.StatisticsId
      },
      () =>
        setTimeout(() => {
          this.content.scrollToTop();
        }, 500)
    );

    this.renderer.listen(this.stickyWidgetSIR.nativeElement, 'click', () => {
      this.widgetActive = 'standard';
      this.renderer.addClass(this.stickyWidgetSIR.nativeElement, 'd-none');
      this.renderer.removeClass(this.standardWidgetSIR.nativeElement, 'd-none');
    });
  }

  setSportRadarWidgetF1() {
    this.renderer.addClass(this.stickyWidgetSIR2.nativeElement, 'd-none');
    SIR(
      'addWidget',
      '.sr-widget-1',
      'match.lmtSticky',
      {
        layout: 'topdown',
        scoreboard: 'disable',
        detailedScoreboard: 'disable',
        tabsPosition: 'top',
        stageId: this.market.StatisticsId
      },
      () =>
        setTimeout(() => {
          this.content.scrollToTop();
        }, 500)
    );
    SIR(
      'addWidget',
      '.sr-widget-2',
      'match.lmtPlus',
      {
        layout: 'single',
        scoreboard: 'disable',
        detailedScoreboard: 'disable',
        tabsPosition: 'top',
        pitchLogo: './assets/global/img/codere-apuestas-mx.svg',
        goalBannerImage: './assets/global/img/codere_green.svg',
        logo: ['./assets/global/img/codere_grey.svg'],
        stageId: this.market.StatisticsId
      },
      () =>
        setTimeout(() => {
          this.content.scrollToTop();
        }, 500)
    );
    this.renderer.listen(this.stickyWidgetSIR2.nativeElement, 'click', () => {
      this.widgetActive = 'standard';
      this.renderer.addClass(this.stickyWidgetSIR2.nativeElement, 'd-none');
      this.renderer.removeClass(this.standardWidgetSIR2.nativeElement, 'd-none');
    });
  }

  getTrackingInfo(): TrackingInfo {
    return {
      uri: encodeURI(`/${this.market.SportHandle}/${this.market.LeagueName}/nonLive/${this.market.Name}`),
      description: `Mercados page - ${this.market.Name}`,
      additionalData: {
        nodeId: this.market.NodeId,
        nodeName: this.market.Name,
        sportHandle: this.market.SportHandle,
        LeagueName: this.market.LeagueName
      }
    };
  }

  getMarketsAndCategories() {
    this.sportService.getMarketCategories(this.market.NodeId).subscribe((categories: any) => {
      this.categories = this.setCategories(categories);
      this.changeCategory(this.categories[0]);
    });
  }

  getMarkets() {
    if (this.currentCategory$.value) {
      return this.sportService
        .getMarketGamesByCategory(this.market.NodeId, this.currentCategory$.value.CategoryId, this.market.LeagueName)
        .pipe(
          map((markets: any[]) => {
            markets.forEach((market, index) => {
              market.nameLeague = this.nameLeague;
              this.sportBookService.getAllSpovesInMarket(markets, market);
            });

            this.sportBookService.getCombinedMarkets(markets, this.market);
            markets = this.sportBookService.getMarketGoalScorer(markets, this.market);
            //Order Resultado Final in three sub-arrays
            markets = this.orderThreeColumns(markets);

            if (this.currentCategory$.value.CategoryId == Constants.goleadoresCat) {
              markets.sort((a, b) => a[Constants.priorityForCat] - b[Constants.priorityForCat]);
            }

            return markets;
          })
        );
    } else {
      of([]);
    }
  }

  setCategories(categories: any, previousCategories?: C_CategoryInfo[]) {
    let categoriesArr = categories.map((category) => {
      return (category = new C_CategoryInfo(category.CategoryId, category.IsRelevant, category.CategoryName));
    });

    categoriesArr = this._checkCategories(categoriesArr);
    categoriesArr = this.createSixPackCategories(categoriesArr);

    return categoriesArr;
  }

  private _checkCategories(categories) {
    let newCategoriesArr: C_CategoryInfo[] = Array.from(categories);

    // Check Special Bets Category
    const specialBetCat = categories.find((i) => i.CategoryId == Constants.specialBetCat);
    const principalesMarketsCat = categories.find((i) => i.CategoryId == Constants.principalesCat);
    const betbuilderBetsCat = categories.find((i) => i.CategoryId == Constants.betbuilderCatId);
    newCategoriesArr = newCategoriesArr.filter(
      (i) =>
        i.CategoryId !== Constants.specialBetCat &&
        i.CategoryId !== Constants.principalesCat &&
        i.CategoryId !== Constants.betbuilderCatId
    );

    if (specialBetCat) newCategoriesArr.push(specialBetCat);
    if (betbuilderBetsCat) newCategoriesArr.unshift(betbuilderBetsCat);
    if (principalesMarketsCat) newCategoriesArr.unshift(principalesMarketsCat);

    return newCategoriesArr;
  }

  public showSportRadar() {
    return (
      (this.market.SportHandle === SportHandleList.soccer ||
        this.market.SportHandle === SportHandleList.tennis ||
        this.market.SportHandle === SportHandleList.basketball) &&
      this.market.LTMEnabled
    );
  }

  private createSixPackCategories(categories) {
    let newCategoriesArr: C_CategoryInfo[] = Array.from(categories);

    if (!this.market.isUsaEvent) {
      newCategoriesArr = this.getPlayerPropsCategory(categories);
      return newCategoriesArr;
    }
    const newSixPackCategories = new C_CategoryInfo(9900, false, '6 Pack', '', '');
    newCategoriesArr.push(newSixPackCategories);
    newCategoriesArr = this.getPlayerPropsCategory(newCategoriesArr);
    return newCategoriesArr;
  }

  private getPlayerPropsCategory(categories: any) {
    let newCategoriesArr: C_CategoryInfo[] = Array.from(categories);

    if (this.market && this.globalVars.FEATURES.playerPropsEventUrl) {
      this.playerPropsService.playerPropsLeagues.map((league) => {
        if (this.market.ParentNodeId == league.nodeId.toString()) {
          let category = new C_CategoryInfo(-1000, false, this.translateService.instant('playerprops'), '', '');
          category.icon = './assets/global/img/sports/playerprops.png';
          newCategoriesArr.splice(2, 0, category);
        }
      });
    }
    return newCategoriesArr;
  }
  private checkPlayerPropsOptions(category): void {
    if (this.playerPropsIframeData) {
      if (category) this.playerPropsIframeData.iframeVisible = category.CategoryId === -1000;

      if (this.playerPropsIframeData.iframeVisible) {
        this.playerPropsIframeData.playerPropsLoader = true;
        this.utils.loader();
      } else {
        this.playerPropsIframeData.playerPropsLoader = false;
      }
    }
  }

  changeCategory(category) {
    this.currentCategory$.next(category);
    this.content.scrollToTop();
    this.markets$ = this.getMarkets();
    this.checkPlayerPropsOptions(category);
    // check if selected category is betbuilder
    if (this.isMobile) {
      if (category) {
        if (category.CategoryId == Constants.betbuilderCatId) {
          this.widgetActive = 'sticky';
          if (this.stickyWidgetSIR) {
            this.renderer.addClass(this.standardWidgetSIR.nativeElement, 'd-none');
            this.renderer.removeClass(this.stickyWidgetSIR.nativeElement, 'd-none');
          }
          if (this.stickyWidgetSIR2) {
            this.renderer.addClass(this.standardWidgetSIR2.nativeElement, 'd-none');
            this.renderer.removeClass(this.stickyWidgetSIR2.nativeElement, 'd-none');
          }
        } else {
          this.widgetActive = 'standard';
          if (this.standardWidgetSIR) {
            this.renderer.removeClass(this.standardWidgetSIR.nativeElement, 'd-none');
            this.renderer.addClass(this.stickyWidgetSIR.nativeElement, 'd-none');
          }
          if (this.standardWidgetSIR2) {
            this.renderer.removeClass(this.standardWidgetSIR2.nativeElement, 'd-none');
            this.renderer.addClass(this.stickyWidgetSIR2.nativeElement, 'd-none');
          }
        }
      }
    }
  }

  public bet(game: any, event: any, gameName: string, type: string, position: string = '') {
    let data = {
      ...game,
      EventDate: this.extractDate.transform(event.StartDate),
      EventName: this.market.Name,
      GameName: gameName
    };

    data = this.correctData(data, event, gameName, type, position);

    if (this.currentCategory$.value.CategoryId == Constants.betbuilderCatId) {
      this.checkBetbuilderSelection(data, data.NodeId, game.GameTypeId);
    } else {
      this.newTicketFacade.checkSelection(data, data.NodeId);
    }
  }

  checkBetbuilderSelection(data, nodeId, gameTypeId) {
    let id = this.betbuilderSelection.findIndex((item) => item.NodeId == nodeId);
    // remove
    if (id !== -1) {
      this.newTicketFacade.removeSmartMarketSelection(nodeId);
    } else {
      //add
      this.newTicketFacade.sendAddSmartMarketSelection(data);
    }
  }

  checkBetbuilderLength() {
    switch (this.betbuilderSelection.length) {
      case 0:
        this.betbuilderMessageText = 'Necesitas al menos 2 selecciones.';
        this.betbuilderCleanValues();
        break;
      case 1:
        this.betbuilderMessageText = 'Añadir al menos 1 o más selecciones.';
        this.betbuilderCleanValues();
        break;
      default:
        this.betbuilderMessageText = 'Apostar o añadir más selecciones';
        this.betbuilderCheck();
        break;
    }
  }

  betbuilderCleanValues() {
    this.betbuilderCuotaText = '';
    this.betbuilderErrorMessage = '';
  }

  betbuilderCheck() {
    // this.utils.loader();
    this.ionLoaderService.showHideAutoLoader();
    this.sportService.addSmartMarketItem(this.betbuilderSelection).subscribe((resp) => {
      this.ionLoaderService.hideLoader();
      if (resp.Odds < 0) {
        this.betbuilderCuotaText = '';
        this.betbuilderErrorMessage =
          'Combinación no válida. Algunas de tus selecciones no son combinables. Por favor, cámbialas para continuar.';
        this.smartMarketRequest = null;
      } else {
        this.betbuilderCuotaText = resp.Odds.toFixed(2).toString();
        this.betbuilderErrorMessage = '';
        this.smartMarketRequest = resp;
      }
    });
  }

  sendAddSmartMarketItem() {
    let smartMarketBet = SmartLineItem.createSmartLineItem(
      this.betbuilderSelection,
      this.market,
      this.betbuilderCuotaText
    );
    delete this.smartMarketRequest['MaxSystem']; // ????
    this.newTicketFacade.sendAddSmartMarketItem(this.smartMarketRequest, smartMarketBet);
  }

  checkSelected(nodeId) {
    let selected = false;
    if (this.currentCategory$.value.CategoryId == Constants.betbuilderCatId) {
      selected = this.betbuilderSelectionCheck(nodeId);
    } else {
      selected = this.newTicketFacade.hasResult(nodeId);
    }
    return selected;
  }

  betbuilderSelectionCheck(nodeId) {
    let nodeSelected = this.betbuilderSelection.filter((item) => item.NodeId === nodeId).length > 0;
    let selected = false;
    selected = this.currentCategory$.value.CategoryId == Constants.betbuilderCatId && nodeSelected;

    return selected;
  }

  //Order Resultados Finales in three colummns: local, tie and visitant
  private orderThreeColumns(markets) {
    markets.forEach((item) => {
      var isFinal = this.gametypes.indexOf(item.GameType) != -1;
      if (isFinal) {
        let local = [];
        let tie = [];
        let visitant = [];
        let a;
        let b;
        let tmp = '';
        item.GroupResults.forEach((it) => {
          tmp = it.Name.split(':');
          a = parseInt(tmp[0]);
          b = parseInt(tmp[1]);
          if (a > b) local.push(it);
          else if (b > a) visitant.push(it);
          else tie.push(it);
        });
        item.GroupResults = [local, tie, visitant];
      }
    });
    return markets;
  }

  public showFormula1() {
    return this.market.SportHandle == SportHandleList.motorsport;
  }

  getName(index: number, evalName: string) {
    let name = '';
    if (evalName == 'Goleadores') {
      switch (index) {
        case 0:
          name = 'Primer Goleador';
          break;
        case 1:
          name = 'Marca Gol Durante el partido';
          break;
        case 2:
          name = 'Último Goleador';
          break;
      }
    }
    return name;
  }

  correctData(data: any, event: any, gameName: string, type: string, position: string) {
    switch (true) {
      case (gameName.includes('Handicap') || gameName.includes('Hándicap')) && type == '1' && position == '1':
        data.Name = `${this.market.teamHome} ${data.Name}`;
        break;
      case (gameName.includes('Handicap') || gameName.includes('Hándicap')) && type == '1' && position == 'X':
        data.Name = `X (${this.market.teamAway} ${data.Name})`;
        break;
      case (gameName.includes('Handicap') || gameName.includes('Hándicap')) && type == '1' && position == '2':
        data.Name = `${this.market.teamAway} ${data.Name}`;
        break;
      case position.includes('Primer'):
        data.GameName = position;
        break;
      case position.includes('Durante'):
        data.GameName = position;
        break;
      case position.includes('Último'):
        data.GameName = position;
        break;
    }
    return data;
  }

  getPosition(length: number, index: number) {
    let position = '';
    if (length % 3 == 0) {
      switch (true) {
        case index == 1:
        case index == 4:
        case index == 7:
          position = 'X';
          break;
        case index == 2:
        case index == 5:
        case index == 8:
          position = '2';
          break;
        default:
          position = '1';
          break;
      }
    } else {
      switch (true) {
        case index == 1:
        case index == 3:
        case index == 5:
          position = '2';
          break;
        default:
          position = '1';
          break;
      }
    }
    return position;
  }

  removeSmartMarketSelections() {
    this.newTicketFacade.clearBetbuilderSelections();
  }

  public navigate(market, gameType?): void {
    let component = market.isLive ? SPORTS_PATHS.NowLiveDetailPage : SPORTS_PATHS.MercadosPage;
    let eventType = market.isLive ? 'pushEventLive' : 'pushEvent';
    this.router.navigate([component], {
      state: { params: market },
      queryParams: { animate: !this.isDesktop }
    });

    this.trackingService.trackEvent([eventType, market.NodeId, window.location.hash, market.Name, 'event']);
  }

  unsuscribeToSubjects() {
    this.newTicketFacade.clearBetbuilderSelections();
    this.betbuilderSelectionSub.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsuscribeToSubjects();
    window.removeEventListener('message', this.playerPropsEventHandler);
    window.removeEventListener('message', this.eventListener);
    if (this.showSportRadar() && this.isMobile) {
      SIR('removeWidget', document.querySelector('.sr-widget-1'));
      SIR('removeWidget', document.querySelector('.sr-widget-2'));
      SIR('removeWidget', document.querySelector('.sr-widget-22'));
    } else if (this.showSportRadar() && this.isDesktop) {
      this.sportService.showSportradar$.next(false);
      this.sportService.eventStatisticsId$.next('');
      this.sportService.isFormula1$.next(false);
    }
    this.removeSmartMarketSelections();
  }
}
