/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnChanges,
  ViewChild,
  OnDestroy,
  inject
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TitleCasePipe } from '@pipes/titlecase/title-case.pipe';
import { Subscription } from 'rxjs';
import { Swiper, SwiperOptions } from 'swiper';
@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sb-filter-mostbet',
  templateUrl: './sb-filter-mostbet.html',
  imports: [CommonModule, IonicModule, TitleCasePipe, CommonModule, FormsModule],
  standalone: true,
  styleUrls: ['./sb-filter-mostbet.scss']
})
export class SbFilterMostBetComponent implements OnChanges, AfterViewInit {
  @ViewChild('line') line: ElementRef;
  @Input() mode = 'grey';
  @Input() filters: any[];
  @Input() switch: boolean;
  @Input() selected: any;
  @Input() keyboard = false;
  @Output() filter: EventEmitter<any> = new EventEmitter();
  //eslint-disable-next-line
  @Output() resize: EventEmitter<any> = new EventEmitter();
  private ref: HTMLElement;
  private lineRef: HTMLElement;
  private filtersWidth: any;

  @ViewChild('swiper') swiperRef:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;
  swiper?: Swiper;

  private _element = inject(ElementRef);
  private _renderer = inject(Renderer2);

  ngOnChanges() {
    if (this.filters) {
      this.display();
      this.setFiltersWidth();
    }
    if (this.selected && this.filters) {
      let index = this.filters.findIndex((filter) => filter?.SportHandle === this.selected?.SportHandle);
      index = index === -1 ? 0 : index;
      this._select(this.selected, index);
    }

    if (!this.swiper) return;
    this.swiper.update();
  }
  ngAfterViewInit(): void {
    this.ref = this._element.nativeElement;
    this.lineRef = this.line.nativeElement;

    this.setColorMode();
    this.display().then((_) => this.resize.emit());
    if (this.switch) return;

    const config: SwiperOptions = {
      slidesPerView: 'auto',
      spaceBetween: 0,
      freeMode: true,
      direction: 'horizontal',
      grabCursor: true,
      observer: false,
      loop: false,
      noSwiping: this.switch,
      followFinger: !this.switch,
      simulateTouch: !this.switch,
      allowSlidePrev: !this.switch,
      allowSlideNext: !this.switch
    };

    const swiperEl = Object.assign(this.swiperRef.nativeElement, config);
    swiperEl.initialize();
    this.swiper = this.swiperRef.nativeElement.swiper;
  }

  _select(filter: any, index: number) {
    this.markAsSelected(filter);
    this.setFiltersWidth().then(() => {
      this.display().then((_) => setTimeout(() => this.moveLine(index), 200));
    });
  }

  select(filter: any, index: number): void {
    if (this.keyboard) return;
    this.filter.emit(filter);
    this._select(filter, index);
  }

  private setColorMode() {
    this._renderer.setAttribute(this.ref, 'mode', this.mode);
    this._renderer.addClass(this.ref, 'background-color-regular');
  }

  private markAsSelected(currentFilter: any) {
    this.filters.forEach((filter) => {
      filter.selected = filter?.SportHandle === currentFilter?.SportHandle;
    });
  }

  private moveLine(i: number) {
    const item = this.filtersWidth[i];
    const firstItemLeft = this.filtersWidth[0]?.rect?.left;

    this._renderer.setStyle(this.lineRef, 'left', `${item.rect.left - firstItemLeft}px`);
    this._renderer.setStyle(this.lineRef, 'width', `${item.rect.width}px`);
  }

  private setFiltersWidth() {
    return new Promise<void>((res, rej) => {
      this.filtersWidth = {};
      const swiperContainer = this.swiperRef?.nativeElement;

      if (swiperContainer) {
        const slideElements = Array.from(swiperContainer.querySelectorAll('.sb-filter-mostbet-item'));
        this.filtersWidth = slideElements.map((slide: Element) => ({
          rect: slide.getBoundingClientRect(),
          element: slide
        }));
        res();
      }
    });
  }

  private display() {
    return new Promise<void>((res, rej) => {
      let display;
      if (Array.isArray(this.filters)) {
        if (this.switch) {
          display = this.filters.length <= 1 ? 'none' : 'block';
        } else {
          display = this.filters.length ? 'block' : 'none';
        }
      } else {
        display = 'none';
      }

      this._renderer.setStyle(this._element.nativeElement, 'display', display);
      this.resize.emit();

      if (this.swiper) {
        this.swiper.update();
      }
      res();
    });
  }
}
