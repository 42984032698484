/* eslint-disable eqeqeq */
import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, HostListener, inject } from '@angular/core';
import { LicenseTypes } from '../../../models/MasterData';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { FundsOrigin } from '@providers/payments/BankService';

@Component({
  selector: 'cs-document-item',
  templateUrl: './cs-document-item.html',
  styleUrls: ['./cs-document-item.scss'],
  standalone: true,
  host: {
    class: 'cs-document-item background-color-white color-dark'
  },
  imports: [TranslateModule, CommonModule]
})
export class CsDocumentItemComponent {
  @Input() document: any;
  @Input() verificationType = false;
  @Input() openInstrucionsNumber = 0;
  @Input() isOriginFunds?: boolean;
  @Input() originFunds?: string | FundsOrigin;
  @Output() navigate: EventEmitter<any> = new EventEmitter();
  @Output() openInstructions: EventEmitter<any> = new EventEmitter();
  private globalVars!: GlobalVarsModel;
  private newBaseService = inject(NewBaseService);
  public translate = inject(TranslateService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  @HostListener('click')
  _navigate() {
    if (this.isArgentina && this.openInstrucionsNumber != 0) {
      this.openInstructions.emit(this.openInstrucionsNumber);
    } else {
      this.navigate.emit();
    }
  }

  public get applyClassToDocumentContainer() {
    const defaultClass = 'cs-document-item--container';
    return this.isColombia ? `${defaultClass} m-left-1` : defaultClass;
  }

  public get isArgentina() {
    return (
      this.globalVars.licenseType == LicenseTypes.ArgentinaPBA ||
      this.globalVars.licenseType == LicenseTypes.ArgentinaCaba ||
      this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza
    );
  }

  public get isSpain() {
    return (
      this.globalVars.licenseType == LicenseTypes.Nacional ||
      this.globalVars.licenseType == LicenseTypes.Madrid ||
      this.globalVars.licenseType == LicenseTypes.PaisVasco
    );
  }

  public get isColombia() {
    return this.globalVars.licenseType == LicenseTypes.Colombia;
  }
}
