import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'sb-tabs',
  templateUrl: './sb-tabs.component.html',
  styleUrls: ['./sb-tabs.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule]
})
export class SbTabsComponent {
  /**
   * categories Array
   *
   * @input categories[]
   * @type Array
   * */
  @Input() categories: any;
  /**
   * color mode: 'regular', 'dark'
   * @input color
   * @type string
   */
  @Input() color: string = 'regular';
  /**
   * Selected filter
   * @input selected
   * @type {category}
   */
  @Input() selectedTab: any;
  /**
   * Event emmited when the user selects a tab
   * @output category
   * @type {category}
   */
  @Output() selectTab: EventEmitter<any> = new EventEmitter();

  constructor() {}

  /**
   * Selects an emmit the category selection
   * @param cat
   * @param index
   */
  onClick(cat, index) {
    this.selectTab.emit({ ...cat, index });
  }
}
