/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Subscription, Observable, lastValueFrom } from 'rxjs';

import { BaseService } from '../../providers/BaseService';
import { LogService } from '../../providers/LogService';
import { UserService } from '../../providers/UserService';
import { SignalRService } from '../../providers/SignalRService';
import { DeviceService } from '../../services/device.service';
import { NavService } from '../../providers/NavService';
import { BalancesService } from '../../providers/BalancesService';
import { GlobalVarsModel } from '../../models/ModelGlobalsVar';
import { SlotsService } from '../../providers/SlotsService';

import { Category, Filter, Game } from '../../models/lobby/casinoDataModels';
import { LicenseTypes } from '../../models/MasterData';
import { CONSTANTS } from '../../constants';
import { Router } from '@angular/router';
import { AnimationController, ModalController, Animation } from '@ionic/angular';
import { UserServiceMSO } from '../../providers/UserServiceMSO';
import { CasinoFilter, CasinoFilterGroup } from '@interfaces/casinoMoreFiltersInterfaces';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class CasinoService extends BaseService {
  private categories: Category[] = [];
  private currentLobby: string = undefined;
  private filters: Filter[] = [];
  private games: any;
  private inactiveGames: any;
  private lobbies: any;
  private jackpots: any;
  private infoSingleLobby: any;
  private lobbyState$ = new BehaviorSubject<Filter | Category | Category[]>(undefined);
  slotsBanners: any[] = [];
  broadcasterSubscription: Subscription;
  leavingCasino = false;
  isMobile: boolean;
  isDesktop: boolean;
  moreFilters: CasinoFilterGroup[];
  moreFiltersApplied = new BehaviorSubject<CasinoFilter[]>(undefined);
  casinoFilter: Filter;
  public filterName$ = new BehaviorSubject<string>(undefined);

  balancesService = inject(BalancesService);
  logService = inject(LogService);
  userService = inject(UserService);
  signalRService = inject(SignalRService);
  slotsService = inject(SlotsService);
  navService = inject(NavService);
  router = inject(Router);
  deviceService = inject(DeviceService);

  userServiceMSO = inject(UserServiceMSO);
  modalCtrl = inject(ModalController);
  translate = inject(TranslateService);
  animationCtrl = inject(AnimationController);

  constructor() {
    super();

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.deviceService.isMobile();
    this.isDesktop = this.deviceService.isDesktop();

    this.events.subscribe('user:logout', () => {
      if (this.router.url === 'LobbyCasinoPage') {
        this.getRpg(this.currentLobby, true);
      }
    });
    this.events.subscribe('LobbyCasinoPage:true', () => {
      this.leavingCasino = true;
      this.destroyBroadcaster();
    });
    this.events.subscribe('returnCasino', () => {
      this.subscribeToSignalR(this.currentLobby);
    });
  }

  async setLobbyData(lobby: string) {
    const isLobbySingle = this.getIsSingleLobby(lobby);
    await this.destroyBroadcaster();
    this.leavingCasino = false;

    //eslint-disable-next-line
    if (this.lobbies == null) {
      await this.getInitialData();

      if (this.globalVars.FEATURES.JackpotCallServiceActive) {
        await this.getJackpotData(lobby);
      }
    }

    if (
      this.globalVars.FEATURES.SignalRCasinoJackpots ||
      (this.globalVars.FEATURES.SignalRCasinoLiveGames && !isLobbySingle)
    ) {
      this.subscribeToSignalR(lobby);
    }

    if (this.currentLobby !== lobby || isLobbySingle) {
      //eslint-disable-next-line
      if (lobby === CONSTANTS.jackpot && this.jackpots == null) {
        await this.getJackpotData(lobby);
      } else if (lobby !== CONSTANTS.jackpot && this.lobbies[lobby] && !isLobbySingle) {
        this.getDy(lobby);
      }

      await this.setCurrentLobbyData(lobby);
    } else {
      if (lobby === CONSTANTS.jackpot) {
        this.lobbyState$.next(this.jackpots.jackpots);
      } else {
        this.lobbyState$.next(this.categories);
      }
    }
  }

  async setFilter(name: string, component?: any) {
    this.filterName$.next(name);
    if (this.filters.length > 0) {
      if (name === CONSTANTS.all) {
        this.unapplyMoreFilters(false);
        this.lobbyState$.next(this.categories);
      } else {
        const newFilter = this.filters.find((filter) => filter.Name === name);
        if (newFilter) {
          if (newFilter?.DYConfig) {
            this.unapplyMoreFilters(false);
            if (newFilter?.DYConfig?.IntegrationMode !== 'None') {
              const filterFRomDy = await this.getFilterFromDy(
                newFilter?.FilterIcon,
                newFilter?.Name,
                newFilter?.Order,
                newFilter?.grid,
                newFilter?.type,
                newFilter?.typeCategory
              );
              if (filterFRomDy) {
                this.lobbyState$.next(filterFRomDy);
              } else {
                this.lobbyState$.next(newFilter);
              }
            } else {
              this.lobbyState$.next(newFilter);
            }
          } else {
            if (newFilter.Name === this.globalVars.FEATURES.casinoCalendarName) {
              // Refresh calendar filter data
              this.unapplyMoreFilters(false);
              const calendarFilter = await this.checkCalendar();
              newFilter.data = calendarFilter.data;

              const modal = await this.modalCtrl.create({
                component: component,
                componentProps: { sections: newFilter.data },
                backdropDismiss: true,
                cssClass: 'modalCalendar'
              });
              await modal.present();

              this.lobbyState$.next(this.categories);
            } else if (newFilter.Name === this.translate.instant('casinoMoreFilters')) {
              this.showMoreFilters(newFilter, component);
            } else {
              this.unapplyMoreFilters(false);
              this.lobbyState$.next(newFilter);
            }
          }
        } else {
          this.unapplyMoreFilters(false);
          this.lobbyState$.next(this.categories);
        }
      }
    } else if (this.filters.length === 0) {
      this.unapplyMoreFilters(false);
      this.lobbyState$.next(this.categories);
    }
  }

  private async showMoreFilters(casinoFilter: Filter, component): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: component,
      cssClass: 'modalMoreFilters',
      enterAnimation: this.slideInAnimation.bind(this),
      leaveAnimation: this.slideOutAnimation.bind(this)
    });
    await modal.present();
    modal.onDidDismiss().then((applyFilters) => {
      this.casinoFilter = casinoFilter;
      this.applyFilters(applyFilters.data, casinoFilter);
    });
  }

  private slideInAnimation(baseEl: HTMLElement): Animation {
    const root = baseEl.shadowRoot;
    const animation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .duration(500)
      .fromTo('transform', 'translateY(100%)', 'translateY(0)')
      .fromTo('opacity', '0', '1');
    return this.animationCtrl.create().addElement(baseEl).easing('ease-out').duration(500).addAnimation(animation);
  }

  private slideOutAnimation(baseEl: HTMLElement): Animation {
    const root = baseEl.shadowRoot;
    const animation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .duration(500)
      .fromTo('transform', 'translateY(0)', 'translateY(100%)')
      .fromTo('opacity', '1', '0');
    return this.animationCtrl.create().addElement(baseEl).easing('ease-out').duration(500).addAnimation(animation);
  }

  async getFilterFromDy(FilterIcon, Name, Order, grid, type, typeCategory) {
    const newGames = {
      FilterIcon: FilterIcon,
      Name: Name,
      Order: Order,
      grid: grid,
      type: type,
      typeCategory: typeCategory,
      DySelector: '',
      Games: [],
      JackpotId: null,
      additionalData: null
    };
    const url =
      this.globalVars.URLBASE_REPORTS +
      'SlotGames/GetDYFilterGames?lobby=' +
      this.currentLobby +
      '&mobile=' +
      this.isMobile +
      '&filter=' +
      Name;
    const gamesFilter = await this.myGet(url)
      .toPromise()
      .catch(() => {
        console.error('Fail service filter Dy');
      });
    if (gamesFilter) {
      if (gamesFilter.RecommendedGames) {
        const mappedGames = gamesFilter.RecommendedGames.map((game) =>
          this.mapGame(game.GameId, game.SlotId ? game.SlotId : null)
        );
        if (mappedGames.length > 0) {
          newGames.Games = mappedGames;
          return newGames;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  setCategory(name: string) {
    if (this.categories.length > 0) {
      if (name === CONSTANTS.all) {
        this.lobbyState$.next(this.categories);
      } else {
        const newCategory = this.categories.find((category) =>
          category.Name.toUpperCase().includes(name.toUpperCase())
        );
        if (newCategory) {
          this.lobbyState$.next(newCategory);
        } else {
          this.lobbyState$.next(this.categories);
        }
      }
    }
  }

  search(word: string) {
    const search: Filter = {
      Name: CONSTANTS.searchFilterName,
      Games: [],
      Order: 0,
      type: CONSTANTS.filter,
      typeCategory: '',
      grid: CONSTANTS.detail,
      icon: '',
      FilterIcon: '',
      FilterUrlIcon: '',
      DYConfig: ''
    };
    if (word.length > 0) {
      Object.keys(this.games).forEach((id) => {
        if (this.games[id].Name.toLowerCase().indexOf(word.toLowerCase()) >= 0) {
          const game = this.games[id];
          game.GameId = id;
          game.gameTileSize = game.DefaultTileSize ? game.DefaultTileSize : CONSTANTS.square;
          game.imageType = game.DefaultImageType;
          search.Games.push(game);
        }
      });
    }
    this.lobbyState$.next(search);
  }

  get filtersList() {
    const filters = this.filters.map((filter) => {
      if (filter.Name === this.globalVars.FEATURES.casinoCalendarName) {
        return {
          label: filter.Name,
          selected: false,
          img: 'assets/global/img/calendarNew.svg'
        };
      } else if (filter.FilterUrlIcon) {
        return {
          label: filter.Name,
          selected: false,
          img: filter.FilterUrlIcon
        };
      } else {
        return {
          label: filter.Name,
          selected: false,
          icon: filter.FilterIcon ? filter.FilterIcon : CONSTANTS.slots
        };
      }
    });
    filters.unshift({
      label: CONSTANTS.all,
      selected: true,
      icon: 'home'
    });

    return filters;
  }

  get lobbyState() {
    return this.lobbyState$.asObservable();
  }

  getlobbyName(lobby) {
    const lobbies = this.globalVars.FEATURES.Lobbies;

    lobbies.forEach((ele) => {
      if (!ele.lobbyUrl) {
        ele.lobbyUrl = ele.LobbyTitle.replace(/ /g, '');
      }
    });
    return lobby === CONSTANTS.jackpot
      ? CONSTANTS.jackpotPage
      : lobbies.find((lb) => lb.LobbyName === lobby).lobbyUrl + 'Page';
  }

  getLobbyFromUrl() {
    const lobbies = this.globalVars.FEATURES.Lobbies;
    lobbies.forEach((ele) => {
      if (!ele.lobbyUrl) {
        ele.lobbyUrl = ele.LobbyTitle.replace(/ /g, '');
      }
    });
    let route = 'LobbyCasino';
    if (window.location.hash) route = window.location.hash.split('/')[1].replace('Page', '');

    //Coming from master branch
    const paramsUrl = route.indexOf('?');
    if (paramsUrl !== -1) {
      route = route.substring(0, paramsUrl);
    }
    let defaultLobby = lobbies.find((lobby) => lobby.LobbyTitle === CONSTANTS.casinoLobbyName);
    defaultLobby = defaultLobby !== undefined ? defaultLobby.LobbyName : null;
    const foundLobby = lobbies.find((lobby) => lobby.LobbyTitle.replace(/ /g, '') === route);
    // eslint-disable-next-line no-nested-ternary
    return route === CONSTANTS.Jackpot ? CONSTANTS.jackpot : foundLobby ? foundLobby.LobbyName : defaultLobby;
  }

  getIsSingleLobby(lobby) {
    const lobbyC = this.globalVars.FEATURES.Lobbies.find((lb) => lb.LobbyName === lobby);
    // eslint-disable-next-line no-nested-ternary
    return lobbyC === undefined ? false : !!lobbyC.SingleGameLobby;
  }

  getIsOmnichannel(lobby) {
    const isOmni = this.globalVars.FEATURES.Lobbies.find((lb) => lb.LobbyName === lobby);
    //eslint-disable-next-line
    return isOmni === undefined ? false : isOmni.LobbyName == CONSTANTS.omnichannel ? true : false;
  }

  getCurrentLobby() {
    return this.currentLobby;
  }

  getLobbyBanner(lobby) {
    const lobbies = this.globalVars.FEATURES.Lobbies;
    //** Equipo migracion */
    // se modifica el nombre de la variable 'lb' dentro de la funcion 'find()' por 'lobbyItem'
    // ya que esto puede causar conflictos y confusiones en el alcance de las variables.
    //** */
    const lb = lobbies.find((lobbyItem) => lobbyItem.LobbyName === lobby);
    if (
      lb.BannerMobile === '-- missing --' ||
      lb.BannerPC === '-- missing --' ||
      lb.BannerMobile === undefined ||
      lb.BannerPC === undefined
    ) {
      return this.isMobile ? lobby : lobby + 'Pc';
    } else {
      return this.isMobile ? lb.BannerMobile : lb.BannerPC;
    }
  }

  async getInitialData() {
    const url =
      this.globalVars.URLBASE_REPORTS +
      CONSTANTS.lobbiesDataUrl +
      this.isMobile +
      '&mirror=' +
      this.globalVars.isMirror;

    const { ActiveGames, Lobbies, InactiveGames, Providers } = await this.myGet(url).toPromise();

    if (this.globalVars.licenseType === LicenseTypes.Nacional) {
      const initialLiveData = await this.initialLiveData();

      Object.keys(ActiveGames).forEach((gameId) => {
        const liveData = initialLiveData[gameId];
        //eslint-disable-next-line
        ActiveGames[gameId].GameId = gameId;
        ActiveGames[gameId].live = liveData != null;
        if (ActiveGames[gameId].live) {
          ActiveGames[gameId].additionalData = new BehaviorSubject<any>(liveData);
        } else {
          ActiveGames[gameId].additionalData = new BehaviorSubject<any>({});
        }
      });
    } else {
      Object.keys(ActiveGames).forEach((gameId) => {
        ActiveGames[gameId].GameId = gameId;
        ActiveGames[gameId].additionalData = new BehaviorSubject<any>({});
      });
    }

    this.games = ActiveGames;
    Object.keys(this.games).forEach((gameId) => {
      this.games[gameId].lobbyType = this.getLobbyType(this.games[gameId]);
    });

    if (InactiveGames) {
      Object.keys(InactiveGames).forEach((gameId) => {
        InactiveGames[gameId] = this.mapInactiveGame(gameId, InactiveGames);
      });
      this.inactiveGames = InactiveGames;
    }

    let calendar;
    if (this.globalVars.FEATURES.CasinoCalendarEnabled) {
      calendar = await this.checkCalendar();
    }

    let moreFilterMenu;
    if (this.globalVars.FEATURES.CAS_MoreFilters || this.globalVars.isMirror) {
      moreFilterMenu = this.createMoreFilterMenu();
      this.initFilters(Providers);
    }

    Object.keys(Lobbies).forEach((lb) => {
      Lobbies[lb].Categories = this.map(Lobbies[lb].Categories, CONSTANTS.category, CONSTANTS.detail);
      Lobbies[lb].Filters = this.map(Lobbies[lb].Filters, CONSTANTS.filter, CONSTANTS.detail);
      if (moreFilterMenu) {
        Lobbies[lb].Filters.push(moreFilterMenu);
      }
      if (calendar) {
        Lobbies[lb].Filters.push(calendar);
      }
    });

    this.lobbies = Lobbies;
  }

  private createMoreFilterMenu(): Filter {
    return {
      FilterIcon: 'options-outline',
      Name: this.translate.instant('casinoMoreFilters'),
      Order: 199,
      grid: CONSTANTS.detail
    };
  }

  private initFilters(providers: string[]): void {
    const url = 'assets/data/casinoMoreFilters/casinoMoreFilters.json';
    this.httpClient.get(url).subscribe((data) => {
      this.moreFilters = data as unknown as CasinoFilterGroup[];
      this.addProviderFilters(providers);
      this.countFilters();
    });
  }

  public getMoreFilters(): CasinoFilterGroup[] {
    return JSON.parse(JSON.stringify(this.moreFilters));
  }

  private setMoreFilters(filters: CasinoFilterGroup[]): void {
    this.moreFilters = filters;
  }

  private addProviderFilters(providers: string[]): void {
    const providerFilters: CasinoFilter[] = [];
    providers.forEach((provider: string) => {
      const providerFilter: CasinoFilter = {
        id: provider,
        text: provider,
        image: `${this.globalVars.PROVIDER_IMAGES}${provider}.png`
      };
      providerFilters.push(providerFilter);
    });
    const providerFilterGroup = this.moreFilters?.filter((moreFilter) => moreFilter.gameParam === 'RealProvider')[0];
    providerFilterGroup.filters = providerFilters;
  }

  private countFilters(): void {
    this.moreFilters?.forEach((filterGroup: CasinoFilterGroup) => {
      const param = filterGroup.gameParam;
      filterGroup.filters.forEach((casinoFilter: CasinoFilter) => {
        let counter = 0;
        for (const gameId in this.games) {
          const gameParam = this.games[gameId][param];
          if (this.isFilterOnGame(gameParam, casinoFilter)) {
            counter++;
          }
        }
        casinoFilter.count = counter;
      });
      if (filterGroup?.gameParam === 'RealProvider') {
        filterGroup?.filters?.sort((filterA, filterB) => filterB?.count - filterA?.count);
      }
    });
  }

  public unapplyMoreFilters(setTodos: boolean): void {
    this.moreFilters?.forEach((filterGroup: CasinoFilterGroup) => {
      filterGroup.filters.forEach((casinoFilter: CasinoFilter) => {
        casinoFilter.selected = false;
      });
    });
    if (setTodos) {
      this.setFilter('Todos');
    }
    this.moreFiltersApplied.next([]);
  }

  public unapplyMoreFilter(casinoFilter: CasinoFilter) {
    this.moreFilters?.forEach((filterGroup: CasinoFilterGroup) => {
      const unappliedFilter = filterGroup.filters.filter((filter) => filter.id === casinoFilter.id)[0];
      if (unappliedFilter) {
        unappliedFilter.selected = false;
      }
    });
    const data = {
      deleteFilters: this.moreFiltersApplied.value.length <= 1,
      filters: this.moreFilters
    };
    this.applyFilters(data, this.casinoFilter);
  }

  private applyFilters(data: any, filter: Filter): void {
    if (data.deleteFilters || !this.areMoreFiltersApplied(data.filters)) {
      this.unapplyMoreFilters(true);
    } else if (data.filters) {
      this.setMoreFilters(data.filters);
      filter.Games = this.filteredGames();
      filter.grid = 'detail';
      this.lobbyState$.next(filter);
    }
  }

  private filteredGames(): Game[] {
    const moreFiltersApplied = [];
    let games: Game[] = this.games;
    this.moreFilters?.forEach((filterGroup: CasinoFilterGroup) => {
      const param = filterGroup.gameParam;
      const appliedFilters = filterGroup.filters.filter((filter) => filter.selected);
      if (appliedFilters.length) {
        const gamesFiltered: Game[] = [];
        appliedFilters.forEach((casinoFilter: CasinoFilter) => {
          casinoFilter.filterGroupTitle = filterGroup.title;
          moreFiltersApplied.push(casinoFilter);
          for (const gameId in games) {
            const game = games[gameId];
            const gameParam = game[param];
            if (this.isFilterOnGame(gameParam, casinoFilter)) {
              game.gameTileSize = game.DefaultTileSize ? game.DefaultTileSize : CONSTANTS.square;
              game.imageType = game.DefaultImageType;
              gamesFiltered.push(game);
            }
          }
        });
        games = gamesFiltered;
      }
    });
    this.moreFiltersApplied.next(moreFiltersApplied);
    return games;
  }

  areMoreFiltersApplied(data: CasinoFilterGroup[]): boolean {
    const moreFiltersApplied = [];
    data.forEach((filterGroup: CasinoFilterGroup) => {
      const appliedFilters = filterGroup.filters.filter((filter) => filter.selected);
      if (appliedFilters.length) {
        appliedFilters.forEach((casinoFilter: CasinoFilter) => {
          moreFiltersApplied.push(casinoFilter);
        });
      }
    });
    return moreFiltersApplied?.length > 0;
  }

  private isFilterOnGame(gameParam, casinoFilter: CasinoFilter): boolean {
    return (
      gameParam == casinoFilter.id ||
      this.isOnString(gameParam, casinoFilter) ||
      this.isOnRange(gameParam, casinoFilter)
    );
  }

  private isOnString(gameParam, casinoFilter: CasinoFilter): boolean {
    return typeof gameParam[0] === 'string' && gameParam[0].includes(casinoFilter.id);
  }

  private isOnRange(gameParam, casinoFilter: CasinoFilter): boolean {
    return casinoFilter.minValue <= gameParam && gameParam < casinoFilter.maxValue;
  }

  private async getJackpotData(lobby) {
    const url = this.globalVars.LOBBY_JACKPOTS_TICKERS + CONSTANTS.lobbyJackpotUrl;
    const jackpots = await lastValueFrom(this.myGet(url));

    if (jackpots && jackpots.length > 0) {
      this.jackpots = {
        Name: CONSTANTS.jackpot,
        jackpots: this.mapJackpot(jackpots, lobby)
      };
    }
  }

  private async setCurrentLobbyData(lobby) {
    this.currentLobby = lobby;
    const lobbyC = this.globalVars.FEATURES.Lobbies.find((lb) => lb.LobbyName === lobby);
    if (lobby === CONSTANTS.jackpot) {
      this.lobbyState$.next(this.jackpots.jackpots);
    } else if (lobbyC.SingleGameLobby) {
      const selectedLobby = this.lobbies[lobby];
      const obj = {
        game: selectedLobby.Categories[0].Games[0]
      };
      obj.game.isIframe = true;
      obj.game.lobby = lobbyC.LobbyName;
      if (lobbyC.LobbyTitle === 'Aviator') {
        obj.game.srcImg = this.isDesktop ? CONSTANTS.aviatorImgPc : CONSTANTS.aviatorImgMobile;
      } else {
        obj.game.srcImg = lobbyC.Img;
      }
      this.infoSingleLobby = obj;
      this.lobbyState$.next(this.infoSingleLobby);
    } else {
      this.slotsBanners = [];
      await this.setBanners(lobby);
      let selectedLobby = this.lobbies[lobby];

      if (selectedLobby === undefined) {
        const object = {
          Categories: [],
          Filters: []
        };
        this.lobbies[lobby] = object;
        selectedLobby = this.lobbies[lobby];
      }

      this.categories = selectedLobby.Categories;
      this.filters = selectedLobby.Filters;
      this.getRpg(lobby);
      this.lobbyState$.next(this.categories);
    }
  }

  private async checkCalendar() {
    let newFilter;
    const filtered = [];
    const dateNow = new Date().getTime();

    const CalendarSections = await this.getCalendarInfo();

    if (CalendarSections && !CalendarSections.HasError) {
      if (CalendarSections.Data) {
        CalendarSections.Data.forEach((element) => {
          const dateIni = new Date(element.startDate).getTime();
          const dateFin = new Date(element.endDate).getTime();
          if (dateIni <= dateNow && dateFin >= dateNow && element.games) {
            filtered.push(element);
          }
        });

        //Order
        filtered.sort(function (a, b) {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          return 0;
        });

        if (filtered.length > 0) {
          newFilter = {
            DYConfig: null,
            FilterIcon: 'calendar',
            Games: [],
            Name: this.globalVars.FEATURES.casinoCalendarName,
            Order: 200,
            grid: '',
            type: '',
            icon: '',
            data: filtered
          };
        }
      }
    }
    return newFilter;
  }

  public async getCalendarInfo() {
    return this.slotsService.getCalendarInfo();
  }

  private async subscribeToSignalR(lobby) {
    const isLobbySingle = this.getIsSingleLobby(lobby);
    if (!isLobbySingle) {
      if (lobby.toLowerCase().indexOf(CONSTANTS.playtech) >= 0 && this.globalVars.LIVEGAMEDATA) {
        await this.signalRService.startConnection(CONSTANTS.liveGames);
        this.signalRService.addDataListener(CONSTANTS.rouletteRound, CONSTANTS.liveGames);
        this.signalRService.addDataListener(CONSTANTS.roulettePlayerCount, CONSTANTS.liveGames);
      }
      if (this.globalVars.FEATURES.SignalRCasinoJackpots) {
        await this.signalRService.startConnection(CONSTANTS.jackpot);
        this.signalRService.addDataListener(CONSTANTS.jackpotTicker, CONSTANTS.jackpot);
      }

      this.broadcasterSubscription = this.signalRService.broadcaster.subscribe((data) => {
        switch (data.type) {
          case CONSTANTS.rouletteRound:
            this.addAdditionalData(data, CONSTANTS.roundField);
            break;
          case CONSTANTS.roulettePlayerCount:
            this.addAdditionalData(data, CONSTANTS.countField);
            break;
          case CONSTANTS.jackpotTicker:
            this.updateJackpots(data.payload);
            break;
        }
      });
    }
  }

  public getRpg(selectedLobby, force?) {
    if (selectedLobby === 'fromLobby') {
      selectedLobby = this.currentLobby;
    }
    const isLobbySingle = this.getIsSingleLobby(selectedLobby);
    if (selectedLobby !== 'jackpot' && !isLobbySingle) {
      if (this.globalVars.user.logged && !force) {
        const url = this.globalVars.RECENTGAMES + this.isMobile;

        this.myGet(url, true, (err: any, caught: Observable<any>): any => {
          this.logService.error(err);
        }).subscribe((games) => {
          if (games) {
            const mappedGames = games
              .map((gameId) => this.mapGame(gameId, '', selectedLobby))
              //eslint-disable-next-line
              .filter((game) => game != null);
            Object.keys(this.lobbies).forEach((lb) => {
              const rpgCategory = this.lobbies[lb].Categories.find(
                (category) => category.CustomizedService === CONSTANTS.rpg
              );
              // SI rpgCategory ES POSICION 0 mainCategory true en posicion 0 y si la posicion 1 estaba a true ponerlo a false
              const index = this.lobbies[lb].Categories.findIndex(
                (category) => category.CustomizedService === CONSTANTS.rpg
              );
              if (index === 0) {
                rpgCategory.mainCategory = true;
                this.lobbies[lb].Categories[1].mainCategory = false;
              }
              if (rpgCategory) {
                rpgCategory.Games = mappedGames;
                rpgCategory.hide = false;
                //comprobar que hay mas de 3 juegos o no mostrar RPG
                if (rpgCategory.Games.length < 3) {
                  rpgCategory.hide = true;
                }
              }
            });
            this.lobbyState$.next(this.lobbies[selectedLobby].Categories);
          }
        });
      } else {
        Object.keys(this.lobbies).forEach((lb) => {
          const rpgCategory = this.lobbies[lb].Categories.find(
            (category) => category.CustomizedService === CONSTANTS.rpg
          );
          // SI rpgCategory ES POSICION 0 mainCategory true pasa a la posicion 1 y mainCategory en la posicion 0 es false
          const index = this.lobbies[lb].Categories.findIndex(
            (category) => category.CustomizedService === CONSTANTS.rpg
          );
          if (index === 0) {
            rpgCategory.mainCategory = false;
            this.lobbies[lb].Categories[1].mainCategory = true;
          }
          if (rpgCategory) {
            rpgCategory.hide = true;
          }
          this.lobbyState$.next(this.lobbies[selectedLobby].Categories);
        });
      }
    }
  }

  private getDy(selectedLobby) {
    if (this.globalVars.FEATURES.DynamicYieldRecommendationEnabled) {
      const actualCategories = this.lobbies[selectedLobby].Categories;
      actualCategories.forEach((cat, index) => {
        if (cat.CustomizedService === CONSTANTS.dy) {
          let url = this.globalVars.DYNAMICYIELD + this.isMobile + CONSTANTS.lobbyParam + selectedLobby;
          if (cat.DySelector) {
            url += CONSTANTS.dyParam + cat.DySelector;
          }
          if (cat.Ranking) {
            const numberGet = cat.mainCategory && this.isDesktop ? 11 : 10;
            url += CONSTANTS.dyMaxnumber + numberGet;
          }
          this.myGet(url, true, (err: any, caught: Observable<any>): any => {
            this.logService.error(err);
          }).subscribe((data) => {
            if (data.RecommendedGames) {
              const mappedGames = data.RecommendedGames.map((game) =>
                this.mapGame(game.GameId, game.SlotId, selectedLobby)
              ).filter((game) => game != null);
              if (mappedGames.length > 0) {
                cat.hide = false;
              }
              if (cat.Ranking && mappedGames.length > 0) {
                mappedGames.forEach((game, index2) => {
                  game.numberRanking = index2 + 1;
                });
              }
              cat.Games = mappedGames;
              if (cat.Name.includes('??')) {
                const gameName = mappedGames[0].Name;
                const newName = cat.Name.replace('??', gameName);
                this.lobbies[selectedLobby].Categories[index].Name = newName;
              }
              this.lobbyState$.next(this.lobbies[selectedLobby].Categories);
            }
          });
        }
      });
    }
  }

  private mapGame(id, dyId?, lobby?) {
    let gameMap = { ...this.games[id] };
    if (Object.keys(gameMap).length === 0) {
      gameMap = null;
    } else {
      gameMap.gameTileSize = gameMap.DefaultTileSize ? gameMap.DefaultTileSize : CONSTANTS.square;
      gameMap.imageType = gameMap.DefaultImageType;
      gameMap.GameId = id;
      gameMap.lobbyType = this.getLobbyType(gameMap);
      gameMap.product = 'Casino';

      if (gameMap.live) {
        gameMap.additionalData = gameMap.additionalData.asObservable();
      }
      if (dyId) {
        gameMap.dyId = dyId;
        gameMap.GameCategoryName = CONSTANTS.dy;
      } else {
        gameMap.GameCategoryName = CONSTANTS.rpg;
      }
    }
    return gameMap;
  }
  private mapInactiveGame(id, allGames) {
    let gameMap = { ...allGames[id] };
    if (Object.keys(gameMap).length === 0) {
      gameMap = null;
    } else {
      gameMap.gameTileSize = gameMap.DefaultTileSize ? gameMap.DefaultTileSize : CONSTANTS.square;
      gameMap.imageType = gameMap.DefaultImageType;
      gameMap.GameId = id;
      gameMap.product = 'Casino';
      gameMap.GameCategoryName = CONSTANTS.rpg;
    }
    return gameMap;
  }

  private map(array, type, grid, lobby?) {
    return array.map((item, i) => {
      item.mainCategory = i === 0;
      if (item.JackpotId !== '' && item.JackpotId !== null && item.JackpotId !== undefined) {
        item.additionalData = new BehaviorSubject<any>({});
      }

      if (Array.isArray(item.Games)) {
        item.Games.forEach((game, index) => {
          let gameMap = { ...this.games[game.GameId] };
          if (Object.keys(gameMap).length === 0) {
            gameMap = null;
          } else {
            gameMap.gameTileSize = type === CONSTANTS.filter ? CONSTANTS.square : game.GameTileSize;
            gameMap.imageType = game.ImageType;
            gameMap.GameId = game.GameId;
            gameMap.GameCategoryName = item.Name + ' ' + type;
            gameMap.product = 'Casino';
            gameMap.lobbyType = this.getLobbyType(gameMap);
            if (this.globalVars.FEATURES.CasinoLiveGameImageTypeUrl && game.StreamName) {
              let url = this.globalVars.FEATURES.CasinoLiveGameImageTypeUrl;
              url = url.replace('{0}', game.StreamName);
              gameMap.streamUrl = url;
            } else {
              gameMap.streamUrl = null;
            }
            if (gameMap.live) {
              gameMap.additionalData = gameMap.additionalData.asObservable();
            }
            item.Games[index] = gameMap;
          }
        });
        //eslint-disable-next-line
        item.Games.filter((game) => game != null);
      }
      item.type = type;
      item.grid = grid;
      item.typeCategory = item.CategoryType;
      if (item.Ranking && item.Games.length > 0) {
        item.Games.forEach((game, index) => {
          game.numberRanking = index + 1;
        });
      }
      if (item.Games.length === 0) {
        item.hide = true;
      }
      return item;
    });
  }

  private mapJackpot(array, lobby) {
    return array.map((item) => {
      const newGames = [];
      const highNumber = this.highAmount(item.jackpotInfo.currentAmounts);
      const timer = item.jackpotInfo.secondsBetweenUpdates || 600;
      item.jackpotInfo.games.forEach((game) => {
        const gameMap = { ...this.games[game.gameId] };
        if (Object.keys(gameMap).length === 0) {
          game = null;
        } else {
          gameMap.gameTileSize = gameMap.DefaultTileSize ? gameMap.DefaultTileSize : CONSTANTS.square;
          gameMap.GameId = game.gameId;
          gameMap.lobbyType = gameMap.lobbyType ? gameMap.lobbyType : this.getLobbyType(gameMap);
          gameMap.product = 'Casino';
          gameMap.streamUrl = null;

          newGames.push(gameMap);
          // initial jackpots count
          this.updateGamesForJackpots(gameMap.GameId, highNumber, timer);
        }
      });
      item.jackpotInfo.games = newGames;
      if (item.jackpotInfo.currentAmounts) {
        const length = item.jackpotInfo.currentAmounts.length;
        for (let i = 0; i < length; i++) {
          item.jackpotInfo.currentAmounts[i] = new BehaviorSubject<number>(item.jackpotInfo.currentAmounts[i]);
        }
      }
      this.updateCategoriesForJackpots(item.jackpotInfo.jackpotId, highNumber, timer);
      return item;
    });
  }

  private getLobbyType(game: Game) {
    let type = '';
    switch (game.GameType) {
      case CONSTANTS.roulettePc:
      case CONSTANTS.rouletteMobile:
      case CONSTANTS.blackJackPc:
      case CONSTANTS.blackJackMobile:
      case CONSTANTS.tablePc:
      case CONSTANTS.tableMobile:
        type = CONSTANTS.casinoLobbyName;
        break;
      default:
        type = CONSTANTS.slotsLobbyName;
        break;
    }
    return type;
  }

  public async loadGameByURL(name: string) {
    let lobbyGame;
    Object.keys(this.games).forEach((id) => {
      if (this.games[id].Name.toLowerCase() === name.toLowerCase()) {
        lobbyGame = this.games[id];
      }
    });
    if (lobbyGame) {
      return lobbyGame;
    }
  }

  private async setBanners(lobby) {
    let data;
    if (this.globalVars.FEATURES.NewCarouselActivated) {
      data = await this.userServiceMSO.getNewBanners(this.getLobbyBanner(lobby)).catch(() => {
        console.error('DONT SHOW ANY BANNER');
        console.error('BANNERS ERROR: ' + lobby + ' missing info for this lobby banners');
      });
    } else {
      data = await this.userServiceMSO
        .getBanners(this.getLobbyBanner(lobby))
        .toPromise()
        .catch(() => {
          console.error('DONT SHOW ANY BANNER');
          console.error('BANNERS ERROR: ' + lobby + ' missing info for this lobby banners');
        });
    }
    if (data) {
      this.slotsBanners = data;
    } else {
      this.slotsBanners = [];
    }
  }

  private async initialLiveData() {
    const data = await this.slotsService
      .getLiveCasino(this.isMobile)
      .toPromise()
      .catch(() => {
        console.error('error service live casino');
      });
    const result = {};
    if (data && data.roulettes) {
      const roulettes = data.roulettes;
      roulettes.forEach((game) => {
        result[game.codereGameId] = {
          minLimit: game.minLimit,
          maxLimit: game.maxLimit,
          PlayerCount: game.playersInTable
        };
      });
    }
    return result;
  }

  private addAdditionalData(data, field) {
    const gameData = data.payload;
    const gameId = this.isMobile ? gameData.MobileGameId : gameData.PcGameId;
    const lastGameData = this.games[gameId].additionalData.value;
    lastGameData[field] = gameData[field];
    lastGameData.timeStamp = gameData.UnixUtcTimeStamp;
    if (field !== 'LastNumbers') {
      lastGameData.replaceNumbers = false;
    } else {
      lastGameData.replaceNumbers = true;
    }
    this.games[gameId].additionalData.next(lastGameData);
  }

  private updateJackpots(data) {
    if (this.jackpots && this.jackpots.jackpots.length > 0) {
      this.jackpots.jackpots.forEach((jp) => {
        if (jp.jackpotInfo.jackpotId === data.JackpotId) {
          let highNumber;
          if (Array.isArray(data.CurrentAmounts)) {
            if (jp.jackpotInfo.currentAmounts) {
              if (jp.jackpotInfo.currentAmounts.length > 1) {
                jp.jackpotInfo.currentAmounts[0].next(data.CurrentAmounts[0]);
                jp.jackpotInfo.currentAmounts[1].next(data.CurrentAmounts[1]);
              } else {
                jp.jackpotInfo.currentAmounts[0].next(data.CurrentAmounts[0]);
              }
            }
            highNumber = this.highAmount(data.CurrentAmounts);
          }
          this.updateCategoriesForJackpots(data.JackpotId, highNumber, jp.jackpotInfo.secondsBetweenUpdates || 600);
          jp.jackpotInfo.games.forEach((game) => {
            this.updateGamesForJackpots(game.GameId ? game.GameId : game.gameId, highNumber);
          });
        }
      });
    }
  }

  private updateGamesForJackpots(id, value, timer?) {
    const game = this.games[id];
    if (game) {
      const data = {
        ...game.additionalData.value,
        ...(timer && { timer }),
        jp: value
      };
      game.additionalData.next(data);
    }
  }

  private updateCategoriesForJackpots(id, value, timer?) {
    Object.keys(this.lobbies).forEach((lb) => {
      const cat = this.lobbies[lb].Categories.find((cate) => cate.JackpotId === id);
      if (cat) {
        const data = {
          ...cat.additionalData.value,
          ...(timer && { timer }),
          jp: value
        };
        cat.additionalData.next(data);
      }
    });
  }

  private highAmount(arrayNumbers) {
    const max = Math.max(...arrayNumbers);
    return max;
  }

  public checkBalance() {
    if (this.globalVars.user.logged) {
      if (
        this.balancesService.inCasino() ||
        this.balancesService.inSlotsBonus() ||
        this.balancesService.inJackpot() ||
        this.balancesService.inCasinoPanama()
      ) {
        this.balancesService.refreshBalance();
      }
    }
  }

  async destroyBroadcaster() {
    if (this.broadcasterSubscription) {
      this.broadcasterSubscription.unsubscribe();
    }
  }

  getGames() {
    return this.getGamesFromOutCasino();
  }

  async getInactiveGames() {
    if (this.inactiveGames) {
      return this.inactiveGames;
    } else {
      const url =
        this.globalVars.URLBASE_REPORTS +
        CONSTANTS.lobbiesDataUrl +
        this.isMobile +
        '&mirror=' +
        this.globalVars.isMirror;
      const { InactiveGames } = await lastValueFrom(this.myGet(url));
      Object.keys(InactiveGames).forEach((gameId) => {
        InactiveGames[gameId] = this.mapInactiveGame(gameId, InactiveGames);
      });
      this.inactiveGames = InactiveGames;
      return this.inactiveGames;
    }
  }

  async getGamesFromOutCasino() {
    if (this.games) {
      return this.games;
    } else {
      let returnGames = null;
      const url =
        this.globalVars.URLBASE_REPORTS +
        CONSTANTS.lobbiesDataUrl +
        this.isMobile +
        '&mirror=' +
        this.globalVars.isMirror;
      const { ActiveGames } = await lastValueFrom(this.myGet(url));
      returnGames = ActiveGames;
      Object.keys(returnGames).forEach((gameId) => {
        returnGames[gameId].lobbyType = this.getLobbyType(returnGames[gameId]);
      });
      return returnGames;
    }
  }

  getPlatform(): string {
    return this.slotsService.getPlatform();
  }
}
