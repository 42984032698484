import { C_Game } from './C_Game';
import { DisplayType } from './sportbook/display-tipe.enum';
import { SixPack } from './sportbook/sixpack.model';
import { Participant } from './C_Participant';
import { LiveHistory } from './C_LiveHistory';

// models/C_Event.ts
export class C_Event {
  ChildrenCount: number;
  CountryCode?: string;
  Games: Array<C_Game>;
  LiveHistory: Array<LiveHistory>;
  LeagueName: string;
  Locked?: boolean;
  ParentNodeId: string;
  Participants: Array<Participant>;
  Priority: any;
  SmartMarketReferenceGameTypeIds: string;
  SportHandle: string;
  SportName?: string;
  StartDate: string;
  StatisticsId: string;
  StreamingEnabled: string;
  detailScoreboard?: string;
  isLive: boolean;
  isSixPack?: boolean;
  isSpecial?: boolean;
  LTMEnabled?: boolean;
  mode?: string = 'teams';
  Name: string;
  NodeId: string;
  overviewScoreboard?: string;
  pitcherAway?: string;
  pitcherHome?: string;
  scoreboardType: string; // remove
  sixPack?: any;
  teamAway: string;
  teamHome: string;
  liveData?: any;
  constructor(
    games?: Array<C_Game>,
    leagueName?: string,
    startDate?: string,
    isLive?: boolean,
    statisticsId?: string,
    streamingEnabled?: string,
    name?: string,
    nodeId?: string,
    parentNodeId?: string,
    priority?: any,
    sporthandle?: string,
    childrenCount?: number,
    isSixPack?: boolean,
    isSpecial?: boolean,
    Locked?: boolean,
    sixPack?: any,
    LTMEnabled?: boolean
  ) {
    this.Games = games;
    this.LeagueName = leagueName;
    this.StartDate = startDate;
    this.isLive = isLive;
    this.StatisticsId = statisticsId;
    this.StreamingEnabled = streamingEnabled;
    this.Name = name;
    this.NodeId = nodeId;
    this.ParentNodeId = parentNodeId;
    this.Priority = priority;
    this.SportHandle = sporthandle;
    this.ChildrenCount = childrenCount;
    this.isSixPack = isSixPack;
    this.Locked = Locked;
    this.sixPack = sixPack;
    this.LTMEnabled = LTMEnabled;
  }

  static parse(obj: any): C_Event {
    if (obj != undefined && obj != null) {
      const event = Object.assign(new C_Event(), obj);
      event.Name = event.Name ? event.Name.replace(/%20/g, ' ') : undefined;
      event.LeagueName = event.LeagueName ? event.LeagueName.replace(/%20/g, ' ') : undefined;
      event.Games = event && event.Games ? event.Games.filter(Boolean) : [];
      event.setHomeAndAway();
      event.setScoreboardType();
      event.setDetailScoreboardType();
      event.setLockedResults();
      event.setMode();
      return event;
    } else {
      return undefined;
    }
  }

  //Todo refactor
  public setMode() {
    this.Games = this.Games.filter(Boolean);
    this.mode =
      (this.Games.length && this.Games[0] && this.Games[0].Results && this.Games[0].Results.length > 3) ||
      (this.Games.length && this.Games[0].DisplayTypeName === DisplayType.OUTRIGHTLIST)
        ? 'expanded'
        : 'teams';
  }

  public setHomeAndAway() {
    if (!this.Name) {
      return;
    }
    const regexCleanPitcherName = /\S [A-Za-z]+/g;
    const regex = new RegExp(/\((.*?)\)/, 'g');

    if (this.Name.match(regex) && this.SportHandle === 'baseball') {
      [this.pitcherHome, this.pitcherAway] = this.Name.match(regex);
      if (this.pitcherHome && this.pitcherAway) {
        this.Name = this.Name.replace(this.pitcherHome, '').replace(this.pitcherAway, '');
        this.pitcherHome = this.pitcherHome.match(regexCleanPitcherName)
          ? this.pitcherHome.substring(1, this.pitcherHome.length - 1).replace('.', '')
          : undefined;
        this.pitcherAway = this.pitcherAway.match(regexCleanPitcherName)
          ? this.pitcherAway.substring(1, this.pitcherAway.length - 1).replace('.', '')
          : undefined;
      }
    }

    //delete last character whitespace
    const setNameLasChat: string = this.Name.slice(-1);
    if (setNameLasChat.match(/^\s*$/g)) this.Name = this.Name.slice(0, -1);

    [this.teamHome, this.teamAway] = this.Name.split(' - ');
  }

  //TODO: we should remove parameters;
  public setScoreboardType(sport?: C_Event, isLive: boolean = false) {
    if (!this.isLive) {
      return '';
    }

    switch (this.SportHandle) {
      case 'american_football':
      case 'baseball':
      case 'basketball':
      case 'billard':
      case 'darts':
      case 'esports':
      case 'futsal':
      case 'golf':
      case 'handball':
      case 'ice_hockey':
      case 'motor':
      case 'rugby':
      case 'snooker':
      case 'soccer_indoor':
      case 'soccer':
      case 'ebasket':
      case 'efootball':
        this.overviewScoreboard = 'regular';
        break;
      case 'badminton':
      case 'beach_soccer':
      case 'beach_volleyball':
      case 'tennis':
      case 'table_tennis':
      case 'tennis_clay': //TODO
      case 'tennis_grass': //TODO
      case 'tennis_indoor': // TODO
      case 'volleyball':
        this.overviewScoreboard = 'sets';
        break;
      default:
        this.overviewScoreboard = '';
        break;
    }
  }

  //TODO: remove parameter
  public setDetailScoreboardType(sport?: C_Event) {
    if (!this.isLive) {
      return;
    }

    switch (this.SportHandle) {
      case '':
      case 'billard':
      case 'darts':
      case 'esports':
      case 'futsal':
      case 'golf':
      case 'handball':
      case 'motor':
      case 'rugby':
      case 'snooker':
        this.detailScoreboard = 'regular';
        break;
      case 'american_football':
      case 'badminton':
      case 'basketball':
      case 'beach_soccer':
      case 'beach_volleyball':
      case 'ebasket':
      case 'efootball':
      case 'eSports_sports':
      case 'Esports_sports':
      case 'ice_hockey':
      case 'soccer':
      case 'table_tennis':
      case 'tennis_clay': //TODO
      case 'tennis_grass': //TODO
      case 'tennis_indoor': // TODO
      case 'tennis':
      case 'volleyball':
        this.detailScoreboard = 'sets';
        break;
      case 'baseball':
        this.detailScoreboard = 'baseball';
        break;
      default:
        this.detailScoreboard = '';
        break;
    }
  }

  public setGamesByPlatform(gameTypes: number[], isMobile: boolean = true, hasCategory: boolean = false) {
    // -> este evento tiene todos los games pedidos como sport;
    if (this.isSpecial || hasCategory) {
      return;
    }
    this.filterGames(gameTypes, isMobile);
  }

  // NEED REVIEW REQUIREMENTS
  public hasSixPack(category: number = 0) {
    if (category && category > 0) {
      this.isSixPack = false;
      return this.isSixPack;
    }
    this.isSixPack = !this.isSpecial && !this.isLive && SixPack.validate(this.SportHandle, this.CountryCode);
    return this.isSixPack;
  }

  public hasSpecialGame(gameTypes: number[]) {
    this.isSpecial = this.Games && this.Games.length === 1 && gameTypes.indexOf(this.Games[0].GameType) !== -1;
    if (this.isSpecial) {
      this.mode = 'expanded';
    }
    return this.isSpecial;
  }

  setLockedResults() {
    if (this.Games) {
      return;
    }
    this.Games.forEach((game) => {
      if (!game) {
        return;
      }
      return game.Results.map((result) => (result.Locked = game.Locked));
    });
  }

  private filterGames(gameTypes: number[] = [], isMobile: boolean) {
    const games = [];
    gameTypes.forEach((gt) => {
      const index = this.Games.findIndex((g) => Boolean(g) && g.GameType === gt);
      if (index !== -1) {
        games.push(this.Games[index]);
        return;
      }

      if (isMobile) {
        return;
      }

      games.push(new C_Game([]));
    });

    const limit = isMobile && !this.isSixPack ? 0 : games.length - 1;

    this.Games = Object.assign(
      [],
      games.filter((_, index) => index <= limit)
    );
  }
}
