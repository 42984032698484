import { createReducer, on } from '@ngrx/store';
import * as SportsDataActions from '../actions/sportsData.actions';
import { homeDataModel, paisDataModel } from '../models/sportsData.model';

export interface sportsDataState {
  homeData: homeDataModel[];
  paisData: paisDataModel;
  eventData: any[];
  marketData: any[];
  liveData: any[];
  liveMarketData: any[];
  raceData: any[];
  raceMarketData: any[];
  todayData: any[];
  specialTeamData: any[];
  sportEventsData: any[];
}

export const initialState: sportsDataState = {
  homeData: [],
  paisData: {
    parentId: '',
    highlights: [],
    countries: []
  },
  eventData: [],
  marketData: [],
  liveData: [],
  liveMarketData: [],
  raceData: [],
  raceMarketData: [],
  todayData: [],
  specialTeamData: [],
  sportEventsData: []
};

export const sportsDataReducer = createReducer(
  initialState,
  on(SportsDataActions.dataPaisSports, (state, payload) => ({
    ...state,
    paisData: payload.paisData
  })),
  on(SportsDataActions.dataPaisSportsNodeId, (state, payload) => ({
    ...state,
    paisData: { ...state.paisData, parentId: payload.NodeId }
  }))
);
