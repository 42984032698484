export interface homeDataModel {
  marquee: any[];
  liveEvents: {
    LiveEventsCount: number;
    LiveSport: any[];
  };
  highlightsEvents: any[];
  betbuilderEvents: any[];
}

export interface paisDataModel {
  parentId: string;
  highlights: any[];
  countries: any[];
}

export class M_PaisData {
  parentId: string;
  highlights: any[];
  countries: any[];
  constructor(parentId, highlights, countries) {
    this.parentId = parentId;
    this.highlights = highlights;
    this.countries = countries;
  }
}
