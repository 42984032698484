/* eslint-disable eqeqeq */
import { Injectable, inject } from '@angular/core';

import { EMPTY, catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';

import * as SportsDataActions from '../actions/sportsData.actions';

import { NewBaseService } from '../../../../providers/newBase.service';
import { SportService } from '../../../../providers/SportService';

import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import * as models from '../../../../models/index';

@Injectable({ providedIn: 'root' })
export class SportsDataEffects {
  newBaseService = inject(NewBaseService);
  sportService = inject(SportService);
  globalVars!: GlobalVarsModel;

  constructor(private actions$: Actions) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  setHighlights(sportsData) {
    const highlights = sportsData['highlights'];
    //remove event 'mejora_la_linea'
    const index = highlights?.findIndex((myItem) => myItem.SportsHandle == 'mejora_la_linea', 0);
    if (index > -1) {
      highlights.splice(index, 1);
    }
    for (let i: number = highlights?.length - 1; i >= 0; i--) {
      if (this.globalVars.FEATURES.ForbiddenSports) {
        if (this.globalVars.FEATURES.ForbiddenSports.indexOf(highlights[i].SportsHandle) != -1) {
          highlights.splice(i, 1);
        }
      }
    }
    return highlights;
  }

  setHighlightsConfig(sportsData) {
    return sportsData['highlightsConfig'].map(
      (specialSport) =>
        new models.C_SpecialSports(
          specialSport.CountryCode,
          specialSport.IconUrl,
          specialSport.IdLeagues,
          specialSport.LeagueName,
          specialSport.SportHandle,
          specialSport.SportName
        )
    );
  }
}
