/* eslint-disable @angular-eslint/no-output-native*/
/* eslint-disable eqeqeq */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnChanges,
  ViewChildren,
  ViewChild,
  QueryList,
  inject
} from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';

import { NewTicketFacade } from '../../../models/sports/store/facades/ticket.facade';
import { ExtractDatePipe } from '../../../pipes/extractDate.pipe';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { TruncateStringPipe } from '../../../pipes/truncateString.pipe';
import { FormatOdd } from '../../../pipes/formatOdd.pipe';
import { DeviceService } from '../../../services/device.service';
import { TrackingService } from '../../../providers/TrackingService';
import { SportService } from '../../../providers/SportService';
import { CommonModule } from '@angular/common';
import { SbButtonComponent } from '../sb-button/sb-button';
import { SbOverviewScoreSetsComponent } from '../sb-overview-score-sets/sb-overview-score-sets';

import { Swiper } from 'swiper';

import { BetbuilderItem, BetbuilderItemMethods } from '../../../models';
import { Utils } from '../../../utils/Utils';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'sb-marquee-betbuilder',
  templateUrl: 'sb-marquee-betbuilder.html',
  standalone: true,
  imports: [
    CommonModule,
    SbButtonComponent,
    TruncateStringPipe,
    ExtractDatePipe,
    SbOverviewScoreSetsComponent,
    FormatOdd,
    TranslateModule
  ],
  styleUrls: ['./sb-marquee-betbuilder.scss']
})
export class SbMarqueeBetbuilderComponent implements OnChanges, AfterViewInit {
  @ViewChildren('list') list: QueryList<any>;
  @ViewChild('swiper') swiperContainer: ElementRef;
  @Input() betbuilderEventsData: BetbuilderItem[];
  @Input() switch: boolean;
  @Output() resize: EventEmitter<any> = new EventEmitter();

  public swiper: any;
  globalVars!: GlobalVarsModel;
  betbuilderItems: BetbuilderItem[] = [];

  _element = inject(ElementRef);
  _renderer = inject(Renderer2);
  extractDate = inject(ExtractDatePipe);
  nav = inject(NavController);
  trackingService = inject(TrackingService);
  newTicketFacade = inject(NewTicketFacade);
  sportService = inject(SportService);
  alertCtrl = inject(AlertController);

  isMobile: boolean = false;
  isDesktop: boolean = true;
  deviceService = inject(DeviceService);

  public utils = inject(Utils);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnChanges() {
    this.updateBetbuilderItems();
    if (!this.swiper) return;
    this.swiper.update();
  }

  ngAfterViewInit() {
    this.display().then((_) => this.resize.emit());

    if (this.switch) return;
    this.swiper = new Swiper(this.swiperContainer.nativeElement, {
      slidesPerView: 'auto',
      spaceBetween: 0,
      freeMode: true,
      direction: 'horizontal',
      grabCursor: true,
      observer: false,
      loop: false,
      noSwiping: this.switch,
      followFinger: !this.switch,
      simulateTouch: !this.switch,
      allowSlidePrev: !this.switch,
      allowSlideNext: !this.switch
    });
  }

  private display() {
    return new Promise((res, rej) => {
      let display;
      if (Array.isArray(this.betbuilderItems)) {
        display = this.betbuilderItems.length < 1 ? 'none' : 'block';
      }

      this._renderer.setStyle(this._element.nativeElement, 'display', display);
      this.resize.emit();

      if (this.swiper) {
        this.swiper.update();
      }
      res(null);
    });
  }

  updateBetbuilderItems() {
    this.betbuilderItems = this.betbuilderEventsData;
  }

  navigate(NodeId: string) {
    this.sportService.getGameByNodeId(NodeId).subscribe((data) => {
      if (!data) {
        this.showDismiss('', 'No hay mercados abiertos en este evento');
        return;
      }
      let nodeId = data.NodeId;
      let parentNodeId = data.ParentNodeId;
      this.sportService.deepLinkFromMarquee(parentNodeId, nodeId);
    });
  }

  async showDismiss(title: string, msg: string) {
    const alertOptions = {
      enableBackdropDismiss: false,
      title: title,
      message: msg,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: () => {
            //nothing, this is just a dismiss
          }
        }
      ]
    };
    const alert = await this.alertCtrl.create(alertOptions);
    alert.present();
  }

  bet(betbuilderItem: BetbuilderItem) {
    if (this.isSelected(betbuilderItem)) {
      let betbuilderItem = this.newTicketFacade.ticketView.SmartLineItems.find(
        (item) => item.BetBuilderId == betbuilderItem.BetBuilderId
      );
      this.newTicketFacade.removeSmartMarketItem(betbuilderItem.ItemId);
      return;
    }
    this.utils.loader();
    this.sportService.addBetbuilderItem(betbuilderItem.BetBuilderId).subscribe((resp) => {
      let smartMarketBet = BetbuilderItemMethods.createBetbuilderLineItem(betbuilderItem);
      delete resp['MaxSystem'];
      this.utils.closeLoader();
      this.newTicketFacade.sendAddSmartMarketItem(resp, smartMarketBet);
    });
  }

  isSelected(betbuilderItem: BetbuilderItem) {
    let ticket = this.newTicketFacade.ticketView;
    let betBuilderId = betbuilderItem.BetBuilderId;
    return ticket.SmartLineItems.some((item) => item.BetBuilderId == betBuilderId);
  }
}
