/* eslint-disable */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnChanges,
  ViewChildren,
  ViewChild,
  QueryList,
  inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { C_TicketApuestasNew, TicketMethods } from '../../../models/C_TicketApuestas';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TitleCasePipe } from '../../../pipes/titlecase/title-case.pipe';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { Swiper } from 'swiper';
import { DirectivesModule } from '../../../directives/directives.module';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'ticket-filter',
  templateUrl: './ticket-filter.html',
  styleUrls: ['./ticket-filter.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule, TitleCasePipe, DirectivesModule]
})
export class TicketFilterComponent implements OnChanges, AfterViewInit {
  @ViewChildren('list') list: QueryList<any>;
  @ViewChild('line') line: ElementRef;
  @ViewChild('swiper') swiperContainer: ElementRef;

  @Input() mode: string = 'grey';
  @Input() colorsOptions: any = {
    text: 'color-muted',
    background: 'background-color-regular',
    line: 'background-color-dark'
  };
  @Input() setFilterAvailable: boolean = true;
  @Input() filters: any[];
  @Input() switch: boolean;
  @Input() selected: any;
  @Input() keyboard: boolean = false;
  @Input() ticket: C_TicketApuestasNew;

  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Output() resize: EventEmitter<any> = new EventEmitter();
  private list$: Subscription;
  private ref: HTMLElement;
  private lineRef: HTMLElement;
  private listRef: HTMLElement;
  private filtersWidth: any;
  private listWidth: number;
  public swiper: any;

  globalVars: GlobalVarsModel;
  _element = inject(ElementRef);
  _renderer = inject(Renderer2);
  deviceService = inject(DeviceService);

  newBaseService = inject(NewBaseService);

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  ngOnChanges() {
    this.filters = JSON.parse(JSON.stringify(this.filters)); //<- bad solution? https://stackoverflow.com/a/58279719
    this.filters.forEach((filter) => {
      if (this.globalVars.FEATURES.SPO_ShowSystemTab != undefined) {
        if (!this.globalVars.FEATURES.SPO_ShowSystemTab) {
          // BAUSPO-1556
          if (filter.betType.toLowerCase() == 'system') {
            filter.hidden = true;
          }
          // end BAUSPO-1556
        } else {
          // BAUSPO-1556
          if (filter.betType.toLowerCase() == 'system') {
            filter.hidden = false;
          }
          // end BAUSPO-1556
        }
      }
    });
    if (this.selected && this.filters) {
      let index = this.filters.findIndex((filter) => filter.name === this.selected.name);
      index = index === -1 ? 0 : index;
      this._select(this.selected, index);
    }

    if (!this.swiper) return;
    this.swiper.update();
  }

  ngAfterViewInit() {
    this.ref = this._element.nativeElement;
    this.lineRef = this.line.nativeElement;
    this.listRef = this.list.first.nativeElement;
    this.setColorMode();

    this.list$ = this.list.changes.subscribe(() => {
      this.display();
      this.setFiltersWidth();
    });

    this.listWidth = this.list.first.nativeElement.scrollWidth;
    this.display().then((_) => this.resize.emit());

    if (this.switch) return;
    this.swiper = new Swiper(this.swiperContainer.nativeElement, {
      slidesPerView: 'auto',
      spaceBetween: 0,
      freeMode: true,
      direction: 'horizontal',
      grabCursor: true,
      observer: false,
      loop: false,
      noSwiping: this.switch,
      followFinger: !this.switch,
      simulateTouch: !this.switch,
      allowSlidePrev: !this.switch,
      allowSlideNext: !this.switch
    });
  }

  ngAfterContentChecked() {
    if (this.selected && this.filters) {
      let index = this.filters.findIndex((filter) => filter.name === this.selected.name);
      index = index === -1 ? 0 : index;
      this._select(this.selected, index);
    }
  }

  ngOnDestroy() {
    if (this.list$) {
      this.list$.unsubscribe();
    }
  }

  _select(filter: any, index: number) {
    this.markAsSelected(filter);
    this.setFiltersWidth().then(() => {
      this.display().then((_) => setTimeout(() => this.moveLine(index), 75));
      this.changeDetectorRef.detectChanges();
    });
  }

  select(filter: any, index: number): void {
    if (this.keyboard) return;
    if (TicketMethods.getHasFreebet(this.ticket)) return;
    this.filter.emit(filter);
    if (this.setFilterAvailable) this._select(filter, index);
  }

  private setColorMode() {
    this._renderer.setAttribute(this.ref, 'mode', this.mode);
    this._renderer.addClass(this.ref, this.colorsOptions.background);
    this._renderer.addClass(this.lineRef, this.colorsOptions.line);
  }

  private markAsSelected(currentFilter: any) {
    this.filters.forEach((filter) => {
      filter.selected = filter.name === currentFilter.name;
    });
  }

  private moveLine(i: number) {
    const item = this.filtersWidth[i];

    if (this.deviceService.isMobile()) {
      this._renderer.setStyle(
        this.lineRef,
        'transform',
        `translateX(${(this.swiperContainer.nativeElement.clientWidth / 3) * i}px)`
      );
    } else {
      this._renderer.setStyle(this.lineRef, 'transform', `translateX(${item.element.offsetLeft}px)`);
    }

    this._renderer.setStyle(this.lineRef, 'width', item.rect.width + 'px');
  }

  private setFiltersWidth() {
    return new Promise((res, rej) => {
      this.filtersWidth = {};
      if (this.listRef) {
        for (let i = 0; i < this.listRef.children.length; i++) {
          this.filtersWidth[i] = {
            element: this.listRef.children[i],
            rect: this.listRef.children[i].getBoundingClientRect()
          };
        }
        res(null);
      }
    });
  }

  private display() {
    return new Promise((res, rej) => {
      let display;
      if (Array.isArray(this.filters)) {
        if (this.switch) {
          display = this.filters.length <= 1 ? 'none' : 'block';
        } else {
          display = !!this.filters.length ? 'block' : 'none';
        }
      } else {
        display = 'none';
      }

      this._renderer.setStyle(this._element.nativeElement, 'display', display);
      this.resize.emit();

      if (this.swiper) {
        this.swiper.update();
      }
      res(null);
    });
  }
}
