<ng-container *ngIf="bono !== null">
  <ion-row
    class="allbonus contentpromo"
    [ngClass]="{'height100': (globalVars.licenseType === 22 || utils.isArgentina()) }"
  >
    <div class="titlebonus m-0" *ngIf="isDesktop" [innerHTML]="bono?.tabTitle | translate | uppercase"></div>
    <div class="m-0">
      <img [src]="bono?.img" [alt]="'Bono de ' + bono?.bono.promoProduct" />
    </div>
    <div class="font1_2">
      <div class="fontweight" [innerHTML]="bono?.title"></div>
      <ul class="txtleft">
        <li *ngFor="let t of bono?.txt" [innerHTML]="t"></li>
      </ul>
    </div>
    <div class="m-0 btn" [ngClass]="{'margintauto': (globalVars.licenseType === 22 || utils.isArgentina()) }">
      <button (click)="aceptBonus()" [class]="bono.button">{{ bono.button.txt| translate | uppercase}}</button>
    </div>
    <div class="m-0">
      <a external-link [href]="bono?.tyc" target="_blank">{{'InviteFriendtxt06' | translate | uppercase}}</a>
    </div>
  </ion-row>
</ng-container>
