<swiper-container class="sb-filter-mostbet" [class.is-switch]="switch" init="false" #swiper>
  <swiper-slide
    [class.is-switch]="switch"
    class="sb-filter-mostbet-item"
    *ngFor="let filter of filters; let index = index"
    [class.is-selected]="filter?.selected"
    [class.is-disabled]="filter?.disabled"
    (click)="select(filter, index)"
  >
    <div>
      <i
        [ngClass]="'icon-' + filter?.SportHandle"
        class="sb-grid-header--sport-icon sb-filter-mostbet--icon codere-icon color-muted"
      ></i>
      <span class="sb-filter-mostbet--title color-muted" [ngClass]="{'line-through' : filter?.disabled}">
        {{ filter?.SportName | titleCase }}</span
      >
    </div>
  </swiper-slide>
  <div class="sb-filter-mostbet-line background-color-dark" #line></div>
</swiper-container>
