/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, concat, from, of, throwError } from 'rxjs';
import { map, tap, catchError, finalize } from 'rxjs/operators';
import { BaseService } from '../providers/BaseService';
import { LogService } from '../providers/LogService';
import { C_Ticket } from '../models/C_ResponseTickets';
import { C_DepositPaymentDateList } from '../models/C_Deposits';
import { C_loadTicketCaptcha } from '../models/C_loadTicketCaptcha';
import { C_UserDataBGT_MX } from '../models/C_UserDataMX';
import { LicenseTypes, TransactionTypes } from '../models/MasterData';
import { ListOfBanks } from '../models/ListOfBanks';
import { TrueLayerListOfBanks } from '../models/TrueLayerListOfBanks';
import { TrackersCodereId } from '../models/TrackingEvents';
import { I_MyActivity, I_ReactivateAccount } from '../models/I_MyActivity';
import { I_RowExtractTable } from '../models/I_ExtractTable';
import { I_NewCarousel } from '../models/I_NewCarousel';
import { C_Banners } from '../models/C_banners';
import { CookieNames } from '../models/CookieNames';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
// import { IabService } from '../providers/iabService';
import { C_Features } from '../models/C_Features';
import { PAYMENT_CONSTANTS } from '../constants/payment.constants';
import { Constants } from '../constants';
import { BasicActionResultStatus, PaySafeCardDepositActionResult } from '@models/C_Transactions';

declare var codereSharedJS;

export interface CodereMillonResult {
  isPromotable: boolean;
  token: string;
  errors: string;
  isAuthenticate: string;
}

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  // codereSharedJS: any;
  errorList: Array<{ code: string; label: string }>;
  userLogged = false;
  FEATURES: any = null;
  logService = inject(LogService);
  // todo iabService
  // iabService = inject(IabService);

  constructor() {
    super();
    this.errorList = [];
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
      this.userLogged = this.globalVars.user.logged || this.getCookieValue(CookieNames.UserInfo) !== null;
    });
  }

  // TODO ELIMINAR : metodo existente en cookiesService duplicado en userService por dependencias circulares
  private getCookieValue = (cookieName: string): string => {
    let r: string,
      u: string,
      f: string = null;
    const t: Array<string> = document.cookie.split(';');
    for (let i = 0; i < t.length; i++)
      (u = t[i].substr(t[i].indexOf('=') + 1)),
        (r = t[i].substr(0, t[i].indexOf('=')).replace(/^\s+|\s+$/g, '')),
        r == cookieName && (f = decodeURI(u));

    return f;
  };

  getRFToken(): string {
    //gets the requestverificationtoken from DOM
    try {
      var token: HTMLInputElement = <HTMLInputElement>document.getElementsByName('__RequestVerificationToken')[0];
      return token.value;
    } catch (err) {
      return '';
    }
  }

  getCurrentUser(): Observable<any> {
    let url: string =
      this.getUrlUserService() +
      (Boolean(this.globalVars.FEATURES.userSErviceStandAlone) ? 'autologin' : 'player/GetCurrentUser');
    return this.myGet(url, true);
  }

  getUserData(): Observable<any> {
    var url = this.globalVars.URLBASE + 'account/getuserdata';
    if (this.globalVars.licenseType != LicenseTypes.Mexico) {
      url =
        this.getUrlUserService() +
        (Boolean(this.globalVars.FEATURES.userSErviceStandAlone) ? 'userdata' : 'player/GetUserData');
    }

    return this.myGet(url);
  }

  getUrlUserService() {
    return this.globalVars.FEATURES.userSErviceStandAlone !== undefined &&
      this.globalVars.FEATURES.userSErviceStandAlone === true
      ? this.globalVars.URLBASE_USERSERICE_STAND_ALONE
      : this.globalVars.URLBASE_USERSERVICE;
  }

  getMigrationUser(cdrcode: string): Observable<any> {
    let url: string = this.globalVars.DOMAIN + '/CheckReceiveMailMvc/colombia/GetMigrationUser?cdrCode=' + cdrcode;
    return this.myGet(url, true);
  }

  getLanguage(language: string) {
    // Set language, if the final call is through UrlCommonApiBase, we need to set the language in the main API
    let setLanguageUrl: string = this.globalVars.URLBASE + 'home/setlanguagecode?languageCode=' + language;
    this.myGet(setLanguageUrl, true).subscribe();

    let url: string = this.globalVars.URLBASE + 'home/SetLanguageAndGetResources?languageCode=' + language;
    if (!!this.globalVars.UrlCommonApiBase) {
      url =
        this.globalVars.UrlCommonApiBase +
        'Resources/SetLanguageAndGetResources?languageCode=' +
        this.globalVars.Idioma;
    }
    return this.myGet(url);
  }

  login(username: string, password: string, persistCookie?: boolean): Observable<any> {
    var afToken: string = this.getRFToken();
    if (!persistCookie) {
      persistCookie = false;
    }

    var creds = {
      loginName: username,
      password: password,
      persistCookie: persistCookie,
      deviceType: this.globalVars.PLATFORM,
      __RequestVerificationToken: afToken
    };

    let url: string = this.globalVars.URLBASE + 'account/loginjson';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    return this.myPost(url, creds, { headers: headers }).pipe(
      tap((user) => {
        if (user.success) {
          this.getCustomizations().subscribe((custom) => {
            //** Equipo migracion: funcion getCuotaAccept() --> Movido a Utils */
            //this.globalVars.getCuotaAccept(custom.customizations);
            this.utils.getCuotaAccept(custom.customizations);
            return user;
          });
        } else return user;
      })
    );
  }
  storePass(username: string, password: string): Observable<any> {
    let data: any = { user: username, password };
    let url: string = this.globalVars.URLBASE + 'NativeApps/NewLogin';
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.myPost(url, data, { headers: headers });
  }

  loginNative(username: string, encryptedPassword: string): Observable<any> {
    let url: string =
      this.globalVars.URLBASE + 'NativeApps/NewAutoLogin?user=' + username + '&encryptedPassword=' + encryptedPassword;
    return this.myGet(url);
  }

  saveDeviceId(tag: string, deviceType: string): Observable<any> {
    let url: string = this.globalVars.URLBASE + 'NativeApps/SaveDeviceId?deviceID=' + tag + '&deviceType=' + deviceType;
    return this.myGet(url);
  }

  forgotPassword(username: string, captcha: string): Observable<any> {
    let url: string = this.globalVars.URLBASE + 'Account/ForgotPassword?userName=' + username + '&captcha=' + captcha;
    return this.myGet(url);
  }

  createAutoExclusion(date: string, renew: boolean, revoke: boolean = false) {
    if (this.globalVars.licenseType != LicenseTypes.Colombia) {
      let url: string = this.globalVars.URLBASE + 'Colombia/CreateSelfExclusion?newDate=' + date + '&renew=' + renew;
      return this.myGet(url);
    } else {
      return this.createSelfExclusUserService(date, renew, revoke);
    }
  }

  createSelfExclusUserService(date: string, renew: boolean, revoke: boolean = false) {
    let url: string =
      this.globalVars.URLBASE_USERSERVICE +
      'Colombia/CreateSelfExclusion?newDate=' +
      date +
      '&renew=' +
      renew +
      '&revoke=' +
      revoke;
    return this.myPost(url, {});
  }

  loadCodereCard(): Observable<any> {
    let url: string = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardGetUserCardNumber}`;
    return this.myGet(url);
  }

  loadWinLineCard(): Observable<any> {
    let url: string = this.globalVars.URLBASE + 'CodereCard/GetUserWinlineCardNumber';
    return this.myPost(url, null, null, true);
  }
  loadWCPointsAndPosition(): Observable<any> {
    let url: string = this.globalVars.DOMAIN + '/CheckReceiveMailMvc/orderform/GetMundialitoInfo';
    return this.myGet(url);
  }

  loadFreeBets(checkDepositsFB: boolean = true, hideLoader: boolean = true): Observable<any> {
    let url: string = this.globalVars.UrlFreebetBonus + 'GetUserActiveVouchers?checkDepositsFB=' + checkDepositsFB;
    return this.myGet(url, hideLoader);
  }
  createAutoExclusionAR(time: string, state: any) {
    // https://qm.codere.com.ar/UserServiceApi...
    const url: string =
      this.globalVars.URLBASE_USERSERVICE +
      'UpdateTimeOut' +
      '?timeOutSettings.durationType=' +
      'minutos' +
      '&timeOutSettings.duration=' +
      time +
      '&timeOutSettings.slots=' +
      state.slots +
      '&timeOutSettings.casino=' +
      state.casino +
      '&timeOutSettings.bets=' +
      state.sports;
    return this.myPost(url, {});
  }
  loadInitialXState() {
    let licenseType = this.globalVars.licenseType;
    let url: string = `${this.globalVars.DOMAIN}/BetPlacement/InitialXState?licenseType=${licenseType}`;
    return this.myGet(url);
  }

  loadFreeBetsAuthorization() {
    let licenseType = this.globalVars.licenseType;
    let url: string = `${this.globalVars.DOMAIN}/BetPlacement/AuthorizationHeader?licenseType=${licenseType}`;
    return this.myGet(url);
  }

  checkPhoneFreeBets(codeFB: string) {
    let url: string = this.globalVars.URLBASE + 'account/CheckPhoneFB?c=' + codeFB;
    return this.myGet(url, true);
  }

  resendFBCode() {
    let url: string = this.globalVars.URLBASE + 'account/checkresendsMs';
    return this.myGet(url, true);
  }
  checkSmsEmail(userName: string): Observable<any> {
    let url: string = this.globalVars.DOMAIN + '/CheckReceiveMailMvc/home/index?username=' + userName;
    return this.myGet(url, true);
  }

  setReceive(): Observable<any> {
    let url: string =
      this.globalVars.DOMAIN + '/CheckReceiveMailMvc/home/SetReceive?username=' + this.globalVars.user.username;
    return this.myGet(url, false);
  }

  changePromotion(): Observable<any> {
    let url: string = this.globalVars.DOMAIN + '/CheckReceiveMailMvc/home/ChangeSignupPromo';
    return this.myGet(url, true);
  }

  createDepositByTeleingreso(username: string, amount: number) {
    let url = this.globalVars.UrlTransactionsApiBase + 'Payments/CreateTeleingreso?amount=' + amount;
    return this.myGet(url);
  }

  createDepositByPaynet(username: string, amount: number) {
    let url = this.globalVars.UrlTransactionsApiBase + 'Payments/CreateTeleingreso?amount=' + amount + '&isPaynet=true';
    return this.myGet(url);
  }

  checkTeleingreso(username: string) {
    let url = this.globalVars.UrlTransactionsApiBase + 'Payments/GetLastTeleingreso';
    return this.myGet(url);
  }

  createDepositByPagueloFacil(amount: number, method: string) {
    let url = `${this.globalVars.UrlTransactionsApiBase}panama/paguelofacil/deposit?amount=${amount}&method=${method}`;
    let data: string = 'amount=' + amount;
    return this.myPost(url, data);
  }

  checkPaynet(username: string) {
    let url = this.globalVars.UrlTransactionsApiBase + 'Payments/GetLastTeleingreso?isPaynet=true';
    return this.myGet(url);
  }

  getTable(table: string): Observable<any> {
    var url =
      this.globalVars.URLBASE + 'masterdata/gettable?languageCode=' + this.globalVars.Idioma + '&table=' + table;
    return this.myGet(url, true);
  }

  phoneCallRegister(phone: string, captcha: string): Observable<any> {
    var url = this.globalVars.URLBASE + 'account/PhoneCallRegister?number=' + phone + '&captcha=' + captcha;
    return this.myGet(url, true);
  }

  //
  verifyCode(code: string): Observable<any> {
    var url = this.globalVars.URLBASE + 'Account/VerifyCode?code=' + code;
    return this.myGet(url, true);
  }

  getFriendsID(): Observable<any> {
    var url = this.globalVars.DOMAIN + '/checkreceivemailmvc/Home/GetFriendId';
    return this.myGet(url, true);
  }

  findFriendByID(friendId: string): Observable<any> {
    var url = this.globalVars.DOMAIN + '/checkreceivemailmvc/Home/FindFriendByID?friendId=' + friendId;
    return this.myGet(url, true);
  }

  setFriendship(friendId: string): Observable<any> {
    var url = this.globalVars.DOMAIN + '/checkreceivemailmvc/Home/SetFriendship?friendId=' + friendId;
    return this.myGet(url, true);
  }

  getDepositDrawInformation(drawid: string): Observable<any> {
    var url = this.globalVars.UrlTransactionsApiBase + '/Payments/DepositDrawInformation?drawid=' + drawid;
    return this.myGet(url);
  }
  getChargeDrawInformation(drawid: string): Observable<any> {
    var url = this.globalVars.UrlTransactionsApiBase + '/Payments/WitdrawTaxRetentionInformation?withdrawId=' + drawid;
    return this.myGet(url);
  }

  serializeJSON(queJSON: any) {
    //serializa la cadena de datos  JSON a queryString
    var str = '';
    var cont = 0;
    for (var x in queJSON) {
      if (cont > 0) {
        str += '&';
      }
      str += x + '=' + queJSON[x];
      cont++;
    }
    // }
    return str;
  }

  getDeposits(state: string, days: number): Observable<C_DepositPaymentDateList[]> {
    var url = this.globalVars.URLBASE + 'Account/GetDeposits?state=' + state + '&days=' + days;
    if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_CRUDoverTransactions) {
      url = this.globalVars.UrlTransactionsApiBase + 'Payments/GetDeposits?state=' + state + '&days=' + days;
    }
    return this.myGet(url);
  }

  createDeposit(name: string): Observable<any> {
    var url = this.globalVars.URLBASE + 'Account/CreateDeposit?nombre=' + name;
    if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_PointofSaleDeposit) {
      url = this.globalVars.UrlTransactionsApiBase + 'Payments/CreateDeposit?nombre=' + name;
    }
    return this.myGet(url);
  }

  createLocalChargeMex(): Observable<any> {
    var url = this.globalVars.UrlTransactionsApiBase + 'Mexico/CreatePayment';
    return this.myGet(url);
  }

  getPayments(state: string, days: number): Observable<C_DepositPaymentDateList[]> {
    var url = this.globalVars.URLBASE + 'Account/GetPayments?state=' + state + '&days=' + days;
    if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_CRUDoverTransactions) {
      url = this.globalVars.UrlTransactionsApiBase + 'Payments/GetPayments?state=' + state + '&days=' + days;
    }
    return this.myGet(url);
  }

  getDepositsWallet(state: string, days: string): Observable<C_DepositPaymentDateList[]> {
    var url = this.globalVars.URLBASE + 'Account/GetDeposits?' + days + '&state=' + state;
    if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_CRUDoverTransactions) {
      url = this.globalVars.UrlTransactionsApiBase + 'Payments/GetDeposits?' + days + '&state=' + state;
    }
    return this.myGet(url);
  }

  getPaymentsWallet(state: string, days: string): Observable<C_DepositPaymentDateList[]> {
    var url = this.globalVars.URLBASE + 'Account/GetWithdrawals?' + days + '&state=' + state;
    if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_CRUDoverTransactions) {
      url = this.globalVars.UrlTransactionsApiBase + 'Payments/GetWithdrawals?' + days + '&state=' + state;
    }
    return this.myGet(url);
  }

  getGenericBalanceDetails(hideLoader?: boolean): Observable<any> {
    var url = this.globalVars.URLBASE_USERSERVICE + 'player/GetSportBalanceDetails';
    return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
      this.logService.error(err);
    });
  }

  getUserActivity(fromDate: string, toDate: string): Observable<I_MyActivity> {
    let url = this.globalVars.DOMAIN + this.globalVars.FEATURES.UrlNewUserMyActivity + fromDate + '/' + toDate;
    let dataToShow: I_MyActivity;
    dataToShow = {
      accessToPlatform: 12,
      depositLimits: {
        dailyLimit: {
          current: 500,
          changes: [
            { dateChange: new Date(), amount: 500 },
            { dateChange: new Date(), amount: 400 }
          ]
        },
        monthlyLimit: {
          current: 1000,
          changes: [
            { dateChange: new Date(), amount: 1000 },
            { dateChange: new Date(), amount: 700 }
          ]
        },
        weeklyLimit: {
          current: 3000,
          changes: [
            { dateChange: new Date(), amount: 3000 },
            { dateChange: new Date(), amount: 2000 }
          ]
        }
      },
      depositsPaymentMethods: {
        numberOfDeposits: 35,
        totalDeposits: 500,
        paymentMethods: [
          { methodName: 'Paypal', quantity: 100 },
          { methodName: 'Bizum', quantity: 300 },
          { methodName: 'VISA', quantity: 100 }
        ]
      },
      summaryOfMovements: {
        finalBalance: 4000,
        openingBalance: 5000,
        summaryData: {
          awards: 10,
          bonuses: 5,
          deposits: 6000,
          participations: 4000,
          withdrawals: 1000
        }
      }
    };
    return this.myGet(url);
  }

  getReactivateAccountData(): Observable<I_ReactivateAccount> {
    let url = this.globalVars.DOMAIN + this.globalVars.FEATURES.UrlNewUserMyActivity + 'last90daysbeforeautoexclusion';
    let dataToShow: I_ReactivateAccount = {
      accessToCasino: '12h 25m',
      accessToPlatform: 9,
      dateRGIAJ: '22/10/2022 - 20/01/2023',
      summaryData: {
        deposits: 1546,
        withdrawals: 642,
        openingBalance: 1365.42,
        finalBalance: 2207.38
      },
      cprCase: 1
    };
    // return this.getObservableMockup<I_ReactivateAccount>(dataToShow);
    return this.myGet(url).pipe(map((response) => response.data));
  }

  getUserExtractTable(startDate: string, endDate: string): Observable<I_RowExtractTable[]> {
    const url =
      this.globalVars.DOMAIN + this.globalVars.FEATURES.UrlNewUserMyActivityExtractable + startDate + '/' + endDate;

    const dataToShow: I_RowExtractTable[] = [
      {
        balance: 1400.0,
        casinoSessionId: '4324fef',
        debit: null,
        credit: null,
        dateTime: new Date(),
        transactionType: 'Saldo inicial',
        idTransaction: null
      },
      {
        balance: 1390.0,
        dateTime: new Date(),
        debit: -10.0,
        credit: null,
        idBet: '39288201',
        idTransaction: '1168262892',
        transactionType: 'Apuesta formalizada'
      },
      {
        balance: 1410.0,
        credit: 20,
        dateTime: new Date(),
        idTransaction: '553735282WL',
        transactionType: 'Depósito Visa'
      },
      {
        balance: 20,
        casinoSessionId: '4324fef',
        credit: 20,
        dateTime: new Date(),
        debit: 30,
        idBet: 'daf2343',
        idTransaction: '4243dw',
        transactionType: 'Es una prueba'
      },
      {
        balance: 20,
        casinoSessionId: '4324fef',
        credit: 20,
        dateTime: new Date(),
        debit: 30,
        idBet: 'daf2343',
        idTransaction: '4243dw',
        transactionType: 'Es una prueba'
      },
      {
        balance: 20,
        casinoSessionId: '4324fef',
        credit: 20,
        dateTime: new Date(),
        debit: 30,
        idBet: 'daf2343',
        idTransaction: '4243dw',
        transactionType: 'Es una prueba'
      },
      {
        balance: 20,
        casinoSessionId: '4324fef',
        credit: 20,
        dateTime: new Date(),
        debit: 30,
        idBet: 'daf2343',
        idTransaction: '4243dw',
        transactionType: 'Es una prueba'
      }
    ];

    return this.myGet(url).pipe(map((resp) => resp.data));
  }

  resetPassword(data: any): Observable<{ success: string; status: string; errCode?: string }> {
    const url = this.globalVars.URLBASE_USERSERVICE + 'player/ResetPassword';
    return this.myPost(url, {
      data
    });
  }

  changeLimits(amountDay: number, amoutWeek: number, amountMonth: number): Observable<any> {
    let url = '';
    if (
      this.globalVars.licenseType == LicenseTypes.Panama ||
      this.globalVars.licenseType == LicenseTypes.ArgentinaCaba ||
      this.globalVars.licenseType == LicenseTypes.ArgentinaPBA
    ) {
      url = this.globalVars.URLBASE_USERSERVICE + 'limits/check';
      return this.myPost(url, { newAmountDay: amountDay, newAmountWeek: amoutWeek, newAmountMonth: amountMonth });
    } else {
      url =
        this.globalVars.URLBASE +
        'account/ChangeLimits?newAmountDay=' +
        amountDay +
        '&newAmountWeek=' +
        amoutWeek +
        '&newAmountMonth=' +
        amountMonth;
      return this.myGet(url);
    }
  }

  getLimits(): Observable<{
    day: string;
    week: string;
    month: string;
    SessionLimitsGasto: string;
    SessionLimitsPerdida: string;
    SessionLimitsTiempo: string;
  }> {
    var url = this.globalVars.URLBASE + 'account/GetLimits';
    return this.myGet(url, true);
  }

  changeLimitswithTest(
    text: string,
    form: boolean,
    amountDay: number,
    amoutWeek: number,
    amountMonth: number
  ): Observable<any> {
    var url = this.globalVars.URLBASE_USERSERVICE + 'Limits/appendlimitssurvey';
    let data = {
      surveyContent: text,
      isApproved: form,
      newAmountDay: Number(amountDay),
      newAmountWeek: amoutWeek,
      newAmountMonth: amountMonth
    };
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8;');
    return this.myPost(url, data, { headers: headers });
  }

  limitsChangeRequestStatus(amount = {}): Observable<any> {
    let data = amount;

    var url = this.globalVars.URLBASE_USERSERVICE + 'Limits/Check';
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8;');
    return this.myPost(url, data, { headers: headers });
  }

  changeSessionLimits(data: { amountSpent: number; amountLost: number; time: number }): Observable<any> {
    let url = this.globalVars.URLBASE_USERSERVICE + 'player/UpdateSessionLimits';
    if (this.globalVars.licenseType == LicenseTypes.Panama) {
      url = this.globalVars.URLBASE_USERSERVICE + 'limits/checksessions';
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json;');
    return this.myPost(url, data, { headers: headers });
  }

  updateData(data: any): Observable<any> {
    if (
      this.globalVars.licenseType != LicenseTypes.Colombia &&
      this.globalVars.licenseType != LicenseTypes.Mexico &&
      this.globalVars.licenseType != LicenseTypes.Panama
    ) {
      let url: string = this.globalVars.URLBASE_USERSERVICE + 'player/UpdateData';
      let data2: string = this.serializeJSON(data);
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      return this.myPost(url, data, { headers: headers });
    } else {
      let url: string = this.globalVars.URLBASE + 'account/UpdateData';
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json;');

      return this.myPost(url, data, { headers: headers });
    }
  }

  updatePrp(isPrp: boolean) {
    //let headers = new HttpHeaders().append('Content-Type', 'application/json;');
    let url: string = this.globalVars.URLBASE_USERSERVICE + 'player/SetPrp?prp=' + isPrp;
    return this.myPost(url, null);
  }

  updateOriginOfFunds(origin: string) {
    let url: string = `${this.globalVars.UrlTransactionsApiBase}contact/update-funds?fundsOrigin=${origin}`;
    return this.myPost(url, null);
  }

  getticket(systemid: string, numTicket: string, captcha: string): Observable<C_Ticket> {
    let date = new Date().getMilliseconds() * 55577;
    let url =
      this.globalVars.URLBASE +
      'viewticket/getticket?systemid=' +
      systemid +
      '&ticketid=' +
      numTicket +
      '&h=' +
      date.toString() +
      '&captcha=' +
      captcha;
    return this.myGet(url);
  }

  loadTicketCaptcha(numTicket: string, captcha: string): Observable<C_loadTicketCaptcha> {
    let url = this.globalVars.URLBASE + 'viewticket/loadTicketJson?ticketid=' + numTicket + '&captcha=' + captcha;
    return this.myGet(url);
  }

  loadUrlSST(number: string) {
    let url = this.globalVars.DOMAIN + '/csbgonlineSSTCasino/home/SessionActivate?identifier=' + number;
    return this.myGet(url);
  }

  public getFeatures(region?: string): Observable<C_Features> {
    if (region === undefined || region === null || region === '') {
      region = '0';
    }
    let url = this.globalVars.DOMAIN + '/SportsMisc/api/Home/GetFeatures?region=' + region;
    return this.myGet(url);
  }

  createOpenPayPaynetDeposit(amount: number, description: string) {
    let url = this.globalVars.UrlTransactionsApiBase + 'payments/openpay/deposit/paynet/' + amount + '/' + description;
    return this.myGet(url);
  }

  checkOpenPayPaynetDeposit() {
    let url = this.globalVars.UrlTransactionsApiBase + 'payments/openpay/deposit/paynet';
    return this.myGet(url);
  }

  validateDepositVISA(username: string, amount: number, acceptColombiaConditions: boolean = false) {
    let url =
      this.globalVars.UrlTransactionsApiBase +
      'Payments/ValidateCardDeposit?userName=' +
      username +
      '&amount=' +
      amount +
      '&acceptColombiaConditions=' +
      acceptColombiaConditions;
    return this.myGet(url);
  }

  validateDepositPtoPse(username: string, amount: number, acceptColombiaConditions: boolean = false) {
    //url comprobacion permiso cobro con tarjeta
    let url =
      this.globalVars.UrlTransactionsApiBase +
      'Payments/GetPtoTPaymentPse?amount=' +
      amount +
      '&acceptColombiaConditions=' +
      acceptColombiaConditions;
    return this.myGet(url);
  }

  validateDepositPtoPCash(username: string, amount: number, acceptColombiaConditions: boolean = false) {
    //url comprobacion permiso cobro con tarjeta
    let url =
      this.globalVars.UrlTransactionsApiBase +
      'Payments/GetPtoTPaymentCash?amount=' +
      amount +
      '&acceptColombiaConditions=' +
      acceptColombiaConditions +
      '&ptopPamentMethods=';
    return this.myGet(url);
  }

  validateDepositPayValidaCreditCard(amount: number, acceptColombiaConditions: boolean = false) {
    //url comprobacion permiso cobro con tarjeta
    let url =
      this.globalVars.UrlTransactionsApiBase +
      'Payments/GetPayValidaPaymentCard?amount=' +
      amount +
      '&acceptColombiaConditions=' +
      acceptColombiaConditions;
    return this.myGet(url);
  }

  validateDepositPayValidaCash(amount: number, type: string, acceptColombiaConditions: boolean = false) {
    //url comprobacion permiso cobro con tarjeta
    const { UrlTransactionsApiBase, FEATURES, paymentMethod } = this.globalVars;
    const { Payments_deposits_URL, payValidaPaymentCash } = FEATURES;
    let flagNameTemplate = 'Payments_MethodName_UsePaymentsv2';
    let usePaymentv2 = false;
    let replaceWith = '';
    let replaceWithVersion = '';
    let urlGetPayValidaPaymentCash = '';
    if (payValidaPaymentCash) {
      urlGetPayValidaPaymentCash = 'Payments/GetPayValidaPaymentCash?amount=';
    } else {
      urlGetPayValidaPaymentCash = 'efecty/deposit?amount=';
    }
    let url =
      UrlTransactionsApiBase +
      urlGetPayValidaPaymentCash +
      amount +
      '&acceptColombiaConditions=' +
      acceptColombiaConditions +
      '&payValidaMethod=' +
      type;
    if ((paymentMethod as any).name == 'Efecty') {
      usePaymentv2 = FEATURES[flagNameTemplate.replace('MethodName', 'Efecty')];
      replaceWith = PAYMENT_CONSTANTS.METHOD.EFECTY;
      replaceWithVersion = PAYMENT_CONSTANTS.VERSION.EFECTY;
    }

    if ((paymentMethod as any).name == 'Dimonex') {
      usePaymentv2 = FEATURES[flagNameTemplate.replace('MethodName', 'Dimonex')];
      replaceWith = PAYMENT_CONSTANTS.METHOD.DIMONEX;
      replaceWithVersion = PAYMENT_CONSTANTS.VERSION.DIMONEX;
    }

    if ((paymentMethod as any).name == 'PuntoRed') {
      usePaymentv2 = FEATURES[flagNameTemplate.replace('MethodName', 'PuntoRed')];
      replaceWith = PAYMENT_CONSTANTS.METHOD.PUNTO_RED;
      replaceWithVersion = PAYMENT_CONSTANTS.VERSION.PUNTO_RED;
    }

    if (usePaymentv2) {
      url = Payments_deposits_URL.replace('{0}', replaceWith);
      url = url.replace('{1}', replaceWithVersion);
    }
    return usePaymentv2 ? this.myPost(url, { amount, acceptColombiaConditions, type }) : this.myGet(url);
  }

  validateDepositColombiaEfecty(amount: number, acceptColombiaConditions = false, type = 'efecty') {
    const { FEATURES } = this.globalVars;
    const { payValidaPaymentCash } = FEATURES;
    let urlGetPayValidaPaymentCash = '';
    if (payValidaPaymentCash) {
      urlGetPayValidaPaymentCash = 'Payments/GetPayValidaPaymentCash?amount=';
    } else {
      urlGetPayValidaPaymentCash = 'efecty/deposit?amount=';
    }
    let url =
      this.globalVars.UrlTransactionsApiBase +
      urlGetPayValidaPaymentCash +
      amount +
      '&acceptColombiaConditions=' +
      acceptColombiaConditions +
      '&payValidaMethod=' +
      type;
    return this.myGet(url);
  }

  validateDepositLotba(id: string, amount: number) {
    let url = this.globalVars.UrlTransactionsApiBase + 'argentinacaba/lotba/deposit';
    return this.myPost(url, { "'id'": id, amount: amount });
  }

  validateDepositPayValidaRecurrentCash(type: string, acceptColombiaConditions: boolean = false) {
    //url comprobacion permiso cobro con tarjeta
    let url =
      this.globalVars.UrlTransactionsApiBase +
      'Payments/GetPayValidaPaymentRecurringCash?acceptColombiaConditions=' +
      acceptColombiaConditions +
      '&payValidaMethod=' +
      type;
    return this.myGet(url);
  }

  validateDepositPAYSAFE(username: string, amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_PaySafe_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/GetPaySafeCard?amount=' + amount;
    if (Payments_PaySafe_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.PAY_SAFE);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.PAY_SAFE);
    }
    return Payments_PaySafe_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateDepositBizum(username: string, amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Bizum_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/getEpgBizum?amount=' + amount;
    if (Payments_Bizum_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.BIZUM);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.BIZUM);
    }
    return Payments_Bizum_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateDepositOxxo(username: string, amount: number, documentNumber: string) {
    let url2 =
      this.globalVars.UrlTransactionsApiBase +
      'Payments/GetAstropayOxxo?amount=' +
      amount +
      '&userDocument=' +
      documentNumber;
    return this.myGet(url2);
  }

  validateDepositOxxoBank(username: string, amount: number, documentNumber: string, type: string) {
    const { UrlTransactionsApiBase, FEATURES, paymentMethod } = this.globalVars;
    const { Payments_deposits_URL } = FEATURES;
    let flagNameTemplate = 'Payments_MethodName_UsePaymentsv2';
    let usePaymentv2 = false;
    let replaceWith = '';
    let replaceWithVersion = '';
    let url =
      UrlTransactionsApiBase +
      'Payments/GetAstropayOxxo?amount=' +
      amount +
      '&userDocument=' +
      documentNumber +
      '&method=' +
      type;
    if ((paymentMethod as any).name == 'PSE') {
      usePaymentv2 = FEATURES[flagNameTemplate.replace('MethodName', 'Pse')];
      replaceWith = PAYMENT_CONSTANTS.METHOD.PSE;
      replaceWithVersion = PAYMENT_CONSTANTS.VERSION.PSE;
    }

    if ((paymentMethod as any).name == 'Daviplata') {
      usePaymentv2 = FEATURES[flagNameTemplate.replace('MethodName', 'Daviplata')];
      replaceWith = PAYMENT_CONSTANTS.METHOD.DAVI_PLATA;
      replaceWithVersion = PAYMENT_CONSTANTS.VERSION.DAVI_PLATA;
    }

    if ((paymentMethod as any).name == 'Nequi') {
      usePaymentv2 = FEATURES[flagNameTemplate.replace('MethodName', 'Nequi')];
      replaceWith = PAYMENT_CONSTANTS.METHOD.NEQUI;
      replaceWithVersion = PAYMENT_CONSTANTS.VERSION.NEQUI;
    }

    if ((paymentMethod as any).name == 'Almacenes Exito') {
      usePaymentv2 = FEATURES[flagNameTemplate.replace('MethodName', 'AlmacenesExito')];
      replaceWith = PAYMENT_CONSTANTS.METHOD.ALMACENES_EXITO;
      replaceWithVersion = PAYMENT_CONSTANTS.VERSION.ALMACENES_EXITO;
    }

    if ((paymentMethod as any).name == 'Carulla') {
      usePaymentv2 = FEATURES[flagNameTemplate.replace('MethodName', 'Carulla')];
      replaceWith = PAYMENT_CONSTANTS.METHOD.CARULLA;
      replaceWithVersion = PAYMENT_CONSTANTS.VERSION.CARULLA;
    }

    if ((paymentMethod as any).name == 'ganaCerca') {
      usePaymentv2 = FEATURES[flagNameTemplate.replace('MethodName', 'GanaCerca')];
      replaceWith = PAYMENT_CONSTANTS.METHOD.GANA_CERCA;
      replaceWithVersion = PAYMENT_CONSTANTS.VERSION.GANA_CERCA;
    }

    if ((paymentMethod as any).name == 'PagoTodo') {
      usePaymentv2 = FEATURES[flagNameTemplate.replace('MethodName', 'PagoTodo')];
      replaceWith = PAYMENT_CONSTANTS.METHOD.PAGO_TODO;
      replaceWithVersion = PAYMENT_CONSTANTS.VERSION.PAGO_TODO;
    }

    if ((paymentMethod as any).name == 'OxxoBank-GanaBank') {
      usePaymentv2 = FEATURES[flagNameTemplate.replace('MethodName', 'GanaBank')];
      replaceWith = PAYMENT_CONSTANTS.METHOD.GANA_BANK;
      replaceWithVersion = PAYMENT_CONSTANTS.VERSION.GANA_BANK;
    }

    if (usePaymentv2) {
      url = Payments_deposits_URL.replace('{0}', replaceWith);
      url = url.replace('{1}', replaceWithVersion);
    }

    return usePaymentv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateEfectyWithdrawal(amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Efecty_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'efecty/withdrawal?amount=' + amount;

    if (Payments_Efecty_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.EFECTY);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.EFECTY);
    }
    return Payments_Efecty_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  chargeByTransfer(
    username: string,
    incumbent: string,
    amount: number,
    account: string,
    switfbic: string,
    type: string
  ) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_BankTransfer_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url;
    switch (this.globalVars.licenseType) {
      case 4:
        url =
          UrlTransactionsApiBase +
          'Mexico/CreateOnlinePayment?incumbent=' +
          incumbent +
          '&account=' +
          account +
          '&details=' +
          switfbic;
        break;
      case 21:
        url =
          UrlTransactionsApiBase.replace('TransactionsApiCo', 'TransactionsApiCoBanksEnum') +
          'Payments/CreateOnlinePayment?userName=' +
          username +
          '&incumbent=' +
          incumbent +
          '&amount=' +
          amount +
          '&account=' +
          account +
          '&details=' +
          switfbic +
          '&typeaccount=' +
          type;
        break;
      default:
        url =
          UrlTransactionsApiBase +
          'Payments/CreateOnlinePayment?userName=' +
          username +
          '&incumbent=' +
          incumbent +
          '&amount=' +
          amount +
          '&account=' +
          account +
          '&details=' +
          switfbic +
          '&typeaccount=' +
          type;
        break;
    }
    if (Payments_BankTransfer_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.BANK_TRANSFER);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.BANK_TRANSFER);
    }
    return Payments_BankTransfer_UsePaymentsv2
      ? this.myPost(url, { amount, incumbent, account, switfbic, type })
      : this.myGet(url);
  }

  chargeByTransferITAU(
    username: string,
    incumbent: string,
    amount: number,
    account: string,
    switfbic: string,
    type: string,
    fundsorigin: string
  ) {
    let url =
      this.globalVars.UrlTransactionsApiBase +
      'Payments/CreateOnlinePaymentItau?userName=' +
      username +
      '&incumbent=' +
      incumbent +
      '&amount=' +
      amount +
      '&account=' +
      account +
      '&details=&typeaccount=' +
      type;
    return this.myGet(url);
  }

  checkChargeByTransfer(
    username: string,
    incumbent: string,
    amount: number,
    account: string,
    switfbic: string,
    type: string
  ) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_BankTransfer_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url;
    switch (this.globalVars.licenseType) {
      case 4:
        url =
          UrlTransactionsApiBase +
          'Mexico/CheckCreateOnlinePayment?incumbent=' +
          incumbent +
          '&account=' +
          account +
          '&details=' +
          switfbic +
          '&typeaccount=' +
          type;
        break;
      default:
        url =
          UrlTransactionsApiBase +
          'Payments/CheckCreateOnlinePayment?userName=' +
          username +
          '&incumbent=' +
          incumbent +
          '&amount=' +
          amount +
          '&account=' +
          account +
          '&details=' +
          switfbic +
          '&typeaccount=' +
          type;
        break;
    }
    if (Payments_BankTransfer_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.BANK_TRANSFER);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.BANK_TRANSFER);
      url = `${url}/check`;
    }
    return Payments_BankTransfer_UsePaymentsv2
      ? this.myPost(url, { amount, incumbent, account, switfbic, type })
      : this.myGet(url);
  }

  createChargeByTransfer(
    username: string,
    incumbent: string,
    amount: number,
    account: string,
    switfbic: string,
    type: string
  ) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_BankTransfer_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url =
      UrlTransactionsApiBase +
      'Payments/CreateOnlinePayment?userName=' +
      username +
      '&incumbent=' +
      incumbent +
      '&amount=' +
      amount +
      '&account=' +
      account +
      '&details=' +
      switfbic +
      '&typeaccount=' +
      type;
    if (Payments_BankTransfer_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.BANK_TRANSFER);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.BANK_TRANSFER);
    }
    return Payments_BankTransfer_UsePaymentsv2
      ? this.myPost(url, { amount, username, incumbent, account, switfbic, type })
      : this.myGet(url);
  }

  checkCreateChargeByTransfer(
    username: string,
    incumbent: string,
    amount: number,
    account: string,
    switfbic: string,
    type: string
  ) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_BankTransfer_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url =
      UrlTransactionsApiBase +
      'Payments/CreateOnlinePayment?userName=' +
      username +
      '&incumbent=' +
      incumbent +
      '&amount=' +
      amount +
      '&account=' +
      account +
      '&details=' +
      switfbic +
      '&typeaccount=' +
      type;
    if (Payments_BankTransfer_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.BANK_TRANSFER);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.BANK_TRANSFER);
      url = `${url}/check`;
    }
    return Payments_BankTransfer_UsePaymentsv2
      ? this.myPost(url, { amount, username, incumbent, account, switfbic, type })
      : this.myGet(url);
  }

  checkCreateChargeByTransferMX(incumbent: string, account: string, switfbic: string, type: string) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_BankTransfer_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url =
      UrlTransactionsApiBase +
      'Mexico/CheckCreateOnlinePayment?incumbent=' +
      incumbent +
      '&account=' +
      account +
      '&details=' +
      switfbic +
      '&typeaccount=' +
      type;
    if (Payments_BankTransfer_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.BANK_TRANSFER);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.BANK_TRANSFER);
      url = `${url}/check`;
    }
    return Payments_BankTransfer_UsePaymentsv2
      ? this.myPost(url, { incumbent, account, switfbic, type })
      : this.myGet(url);
  }

  createChargeByTransferMx(incumbent: string, account: string, switfbic: string, type: string) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_BankTransfer_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url =
      UrlTransactionsApiBase +
      'Mexico/CreateOnlinePayment?incumbent=' +
      incumbent +
      '&account=' +
      account +
      '&details=' +
      switfbic;
    if (Payments_BankTransfer_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.BANK_TRANSFER);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.BANK_TRANSFER);
    }
    return Payments_BankTransfer_UsePaymentsv2 ? this.myPost(url, { incumbent, account, switfbic }) : this.myGet(url);
  }

  validateDepositOxxoCreditCard(username: string, amount: number, documentNumber: string, type: string) {
    let url2 =
      this.globalVars.UrlTransactionsApiBase +
      'Payments/GetAstropayPaymentCard?amount=' +
      amount +
      '&method=' +
      type +
      '&userDocument=' +
      documentNumber;
    return this.myGet(url2);
  }

  validateBancomerDep(amount: number, deviceType: string) {
    //url comprobacion permiso cobro con tarjeta
    let url =
      this.globalVars.UrlTransactionsApiBase + 'Payments/GetBBVAMexdeposit?amount=' + amount + '&device=' + deviceType;
    return this.myGet(url);
  }

  validatePayUDep(amount: number, deviceType: string, isPSE: boolean) {
    //url comprobacion permiso cobro con tarjeta
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_PayU_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url =
      UrlTransactionsApiBase +
      'Payments/GetPayUDeposit?amount=' +
      amount +
      '&device=' +
      deviceType +
      '&codePaymentMethod=pu&isPse=' +
      isPSE;
    if (Payments_PayU_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.PAY_U);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.PAY_U);
    }
    return Payments_PayU_UsePaymentsv2 ? this.myPost(url, { amount, deviceType, isPSE }) : this.myGet(url);
  }

  getAstropayMethods(license: string, type: string) {
    let url =
      this.globalVars.UrlTransactionsApiBase + 'Payments/GetAstropayMethods?license=' + license + '&bankType=' + type;
    return this.myGet(url);
  }
  getAllAstropayMethods(license: string) {
    let url = this.globalVars.UrlTransactionsApiBase + 'Payments/GetAllAstropayMethods?license=' + license;
    return this.myGet(url);
  }
  getTransanctionIdEvoPayments(amount: number) {
    let url = this.globalVars.UrlTransactionsApiBase + 'Payments/CreateEvoPayment?amount=' + amount;
    return this.myGet(url);
  }
  cancelPaymentEvoPayments(idTransaccion: string) {
    let url = this.globalVars.UrlTransactionsApiBase + 'EvoPayments/CancelPayment?orderid=' + idTransaccion;
    return this.myPost(url, null);
  }
  forcedPaymentEvoPayments(idTransaccion: string) {
    let url = this.globalVars.UrlTransactionsApiBase + 'EvoPayments/forcePayment?orderid=' + idTransaccion;
    return this.myPost(url, null);
  }

  validateDepositEPG(username: string, amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_CreditCards_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/GetEpgPaymentCard?amount=' + amount;
    if (Payments_CreditCards_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.CREDIT_CARDS);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.CREDIT_CARDS);
    }

    return Payments_CreditCards_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateDepositPtot(username: string, amount: number, acceptColombiaConditions: boolean = false) {
    let url =
      this.globalVars.UrlTransactionsApiBase +
      'Payments/GetPtoTPaymentCard?amount=' +
      amount +
      '&acceptColombiaConditions=' +
      acceptColombiaConditions;
    return this.myGet(url);
  }

  createChargeByHalcash(amount: number, phone: string, pincode: string) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_HalCash_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url =
      UrlTransactionsApiBase +
      'Payments/CreateHalCashPayment?amt=' +
      amount +
      '&phonenumber=' +
      phone +
      '&pin=' +
      pincode;
    if (Payments_HalCash_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.HAL_CASH);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.HAL_CASH);
    }
    return Payments_HalCash_UsePaymentsv2 ? this.myPost(url, { amount, phone, pincode }) : this.myGet(url);
  }
  checkCreateChargeByTpaga(amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Tpaga_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'tpaga/charges/create/' + amount;
    if (Payments_Tpaga_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.T_PAGA);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.T_PAGA);
    }
    return Payments_Tpaga_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  createChargeByTpaga(amount: number) {
    let url = `${this.globalVars.UrlTransactionsApiBase}tpaga/charges/create/${amount}`;
    return this.myGet(url);
  }

  createChargeByCoinspaid(amount: number, address: string) {
    //let url:string = 'https://dm.apuestas.codere.es/TransactionsApiCoinspaid/coinspaid/whitdrawals/create?amount='+amount+'&address='+address
    let url: string = `${this.globalVars.UrlTransactionsApiBase}coinspaid/withdrawals/create/${amount}`;
    return this.myGet(url);
  }

  CheckBankTransfers() {
    let url = this.globalVars.UrlTransactionsApiBase + 'account/CheckBankTransfers';
    return this.myGet(url, true);
  }

  getPendingPayments(username: string) {
    let url = this.globalVars.URLBASE + 'account/ConsultPendingPayments?username=' + username;
    if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_CRUDoverTransactions) {
      url = this.globalVars.UrlTransactionsApiBase + 'Payments/ConsultPendingPayments?userName=' + username;
    }
    return this.myGet(url);
  }

  checkCreateChargeByHalcash(amount: number, phone: string, pincode: string) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_HalCash_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url =
      UrlTransactionsApiBase +
      'Payments/CheckCreateHalCashPayment?amt=' +
      amount +
      '&phonenumber=' +
      phone +
      '&pin=' +
      pincode;
    if (Payments_HalCash_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.HAL_CASH);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.HAL_CASH);
      url = `${url}/check`;
    }
    return Payments_HalCash_UsePaymentsv2 ? this.myPost(url, { amount, phone, pincode }) : this.myGet(url);
  }

  checkCreateChargeByPaypal(amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Paypal_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/CreatePaypalCharge?amount=' + amount;
    if (Payments_Paypal_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.PAYPAL);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.PAYPAL);
    }
    return this.myPost(url, { amount });
  }

  getHalcash() {
    let url = this.globalVars.URLBASE + 'account/getuserdata';
    return this.myGet(url, true);
  }

  getPaypal(): Observable<any> {
    let url = this.globalVars.UrlTransactionsApiBase + 'Payments/PaypalChargeAvailable';
    return this.myGet(url, true);
  }

  cancelPendingPayments(username: string, referencia: string, isCancelExternal: boolean) {
    let url =
      this.globalVars.URLBASE +
      'account/CancelOnlinePayment?id=' +
      referencia +
      '&username=' +
      username +
      '&isCancelExternal=' +
      isCancelExternal;
    if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_CRUDoverTransactions) {
      url =
        this.globalVars.UrlTransactionsApiBase +
        'Payments/CancelOnlinePayment?id=' +
        referencia +
        '&username=' +
        username +
        '&isCancelExternal=' +
        isCancelExternal;
    }
    return this.myGet(url);
  }

  checkPayment(username: string) {
    let url = this.globalVars.UrlTransactionsApiBase + 'Payments/CheckPayment?userName=' + username;
    return this.myGet(url);
  }

  createChargeByLocalCodere(username: string, amount: string) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_LocalCodere_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/CreatePayment?nombre=' + username + '&cantidad=' + amount;
    if (Payments_LocalCodere_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.LOCAL_CODERE);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.LOCAL_CODERE);
    }
    return Payments_LocalCodere_UsePaymentsv2 ? this.myPost(url, { amount, username }) : this.myGet(url);
  }

  checkCreateChargeByLocalCodere(username: string, amount: string) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_LocalCodere_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/CheckCreatePayment?nombre=' + username + '&cantidad=' + amount;
    if (Payments_LocalCodere_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.LOCAL_CODERE);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.LOCAL_CODERE);
      url = `${url}/check`;
    }
    return Payments_LocalCodere_UsePaymentsv2 ? this.myPost(url, { amount, username }) : this.myGet(url);
  }

  cancelTransaction(referencia: string, amount: string) {
    let url = this.globalVars.URLBASE + 'account/CancelTransaction?cantidad=' + amount + '&id=' + referencia;
    if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_CRUDoverTransactions) {
      url = this.globalVars.UrlTransactionsApiBase + 'Payments/CancelTransaction?id=' + referencia;
    }
    return this.myGet(url);
  }

  getPendingOnlinePayments(username: string) {
    let url = this.globalVars.URLBASE + 'account/ConsultOnlinePayments?username=' + username;
    if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_CRUDoverTransactions) {
      url = this.globalVars.UrlTransactionsApiBase + 'Payments/ConsultOnlinePayments?userName=' + username;
    }
    return this.myGet(url);
  }

  async getNewBanners(bannerswebapppc: string = '') {
    const bannerType: string = this.utils.getBannerTypeByLobby(bannerswebapppc);
    const url = this.globalVars.DOMAIN + this.globalVars.FEATURES.NewCarouselApi + `carousel/templates/${bannerType}`;
    const data: I_NewCarousel[] = await this.myGet(url)
      .pipe(
        map<any, I_NewCarousel[]>((data) =>
          this.utils.mapResponseBanners(data).sort((a, b) => {
            if (a.priority > b.priority) {
              return 1;
            }
            if (a.priority < b.priority) {
              return -1;
            }
            return 0;
          })
        )
      )
      .toPromise();
    const bannersErrorCSV: Array<{ templates: string; key: number }> = [];
    await Promise.all(
      data.map(async (r, i) => {
        const canBetRequest: number = this.utils.canRequestForOddAmount(r);
        if (canBetRequest) {
          try {
            if (r.typeOfTemplate === 'One Odd') {
              const response = await this.getEventMarketsByNode(canBetRequest).toPromise();
              data[i].oneOddAmount = response.Odd;
            } else {
              const responseLeftTeam = await this.getEventMarketsByNode(r.leftTeamBetKey).toPromise();
              data[i].leftTeamAmount = responseLeftTeam.Odd;
              if (r.typeOfTemplate == 'Two teams with draw') {
                const responseDraw = await this.getEventMarketsByNode(r.drawBetKey).toPromise();
                data[i].drawAmount = responseDraw.Odd;
              }
              const responseRightTeam = await this.getEventMarketsByNode(r.rightTeamBetKey).toPromise();
              data[i].rigthTeamAmount = responseRightTeam.Odd;
            }
          } catch (error) {
            bannersErrorCSV.push({ templates: r.typeOfTemplate, key: canBetRequest });
          }
        }
      })
    );
    const auxdata: I_NewCarousel[] = data.filter((r) => {
      let isBad: boolean = false;
      bannersErrorCSV.forEach((b) => {
        if (b.templates === r.typeOfTemplate) {
          switch (r.typeOfTemplate) {
            case 'One Odd':
            case 'Two teams with draw':
            case 'Two teams without draw':
            case 'Tennis Match':
              if (!isBad) {
                isBad = b.key === r.matchKey;
              }
              break;
          }
        }
      });
      return !isBad;
    });
    return auxdata;
  }

  getBanners(bannerswebapppc: string = ''): Observable<any> {
    // this.globalVars.LicenseTypeString -> movido a utils
    let license: string = this.utils.LicenseTypeString(this.globalVars.licenseType);
    let url: string = '';

    // jsonp ya añade "&callback=JSONP_CALLBACK" al final
    url = `${this.globalVars.urlBaseBanners}?anonymous=${!this.globalVars.user
      .logged}&license=${license}&sharepointImagesList=${bannerswebapppc}`;

    return this.httpClient.jsonp(url, 'callback').pipe(
      map((res: any) => {
        const data = this.utils.xml2json(res);
        if (!data.banners) {
          return;
        }
        data.banners.banner = Array.isArray(data.banners.banner) ? data.banners.banner : [data.banners.banner];
        return data.banners.banner
          .filter((banner) => Boolean(banner['@imagen']))
          .map((banner) => new C_Banners(banner['@imagen'], banner['@url'], banner['@cid'], banner['@target']));
      })
    );
  }

  public getEventMarketsByNode(nodeEvent: number) {
    let url = this.globalVars.DOMAIN + this.globalVars.FEATURES.MSO_BannersEndpointURL + nodeEvent;
    return this.myGet(url, false);
  }

  getCarrousel(): Observable<any> {
    // this.globalVars.LicenseTypeString -> movido a utils
    let license: string = this.utils.LicenseTypeString(this.globalVars.licenseType);
    let anonymous: string = this.globalVars.user.logged ? '1' : '0';
    let url: string = `assets/data/carrousel/carrousel_${anonymous}_${license}` + '.json?' + Math.random();
    return this.myGet(url, true).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        //Observable.throw(error) -> throwError
        return throwError(() => new Error(error));
      })
    );
  }

  getPaymentMethods(): Observable<Array<any>> {
    const { UrlOperationsApiBase, FEATURES } = this.globalVars;
    const { depositsJSONApiActive } = FEATURES;
    let url: string;
    let license: string;
    if (depositsJSONApiActive) {
      license = this.utils.LicenseTypeBackendString(this.globalVars.licenseType);
      url = `${UrlOperationsApiBase}api/v1/cashier?license=${license}&transactionType=${TransactionTypes.Deposit}`;
    } else {
      let date = new Date().getTime();
      license = this.utils.LicenseTypeString(this.globalVars.licenseType);
      url = `assets/paymentMethods/deposits/depositMethods${license}.json?v=${date}`;
    }

    return this.myGet(url, true).pipe(
      map((data) => {
        return data.filter((paymentMethod) => (paymentMethod.enabled !== undefined ? paymentMethod.enabled : true));
      }),
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  getWithdrawalsMethods(): Observable<any[]> {
    const { UrlOperationsApiBase, FEATURES } = this.globalVars;
    const { withdrawalsJSONApiActive } = FEATURES;
    let url: string;
    let license: string;
    if (withdrawalsJSONApiActive) {
      license = this.utils.LicenseTypeBackendString(this.globalVars.licenseType);
      url = `${UrlOperationsApiBase}api/v1/cashier?license=${license}&transactionType=${TransactionTypes.Withdrawal}`;
    } else {
      var date = new Date().getTime();
      license = this.utils.LicenseTypeString(this.globalVars.licenseType);
      url = `assets/paymentMethods/withdrawals/withdrawalsMethods${license}.json?v=${date}`;
    }

    return this.myGet(url, true).pipe(
      map((data) => {
        return data.filter((paymentMethod) => (paymentMethod.enabled !== undefined ? paymentMethod.enabled : true));
      }),
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  setNewPin(creds: { oldPin: string; newPin: string }): Observable<any> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardSetNewPin}`;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.myPost(url, creds, { headers: headers });
  }

  checkCardLocked(): Observable<any> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardCheckCardLocked}`;
    return this.myPost(url, null);
  }

  validatePasswordAndCard(creds: { password: string }): Observable<any> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardValidatePasswordAndCard}`;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.myPost(url, creds, { headers: headers });
  }

  cardStatus(creds: { cardNumber: string }): Observable<any> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardCardStatus}`;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.myPost(url, creds, { headers });
  }

  addMexicoCard(creds: { cardNumber: string }): Observable<any> {
    let url = this.globalVars.URLBASE + 'Mexico/AddMexicoCard';
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.myPost(url, creds, { headers: headers });
  }

  hasCardNIF(card: string, nif: string) {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardHasCardNif}?card=${card}&nif=${nif}`;
    return this.myGet(url);
  }

  addNewCardToClient(creds: { cardNumber: string; pin: string }): Observable<any> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardAddNewCardToClient}`;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.myPost(url, creds, { headers: headers });
  }

  addClientCard(creds: { cardNumber: string; pin: string }): Observable<any> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardAddClientCard}`;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.myPost(url, creds, { headers: headers });
  }

  validatePassword(creds: { password: string }): Observable<any> {
    let url = this.globalVars.URLBASE + 'Account/ValidatePassword';
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    return this.myPost(url, creds, { headers: headers });
  }

  unlockedCard(creds: { pin: string }): Observable<any> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardUnlockedCard}`;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    let data: string = this.serializeJSON(creds);

    return this.myPost(url, data, { headers });
  }

  reuseCard(creds: { newCard: string; pin: string }): Observable<any> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardReuseCard}`;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.myPost(url, creds, { headers: headers });
  }

  //CodereCard/GetAllowedRegions

  getAllowedRegions() {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardGetAllowedRegions}`;
    return this.myGet(url);
  }

  createCardRequest(creds: {
    city: string;
    state: string;
    ad1: string;
    ad2: string;
    postalCode: string;
    cardregion: string;
    pin: string;
  }): Observable<any> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardCreateCardRequest}`;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.myPost(url, creds, { headers: headers });
  }

  cardRequestExists(): Observable<any> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardCardRequestExists}`;
    return this.myPost(url, '', null, true);
  }

  sendHelpMail(name: string, email: string, phone: string, comments: string, captcha: string) {
    let username = !!this.globalVars.user.username ? this.globalVars.user.username : 'NoJugador';
    let platform = 'WebApp';
    let url =
      this.globalVars.URLBASE +
      'account/sendHelpMail?userName=' +
      username +
      '&name=' +
      name +
      '&emailAdress=' +
      email +
      '&phone=' +
      phone +
      '&comment=' +
      comments +
      '&plataform=' +
      platform +
      '&captcha=' +
      captcha;
    return this.myGet(url);
  }

  checkCanSendCodere(): Observable<any> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardDocAndResidentNotAllowed}`;
    return this.myGet(url);
  }

  hasDeposit(): Observable<any> {
    let url: string = this.globalVars.URLBASE + 'Account/HasDeposits';
    return this.myPost(url, '', null, true);
  }

  CountPendingPayments(username: string): Observable<any> {
    let url: string = this.globalVars.URLBASE + 'account/CountPendingPayments?username=' + username;
    return this.myGet(url);
  }

  AddDocVerification(inData: { type: string; face: string; doc: string; docName: string }): Observable<any> {
    let url = this.globalVars.URLBASE + 'Account/AddDocVerification';
    let header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' });

    inData['docverificationid'] = '00000000-0000-0000-0000-000000000000';
    let data: string = this.serializeJSON(inData);
    return this.myPost(url, data, { headers: header });
  }

  GetDocVerifications() {
    let url: string = this.globalVars.DOMAIN + '/checkreceivemailmvc/home/GetDocVerifications';
    return this.myGet(url);
  }

  getCardPremia(cardPremia: number): Observable<C_UserDataBGT_MX> {
    var url = this.globalVars.URLBASE + 'Mexico/GetUserData?cardnumber=' + cardPremia;
    return this.myPost(url, '', null, true);
  }

  checkPinCardPremia(cardPremia: number, pin: number): Observable<any> {
    var url = this.globalVars.URLBASE + 'Mexico/checkCardPin?cardnumber=' + cardPremia + '&pin=' + pin;
    return this.myPost(url, '', null, true);
  }

  activateBGTCard(cardPremia: number, pin: number): Observable<any> {
    var url = this.globalVars.URLBASE + 'Mexico/RegisterBGTMexico?cardnumber=' + cardPremia + '&pin=' + pin;
    return this.myPost(url, '', null, true);
  }

  registerMX(data: any): Observable<any> {
    let url: string = this.globalVars.URLBASE + 'mexico/registeruser';
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json;');
    return this.myPost(url, data, { headers: headers });
  }

  updateCustomizations(customizations: any) {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.CustomizationAddParameter}?AutoAcceptOddUpChanges=${customizations.AutoAcceptOddUpChanges}&AutoAcceptOddDownChanges=${customizations.AutoAcceptOddDownChanges}`;
    return this.myPost(url, null, null, true);
  }
  /**
   * LLamada al endpoint que actualiza las opciones de comunicaciÃƒÂ³n del usuario
   * @param options : Objeto col los valores booleanos de las cuatro opciones
   */
  updateComunications(options: { phone: boolean; sms: boolean; mail: boolean; alerts: boolean }) {
    let url = `${this.globalVars.URLBASE_USERSERVICE}UpdateNotificationsAllowed`;
    // http://dm.apuestas.codere.es/UserServiceApi/UpdateNotificationsAllowed
    let data = {
      AllowSendSMS: options.sms,
      AllowSendEmail: options.mail,
      AllowSendPhoneCall: options.phone,
      AllowSendFax: options.alerts
    };
    return this.myPost(url, data);
  }

  getCustomizations() {
    let url: string = `${this.globalVars.SportsMiscUrl}${Constants.CustomizationGetParameters}`;
    return this.myGet(url);
  }

  updateFastBetCustomization(acceptFastBet: boolean) {
    let urlFastBet = `${this.globalVars.SportsMiscUrl}${Constants.CustomizationAddParameter}?parameterName=AcceptFastBet&parameterValue=${acceptFastBet}`;
    return this.myPost(urlFastBet, null, true).pipe(
      tap((val) => {
        return val;
      })
    );
  }

  /**
   * LLamada al endpoint que devuelve las opciones de comunicaciÃƒÂ³n del usuario
   */
  getComunications() {
    let url = `${this.globalVars.URLBASE_USERSERVICE}GetNotificationsAllowed`;
    // http://dm.apuestas.codere.es/UserServiceApi/GetNotificationsAllowed
    return this.myGet(url);
  }

  getJobTitle(): Observable<any> {
    let url = this.globalVars.URLBASE_USERSERVICE + '/player/GetJobTitleFromPlayer';
    return this.myGet(url);
  }

  getUserNameSugestion(userName: string): Observable<any> {
    let url = this.globalVars.URLBASE_USERSERVICE + 'register/GenerateUserSugestion?name=' + userName;
    return this.myGet(url);
  }

  validateWidhdtrawEPG(username: string, amount: number): Observable<any> {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_CreditCards_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/GetEpgWidthDrawCard?amount=' + amount;
    if (Payments_CreditCards_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.CREDIT_CARDS);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.CREDIT_CARDS);
    }
    return Payments_CreditCards_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }
  validateWidhdtrawSkrill(username: string, amount: number): Observable<any> {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Skrill_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/GetAPMSkrillCharge?amount=' + amount;
    if (Payments_Skrill_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.SKRILL);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.SKRILL);
    }
    return Payments_Skrill_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateWidhdtrawNeteller(username: string, amount: number): Observable<any> {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Neteller_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/GetAPMNetelerCharge?amount=' + amount;
    if (Payments_Neteller_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.NETELLER);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.NETELLER);
    }
    return Payments_Neteller_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateDepositSkrill(username: string, amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Skrill_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/GetAPMSkrillDeposit?amount=' + amount;
    if (Payments_Skrill_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.SKRILL);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.SKRILL);
    }
    return Payments_Skrill_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateDepositSkrillPayV2(amount: number) {
    const { FEATURES } = this.globalVars;
    const { Payments_Skrill_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = Payments_deposits_URL;
    if (Payments_Skrill_UsePaymentsv2) {
      url = url.replace('{0}', PAYMENT_CONSTANTS.METHOD.SKRILL);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.SKRILL);
    }
    let data = {
      amount: amount
    };

    return this.myPost(url, data);
  }

  validateDepositRapidTransfer(username: string, amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_RapidTransfer_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'epg/skrill/deposit?amount=' + amount;
    if (Payments_RapidTransfer_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.RAPID_TRANSFER);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.RAPID_TRANSFER);
    }
    return Payments_RapidTransfer_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateDepositCyberSource(playerId: string, amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Cybersource_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url =
      UrlTransactionsApiBase + 'payments/cybersource/api/v1/deposits/form?amount=' + amount + '&playerId=' + playerId;
    if (Payments_Cybersource_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.CYBERSOURCE);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.CYBERSOURCE);
    }
    return Payments_Cybersource_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateDepositCyberSourcePayV2(amount: number) {
    const { FEATURES } = this.globalVars;
    const { Payments_Cybersource_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = Payments_deposits_URL;
    if (Payments_Cybersource_UsePaymentsv2) {
      url = url.replace('{0}', PAYMENT_CONSTANTS.METHOD.CYBERSOURCE);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.CYBERSOURCE);
    }
    let data = {
      amount: amount
    };

    return this.myPost(url, data);
  }

  validateDepositNeteller(username: string, amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Neteller_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/GetAPMNetelerDeposit?amount=' + amount;
    if (Payments_Neteller_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.NETELLER);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.NETELLER);
    }
    return Payments_Neteller_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateMergadoPagoDeposit(username: string, amount: number, cards?: boolean) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_MercadoPago_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'argentinacaba/cashierDeposit?amount=' + amount + (cards ? '&card=true' : '');
    if (Payments_MercadoPago_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.MERCADO_PAGO);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.MERCADO_PAGO);
    }
    return Payments_MercadoPago_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateMergadoPagoCharge(username: string, amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_MercadoPago_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'argentinacaba/cashierWithdrawals?amount=' + amount;
    if (Payments_MercadoPago_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.MERCADO_PAGO);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.MERCADO_PAGO);
    }
    return Payments_MercadoPago_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateLotbaCharge(username: string, amount: number) {
    let url = this.globalVars.UrlTransactionsApiBase + 'argentinacaba/cashierWithdrawals?amount=' + amount;
    return this.myGet(url);
  }

  validateWidhdtrawAstropay(amount: number, code: string): Observable<any> {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Astropay_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'directa/withdrawals/amounts?=' + amount + '&code=' + code;
    if (Payments_Astropay_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.ASTROPAY);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.ASTROPAY);
    }
    return Payments_Astropay_UsePaymentsv2 ? this.myPost(url, { amount, code }) : this.myGet(url);
  }

  validateDepositEPGAstropay(amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Astropay_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'Payments/GetEpgAstropayPaymentCard?amount=' + amount;
    if (Payments_Astropay_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.ASTROPAY);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.ASTROPAY);
    }
    return Payments_Astropay_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  GetListOfBanksDirect24(): Observable<ListOfBanks[]> {
    let url = this.globalVars.UrlTransactionsApiBase + 'direct24listofbanks/GetListOfDirect24Banks';
    return this.myGet(url);
  }

  BankTransferDirect24(amount: number, code: string, account: string, bankOwner: string): Observable<any> {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_BankTransfer_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url =
      UrlTransactionsApiBase +
      'directa/withdrawals?amounts=' +
      amount.toString() +
      '&code=' +
      code +
      '&account=' +
      account +
      '&bankOwner=' +
      bankOwner;
    if (Payments_BankTransfer_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.BANK_TRANSFER);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.BANK_TRANSFER);
    }
    return Payments_BankTransfer_UsePaymentsv2
      ? this.myPost(url, { amount, code, account, bankOwner })
      : this.myGet(url);
  }

  validateDepositTpaga(username: string, amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_deposits_URL, Payments_Tpaga_UsePaymentsv2 } = FEATURES;
    let url = UrlTransactionsApiBase + 'tpaga/deposit/' + amount;
    if (Payments_Tpaga_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.T_PAGA);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.T_PAGA);
    }
    return Payments_Tpaga_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateDepositCoinspaid(username: string, amount: number) {
    let url = this.globalVars.UrlTransactionsApiBase + 'coinspaid/deposit/' + amount;
    // let url = 'https://dm.apuestas.codere.es/TransactionsApiCoinspaid/coinspaid/deposit/getaddress?amount=' + amount; //test url
    return this.myGet(url);
  }

  validateDepositEPGMuchBetter(username: string, amount: number) {
    let url = this.globalVars.UrlTransactionsApiBase + 'epg/muchbetter/deposits?amount=' + amount;
    return this.myGet(url);
  }
  validateWidthrawalEPGMuchBetter(amount: number) {
    let url = this.globalVars.UrlTransactionsApiBase + 'epg/muchbetter/charge?amount=' + amount;
    return this.myGet(url);
  }

  getQRCodeFromUrl() {
    let url = this.globalVars.URLBASE_USERSERVICE + 'player/GetQrCode';
    return this.myGet(url);
  }

  validateDeposit360(username: string, amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Tarjeta_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'argentinacaba/360pagos/deposit';
    if (Payments_Tarjeta_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.TARJETA);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.TARJETA);
    }
    return Payments_Tarjeta_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  validateTrans360(username: string, amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Debin_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'argentinacaba/360pagos/tb/deposit';
    if (Payments_Debin_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.DEBIN);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.DEBIN);
    }
    return this.myPost(url, { amount });
  }

  setSelfExcluded() {
    let url = this.globalVars.URLBASE_USERSERVICE + 'SetSelfExcluded';
    return this.myPost(url, {});
  }
  getTimeOutData() {
    let url = this.globalVars.URLBASE_USERSERVICE + 'ForbiddenActions';
    return this.myGet(url);
  }
  public async showInstall() {
    let alertOptions = {
      backdropDismiss: false,
      title: 'Instalar',
      cssClass: 'pwaInstallPopup',
      message: 'Ã‚Â¿Quiere instalar como web app?',
      buttons: [
        {
          role: 'cancel',
          text: 'No',
          handler: () => {
            //empty
          }
        },
        {
          role: '',
          text: 'Install',
          handler: (e: any) => {
            window['showInstallPromotion'](e);
          }
        }
      ]
    };
    let alert = await this.utils.alertController.create(alertOptions);
    alert.present();
  }

  Getcvu() {
    let url = this.globalVars.UrlTransactionsApiBase + 'argentinacaba/itau/cvu';
    return this.myGet(url);
  }

  Generatecvu() {
    let url = this.globalVars.UrlTransactionsApiBase + 'argentinacaba/itau/cvu/generate';
    return this.myPost(url, {});
  }

  getPromotionsList() {
    let api = `promotions`;
    let url: string = this.globalVars.URLBASE_USERSERVICE + api;
    return this.myGet(url);
  }

  /**
   * Check if the user "ispromotable" / "ispromotablevip"
   * @returns {boolean}
   */
  getIsFullPromotionable() {
    let api = `promotions/isfullpromotable`;
    let url: string = this.globalVars.URLBASE_USERSERVICE + api;
    return this.myGet(url);
  }
  /**
   * Check if the user "ispromotable" or the user "ispromotablevip"
   * @param {boolean} vip: Sets the url for ispromotablevip service
   * @returns {boolean}
   */
  getIsPromotionable(vip?: boolean) {
    let promotVip = vip ? 'vip' : '';
    let api = `promotions/ispromotable${promotVip}`;
    let url: string = this.globalVars.URLBASE_USERSERVICE + api;
    return this.myGet(url);
  }

  setPromotionsOptOutCO(data: any) {
    let api = 'promotions/OptOutWelcome';
    let url: string = this.globalVars.URLBASE_USERSERVICE + api;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.myPost(url, data, { headers: headers });
  }

  setPromotionsOptIn(data) {
    let api = 'promotions/OptIn';
    let url: string = this.globalVars.URLBASE_USERSERVICE + api;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.myPost(url, data, { headers: headers });
  }

  setPromotionsOptOut(data) {
    let api = `promotions/OptOut`;
    let url: string = this.globalVars.URLBASE_USERSERVICE + api;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.myPost(url, data, { headers: headers });
  }

  getCopaAmericaToken(): Observable<CodereMillonResult> {
    let api = this.globalVars.FEATURES.CopaAmericaAPI; ///UserServiceApiCO/ para calidad en producciÃƒÂ³n /UserServiceApi/
    let url: string = this.globalVars.DOMAIN + api;
    return this.myGet(url);
  }

  getCodereMilloToken(): Observable<CodereMillonResult> {
    let api = `coderemillion/authenticate`;
    let url: string = this.globalVars.URLBASE_USERSERVICE + api;
    return this.myGet(url);
  }

  getMvpCodereToken(): Observable<CodereMillonResult> {
    let api = this.globalVars.FEATURES.MVPCodereAPI; ///UserServiceApiCO/ para calidad en producciÃƒÂ³n /UserServiceApi/
    let url: string = this.globalVars.DOMAIN + api;
    return this.myGet(url);
  }

  getHalcashAvaliable(): Observable<any> {
    let url = this.globalVars.UrlTransactionsApiBase + 'HalCashChargeAvailable';
    return this.myGet(url, true);
  }

  ValidateNequi(amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    let { Payments_Nequi_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'nequi/charge?amount=' + amount + '&code=1507';
    if (Payments_Nequi_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.NEQUI);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.NEQUI);
    }
    return Payments_Nequi_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  ValidateDaviplata(amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Daviplata_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'daviplata/charge?amount=' + amount + '&code=1551';
    if (Payments_Daviplata_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.DAVI_PLATA);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.DAVI_PLATA);
    }
    return Payments_Daviplata_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  ValidatePSE(amount: number) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Pse_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'pse/charge?amount=' + amount + '&code=1551';
    if (Payments_Pse_UsePaymentsv2) {
      url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.PSE);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.PSE);
    }
    return Payments_Pse_UsePaymentsv2 ? this.myPost(url, { amount }) : this.myGet(url);
  }

  /** Redsys implementation - Bizum
   *
   * service url: https://dm.apuestas.codere.es/TransactionApi/bbva/redsys/bizum?amount={{amount}}&form={{true}}
   * @param username st
   * @param amount number
   * @param form boolean
   * @param data any
   * @returns redirection url for Bizum deposit
   */
  validateDepositRedsysBizum(username: string, amount: number, form: boolean, data: any) {
    let url = `${this.globalVars.UrlTransactionsApiBase}bbva/redsys/bizum?amount=${amount}&form=${form}`;
    return this.myPost(url, data);
  }
  /** Redsys implementation - Credit card withdrawals. Card Check.
   * Checks if the user has cards asociated.
   *
   * service url: https://dm.apuestas.codere.es/transactionsapi/payments/redsys/bbva/checkvisacards
   * @param selectedCard { value: card identifier }
   * @param amount number
   * @returns {}
   */
  getCardsAvaliability() {
    let url = `${this.globalVars.UrlTransactionsApiBase}payments/redsys/bbva/checkvisacards`;
    return this.myGet(url, true);
  }
  /** Redsys implementation - Credit card withdrawals.
   * On Selected Card.
   *
   * service url: http://dm.apuestas.codere.es/transactionsapi/payments/bbva/redsys/charge/visa?amount={{amount}}&identifier={{identifier}}
   * @param selectedCard { value: card identifier }
   * @param amount number
   * @returns {}
   */
  ValidateCardSelection(selectedCard: any, amount: number) {
    let url = `${this.globalVars.UrlTransactionsApiBase}Payments/bbva/redsys/charge/visa?amount=${amount}&identifier=${selectedCard.value}`;
    return this.myPost(url, {});
  }
  /** Redsys implementation - Credit card deposit.
   * Check if the payment method is available for current user
   *
   * service url:
   * https://dm.apuestas.codere.es/TransactionAPIBBVA/Payments/bbva/redsys/visa?amount={{amount}}
   * @param selectedCard { value: card identifier }
   * @param amount number
   * @returns {}
   */
  validateDepositRedsysCards(amount: number, data: any = {}) {
    let url = `${this.globalVars.UrlTransactionsApiBase}Payments/bbva/redsys/deposit/visa?amount=${amount}`;
    return this.myPost(url, data);
  }
  /** Redsys implementation - Credit card deposit. Copy
   * Check if the payment method is available for current user
   *
   * service url: https://dm.apuestas.codere.es/TransactionAPIBBVA/Payments/bbva/redsys/visa?amount={{amount}}
   * @param selectedCard { value: card identifier }
   * @param amount number
   * @returns {}
   */
  validateDepositRedsysCardsCopy() {
    let url = `${this.globalVars.UrlTransactionsApiBase}Payments/bbva/redsys/deposit/visa`;
    return this.myPost(url, {});
  }
  /** Redsys implementation - Credit card deposit. (New Card)
   * Charge payment in stored tokenized card.
   *
   * service url: /dm.apuestas.codere.es/TransactionApi/payments/bbva/redsys/deposit/visa/confirmoperation?trxCode={{trxCode}}&idOperration={{idOperration}}
   * @param amount string user selected deposit amount
   * @returns insite form transactionid
   */
  postNewCardDepositRedsysCards(idOper: string, transactionId: string) {
    let url = `${this.globalVars.UrlTransactionsApiBase}payments/bbva/redsys/deposit/visa/confirmoperation?trxCode=${transactionId}&idOperration=${idOper}`;
    return this.myPost(url, {});
  }
  /** Redsys implementation - Credit card deposit. (Tokenized card)
   * Charge payment in stored tokenized card.
   *
   * service url: /dm.apuestas.codere.es/TransactionApi/payments/bbva/redsys/deposit/visa/card?trxCode={{trxCode}}&idOperration={{idOperration}}
   * @param idOper string Card/amount identifier from redsys
   * @param transactionId string Transaction identifier
   * @returns redirection url for cs-redsys-redirect
   */
  postDepositRedsysCards(idOper: string, transactionId: string) {
    let url = `${this.globalVars.UrlTransactionsApiBase}payments/bbva/redsys/deposit/visa/card?trxCode=${transactionId}&idOperration=${idOper}`;
    return this.myPost(url, {});
  }
  /** Redsys. Get the user's tokenized credit card list
   *
   * service url: https://dm.apuestas.codere.es/transactionsApi/payments/redsys/bbva/getlistofcard
   * @returns {success, CardList[]}
   */
  getListOfCards() {
    let url = `${this.globalVars.UrlTransactionsApiBase}payments/redsys/bbva/getlistofcard`;
    return this.myGet(url);
  }

  // VERIDAS API METHODS

  startVerification(): Observable<any> {
    let url: string = this.globalVars.URLBASE_VERIDAS_API + 'api/DocumentVerification/GenerateValidation';
    return this.myGet(url, false);
  }

  sendFrontImages(data: any): Observable<any> {
    let url: string = this.globalVars.URLBASE_VERIDAS_API + 'api/DocumentVerification/SendFrontImages';
    return this.myPost(url, data, null, false);
  }

  sendBackImages(data: any): Observable<any> {
    let url: string = this.globalVars.URLBASE_VERIDAS_API + 'api/DocumentVerification/SendBackImages';
    return this.myPost(url, data, null, false);
  }

  sendSelfieImages(data: any): Observable<any> {
    let url: string = this.globalVars.URLBASE_VERIDAS_API + 'api/DocumentVerification/SendSelfieImages';
    return this.myPost(url, data, null, false);
  }

  /** Astropay implementation - Credit card withdrawals.
   *
   * service url: https://dm.apuestas.codere.es/transactionsapiastropay/payments/epg/charge/astropaycard?amount=10
   * @param amount number
   * @returns {}
   */
  ValidateAstropay(amount: number) {
    let baseUrlAstropay = '';
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Colombia:
        baseUrlAstropay = this.globalVars.UrlTransactionsApiBase.replace(
          'TransactionsApiCo',
          'transactionsapiastropay'
        );
        break;
      case LicenseTypes.Panama:
        baseUrlAstropay = this.globalVars.UrlTransactionsApiBase.replace(
          'TransactionsApiCo',
          'transactionsapiastropay'
        );
        break;
      default:
        baseUrlAstropay = this.globalVars.UrlTransactionsApiBase.replace('TransactionsApi', 'transactionsapiastropay');
        break;
    }
    let url = `${baseUrlAstropay}payments/epg/charge/astropaycard?amount=${amount}`;
    return this.myGet(url, true);
  }
  getTokenVeridas(configData, device): Observable<any> {
    let url = this.globalVars.URLBASE_VERIDAS_API + 'api/DocumentVerification/GenerateToken?device=' + device;
    return this.myPost(url, configData, null, true);
  }
  setFinishVeridasVerification(id): Observable<any> {
    let url =
      this.globalVars.URLBASE_VERIDAS_API + 'api/DocumentVerification/FinishVerificationProcess?validationId=' + id;
    return this.myPost(url, null);
  }

  getConfigVeridas(): Observable<any> {
    let url: string = 'assets/veridasConfig/veridasIframeConfig.json?' + Math.random();
    return this.myGet(url, true).pipe(
      map((data) => {
        switch (this.globalVars.licenseType) {
          case LicenseTypes.Colombia:
            data.documents = ['CO'];
            break;
          case LicenseTypes.Panama:
            data.documents = ['PA'];
            break;
          case LicenseTypes.ArgentinaCaba:
          case LicenseTypes.ArgentinaPBA:
            data.documents = ['AR2'];
            break;
          default:
            data.documents = ['ES2'];
            break;
        }
        return data;
      }),
      catchError((error) => {
        //return Observable.throw(error) -> throwError
        return throwError(() => new Error(error));
      })
    );
  }

  /**
   * Validate TrueLayer accounts
   * service url: /dm.apuestas.codere.es/TransactionApi/payments/truelayer/charge?amount=${amount}&accountNumber=${account};
   * @param amount
   * @param account
   * @returns
   */
  validateTrueLayer(amount: number, account: string) {
    if (account === undefined || account === null || account === '') {
      throw { error: 'Account empty in withdrawals' };
    } else {
      const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
      const { Payments_TrueLayer_UsePaymentsv2, Payments_withdrawals_URL } = FEATURES;
      let url = `${UrlTransactionsApiBase}payments/truelayer/charge?amount=${amount}&accountNumber=${account}`;
      if (Payments_TrueLayer_UsePaymentsv2) {
        url = Payments_withdrawals_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.TRUE_LAYER);
        url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.TRUE_LAYER);
      }
      return Payments_TrueLayer_UsePaymentsv2 ? this.myPost(url, { amount, account }) : this.myPost(url, {});
    }
  }

  /**
   * Send Truelayer Deposit
   * service url: /dm.apuestas.codere.es/TransactionApi/payments/truelayer/deposit;
   * @param data
   * @returns
   */
  validateDepositTrueLayer(data: any) {
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_TrueLayer_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    let url = UrlTransactionsApiBase + 'payments/truelayer/deposit';
    if (Payments_TrueLayer_UsePaymentsv2) {
      url = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.TRUE_LAYER);
      url = url.replace('{1}', PAYMENT_CONSTANTS.VERSION.TRUE_LAYER);
    }
    return this.myPost(url, data);
  }

  /**
   * Get Truelayer banklist
   * @returns
   */
  getBankList(): Observable<TrueLayerListOfBanks[]> {
    let url = this.globalVars.UrlTransactionsApiBase + 'payments/truelayer/banklist';
    return this.myGet(url);
  }

  getBanks(): Observable<any> {
    const url = `${this.globalVars.UrlTransactionsApiBase}panama/banks`;
    return this.myGet(url);
  }

  getAccountTypes(): Observable<any> {
    const url = `${this.globalVars.UrlTransactionsApiBase}panama/types`;
    return this.myGet(url);
  }

  getAssetsBankList(): Observable<any> {
    let date = new Date().getTime();
    //Equipo migracion: globalVars.LicenseTypeString -> movido a utils
    let license: string = this.utils.LicenseTypeString(this.globalVars.licenseType);
    let url: string = `assets/data/bankLists/bankList_${license}.json?v=${date}`;

    return this.myGet(url, true).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  /**
   * Get Truelayer User Accounts
   * @returns
   */
  getUserAccounts(): Observable<any> {
    let url = this.globalVars.UrlTransactionsApiBase + 'account/bankaccounts';
    return this.myGet(url);
  }

  /**
   * Verify Truelayers account
   * @returns
   */
  verifyAccount(): Observable<any> {
    let url = this.globalVars.UrlTransactionsApiBase + 'payments/truelayer/verifyAccount';
    return this.myPost(url, {});
  }
  /** Returns an Observable mockup
   *
   * @param getData
   * @example getObservableMockup[{
   *      label: '**** **** **** 1235', value: '1', type: 'radio', checked: false
   *     }, {
   *       label: '**** **** **** 5321', value: '2', type: 'radio', checked: false
   *     }, {
   *       label: '**** **** **** 8575', value: '3', type: 'radio', checked: false
   *     }, {
   *       label: '**** **** **** 6854', value: '4', type: 'radio', checked: false
   *     }, {
   *       label: '**** **** **** 8547', value: '5', type: 'radio', checked: false
   *     }]
   * @returns Observable
   */
  getObservableMockup<T>(getData: T) {
    let data = new Observable<T>((observer) => {
      let response = getData;
      setTimeout(() => {
        observer.next(response);
        observer.complete();
      }, 2000);
    });
    return data;
  }

  async getTokenForRedirectNativeIos() {
    const url = this.globalVars.DOMAIN + '/userServiceApiTest/security/getToken';
    //** TODO */
    // const token = await this.httpClient
    //   .get(url)
    //   .map((res) => { return res })
    //   .toPromise();
    // return token;
  }

  validateTokentNativeIos(token): Observable<any> {
    let url = this.globalVars.DOMAIN + '/userServiceApiTest/security/checkToken?token=' + token;
    // let url = this.globalVars.URLBASE_USERSERVICE + 'security/checkToken?token=' + token;
    return this.myGet(url, null);
  }

  //Trackers CodereID

  createTrackersCodereId(trackers: TrackersCodereId[]) {
    let url: string;
    if (codereSharedJS.debug.isQA()) {
      url = this.globalVars.DOMAIN + '/csbgonlineCodereIdWebApi/trackers';
    } else {
      url = this.globalVars.DOMAIN + '/CodereIdApi/trackers';
    }
    return this.myPost(url, trackers);
  }

  getTrackersCurrentUser() {
    let url: string;
    if (codereSharedJS.debug.isQA()) {
      url = this.globalVars.DOMAIN + '/csbgonlineCodereIdWebApi/trackers';
    } else {
      url = this.globalVars.DOMAIN + '/CodereIdApi/trackers';
    }
    return this.myGet(url);
  }

  updateTrackersCurrentUser(trackers: TrackersCodereId[]) {
    let url: string;
    if (codereSharedJS.debug.isQA()) {
      url = this.globalVars.DOMAIN + '/csbgonlineCodereIdWebApi/trackers';
    } else {
      url = this.globalVars.DOMAIN + '/CodereIdApi/trackers';
    }
    return this.myPut(url, trackers);
  }

  ValidateLotbaDepost(amount: number) {
    let url = `${this.globalVars.UrlTransactionsApiBase}lotba/withdrawal?amount=${amount}`;
    return this.myGet(url);
  }

  ValidateEfectyDepost(amount: number, acceptColombiaConditions = false, type = 'efecty') {
    const { FEATURES } = this.globalVars;
    const { payValidaPaymentCash } = FEATURES;
    let urlGetPayValidaPaymentCash = '';
    if (payValidaPaymentCash) {
      urlGetPayValidaPaymentCash = 'Payments/GetPayValidaPaymentCash?amount=';
    } else {
      urlGetPayValidaPaymentCash = 'efecty/deposit?amount=';
    }
    let url =
      this.globalVars.UrlTransactionsApiBase +
      urlGetPayValidaPaymentCash +
      amount +
      '&acceptColombiaConditions=' +
      acceptColombiaConditions +
      '&payValidaMethod=' +
      type;
    return this.myGet(url);
  }
  urlQA() {
    return codereSharedJS.debug.isQA();
  }

  SetAcceptanceComunication() {
    let url = this.globalVars.URLBASE_USERSERVICE + 'player/SetAcceptanceComunication';
    return this.myPost(url, {});
  }

  reactivateAccount(username: string, accept: boolean = true) {
    let headers = new HttpHeaders().append('Codere-Key', 'e43cea96-1c4e-4ffe-8a27-180401483a0c');
    let url = this.globalVars.URLBASE_USERSERVICE + 'player/ReactivateAccount';
    return this.myPost(
      url,
      {
        accept,
        username
      },
      { headers: headers }
    );
  }

  sendSavedError(error: string) {
    let url: string = `${this.globalVars.UrlTransactionsApiBase}monitoring/trackfronterror`;
    localStorage.removeItem('savedError');
    return this.myPost(url, error);
  }
}
