/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  inject
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Route, Router, RouterModule } from '@angular/router'; //RouteReuseStrategy,
import { Location as LocationS, CommonModule } from '@angular/common';
import {
  AlertOptions,
  Animation,
  IonicModule,
  IonicSafeString,
  MenuController,
  ModalController,
  NavParams,
  Platform,
  iosTransitionAnimation,
  mdTransitionAnimation
  // Loading
} from '@ionic/angular';
import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { BehaviorSubject, Observable, Subject, firstValueFrom, Subscription } from 'rxjs';
import { TranslateService, LangChangeEvent, TranslateModule } from '@ngx-translate/core';
import { map, finalize, filter, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { UtilsMyBetsService } from '@sports-services/UtilsMyBetsService';
import { SportradarWidgetComponent } from '@sports-components/sportradar-widget/sportradar-widget';
import { ChargeCoderePage } from '@payments-pages/charge-codere/charge-codere';
import { MercadosPage } from '@shared-sports-pages/market/market';
import { CodereSidebarPcComponent } from '@shared-sports-components/codere-sidebar-pc/codere-sidebar-pc';
import { NewTicketFacade } from '@shared-sports-store/facades/ticket.facade';
import * as idfa from '@capacitor-community/advertising-id';
//Services
import { SbFilterComponent } from 'libs/shared/src/components/sports/sb-filter/sb-filter';
import { MyBetsBetslipComponent } from '@sports-components/my-bets-betslip/my-bets-betslip';
import { NewBaseService } from '@providers/newBase.service';
import { NewUserService } from '@providers/NewUserService';
import { UserService } from '@providers/UserService';
import { DataStorageService } from '@services/data-storage.service';
import { UpdatesService } from '@providers/UpdatesService';
import { CodereRouterService } from '@services/codere-router.service';
import { waitForInit, init } from '@decorators/init-decorators';
import { SPORTS_PATHS, PAYMENTS_PATHS, MSO_PATHS, CASINO_PATHS, PAGES_PATHS } from '@shared-constants/routes';
import { NativeService } from '@providers/NativeService';
import { BalancesService } from '@providers/BalancesService';
import { UserMigrationService } from '@providers/UserMigrationService';
import { DeviceService } from '@services/device.service';
import { CodereNavbarPc } from '@shared-common-components/codere-navbar-pc/codere-navbar-pc';
import { CodereNavbarPcSubmenu } from '@shared-common-components/codere-navbar-pc-submenu/codere-navbar-pc-submenu';
import { LoadingPage } from '@shared-pages/loading/loading';
import { CodereNavbar } from '@shared-common-components/codere-navbar/codere-navbar';
import { CodereNavbarPcSearch } from '@shared-common-components/codere-navbar-pc-search/codere-navbar-pc-search';
import { CodereUserSidebar } from '@shared-common-components/codere-user-sidebar/codere-user-sidebar';
import { NavService } from '@providers/NavService';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { Login } from '@shared-mso-pages/login/login';
import { SportService } from '@providers/SportService';
import { Utils } from '@utils/Utils';
import { Constants } from '@utils/constants';
import { CookiesService } from '@providers/CookiesService';
import { EventsService } from '@providers/EventsService';
import { TrackingService } from '@providers/TrackingService';
import { SlotsBonusesService } from '@providers/SlotsBonusesService';
import { MessageService } from '@providers/MessageService';
import { SignalRService } from '@providers/SignalRService';
import { LogService } from '@providers/LogService';
import { SlotsService } from '@providers/SlotsService';
import { AuthenticationService } from '@providers/AuthenticationService';
import { LegacyAuthenticationService } from '@providers/LegacyAuthenticationService';
import { GeolocationService } from '@providers/GeolocationService';
import { ErrorService } from '@providers/ErrorService';
import { RedirectService } from '@providers/RedirectService';
import { AppsFlyerTrackingService } from '@providers/AppsFlyerTrackingService';
import { ScriptService } from '@providers/ScriptService';
import { TicketService } from '@providers/TicketService';
import { UserServiceMSO } from '@providers/UserServiceMSO';
import { VerificationAlerts } from '@providers/VerificationAlert';
import { ticketState } from '@shared-sports-store/reducers/ticket.reducers';
//Models
import { LicenseTypes } from '@models/MasterData';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { C_ItemsMenu } from '@models/C_ItemsMenu';
import { CookieNames } from '@models/CookieNames';
import { C_Sport } from '@models/C_Sport';
import { C_HighLight } from '@models/C_HighLight';
import { C_User } from '@models/C_User';
import { I_UserInfo } from '@models/I_UserInfo';
import { TargetOptInModel } from '@models/I_TargetOptIn';
import { TransactionActionResult } from '@models/C_Transactions';
import { C_Game } from '@models/C_Game';
import { C_Country } from '@models/C_Country';
import { C_ItemMenu } from '@models/C_ItemMenu';
import { C_League } from '@models/C_League';
import { C_BetsData } from '@models/C_BetsData';
import { ProfPrp } from '@models/I_ExtraData';
//Pipes
import { ParseMoney } from '@pipes/parseMoney';
import { ParseStakeMoney } from '@pipes/parseStakeMoney';

import { MSO_ROUTES } from '@mso-routes';
import { TargetedOptInPopupPage } from '@mso-pages/targeted-opt-in-popup/targeted-opt-in-popup';
import { ResponsibleGamblingTestPage } from '@mso-pages/responsible-gambling-test/responsible-gambling-test';
import { XStateService } from '@shared-sports-services/xstate.service';

import { EventoPage } from '@shared-sports-pages/event/event';
import { LobbyCalendarPage } from '@shared-casino-components/lobby-calendar/lobby-calendar';
import { CookiesConsentPage } from 'libs/mso/src/lib/pages/cookies-consent/cookies-consent';
import { TicketMobileComponent } from 'libs/shared/src/components/sports/ticket-mobile/ticket-mobile';

import { register } from 'swiper/element/bundle';
import { v4 as uuid } from 'uuid';
import { C_TicketApuestasNew } from '@models/C_TicketApuestas';
import { AlertController } from '@ionic/angular';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { EncrypteService } from '@providers/EncrypteServices';
import { ExtractDatePipe } from '@pipes/extractDate.pipe';
import { BiometricService } from '@providers/BiometricService';
import { TicketComponent } from '@shared-sports-components/ticket/ticket';
import { QuickLaunchComponent } from '@casino-components/quick-launch-games/quick-launch-games';
import { CASINO_ROUTES } from '@casino-routes';
import { PAYMENTS_ROUTES } from '@payments-routes';
import { VideoPlayerComponent } from '@shared-common-components/video-player/video-player';
import { TruncateStringPipe } from '@pipes/truncateString.pipe';
import { SearchService } from '@providers/SearchService';
import { EmptyState } from '@components/payments/empty-state/empty-state';
import { CasinoService } from '@services/casino/casino.service';
import { AnimationBuilder } from '@angular/animations';
import { TransitionOptions } from '@ionic/core/dist/types/utils/transition';
import { CONSTANTS } from 'libs/shared/src/constants/lobbyConstants';
import { GameService } from '@services/casino/game.service';

// Stores
import { SidebarFacade } from '@models/sports/store/facades/sidebar.facade';
import { INTERFACE } from '@interfaces/general';

import { Referrer } from 'capacitor-referrer';
import { TrackingModule } from '@providers/tracking/tracking/tracking.module';
import { CdrCoreModule } from '@providers/tracking/cdr-core/cdr-core.module';
import { WelcomeBonusPage } from '@mso-pages/welcome-bonus/welcome-bonus';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TRAKERSTYPECODEREID, TrackersCodereId } from '@models/TrackingEvents';
declare global {
  interface Window {
    GLOBALS: any;
  }
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    CodereNavbarPc,
    CodereFooterComponent,
    RouterModule,
    TrackingModule,
    CdrCoreModule,
    CodereNavbarPcSubmenu,
    CodereNavbarPcSearch,
    CodereNavbar,
    TranslateModule,
    CodereSidebarPcComponent,
    CodereUserSidebar,
    ParseMoney,
    ParseStakeMoney,
    TicketComponent,
    SportradarWidgetComponent,
    WelcomeBonusPage,
    VideoPlayerComponent,
    QuickLaunchComponent,
    TargetedOptInPopupPage,
    SbFilterComponent,
    TicketMobileComponent,
    ExtractDatePipe,
    TruncateStringPipe,
    EmptyState,
    MyBetsBetslipComponent
  ],
  providers: [ExtractDatePipe, NavParams, ParseStakeMoney],
  selector: 'codere-ionic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  rootPage: any;
  @HostListener('window:beforeunload', ['$event'])
  deleteLocalStorageOptin() {
    if (this.globalVars.optinPopupOpen && localStorage.getItem('targetOpInOpen')) {
      localStorage.removeItem('targetOpInOpen');
    }
    this.gamesService.closeSessionAndRefreshBalance();
    return true;
  }

  public modalTouchId: any;

  sports: C_Sport[];
  specialGames: Array<any> = [];
  showMiniGame = true;
  ticket: C_TicketApuestasNew;
  opcionSideBarUserActive: number;
  messageCount: number;
  itemsMenu: Array<C_ItemsMenu>;
  itemsMenu$: Observable<C_ItemsMenu[]>;

  numApuestas: number;
  hasSelections = false;

  numLoadingActivos: number;

  betFooterVisible: boolean;

  loadingPresent: boolean;

  MejoraTuPremioForbbiden = true;

  typeActivator: string;

  storedHash = '';
  storedParams: any;
  isMSBrowser = false;
  toggleMiniGame$ = true;
  miniGameHeight: string;
  menuRightOpen: boolean;

  /* TimeLogout Panamá*/
  intervalTimeLogout: any;
  contadorTimeLogout = 0;
  dateTimeLogout: Date;

  /* Betslip */
  multiBetCollapsed = true;
  sectionName = '';
  disabledDot$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  activeTicket$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isCasinoUrl: boolean = false;

  /* Cookies */
  alertCookies: HTMLIonAlertElement;
  alertCookiesOptions: any;
  initSingleLobby = false;
  singleLobbies: any[];
  haveCalendarGames = [];

  /* BetSlip Filter */
  filterColorOptions$: BehaviorSubject<any> = new BehaviorSubject({});
  filtersType$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  selectedFilterType$: BehaviorSubject<any> = new BehaviorSubject(null);
  myBetsFiltersType$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  myBetsSelectedFilterType$: BehaviorSubject<any> = new BehaviorSubject(null);

  /* Natives */
  appPackegeName: string | void;

  /* NEW BETSLIP*/
  ticketState$: Subject<ticketState> = new Subject();
  ticket$: Subject<ticketState> = new Subject();
  keyboard$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  globalVars!: GlobalVarsModel;
  sideBarLeft = true;
  sideBarRight = true;
  isDesktop = false;
  isMobile = false;
  isCompleted = false;
  isTranslate = false;

  currentPage: string;

  geoLocationCheck: boolean;

  urlPlaytechIframe: SafeResourceUrl;

  /**
   * SEARCH MENU
   */
  searchItems = [];
  iniciarBusqueda = false;
  imageSource = 'assets/global/img/nosearch.svg';
  searchQuery = '';
  showEmptyState = false;

  private RDSubscription: Subscription;

  /**
   * ROUTER ANIMATIONS
   */
  showRouterAnimations = false;
  animationDirection = 'forward';
  customTransition: (navEl: HTMLElement, opts: TransitionOptions) => Animation | undefined;

  lobbies: any[] = [];
  registerPages = [
    MSO_PATHS.RegistroNewPage,
    MSO_PATHS.RegistroCONewPage,
    MSO_PATHS.RegistroPAPage,
    MSO_PATHS.RegistroARPage,
    MSO_PATHS.RegistroMZAPage,
    MSO_PATHS.RegistroPBAPage
  ];
  onLoading = true;
  accordionIndexs = [];
  //#region Injects Services
  newBaseService = inject(NewBaseService);
  translate = inject(TranslateService);
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  newUserService = inject(NewUserService);
  utils = inject(Utils);
  navService = inject(NavService);
  router = inject(Router);
  cookiesService = inject(CookiesService);
  platform = inject(Platform);
  trackingService = inject(TrackingService);
  newAuthenticationService = inject(AuthenticationService);
  legacyAuthenticationService = inject(LegacyAuthenticationService);
  slotsBonusesService = inject(SlotsBonusesService);
  messageService = inject(MessageService);
  signalRService = inject(SignalRService);
  nativeService = inject(NativeService);
  sportService = inject(SportService);
  slotsService = inject(SlotsService);
  events = inject(EventsService);
  menu = inject(MenuController);
  alertController = inject(AlertController);
  parseMoney = inject(ParseMoney);
  parseStakeMoney = inject(ParseStakeMoney);
  location = inject(LocationS);
  logService = inject(LogService);
  modalController = inject(ModalController);
  balancesService = inject(BalancesService);
  dataStorageService = inject(DataStorageService);
  appsFlyerTrackingService = inject(AppsFlyerTrackingService);
  redirectService = inject(RedirectService);
  userMigrationService = inject(UserMigrationService);
  geolocationService = inject(GeolocationService);
  public utilsMyBetsService = inject(UtilsMyBetsService);
  errorService = inject(ErrorService);
  route = inject(ActivatedRoute);
  zone = inject(NgZone);
  updatesService = inject(UpdatesService);
  scriptService = inject(ScriptService);
  ticketService = inject(TicketService);
  xStateService = inject(XStateService);
  alertCtrl = inject(AlertController);
  encryptService = inject(EncrypteService);
  extractDatePipe = inject(ExtractDatePipe);
  params = inject(NavParams);
  biometricService = inject(BiometricService);
  deviceService = inject(DeviceService);
  codereRouterService = inject(CodereRouterService);
  newTicketFacade = inject(NewTicketFacade);
  modalCtrl = inject(ModalController);
  searchService = inject(SearchService);
  casinoService = inject(CasinoService);
  animationBuilder = inject(AnimationBuilder);
  verificationAlertsService = inject(VerificationAlerts);
  private eventService = inject(EventsService);
  appsflyerTrackingService = inject(AppsFlyerTrackingService);
  gamesService = inject(GameService);

  sidebarFacade = inject(SidebarFacade);
  sanitizer = inject(DomSanitizer);

  private readonly unsubscribe$ = new Subject();

  constructor() {
    this.globalVars = new GlobalVarsModel(window.GLOBALS);
    this.globalVars.DEVICE_TYPE = this.platform.is('capacitor') ? 'native' : 'web';
    this.globalVars.isNative = this.platform.is('capacitor');
    this.newBaseService.setVar = this.globalVars;
    this.translate.use(this.globalVars.Idioma);
    this.singleLobbies = [];
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.opcionSideBarUserActive = -1;
    register();
    window['thisRef'] = this;
    this.dataStorageService.addData('appGuid', uuid());
    this.messageService.messageCount.subscribe((data) => {
      this.messageCount = data;
    });

    if (this.platform.is('capacitor')) {
      this.checkAppPackegeName();
    }

    this.menu.isOpen('right').then((value) => (this.menuRightOpen = value));
    this.setFirstLogin();
    this.getBetsConfigData();

    this.events.subscribe('miniGame:launch', () => {
      this.zone.run(() => {
        this.showMiniGame = true;
        setTimeout(() => {}, 0);
      });
      this.toggleMiniGame$ = false;
    });

    this.platform.ready().then(async () => {
      await this.scriptService.load('common');
      // check platform
      if (this.platform.is('capacitor') && this.isMobile) {
        this.geolocationService
          .initLocationtCheck()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((geoLocationCheck: boolean) => {
            this.geoLocationCheck = true;
            // if true starts app normally
            if (geoLocationCheck) {
              this.scriptService.load('customInsights').then(async (_) => {
                this.start();
                await this.updatesService.updateIfRequiredTest();
                await this.launchAppsflyer();
                const cookiesData = localStorage.getItem('cookieConsent');
                if (cookiesData) {
                  this.globalVars.isCookiesAccept = true;
                  await this.appsflyerTrackingService.startAppsFlyerSdk();
                }

                await this.launchXtremePushPopup();
                if (this.platform.is('ios')) {
                  this.trackingPermissions();
                }
                if (this.platform.is('android')) {
                  // install referrer only android
                  await this.getInstallReferrer();
                }
              });
            }
          });
      } else {
        // for web, we start without the native calls
        this.scriptService.load('customInsights').then(() => {
          this.start();
        });
        this.geoLocationCheck = true;
      }

      this.sportService.loadAuthorizationHeader();
      this.activeTicket$.next(true);

      this.platform.resize.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.isDesktop = this.deviceService.isDesktop();
        this.isMobile = this.deviceService.isMobile();
      });
    });

    this.opcionSideBarUserActive = -1;
    this.sports = [];
    this.lobbies = [];
    this.singleLobbies = [];

    this.isMSBrowser = false;

    this.setScrollXDesktop();

    this.numLoadingActivos = 0;

    this.loadingPresent = false;

    this.router.navigateByUrl(PAGES_PATHS.LoadingPage, { skipLocationChange: true });
    this.globalVars.currentPage = LoadingPage;
    window['angularComponentRef'] = { component: this, zone: this.zone };

    this.location.subscribe((data) => {
      const namePageUrl = data.url.split('?')[0].substr(1);
      const viewPageName = this.route?.component.name ? this.route?.component.name : '';
      if (
        (data.type == 'popstate' && namePageUrl != '') ||
        (this.globalVars.browser == 'internet' && data.type == 'hashchange' && namePageUrl != '')
      ) {
        //si la página de la url es distinta que la actual
        if (viewPageName != namePageUrl) {
          //comprobamos si es una url válida para navegar
          this.tryToGoPage(namePageUrl);
          if (data.url === 'cookies') {
            this.alertCookies.dismiss();
            this.alertCookies = null;
            this.openModalCookies();
          }
        }
      }
    });

    this.events.subscribe('modalBonusWcCo', () => {
      if (this.globalVars.FEATURES.ModalBonusSportCasino && !this.utils.modalBonusCOOpen) {
        this.utils.modalBonusCOOpen = true;
        this.presentModalBonusColombia();
      }
    });

    this.subscribeLoadEvent();

    /**
     * Custom transition between pages, different for iOS and Android
     */
    let previousUrl = '';
    let currentUrl = '';
    this.router.events.subscribe((event) => {
      this.showRouterAnimations = false;

      // Change variables only if navigation changes
      if (event instanceof NavigationStart && event.url !== currentUrl) {
        previousUrl = currentUrl; // Store the previous URL before navigation starts
        currentUrl = event.url; // Get the current URL
      }

      // Compare old and new URLs when navigation ends
      if (event instanceof NavigationEnd) {
        if (
          !this.isMobile ||
          !previousUrl ||
          !currentUrl ||
          previousUrl === currentUrl ||
          currentUrl === '/EventoPage' ||
          currentUrl === '/MercadosPage'
        ) {
          return;
        }

        const animatedRoutes = [SPORTS_PATHS.DirectosPage, SPORTS_PATHS.NowLiveDetailPage, MSO_PATHS.DownloadApp];

        // Compare old and new URLs and perform actions based on the comparison
        if (animatedRoutes.some((ele) => currentUrl.includes(ele))) {
          // If the user is in any of animatedRoutes
          this.animationDirection = 'forward';
        } else if (animatedRoutes.some((ele) => previousUrl.includes(ele))) {
          // If the user comes back from any of animatedRoutes
          this.animationDirection = 'backward';
        } else {
          // If no route matches
          return;
        }

        this.showRouterAnimations = true;
        this.customTransition = (navEl: HTMLElement, opts: TransitionOptions): Animation => {
          opts.direction = this.animationDirection === 'forward' ? 'forward' : 'back';

          if (opts.mode === 'ios') {
            return iosTransitionAnimation(navEl, opts);
          }

          return mdTransitionAnimation(navEl, opts);
        };
      }
    });
  }

  // unsusbscribe observables
  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  /**
   * Returns true/false if it should render the navbar
   * @returns wether it should render codere-navbar or not
   */
  canShowNavbarInThisPage(): boolean {
    const arrayPaths = [
      CASINO_PATHS.SlotsGamePage,
      CASINO_PATHS.SlotsPage,
      CASINO_PATHS.VIPPage,
      CASINO_PATHS.CasinoPage,
      PAGES_PATHS.LoadingPage,
      CASINO_PATHS.CasinoEnVivoPage,
      CASINO_PATHS.JackpotPage,
      CASINO_PATHS.OmnichannelPage,
      CASINO_PATHS.AviatorPage,
      CASINO_PATHS.BlackjackPage,
      CASINO_PATHS.SlotsdeSalonPage,
      CASINO_PATHS.FruitBlastPage,
      MSO_PATHS.RegistroCONewPage,
      MSO_PATHS.RegistroPAPage,
      MSO_PATHS.ChangePasswordPage,
      MSO_PATHS.GeolocationPage
    ];
    if (arrayPaths.some((ele) => this.router.url.includes(ele))) {
      return false;
    } else {
      return true;
    }
  }

  betslipUnlockerUrl(): boolean {
    return this.router.url.includes(SPORTS_PATHS.BetslipUnlockerPage);
  }

  @waitForInit
  ngOnInit(): void {
    this.currentPage = window.location.pathname;
    this.setSections(); /* Betslip */
    if (this.globalVars.FEATURES.MSO_NewReBranding) {
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
        this.isCasinoUrl = event['url'].startsWith(`/${CASINO_PATHS.BASE}`);
      });
    }
  }

  async checkAppPackegeName() {
    this.appPackegeName = await this.nativeService.setAppVersion();
  }

  async presentModalBonusColombia() {
    const cssClassPA =
      this.globalVars.licenseType == LicenseTypes.Panama || this.utils.isArgentina() ? 'newCssWelcomeBonus' : '';

    const modal = await this.modalController.create({
      backdropDismiss: false,
      component: WelcomeBonusPage,
      cssClass: 'modal-welcomebonus' + ' ' + cssClassPA
    });
    modal.present();
    modal.onDidDismiss().then((data) => {
      this.events.publish('cashier:endEmpty');
      this.utils.modalBonusCOOpen = false;
      this.utils.colombiaBonusModal = false;
      sessionStorage.setItem('modalWelcomeCO', data.data);
    });
  }

  async presentModalTargetOptIn(targetPromo: TargetOptInModel) {
    const modal = await this.modalController.create({
      backdropDismiss: false,
      component: TargetedOptInPopupPage,
      cssClass: 'is-modal',
      componentProps: {
        targetPromo: targetPromo
      }
    });
    modal.present();
    modal.onDidDismiss().then(() => {
      if (localStorage.getItem('targetOpInOpen')) {
        this.globalVars.optinPopupOpen = false;
        localStorage.removeItem('targetOpInOpen');
      }
    });
  }

  showPopupOptIn() {
    const otpOpen = JSON.parse(localStorage.getItem('targetOpInOpen')) || null;
    if (otpOpen && otpOpen.open) {
      return;
    }

    if (this.cookiesService.getCookie(CookieNames.IsPromotable) != null) {
      if (this.cookiesService.getCookie(CookieNames.IsPromotable) == 'true') {
        this.showPopupOptInModal();
      }
    } else {
      this.userServiceMSO.getIsPromotionable(true).subscribe((resp) => {
        if (resp.isPromotable) {
          this.showPopupOptInModal();
        }
      });
    }
  }
  showPopupOptInModal() {
    this.userServiceMSO.getdataTargetOptinPopups().subscribe((response) => {
      let dataToShow: TargetOptInModel = null;

      if (response) {
        if (Array.isArray(response) && (response as []).length > 0) {
          dataToShow = response[0];
        } else if (!response.hasError && response.data) {
          dataToShow = response.data;
        }
      }

      if (dataToShow) {
        this.globalVars.optinPopupOpen = true;
        localStorage.setItem('targetOpInOpen', JSON.stringify({ open: true }));
        this.presentModalTargetOptIn(dataToShow);
      }
    }),
      (err) => {
        this.logService.error(err);
      };
  }
  setScrollXDesktop() {
    if (this.isDesktop) {
      document.body.classList.add('has-x-scroll');
    }
  }

  extTryToGoPage(namePageUrl: string) {
    this.tryToGoPage(namePageUrl);
    return true;
  }

  tryToGoPage(namePageUrl: string, urlPage: string = namePageUrl) {
    if (this.globalVars.FEATURES.MSO_alertCookiesNoBlock) {
      if (namePageUrl.toLowerCase().indexOf('registro') !== -1) {
        if (!this.globalVars.isCookiesAccept) {
          if (this.globalVars.licenseType == LicenseTypes.Nacional) {
            this.alertCookies.dismiss();
            this.openCookiesConsent();
          } else {
            this.openAlertCookies();
          }
          namePageUrl = 'HomePage';
          this.globalVars.actionAfterCookiesAccept = 'reg';
        }
      }
    }
    if (namePageUrl == '') namePageUrl = this.location.path().split('?')[0].substr(1);
    let link = '';
    /*
       SE REALIZO UNA MODIFICACION PARA UNA SOLUCION RAPIDA
       QUE SE BORRA EL QUERYPARAMS DEL CUANDO SE ACTUALIZA SLOTS.
       ESTO PROVOCA QUE SE REDIRECCIONE A CASINO.
    */
    if (typeof namePageUrl == 'string' && !namePageUrl.includes('casino')) {
      const completelink = namePageUrl.split('?');
      if (completelink.length > 1) {
        namePageUrl = completelink[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        link = completelink[1];
      }
    }

    if (typeof namePageUrl == 'string' && namePageUrl.includes('casino')) {
      const arrUrl = this.location.path().split('?');
      let parameters = '';
      let arrParameter = [];
      if (arrUrl?.length > 1) {
        parameters = arrUrl[1].toLowerCase();
        namePageUrl = arrUrl[0].split('/')[arrUrl[0].split('/').length - 1];
      }

      arrParameter = parameters.split('&');

      let lobbyType = namePageUrl;
      let lobby: any;
      let playgame: string;
      let category: string;
      // eslint-disable-next-line no-shadow
      let filter: string;

      arrParameter.forEach((par) => {
        if (par.includes('lobbytype')) {
          lobbyType = par.split('=')[1];
        }
        if (par.includes('lobby')) {
          lobby = par.split('=')[1];
          lobby = lobby.replace(/%20/g, ' ');
        }
        if (par.includes('playgame')) {
          playgame = par.split('=')[1];
        }
        if (par.includes('category')) {
          category = par.split('=')[1];
        }
        if (par.includes('filter')) {
          filter = par.split('=')[1];
        }
      });

      if (lobby) {
        if (lobbyType !== CASINO_PATHS.JackpotPage) {
          lobby = this.lobbies.find((x) => x.LobbyName.toLocaleLowerCase() === lobby.toLocaleLowerCase());
          if (!lobby) {
            lobby = this.lobbies[0];
          }

          this.openLobby(
            lobby,
            playgame?.replace(/%20/g, '-'),
            category?.replace(/%20/g, '-'),
            filter?.replace(/%20/g, '-')
          );

          return;
        }
        this.openJackpots();
        return;
      }
    }

    if (this.globalVars.FEATURES.Lobbies) {
      const lobbyPages = this.globalVars.FEATURES.Lobbies.map((lb) => lb.lobbyUrl + 'Page');
      if (lobbyPages.find((lb) => lb === namePageUrl)) {
        namePageUrl = 'LobbyPage';
      } else if (namePageUrl === 'JackpotPage') {
        namePageUrl = 'LobbyPage';
      }
    }

    // Verificamos si tiene parámetros
    const arrUrl = this.location.path().split('?');
    let parameters = '';
    if (arrUrl?.length > 1) parameters = arrUrl[1];

    if (namePageUrl === PAGES_PATHS.LoadingPage) namePageUrl = this.globalVars.mainPageApp;
    if (parameters !== '') {
      if (urlPage == PAGES_PATHS.HomePage) this.router.navigateByUrl(urlPage + '?' + parameters);
      else {
        const parameterList = parameters.split('&');
        let name = '';
        let nodeId = '';
        let sportHandle = '';
        let sportName = '';
        let leagueName = '';
        let startDate = '';
        parameterList.forEach((param) => {
          const paramName = param.split('=')[0];
          switch (paramName) {
            case 'name':
            case 'Name':
              name = decodeURIComponent(param.split('=')[1]);
              break;
            case 'sporthandle':
            case 'SportHandle':
            case 'Sporthandle':
              sportHandle = decodeURIComponent(param.split('=')[1]);
              break;
            case 'nodeid':
            case 'NodeId':
            case 'Nodeid':
              nodeId = decodeURIComponent(param.split('=')[1]);
              break;
            case 'sportname':
            case 'SportName':
            case 'Sportname':
              sportName = decodeURIComponent(param.split('=')[1]);
              break;
            case 'leaguename':
            case 'LeagueName':
            case 'Leaguename':
              leagueName = decodeURIComponent(param.split('=')[1]);
              break;
            case 'startdate':
            case 'StartDate':
            case 'Startdate':
              startDate = decodeURIComponent(param.split('=')[1]);
              break;
          }
        });
        this.globalVars.SportSelected = sportName;
        let paramsValue = {};

        switch (urlPage) {
          case SPORTS_PATHS.EventoPage:
            paramsValue = {
              Name: name,
              NodeId: nodeId,
              SportHandle: sportHandle
            };
            break;
          case SPORTS_PATHS.MercadosPage:
            paramsValue = {
              Name: name,
              NodeId: nodeId,
              SportHandle: sportHandle,
              LeagueName: leagueName,
              StartDate: startDate,
              SportName: sportName
            };
            break;
        }
        const formatUrl = this.globalVars.DOMAIN + this.location.path();
        const newUrl = new URL(formatUrl);
        const newparams = {};
        Array.from(newUrl.searchParams.entries()).forEach(([key, value]) => {
          newparams[key] = value;
        });

        if (namePageUrl === CASINO_PATHS.SlotsGamePage) {
          this.utils.navigateBack();
        } else {
          this.router.navigate(['/' + urlPage], {
            state: { params: paramsValue },
            queryParams: { animate: !this.isDesktop, ...newparams }
          });
        }
      }
    } else {
      this.router.navigateByUrl(namePageUrl);
    }
  }

  async autoLoginfromNativeIos(dataParam, namePageUrl) {
    const data = await this.userService
      .validateTokentNativeIos(this.storedParams.userInfo)
      .toPromise()
      .catch(() => {});
    if (data && data.login === true) {
      this.globalVars.user.logged = true;
      this.tryToGoPage(namePageUrl);
    } else {
      this.redirectAfterLoginUrl(dataParam, namePageUrl);
    }
  }

  getObjectParam(link = ''): any {
    //returns an object made from querystring
    const dataParam: any = {};
    let queryParam: string = this.location.path().split('?')[1];
    if (queryParam != null || !!link) {
      if (link) {
        queryParam = link;
      }
      const arrParams = queryParam.split('&');

      for (let i = 0; i < arrParams.length; i++) {
        const myVar = arrParams[i].split('=')[0];
        const myVal = arrParams[i].split('=')[1];
        dataParam[myVar] = myVal;
      }

      return dataParam;
    } else {
      return null;
    }
  }

  /**
   * Procesos iniciales invocados desde ngAfterViewInit ()
   */
  start() {
    //right MENU ITEMS/*/
    let tempIdioma = Constants.licenseEspañaLanguage;
    // this language will be used as a fallback when a translation isn't found in the current language
    if (this.globalVars?.LICENSE_TYPE !== undefined) {
      switch (this.globalVars?.LICENSE_TYPE) {
        case LicenseTypes.Mexico:
          this.globalVars.Idioma = 'es-mx';
          this.globalVars.oddType = 'ame';
          this.globalVars.currencyLiteral = 'Pesos';
          this.globalVars.currencySymbol = '$';
          this.translate.setDefaultLang('es-mx');
          this.disabledDot$.next(true); /* Betslip */
          tempIdioma = Constants.licenseMexicoLanguage;
          break;
        case LicenseTypes.Colombia:
          this.globalVars.Idioma = 'es-co';
          this.globalVars.oddType = 'dec';
          this.globalVars.currencyLiteral = 'Pesos';
          this.globalVars.currencySymbol = '$';
          this.translate.setDefaultLang('es-co');
          this.disabledDot$.next(true); /* Betslip */
          tempIdioma = Constants.licenseColombiaLanguage;
          break;
        case LicenseTypes.Panama:
          this.globalVars.Idioma = 'es-pa';
          this.globalVars.oddType = 'ame';
          this.globalVars.currencyLiteral = 'Dólar';
          this.globalVars.currencySymbol = '$';
          this.translate.setDefaultLang('es-pa');
          this.disabledDot$.next(false); /* Betslip */
          tempIdioma = Constants.licensePanamaLanguage;
          break;
        case LicenseTypes.ArgentinaCaba:
          this.globalVars.Idioma = 'es-ar';
          this.globalVars.oddType = 'dec';
          this.globalVars.currencyLiteral = 'Pesos';
          this.globalVars.currencySymbol = '$';
          this.translate.setDefaultLang('es-ar');
          this.disabledDot$.next(true); /* Betslip */
          tempIdioma = Constants.licenseArgentinaCabaLanguage;
          break;
        case LicenseTypes.ArgentinaPBA:
          this.globalVars.Idioma = 'es-ar';
          this.globalVars.oddType = 'dec';
          this.globalVars.currencyLiteral = 'Pesos';
          this.globalVars.currencySymbol = '$';
          this.translate.setDefaultLang('es-ar');
          this.disabledDot$.next(true); /* Betslip */
          tempIdioma = Constants.licenseArgentinaPBALanguage;
          break;
        case LicenseTypes.ArgentinaCordoba:
          this.globalVars.Idioma = 'es-ar';
          this.globalVars.oddType = 'dec';
          this.globalVars.currencyLiteral = 'Pesos';
          this.globalVars.currencySymbol = '$';
          this.translate.setDefaultLang('es-ar');
          this.disabledDot$.next(true); /* Betslip */
          tempIdioma = Constants.licenseArgentinaCordobaLanguage;
          break;
        case LicenseTypes.ArgentinaMendoza:
          this.globalVars.Idioma = 'es-ar';
          this.globalVars.oddType = 'dec';
          this.globalVars.currencyLiteral = 'Pesos';
          this.globalVars.currencySymbol = '$';
          this.translate.setDefaultLang('es-ar');
          this.disabledDot$.next(true); /* Betslip */
          tempIdioma = Constants.licenseArgentinaMendozaLanguage;
          break;
        default:
          this.globalVars.Idioma = 'es';
          this.globalVars.oddType = 'dec';
          this.globalVars.currencyLiteral = 'Euro';
          this.globalVars.currencySymbol = '€';
          this.translate.setDefaultLang('es');
          tempIdioma = Constants.licenseEspañaLanguage;
          break;
      }
    } else {
      this.globalVars.Idioma = 'es';
      this.translate.setDefaultLang('es');
    }

    //waits to load the languague before do anything
    if (this.cookiesService.getCookie(CookieNames.Idioma) != null) {
      this.globalVars.Idioma = this.cookiesService.getCookie(CookieNames.Idioma);
    } else {
      this.cookiesService.setCookie(CookieNames.Idioma, this.globalVars.Idioma, 365 * 24 * 60 * 60 * 1000);
    }

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(tempIdioma);
    this.translate.use(this.globalVars.Idioma).subscribe({
      next: (data) => {
        //segun pais
        this.globalVars.user.userRegion = this.globalVars.LICENSE_TYPE;
        this.globalVars.licenseType = this.globalVars.LICENSE_TYPE;
        this.getFeatures(true);
      },
      error: (error) => {
        throw Error(error);
      }
    });
    this.trackingService.track({ eventType: 1 });
  }

  ngAfterViewInit() {
    this.storedHash = this.location.path().split('?')[0].substr(1);
    this.globalVars.rootScope = this;
  }

  async initializeApp() {
    const cookiesData = localStorage.getItem('cookieConsent');

    this.navService.initRightMenu();
    this.itemsMenu$ = this.navService.itemsMenu$;
    this.filtersType$.next(this._filtersGenerator(['tTitle', 'uPHBet', true]));
    this.selectedFilterType$.next(this.filtersType$.value.find((filterSelected) => filterSelected.selected));
    this.myBetsFiltersType$.next(this._filtersGenerator(['histapabiertas', 'cerradas', false]));
    this.filterColorOptions$.next({
      text: 'color-muted',
      background: 'background-color-dark',
      line: 'background-color-primary'
    });

    if (this.cookiesService.getCookie(CookieNames.Cuota) !== null) {
      this.globalVars.oddType = this.cookiesService.getCookie(CookieNames.Cuota);
    } else {
      this.cookiesService.setCookie(CookieNames.Cuota, this.globalVars.oddType, 365 * 24 * 60 * 60 * 1000);
    }

    this.listenToEvents();

    const afterCheckConnectedCallback = (): void => {
      this.getSpecialGames();
      this.checkVersionIOS();
      if (this.platform.is('capacitor')) {
        this.hideLoadingNative();
      }
      this.events.publish('navbarpc:refresh');
    };

    this.platform.ready().then(() => {
      setTimeout(() => {
        if (this.platform.is('capacitor')) {
          SplashScreen?.hide();
          ScreenOrientation.lock({ orientation: 'portrait' });
          StatusBar.show();
          if (this.platform.is('android')) {
            // only on Android
            StatusBar.setOverlaysWebView({ overlay: false });
            // set status bar to green Codere
            StatusBar.setBackgroundColor({ color: '#79C000' });
          }
        }

        //se comprueba si está conectado el user, una vez comprobado se intenta acceder a la url
        this.checkConnected(afterCheckConnectedCallback);
      }, 800);
    }); // Fin de la respuesta a la Promise de platform.ready()

    if (this.globalVars.FEATURES.MSO_alertCookiesNoBlock) {
      if (
        (this.globalVars.FEATURES.MSO_NewCheckCookieConsent &&
          this.cookiesService.getCookie(CookieNames.ConsentStatusCookies)) ||
        (!this.globalVars.FEATURES.MSO_NewCheckCookieConsent && this.cookiesService.getCookie(CookieNames.CodereCookie))
      ) {
        this.globalVars.isCookiesAccept = true;
      }
    }
    if (
      !this.globalVars.FEATURES.MSO_NewCheckCookieConsent &&
      this.cookiesService.getCookie(CookieNames.CodereCookie)
    ) {
      if (cookiesData) {
        this.globalVars.isCookiesAccept = true;
        this.cookiesService.setCookie(CookieNames.ConsentStatusCookies, cookiesData, 365 * 24 * 60 * 60 * 1000, true);
      }
    }
    if (
      (this.globalVars.FEATURES.MSO_NewCheckCookieConsent &&
        !this.cookiesService.getCookie(CookieNames.ConsentStatusCookies)) ||
      (!this.globalVars.FEATURES.MSO_NewCheckCookieConsent && !this.cookiesService.getCookie(CookieNames.CodereCookie))
    ) {
      if (this.globalVars.licenseType == LicenseTypes.Nacional) {
        this.cookiesService.activateCookies('DY', true, false);
      }
      const msg: IonicSafeString = new IonicSafeString(
        await this.cookiesService.getCookieInfoMessage(this.globalVars.licenseType)
      );
      const title: string = await this.cookiesService.getCookieInfoTitle();

      // this.geoLocationCheck, if true starts normally, so
      // if no cookie data on storage and geoLocationCheck true,
      // we open the alert modal.
      if (!cookiesData && this.geoLocationCheck) {
        if (this.globalVars.licenseType == LicenseTypes.Panama && this.globalVars.isNative && this.platform.is('ios')) {
          this.globalVars.isCookiesAccept = true;
          const consentOptions: INTERFACE.ConsentOptionsData = {
            customOK: true,
            analyticsOK: true,
            advertismentOK: true
          };
          this.cookiesService.setCookieConsent(consentOptions);
          await this.appsFlyerTrackingService.startAppsFlyerSdk();
        } else {
          let alertOptions;
          if (this.globalVars.licenseType == LicenseTypes.Nacional) {
            // España
            alertOptions = {
              backdropDismiss: false,
              header: title,
              message: msg,
              cssClass: 'alertcookies',
              buttons: [
                {
                  role: 'confirm',
                  text: 'Aceptar',
                  handler: async () => {
                    const consentOptions: INTERFACE.ConsentOptionsData = {
                      customOK: true,
                      analyticsOK: true,
                      advertismentOK: true
                    };

                    this.cookiesService.setCookieConsent(consentOptions);
                    if (this.platform.is('capacitor') && this.isMobile) {
                      await this.appsFlyerTrackingService.setAppsFlyerConsent(consentOptions);
                    }
                  }
                }
              ]
            };
          } else {
            alertOptions = {
              backdropDismiss: this.globalVars.FEATURES.MSO_alertCookiesNoBlock,
              header: title,
              message: msg,
              cssClass: 'alert-cookies-default',
              buttons: [
                {
                  role: this.globalVars.FEATURES.MSO_alertCookiesNoBlock ? 'confirm' : 'cancel',
                  text: 'OK',
                  handler: async () => {
                    this.cookiesService.setCookie(
                      CookieNames.CodereCookie,
                      'accepted',
                      365 * 24 * 60 * 60 * 1000,
                      true
                    );
                    const consentOptions: INTERFACE.ConsentOptionsData = {
                      customOK: true,
                      analyticsOK: true,
                      advertismentOK: true
                    };

                    this.cookiesService.setCookieConsent(consentOptions);
                    this.globalVars.isCookiesAccept = true;
                    if (this.platform.is('capacitor') && this.isMobile) {
                      await this.appsFlyerTrackingService.startAppsFlyerSdk();
                    }

                    this.cookiesService.setCookie(
                      CookieNames.ConsentStatusCookies,
                      JSON.stringify(consentOptions),
                      365 * 24 * 60 * 60 * 1000,
                      true
                    );

                    if (this.globalVars.FEATURES.MSO_alertCookiesNoBlock) {
                      this.globalVars.isCookiesAccept = true;
                      if (this.globalVars.actionAfterCookiesAccept) {
                        switch (this.globalVars.actionAfterCookiesAccept) {
                          case 'login':
                            this.openLogin(this.globalVars.callbackAfterLoginAfterCookiesAccep);
                            break;
                          case 'reg':
                            this.openRegistro();
                            break;
                        }
                        this.globalVars.actionAfterCookiesAccept = null;
                      }
                    }
                  }
                }
              ]
            };
          }

          this.alertCookiesOptions = alertOptions;

          if (this.globalVars.FEATURES.MSO_alertCookiesBtnDecline) {
            this.alertCookiesOptions.cssClass += ' cookiesReyected';
            this.alertCookiesOptions.buttons.push({
              cssClass: 'reyectBtnCookie',
              role: 'cancel',
              text: this.translate.instant('btndecline'),
              handler: async () => {
                const consentOptions: INTERFACE.ConsentOptionsData = {
                  customOK: false,
                  analyticsOK: false,
                  advertismentOK: false
                };

                this.cookiesService.setCookieConsent(consentOptions);
                if (this.platform.is('capacitor') && this.isMobile) {
                  await this.appsFlyerTrackingService.setAppsFlyerConsent(consentOptions);
                }
              }
            });
            this.alertCookiesOptions.buttons.push({
              cssClass: 'configurationBtnCookie',
              role: 'config',
              text: this.translate.instant('uso_cookiesES_title'),
              handler: () => {
                this.openCookiesConsent();
              }
            });
          }
          if (
            (this.globalVars.isNative && this.platform.is('cordova')) ||
            (!this.globalVars.isNative && !this.platform.is('cordova'))
          ) {
            this.openAlertCookies();
          } else {
            this.globalVars.isCookiesAccept = true;
          }
        }
      } else if (this.globalVars.licenseType == LicenseTypes.Nacional) {
        const analyticsCookie = JSON.parse(localStorage.getItem('cookieConsent'));
        this.cookiesService.activateCookies('DY', false, analyticsCookie.analyticsOK);
      }
      this.cookiesService.appleITP();
    }
  }

  async openAlertCookies() {
    this.alertCookies = await this.utils.alertController.create(this.alertCookiesOptions);
    this.alertCookies.present().then(() => {
      this.alertCookies.isOpen = true;
      const element = this.alertCookies.querySelector('.footer-link');
      if (element) {
        element.addEventListener('click', this.openCookiesConsent.bind(this));
        element.addEventListener('tap', this.openCookiesConsent.bind(this));
      }
      if (this.globalVars.FEATURES.MSO_alertCookiesNoBlock && this.globalVars.licenseType == LicenseTypes.Nacional) {
        const alertwrapper = this.alertCookies.querySelector('.alert-wrapper') as HTMLElement;
        const alerth = alertwrapper.clientHeight;
        this.alertCookies.style.height = alerth + 'px';
        this.alertCookies.style.width = '100%';
        this.alertCookies.style.top = 'unset';
      }
    });
  }
  openPageMenuUser(event) {
    this.trackingService.trackEvent(['openUserMenu', '', '', '', 'event']);
    const miItem: C_ItemMenu = new C_ItemMenu('Mis apuestas', 'misApuestasPage', 'misApuestasPage.name', true);

    this.itemMenuClick(miItem, 'right', event);
  }

  openXPInbox() {
    if (this.platform.is('cordova')) {
      if (this.globalVars.user.logged) {
        window['XtremePush'].openInbox();
      } else {
        if (this.isMobile) {
          this.menu.close('left');
        }
        this.nativeService.checkTouchId();
      }
    }
  }

  accordionGroupChange(event: any) {
    this.itemsMenu$.subscribe((items) => {
      this.accordionIndexs = this.accordionIndexs.filter((index) => event.detail.value.includes(items[index].title));
      items.forEach((item, index) => {
        if (event.detail.value.includes(item.title) && !this.accordionIndexs.includes(index)) {
          this.accordionIndexs.push(index);
        }
      });
    });
  }

  async itemMenuClick(item: C_ItemMenu | C_ItemsMenu, side: string, event = null) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.isMobile) {
        this.menu.close('right-menu');
      }
    }

    if (item.isPrivate && !this.globalVars.user.logged) {
      this.nativeService.checkTouchId();
      this.globalVars.afterLoginUrl = item.link;
      this.globalVars.afterLoginParamsUrl = item.params;
    } else {
      if (item.link) {
        //caso especial. se comprueba antes de entrar si puede vincular tarjeta
        if (item.link == 'CardLinkPage') {
          this.userService.checkCanSendCodere().subscribe(
            (data) => {
              if (!data.success) {
                if (this.isMobile && this.menu.isOpen('right')) {
                  this.menu.close(side);
                }
                this.router.navigate([`/${MSO_PATHS.BASE}/${item.link}`], {});
              } else {
                if (this.isMobile && this.menu.isOpen('right')) {
                  this.menu.close(side);
                }
                this.utils.confirm(
                  false,
                  this.translate.instant('tFail'),
                  this.translate.instant('associateCard'),
                  this.translate.instant('tCancel'),
                  () => {},
                  this.translate.instant('check'),
                  () => {
                    this.events.publish('documentverification:open');
                  },
                  false,
                  'displayrow'
                );
              }
            },
            (err) => {
              this.utils.confirm(
                false,
                this.translate.instant('tFail'),
                this.translate.instant('associateCard'),
                this.translate.instant('tCancel'),
                () => {},
                this.translate.instant('check'),
                () => {
                  this.events.publish('documentverification:open');
                },
                false,
                'displayrow'
              );
            }
          );
        }
        // Caso especial, comprobamos si el usuario puede cobrar en local codere
        else if (item.link == 'ChargeCoderePage' && this.globalVars.FEATURES.CobLocalEnabled) {
          this.userService.checkPayment(this.globalVars.user.name).subscribe(
            (data) => {
              const transactionResult = <TransactionActionResult>data;
              if (transactionResult.success) {
                this.openModalOrPage(item.link, {}, true, 'modalh800 is-modal');
              } else {
                this.errorService
                  .searchErrorCodeWithParams(transactionResult.errCode.toString(), transactionResult.errorParameters)
                  .subscribe({
                    next: (data2) => this.utils.showError(data2, null),
                    error: () => this.utils.showError(transactionResult.errMessage, null)
                  });
              }
            },
            (err) => {}
          );
        } else if (
          // TODO ERRORES to be reviewed by PAYMENTS team
          item.link == 'CashierPage' ||
          // item.link == 'DepositOnlinePage' ||
          item.link == 'ChargeOnlinePage' ||
          // item.link == 'DepositCoderePage' ||
          (item.link == 'ChargeCoderePage' && this.globalVars.FEATURES.MexCobLocalEnabled)
        ) {
          // TODO ERRORES to be reviewed by PAYMENTS team
          // if (item.link == DepositOnlinePage) {
          //   this.trackingService.trackEvent(['ClickOpenDeposit', 'InnerPageMenu', , '', 'event']);
          // }
          const params = item.params ? item.params : {};
          if (item.link == 'CashierPage') {
            this.openModalOrPage(`${PAYMENTS_PATHS.BASE}/${item.link}`, params, true, 'modalCashier is-modal');
          } else {
            this.openModalOrPage(item.link, {}, true, 'modalh800 is-modal');
          }
        } else {
          //en otro caso se accede directamente a la opción
          // cuando se viene de marquee el link es una entidad y no una cadena
          if (item.link == 'misApuestasPage' || item.link.name == 'misApuestasPage') {
            const filterParam = { dateValue: 0, typeValue: 1 };
            this.router.navigate([SPORTS_PATHS.MisApuestasPage], {
              state: { params: filterParam }
            });
          } else {
            if (item.link === 'ResponsibleGamblingTestPage' && !this.isDesktop) {
              const modal = await this.modalController.create({
                component: ResponsibleGamblingTestPage,
                cssClass: ' is-modal'
              });
              await modal.present();
              return;
            }
            if (item.link == 'SlotsBonusPage') {
              this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.SlotsBonusPage}`], {
                queryParams: { params: item.params }
              });
            } else {
              switch (item.link) {
                case SPORTS_PATHS.FreeBetsPage:
                case SPORTS_PATHS.MisApuestasPresencialesPage:
                  this.router.navigate([`/${SPORTS_PATHS.BASE}/${item.link}`], {});
                  break;
                case CASINO_PATHS.SlotsHistoryPage:
                  this.router.navigate([`/${CASINO_PATHS.BASE}/${item.link}`], {});
                  break;
                case PAYMENTS_PATHS.CheckTicketPage:
                case PAYMENTS_PATHS.PLAYSSTPAGE:
                  this.router.navigate([`/${PAYMENTS_PATHS.BASE}/${item.link}`], {});
                  break;
                default:
                  if (item.link.name == MSO_PATHS.CuotaTypePage) {
                    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.CuotaTypePage}`], {});
                  }
                  if (item.link == MSO_PATHS.SelfExclusionPage) {
                    if (
                      this.globalVars.licenseType == LicenseTypes.Colombia &&
                      this.globalVars.extraUserData.isRevocationPage
                    ) {
                      this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.SelfExclusionPage}`], {
                        queryParams: { revocation: true }
                      });
                    } else {
                      this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.SelfExclusionPage}`], {});
                    }
                  } else {
                    this.router.navigate([`/${MSO_PATHS.BASE}/${item.link}`], {});
                  }
                  break;
              }
            }
          }
        }
      }
    }
  }

  async checkConnected(afterCheckConnectedCallback: () => void) {
    //checks if there is a current user connected
    const jwt = this.fromToRedirectJWT();
    if (jwt != '') {
      try {
        await this.redirectService.loginfromJWT(jwt).toPromise();
      } catch (err) {
        console.error(err);
      }
    }
    this.userService
      .getCurrentUser()
      .pipe(
        finalize(() => {
          afterCheckConnectedCallback();
        })
      )
      .subscribe({
        next: (data) => {
          if (data) {
            if (data.idBGT) {
              this.globalVars.user.idBGT = data.idBGT;
            }
            if (data.sessionOut) {
              //
              this.utils.showError(this.translate.instant('m_menu_TimeOut'), () => {
                // no hacemos nada para no entrar en bucle
              });
              //
              this.slotsService.closeSlotSessions().subscribe({ error: () => {} });
            } else {
              if (data.status == 'ok') {
                //connected
                if (this.globalVars.FEATURES.MSO_GraceTimeLogicEnabled) {
                  this.globalVars.extraUserData.documentationVerification = data.documentationVerification;
                  if (data.documentationVerification) {
                    this.globalVars.extraUserData.graceTime = false;
                  } else {
                    const timenow = new Date();
                    const graceTime = new Date(data['registratedDate']);
                    graceTime.setDate(graceTime.getDate() + 7);
                    this.globalVars.extraUserData.graceTime = timenow.getTime() <= graceTime.getTime();
                  }
                }
                this.globalVars.extraUserData.acceptanceComunication = !!data['acceptanceComunication'];
                this.globalVars.user.username = data.username.toLowerCase();
                if (this.globalVars.licenseType == LicenseTypes.Nacional) {
                  this.globalVars.extraUserData.professionOrPrpMissing = data['professionOrPrpMissing'] || '';
                  this.globalVars.extraUserData.tycSelfie = !!data['policyPrivicyVerification'];
                  this.utils
                    .setGTMTrackers()
                    .then((_) => {})
                    .catch((err) => {
                      this.errorService.logService.error('eventLoginTrakingError', err);
                    });
                }
                if (this.globalVars.licenseType == LicenseTypes.Colombia) {
                  this.globalVars.extraUserData.isRevocationPage = !!data['canRevocate'];
                  this.globalVars.extraUserData.pptNotVerification = !!data['isPPT'];
                }
                this.globalVars.user.registratedDate = new Date(data['registratedDate']);
                this.globalVars.user.lastLoginDate = new Date(data['lastLoginDate']);
                this.globalVars.user.firstDeposit = data['firstDeposit'];
                this.globalVars.user.balance = data.balance;
                this.globalVars.user.logged = true;
                if (this.globalVars.licenseType == LicenseTypes.Panama) {
                  this.globalVars.isProhibited = data.isProhibited;
                }
                if (this.globalVars.FEATURES.csrfControlFront) {
                  this.globalVars.extraUserData.csrfControl = data['csrfControl'];
                }
                this.checkInitSessionDate();

                this.globalVars.user.userRegion = this.globalVars.LICENSE_TYPE;
                this.globalVars.licenseType = this.globalVars.LICENSE_TYPE;

                //GET CUSTOMIZATIONS
                this.userService.getCustomizations().subscribe((val) => {
                  if (val.success) {
                    this.utils.getCuotaAccept(val.customizations);
                  }
                });

                if (this.userMigrationService.isMadridUserMigrationRequired()) {
                  this.userMigrationService.migrateMadridUser();
                } else {
                  this.events.publish('user:login');
                  this.nativeService.checkNotificationNative();
                  this.events.publish('navbarpc:refresh');
                }
                //if user is logged (reload page) try get location ONLY if already granted
                if (this.globalVars.FEATURES.MSO_EnableGeolocation) {
                  if (this.globalVars.FEATURES.MSO_TimerGeolocation) {
                    this.geolocationService.setLoginGeolocation();
                  } else {
                    navigator.permissions &&
                      navigator.permissions.query({ name: 'geolocation' }).then((PermissionStatus) => {
                        if (PermissionStatus.state == 'granted') {
                          this.geolocationService.getGeolocation();
                        } else if (PermissionStatus.state == 'prompt') {
                          // prompt - not yet grated or denied
                          this.geolocationService.showGeolocation();
                        } else {
                          //denied
                          this.geolocationService.showGeolocation();
                        }
                      });
                  }
                }
                this.globalVars.user.id = data.id;
                this.globalVars.user.email = data.email;

                if (data.userCardNumber) {
                  this.globalVars.user.codereCard.cardNumber = data.userCardNumber;
                  this.globalVars.user.codereCard.active = true;
                  this.globalVars.user.codereCard.region = data.userCardNumber.substring(2, 2);
                } else {
                  this.globalVars.user.codereCard.active = false;
                }
                this.subscribeToRoyalDegreeSignalR();
              } else {
                //not connected

                //check native auto login
                const userNative: string = localStorage.getItem('userNative');
                const passNative: string = localStorage.getItem('passNative');

                if (!!userNative && !!passNative) {
                  let loginService: Login;
                  loginService.doAutoLoginNative().subscribe(
                    () => {
                      //
                      this.nativeService.checkNotificationNative();
                      this.redirectPage();
                    },
                    () => {
                      this.nativeService.checkNotificationNative();
                      this.redirectPage();
                    }
                  );
                } else {
                  this.redirectPage();
                }
              }
            }
          }
        },
        error: (error) => console.error(error),
        complete: () => {
          //no action
          this.redirectPage();
        }
      });
  }

  async doLoginFromTouchId(user: string, pass: string) {
    const loginService: Login = new Login(
      this.trackingService,
      this.translate,
      this.extractDatePipe,
      this.parseMoney,
      this.geolocationService,
      this.messageService,
      this.slotsBonusesService,
      this.logService,
      this.userMigrationService,
      this.cookiesService,
      this.userService,
      this.userServiceMSO,
      this.errorService,
      this.utils,
      this.nativeService,
      this.newAuthenticationService,
      this.legacyAuthenticationService,
      this.modalController,
      this.alertController,
      this.platform,
      this.modalController,
      this.events,
      this.router,
      this.params,
      this.newBaseService,
      this.biometricService,
      this.appsflyerTrackingService
    );
    if (this.modalTouchId) this.modalTouchId.dismiss(null);
    // ToAnswer: here old-codere send password encrypte for plugin cordova-plugin-keychain-touch-id?
    // respond is yes: remove decrypt
    // BiometricService encrypt password for security mode SHA-1
    loginService.loginTouchId(user, pass);
  }

  accessLoginFromTouchId(opc: string) {
    opc = opc.toLowerCase();
    const loginMenu = opc !== 'false';
    if (!loginMenu && !this.globalVars.loginOpen) {
      this.globalVars.loginOpen = true;
      this.modalTouchId.present();
    }
  }
  nativeCall() {
    this.nativeService.checkTouchIdCall();
  }

  checkVersionIOS() {
    // todo: mirar en Capacitor
    /* if (this.globalVars.deviceType == 'IOS') {
      try {
        window.location.href = 'js2ios://getappversion';
        window['webkit'].messageHandlers.js2ios.postMessage('getappversion');
      } catch (e) { }
    }
    if (this.globalVars.deviceType == 'ANDROID') {
      try {
        window['CodereNativeAppAndroid'].getappversion();
      } catch (e) { }
    } */
  }

  hideLoadingNative() {
    if (this.globalVars.platforms[2].toLowerCase() == 'ios') {
      window.location.href = 'js2ios://hideLoadingNative';
      window['webkit'].messageHandlers.js2ios.postMessage('hideLoadingNative');
    } else if (this.globalVars.platforms[2].toLowerCase() == 'android') {
      try {
        window['CodereNativeAppAndroid'].hideLoadingNative();
      } catch (e) {
        // todo: revisar
        /* this.routerService.gotoPage(this.globalVars.gNav, this.globalVars.mainPageApp, {}); */
        this.events.publish('loader:dismiss', null);
      }
    } else {
      // todo: revisar
      /* this.routerService.gotoPage(this.globalVars.gNav, this.globalVars.mainPageApp, {}); */
      this.events.publish('loader:dismiss', null);
    }
  }
  appVersion(version: string) {
    this.globalVars.nativeIOSVersion = version;
    this.globalVars.nativeIOSTouchIdAvailable = true;
    if (this.globalVars.platforms[2].toLowerCase() == 'android') {
      if (version != this.globalVars.FEATURES.nativeAndroidVersion) {
        this.trackingService.trackEvent([
          'UpdateAndroidPoppup',
          version,
          this.globalVars.FEATURES.nativeAndroidVersion,
          'Poppup actualizar android',
          'event'
        ]);
        this.utils.alert(false, '', this.translate.instant('msgUpdateAndroidApp'), 'Descargar', () => {
          const url: string = location.origin + location.pathname + '#/DownloadApp';
          window.open(url, '_blank');
          location.href = url;
        });
      }
    }
  }

  checkInitSessionDate() {
    this.newUserService.getSessionInitDate().subscribe((data) => {
      const initDate = new Date(+data.match(/Date\((\d+)\)/)[1]);
      this.globalVars.sessionInitDate = initDate;
    });
  }

  redirectPage() {
    let namePageUrl = this.location.path().split('?')[0].substr(1);
    if (namePageUrl.includes('casino')) namePageUrl = this.location.path();
    if (namePageUrl == '') {
      if (this.storedHash == '') {
        this.router.navigateByUrl(this.globalVars.mainPageApp);
      } else {
        this.tryToGoPage(this.storedHash);
      }
    } else {
      const sportPage: Array<string> = [
        SPORTS_PATHS.SpecialTeamPage,
        SPORTS_PATHS.EventoPage,
        SPORTS_PATHS.EventoSpecialPage,
        SPORTS_PATHS.HorsesMarketPage,
        SPORTS_PATHS.HorsesPage,
        SPORTS_PATHS.MercadosPage,
        SPORTS_PATHS.NowLiveDetailPage,
        SPORTS_PATHS.SportCountriesPage,
        SPORTS_PATHS.SportEventsPage
      ];
      let namePageUrl2Navigate = namePageUrl;
      if (typeof namePageUrl == 'string' && sportPage.includes(namePageUrl)) {
        namePageUrl2Navigate = 'HomePage';
      }
      this.tryToGoPage(namePageUrl2Navigate, namePageUrl);
    }
  }

  listenToEvents() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.isMobile) {
        this.navService.initRightMenu();
      }
    });
    this.events.subscribe('user:cordovaTouchIdLogin', async (data: any) => {
      const decryptPassword = await this.encryptService.decrypt(data.password);
      this.doLoginFromTouchId(data.user, decryptPassword);
    });

    this.events.subscribe('loading:start', () => {
      this.numLoadingActivos++;
    });

    this.events.subscribe('loading:finish', () => {
      this.numLoadingActivos--;
      if (this.numLoadingActivos < 0) {
        this.numLoadingActivos = 0;
      }

      if (this.numLoadingActivos <= 0) {
        //console.info('event:loading:finish->' + this.numLoadingActivos);
      }
    });

    this.events.subscribe('user:licenseChange', () => {
      // triggers when the license has changed, in login/logout/etc...
      //reloadFeatures -> reloadSideMenus
      this.getFeatures(false); //param: is first launch
    });

    this.events.subscribe('user:refreshToken', () => {
      //reloads the antiforgery token
      // TODO getToken()
      //getToken();
    });

    //on logout
    this.events.subscribe('user:logout', () => {
      this.toggleMiniGame$ = true;
      this.deleteCookiesOnLogout();
      if (this.globalVars.FEATURES.csrfControlFront) {
        this.globalVars.extraUserData.csrfControl = null;
      }
      this.globalVars.extraUserData.pptNotVerification = false;
      this.globalVars.extraUserData.dniExpired = false;
      this.globalVars.extraUserData.haveProfesion = false;
      this.globalVars.extraUserData.documentationVerificationStatus = undefined;
      if (this.globalVars.FEATURES.MSO_EnableGeolocation && this.globalVars.FEATURES.MSO_TimerGeolocation) {
        this.geolocationService.resetGeolocationTimer();
      }
      try {
        localStorage.removeItem('userNative');
        localStorage.removeItem('passNative');
        localStorage.removeItem('slot_tournaments');
        localStorage.removeItem('slot_tournament_selected');
      } catch (err) {}
      //
      this.cookiesService.removeCookie(CookieNames.CodereUser);
      this.xStateService.removeXState();
      this.xStateService.removeAuthorization();
      this.events.publish('logout');
      this.events.publish('videoplayer:close');
      this.initSingleLobby = false;
      this.casinoService.unapplyMoreFilters(true);
    });

    this.events.subscribe('user:logout:soft', () => {
      this.events.publish('user:sessionTimeout');
    });

    this.events.subscribe('user:sessionTimeout', () => {
      //close menu if open
      if (this.menu.isOpen('right')) {
        this.menu.close('right');
      }

      this.utils.showError(this.translate.instant('m_menu_TimeOut'), () => {
        this.events.publish('logout');
      });
    });

    this.events.subscribe('logout', () => {
      this.destroySub();
      this.signalRService.stop(CONSTANTS.royalDegree);
      //close menu if open
      if (this.menu.isOpen('right')) {
        this.menu.close('right');
      }
      if (this.globalVars.FEATURES.isOrdenCongelamientoFondos) {
        this.globalVars.isOrdenCongelamientoFondos = false;
      }
      if (this.globalVars.licenseType == LicenseTypes.Panama) {
        const bodyContainer = document.querySelector('body');
        bodyContainer.removeEventListener('click', () => {});
        this.contadorTimeLogout = 0;
        clearInterval(this.intervalTimeLogout);
      }
      this.slotsService.getSessionLimits().subscribe(
        (data2) => {
          //
          if (data2.Active) {
            // Si es licencia mexico no mostramos el resumen.
            if (!!this.globalVars.slotWindow && !!this.globalVars.slotWindow.window) {
              this.globalVars.slotWindow.window.close();
              this.globalVars.slotWindow = null;
            }
            if (this.globalVars.licenseType == LicenseTypes.Mexico) {
              this.endLogout();
            } else {
              const bets: string = data2.BetsValue;
              const wins: string = data2.WinsValue;
              let msg: string = this.translate.instant('hPay') + ': ' + this.parseMoney.transform(bets, [1]) + '<br>';
              msg += this.translate.instant('prizes') + ': ' + this.parseMoney.transform(wins, [1]) + '<br>';
              this.utils.alert(
                false,
                this.translate.instant('SessionSummary'),
                msg,
                'OK',
                () => {
                  this.endLogout();
                },
                'alertResumen'
              );
              try {
                this.slotsService.closeSlotSessions(this.globalVars.user.username).subscribe(
                  (err) => {
                    console.error(err);
                  },
                  () => {
                    //console.info('finally closesses');
                  }
                );
              } catch (err) {}
            }
          } else {
            this.endLogout();
          }
        },
        (err) => {
          //
          console.error(err);
        }
      );
    });

    //on server fails
    this.events.subscribe('user:serverfail', (error) => {
      //close menu if open
      if (this.menu.isOpen('right')) {
        this.menu.close('right');
      }

      this.trackingService.trackEvent([
        'ServerFail',
        '',
        'serverfail',
        // eslint-disable-next-line no-nested-ternary
        'Error: ' + error ? error.status : 'not defined' + ', Error Message: ' + error ? error.message : 'not defined',
        'event'
      ]);

      console.error('Error >>', JSON.stringify(error));

      // this.utils.showError(this.translate.instant('m_menu_serverFail'), () => {
      //   //sessiontimeout
      //   this.events.publish('user:refreshToken');
      //   this.router.navigate(['/']);
      // });
    });

    this.events.subscribe('user:serverfailmaintenace', (error) => {
      //close menu if open
      if (this.menu.isOpen('right')) {
        this.menu.close('right');
      }

      console.error('Error >>', JSON.stringify(error));

      // this.utils.showError(this.translate.instant('GetCardError'), () => {
      //   //serverfailmaintenace
      //   this.events.publish('user:refreshToken');
      //   this.router.navigate(['/']);
      // });
    });

    this.events.subscribe('user:serverfail:soft', () => {
      //close menu if open
      if (this.menu.isOpen('right')) {
        this.menu.close('right');
      }

      this.utils.showError(this.translate.instant('m_menu_serverFail'), () => {
        //sessiontimeout
        this.events.publish('user:refreshToken');
        this.router.navigate(['/']);
      });
    });

    this.events.subscribe('imgStreamingFail', () => {
      this.utils.alert(true, 'Disculpe', 'Evento no disponible', 'OK', null);
    });

    this.events.subscribe('watchbetStreamingFail', () => {
      this.utils.alert(true, 'Disculpe', "Evento no disponible ('Clave invalida')", 'OK', null);
    });

    this.events.subscribe('changePageCasino:finish', () => {
      if (this.globalVars.FEATURES.Casino_EnabledMultiCallBonuses) {
        this.slotsBonusesService.showBonusesWaitingMessages();
      }
    });
    this.events.subscribe('forceRefreshBalance', () => {
      this.refreshBalance(true);
    });

    this.events.subscribe('casinoTrackNative', () => {
      this.nativeService.setNativeForCasinoTrack();
    });

    this.events.subscribe('user:login', () => {
      this.events.publish('user:licenseChange');
      if (!this.globalVars.comeFromLogin) {
        this.events.publish('setUserInfoCookie');
        this.globalVars.comeFromLogin = false;
      }
      if (this.globalVars.FEATURES.messageServiceEnabled) {
        this.messageService.getMessageCount().subscribe(
          (data) => {
            this.messageService.updateMessageCount(data);
          },
          (err) => {
            // Error
            console.error(err);
          }
        );
      }

      if (this.globalVars.FEATURES.isOrdenCongelamientoFondos) {
        this.userServiceMSO.checkOrdenCongelamientoFondos().subscribe({
          next: (data) => {
            this.globalVars.isOrdenCongelamientoFondos = data.success;
          },
          error: (err) => (this.globalVars.isOrdenCongelamientoFondos = false)
        });
      }
      //check tarjeta codere
      // ->
      //this.loadCodereCard();
      if (this.globalVars.user.codereCard.active) {
        this.navService.initRightMenu(false);
      } else {
        if (this.globalVars.licenseType == LicenseTypes.Mexico) {
          this.loadWinLineCard();
        }
      }
      //add cookie for user logged SharePoint
      if (!this.cookiesService.getCookie(CookieNames.LoggedInApplicationSite)) {
        this.cookiesService.setCookie(CookieNames.LoggedInApplicationSite, 'true', 365 * 24 * 60 * 60 * 1000, true);
      }
      //TimeLogout Panamá
      if (this.globalVars.licenseType == LicenseTypes.Panama && this.globalVars.FEATURES.usertimelogout) {
        const bodyContainer = document.querySelector('body');
        this.dateTimeLogout = new Date();
        this.dateTimeLogout.setSeconds(
          this.dateTimeLogout.getSeconds() + Number.parseInt(this.globalVars.FEATURES.usertimelogout)
        );
        bodyContainer.addEventListener('click', () => {
          this.contadorTimeLogout = 0;
          this.dateTimeLogout = new Date();
          this.dateTimeLogout.setSeconds(
            this.dateTimeLogout.getSeconds() + Number.parseInt(this.globalVars.FEATURES.usertimelogout)
          );
        });
        this.intervalTimeLogout = setInterval(() => {
          this.contadorTimeLogout += 1;
          // const maxTime = 1800; 30 minutos
          const maxTime = Number.parseInt(this.globalVars.FEATURES.usertimelogout);
          if (new Date().getTime() >= this.dateTimeLogout.getTime() || this.contadorTimeLogout >= maxTime) {
            clearInterval(this.intervalTimeLogout);
            this.utils.showError(this.translate.instant('m_menu_TimeOut'), () => {
              this.events.publish('logout');
            });
          }
        }, 1000);
      }
      if (this.globalVars.partner === 'mvp') {
        this.globalVars.mvpData.login = '1';
        this.trackingService.trackEvent(['MVPLogin', 'OK', this.globalVars.user.username, '', 'event']);
      }

      //close slot sessions??
      //checks whether or not to close the slot session
      //this.closeSlotSessions(this.globalVars.user.username);

      this.loadWCPointsAndPosition();

      //checkSmsEmail
      let pendingSMS = false;
      this.userService.checkSmsEmail(this.globalVars.user.username).subscribe((data) => {
        if (data.elegible) {
          pendingSMS = true;
          //tiene un mensaje pendiente de confirmar
          //this.utils.showPopUp("adviceSMS", null);
        }
      });

      //push notification

      //TODO: ACTIVE AGAIN WHEN PLUGIN IS ADDED

      // TODO: REVISAR CON CAPACITOR
      if (this.globalVars.ENVIRONMENT == 'mobile') {
        //registramos push en xtremepush y guardamos el deviceid
        this.nativeService.requestXtremePushPermissions(this.globalVars.user.idBGT);
        this.appsFlyerTrackingService
          .setCustomerUserId({ cuid: this.globalVars.user.idBGT.toString() + '_3' })
          .then((_) => {
            console.log('Appsflyer setCustomerUserId: OK');
          })
          .catch((err) => {
            console.error('Appsflyer setCustomerUserId: KO');
            console.error(err);
          });
      }

      if (this.globalVars.licenseType == LicenseTypes.ArgentinaCaba && window['_adftrack']) {
        // AdForm Pixel Code for
        // eslint-disable-next-line no-nested-ternary
        window['_adftrack'] = Array.isArray(window['_adftrack'])
          ? window['_adftrack']
          : window['_adftrack']
            ? [window['_adftrack']]
            : [];
        window['_adftrack'].push({
          HttpHost: 'track.adform.net',
          pm: 2326828,
          divider: encodeURIComponent('|'),
          pagename: encodeURIComponent('Login'),
          order: {
            orderid: '<insert order id value here>'
          }
        });
        (function () {
          const s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js';
          const x = document.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        })();
      }
      // End of AdForm Pixel Code

      if (this.globalVars.afterLoginUrl !== '' && this.globalVars.afterLoginUrl !== null) {
        if (this.cookiesService.getCookie(CookieNames.IforiumPromo) !== null) {
          //there are a cockie for sigunp so return to alow the flow
          return;
        }
        this.loadAfterLoginPage();
      } else {
        let single = false;
        this.singleLobbies.forEach((ele) => {
          const lobby = ele.LobbyName;
          if (window.location.hash.includes(lobby) && !this.initSingleLobby) {
            single = true;
          }
        });
        // TODO REVIEW currentPage
        // if ((single && !this.initSingleLobby) || this.globalVars.currentPage == LoadingPage) {
        //   this.redirectPage();
        // }
      }

      //reloads iFrame in some pages
      // if (this.globalVars.currentPage == CopaAmericaPage) {
      //   this.nav.push(CopaAmericaPage);
      // }

      // if (this.globalVars.currentPage == CodereMillionPage) {
      //   this.nav.push(CodereMillionPage);
      // }
      // if (this.globalVars.currentPage == MvpCoderePage) {
      //   this.globalVars.mvpData.login = '1';
      //   this.nav.push(MvpCoderePage);
      // }

      try {
        //llamada appInsights
        this.trackingService.setAuthenticatedUserContext(this.globalVars.user.username);
      } catch (err) {}

      this.globalVars.loginOpen = false;

      // Register player in Game provider system (Nacional: IMS, Mexico: Iforium...)
      this.slotsBonusesService.signupIfRequired();

      // If there is an IForium promo cookie, stores it #67436
      this.slotsBonusesService.storeIforiumPromoCookie();

      //Check bonus
      this.slotsBonusesService.showBonusesWaitingMessages().then((isIframe) => {
        if (isIframe) {
          this.urlPlaytechIframe = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${this.globalVars.URLBASE_CASINO_BONUSES}/PlayTech/PlayerLogin`
          );
        }
      });

      //Check in Argentina for changes in your limits
      if (this.utils.isArgentina()) {
        this.checkLimitsChange();
      }

      //documentationVerification - PA
      if (this.globalVars.FEATURES.MSO_GraceTimeLogicEnabled) {
        if (!this.globalVars.extraUserData.graceTime && !this.globalVars.extraUserData.documentationVerification) {
          const literalMsg: string =
            this.globalVars.licenseType == LicenseTypes.Panama ? 'gracetimeloginpa' : 'gracetimeloginAr';
          this.utils.alert(false, '¡Coderista!', this.translate.instant(literalMsg), 'OK', () =>
            this.events.publish('cashier:open', { section: 'documents', cashierEmpty: false })
          );
        }
      }

      if (
        this.globalVars.licenseType == LicenseTypes.Nacional &&
        this.globalVars.extraUserData.professionOrPrpMissing !== ''
      ) {
        // TODO extraUserData.professionOrPrpMissing
        // this.alertPrpProfesion(this.globalVars.extraUserData.professionOrPrpMissing);
      }
      //fiveLoginOK AR
      if (this.globalVars.FEATURES.FiveLoginPopup && this.globalVars.fiveLoginOK) {
        this.utils.modalFiveLoginOrAutoexclusionAr(true);
      }

      if (this.globalVars.extraUserData.limitsExpired) {
        this.showAlertLimitsUpdate();
      }

      if (this.globalVars.extraUserData.isRevocationPage) {
        this.showRevocationAlert();
      }

      if (this.globalVars.extraUserData.acceptanceComunication) {
        this.updateComunicatios();
      }
      this.additionalAlertLogin();

      if (!this.utils.colombiaBonusModal) {
        if (
          this.globalVars.FEATURES.MSO_EnabledComplianceRegistroCO &&
          this.globalVars.extraUserData.pptNotVerification
        ) {
          this.showAlertPPT();
        } else {
          this.userWelcomeBonus();
        }
      } else {
        if (
          this.globalVars.FEATURES.MSO_EnabledComplianceRegistroCO &&
          this.globalVars.extraUserData.pptNotVerification
        ) {
          this.showAlertPPT();
        }
      }
      this.subscribeToRoyalDegreeSignalR();
    });

    this.events.subscribe('callRequestComplianceCO', () => {
      this.requestComplianceCO();
    });

    this.events.subscribe('user:openLogin', () => {
      this.openLogin();
    });

    this.events.subscribe('setUserInfoCookie', () => {
      this.setLoginCookies();
    });

    this.events.subscribe('cashier:open', (data = {}) => {
      const cashierEmpty: boolean = data.cashierEmpty != undefined ? data.cashierEmpty : true;
      this.openModalOrPage(
        `/${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`,
        { ...data, cashierEmpty },
        true,
        'modalCashier is-modal'
      );
    });
    this.events.subscribe('documentverification:open', () => {
      this.openModalOrPage(
        `/${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`,
        { section: 'documents' },
        true,
        'modalCashier is-modal'
      );
    });
    this.events.subscribe('badges:refresh', () => {
      this.navService.bindBadges();
    });

    this.events.subscribe('card:refresh', () => {
      //the user has get a new card
      this.loadCodereCard();
    });

    this.events.subscribe('cookies:showAlert', () => {
      this.openAlertCookies();
    });

    this.events.subscribe('popover:open', () => {
      this.addClickAndScrollListeners();
    });

    this.events.subscribe('load:afterlogin', () => {
      this.loadAfterLoginPage();
    });

    this.events.subscribe('welcome-panama', () => {
      const welcomeMessage = this.translate.instant('txtregistropaok');
      const welcomePA = this.utils.alert(false, this.translate.instant('welcomecodere'), welcomeMessage, 'OK');
    });
    this.events.subscribe('initSingleLobby', () => {
      this.initSingleLobby = true;
    });
  }

  loadAfterLoginPage() {
    if (this.globalVars.afterLoginUrl == ChargeCoderePage) {
      this.openModalOrPage(this.globalVars.afterLoginUrl, this.globalVars.afterLoginParams, false);
    }
  }

  endLogout() {
    if (this.menu.isOpen('right')) {
      this.menu.close('right');
    }
    /// UPDATE BAU:  se mantiene siempre en la última licencia que entró
    //  no hay que cambiar de licencia cuando sale
    //
    //Licencia Nacional por defecto
    if (this.globalVars.user.logged) {
      this.globalVars.user = new C_User(false);
      this.cookiesService.removeCookie(CookieNames.CodereUser);
      const authenticationService = this.globalVars.FEATURES.NewLoginServices
        ? this.newAuthenticationService
        : this.legacyAuthenticationService;
      authenticationService.logout().subscribe(() => {
        this.router.navigate(['/']);
      });
    }

    setTimeout(() => {
      this.events.publish('user:refreshToken');
      this.navService.bindBadges();
      this.router.navigate(['/']);
      this.globalVars.user.logged = false;
      this.events.publish('navbarpc:refresh');
    }, 800);
  }

  checkLimitsChange() {
    this.userServiceMSO.checkLimitsChange().subscribe((data) => {
      if (data.isSuccess) {
        this.utils.alert(false, this.translate.instant('tInf'), this.translate.instant('newlimitsar'), 'OK');
      }
    });
  }

  loadCodereCard() {
    //loads the codere card info
    this.userServiceMSO.loadCodereCard().subscribe(
      (data) => {
        if (data.success) {
          this.globalVars.user.codereCard.active = true;
          this.globalVars.user.codereCard.cardNumber = data.cardnumber;
          this.globalVars.user.codereCard.region = data.cardnumber.substr(2, 2);
          //init menu
          this.navService.initRightMenu(false);
        } else this.globalVars.user.codereCard.active = false;
      },
      (err) => {
        //
        this.globalVars.user.codereCard.active = false;
      },
      () => {
        if (this.globalVars.licenseType == LicenseTypes.Mexico && !this.globalVars.user.codereCard.active) {
          this.loadWinLineCard();
        }
      }
    );
  }

  loadWinLineCard() {
    this.userService.loadWinLineCard().subscribe(
      (data) => {
        if (!data.success) {
          this.globalVars.withoutWinlineCard = true;
          this.navService.initRightMenu(false);
        } else this.globalVars.withoutWinlineCard = true;
      },
      () => {
        this.globalVars.withoutWinlineCard = true;
      }
    );
  }

  loadWCPointsAndPosition() {
    if (this.globalVars.FEATURES.rankingMundialEnabled) {
      this.userService.loadWCPointsAndPosition().subscribe((data) => {
        this.globalVars.pointsWC = data.points;
        this.globalVars.positionWC = data.position;
      });
    }
  }

  closeSlotSessions(userName: string) {
    this.slotsService.closeSlotSessions(userName).subscribe(
      () => {
        this.cookiesService.removeCookie(CookieNames.AdviceSlots);
      },
      () => {}
    );
  }

  openLogin(callbackAfterLogin?: (data: any, role: string) => void) {
    if (this.globalVars.FEATURES.MSO_alertCookiesNoBlock) {
      if (!this.globalVars.isCookiesAccept) {
        if (this.globalVars.licenseType == LicenseTypes.Nacional) {
          this.alertCookies.dismiss();
          this.openCookiesConsent();
        } else {
          if (!this.alertCookies.isOpen) {
            this.openAlertCookies();
          }
        }
        this.globalVars.actionAfterCookiesAccept = 'login';
        this.globalVars.callbackAfterLoginAfterCookiesAccep = callbackAfterLogin;
        return;
      }
    }
    this.globalVars.afterLoginUrl = null;

    this.menu.close('right');

    this.nativeService.checkTouchId(callbackAfterLogin);
    const segments = this.router.url.split('/');
    const currentPage = segments[segments.length - 1].split('?')[0];
    this.utils.setGoBackToAfterRegister(currentPage);

    if (this.registerPages.includes(currentPage)) {
      this.router.navigate(['/']);
    }
  }

  openRegistro() {
    if (this.globalVars.FEATURES.MSO_alertCookiesNoBlock) {
      if (!this.globalVars.isCookiesAccept) {
        if (this.globalVars.licenseType == LicenseTypes.Nacional) {
          this.alertCookies.dismiss();
          this.openCookiesConsent();
        } else {
          this.openAlertCookies();
        }
        this.globalVars.actionAfterCookiesAccept = 'reg';
        return;
      }
    }
    this.menu.close('right');
    this.openRegisterByLicense();
  }

  openRegisterByLicense(): void {
    const segments = this.router.url.split('/');
    const currentPage = segments[segments.length - 1].split('?')[0];
    if (!this.registerPages.includes(currentPage)) {
      if (this.globalVars.modalTicket && this.isMobile) {
        this.globalVars.modalTicket.cerrarTicket();
      }
      switch (this.globalVars.licenseType) {
        case LicenseTypes.ArgentinaCaba:
        case LicenseTypes.ArgentinaCordoba:
          this.openModalOrPage(`/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroARPage}`, {}, false);
          break;
        case LicenseTypes.ArgentinaMendoza:
          this.openModalOrPage(`/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroMZAPage}`, {}, false);
          break;
        case LicenseTypes.ArgentinaPBA:
          this.openModalOrPage(`/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroPBAPage}`, {}, false);
          break;

        case LicenseTypes.Colombia:
          this.openModalOrPage(`/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroCONewPage}`, {});
          break;
        case LicenseTypes.Panama:
          this.openModalOrPage(`/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroPAPage}`, {}, false);
          break;
        default:
          this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroNewPage}`], {
            queryParams: { animate: false }
          });
      }
    }
  }

  /**
   * Opens a modal (on desktop) or new page (on mobile device)
   * @param link: View to open
   * @param atrr: data to pass to the view, received in navParams
   * @param enableBackdropDismiss: if clicking outside the modal closes it
   * @param cssClass: class to apply to the modal container
   */
  async openModalOrPage(link: any, atrr: any = {}, enableBackdropDismiss = true, cssClass = 'modalh800') {
    if (this.globalVars.forceToOpenLogin && !this.globalVars.user.logged) {
      this.openLogin(() => {
        if (this.globalVars.user.logged) this.openModalOrPage(link, atrr, enableBackdropDismiss, cssClass);
      });
    } else {
      if (this.isMobile) {
        this.router.navigate([`/${link}`], { queryParams: atrr });
      } else {
        try {
          const componente = this.getComponentForRoute(link);
          const modal = await this.modalController.create({
            component: componente,
            componentProps: atrr,
            cssClass: cssClass,
            backdropDismiss: enableBackdropDismiss
          });
          await modal.present();
        } catch (e) {
          console.error('Error in modal page', e);
          this.router.navigate(['/' + link], { queryParams: { atrr } });
        }
      }
    }

    this.globalVars.forceToOpenLogin = false;
  }

  getComponentForRoute(routePath: string): any {
    const rutas = [...MSO_ROUTES, ...CASINO_ROUTES, ...PAYMENTS_ROUTES];
    const segments = routePath.split('/');
    const lastSegment = segments[segments.length - 1];

    for (const ruta of rutas) {
      if (ruta.path === lastSegment) {
        return ruta.component;
      }
      if (ruta.children) {
        const childComponent = this.getComponentForRouteChildren(ruta.children, lastSegment);
        if (childComponent) {
          return childComponent;
        }
      }
    }

    return undefined;
  }

  getComponentForRouteChildren(children: Route[], lastSegment: string): any {
    for (const ruta of children) {
      if (ruta.path === lastSegment) {
        return ruta.component;
      }
    }

    return undefined;
  }

  doLogout() {
    //logout
    this.globalVars.freeBetIdSelected = '';
    this.events.publish('user:logout');
  }

  getSpecialGames() {
    if (
      this.globalVars.FEATURES.rightMenuSpecialGame != undefined &&
      this.globalVars.FEATURES.rightMenuSpecialGame != ''
    ) {
      this.specialGames = JSON.parse(this.globalVars.FEATURES.rightMenuSpecialGame);
    }
  }

  openMejoraTuPremio() {
    //buscamos el id del nodo en lugar de un nodeId fijo
    this.router.navigateByUrl('EventoMejoraPage');
    this.trackingService.trackEvent([
      'GoToMejoraTuPremio',
      '',
      window.location.hash,
      'Ir a mejoratu premio desde ',
      'event'
    ]);
  }

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  openPage(event, item: C_Game | C_HighLight | C_Sport, isHighLight: boolean = false, isSport: boolean = false) {
    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    this.globalVars.SportSelected = item.Name;

    //each sports has different target page
    let countriesAux: Array<C_Country>;
    let page: any;
    let itemAux: any;

    let sportHandle: string;
    let childrenCount: number;

    if (item['SportHandle'] !== undefined) {
      const itemC: C_Game = <C_Game>item;
      sportHandle = itemC.SportHandle;
      childrenCount = itemC.ChildrenCount;
    } else {
      const itemC: C_HighLight = <C_HighLight>item;
      sportHandle = itemC.SportsHandle;
      childrenCount = null;
    }
    this.globalVars.SportHandleSelected = sportHandle;

    switch (sportHandle) {
      case 'greyhound_racing':
      case 'horse_racing':
        //horse && greyhounds
        page = SPORTS_PATHS.HorsesPage;
        itemAux = item;
        this.router.navigate([page], {
          state: { params: itemAux }
        });
        this.trackingService.trackEvent([
          isHighLight ? 'GotoHorsesPage_Destacado' : 'GoToHorsesPage',
          sportHandle,
          window.location.hash,
          '',
          'event'
        ]);
        break;
      default:
        page = SPORTS_PATHS.SportCountriesPage;
        itemAux = item;
        //other
        //en este punto antes de cargar la página de país-liga, vamos a comprobar si el juego tiene un solo pais y si lo tiene, entonces preguntamos si ese pais tiene un evento solo. Y si lo tiene, entonces cargaremos la página del evento directamente sin pasar por el paisliga.
        if (childrenCount == 1 || !childrenCount) {
          this.sportService.getCountries(item.NodeId).subscribe({
            next: (data) => {
              countriesAux = data;
              if (countriesAux.length === 1) {
                //si solo hay un pais y solo tiene una liga cargamos la página de eventos directamente.
                if (countriesAux[0].Leagues.length === 1) {
                  const hasBackButton: string = this.router.routerState.snapshot.url;
                  // eslint-disable-next-line no-unneeded-ternary
                  this.eventService.sendCanGoBack(hasBackButton === '/HomePage' ? true : false);
                  page = SPORTS_PATHS.EventoPage;
                  itemAux = countriesAux[0].Leagues[0];
                  this.router.navigate([page], {
                    state: { params: itemAux, isLeftMenuAZ: true }
                  });
                } else {
                  this.router.navigate([page], {
                    state: { params: itemAux }
                  });
                }
              } else {
                this.router.navigate([page], {
                  state: { params: itemAux }
                });
              }
              this.trackingService.trackEvent([
                isHighLight ? 'GoToSport_Destacado' : 'GoToSport',
                item.Name,
                window.location.hash,
                item.NodeId,
                'event'
              ]);
            },
            error: (error) => console.error(error),
            complete: () => {
              // getSportsCountries en pais liga completed
            }
          });
        } else {
          this.router.navigate([SPORTS_PATHS.SportCountriesPage], {
            state: { params: itemAux }
          });
          this.trackingService.trackEvent([
            isHighLight ? 'GoToSport_Destacado' : 'GoToSport',
            item.Name,
            window.location.hash,
            item.NodeId,
            'event'
          ]);
        }
        break;
    }
    if (this.isMobile) this.toggleSidebarMenu('left-menu');
  }

  openBetSlip(event = null) {
    this.globalVars.TicketOpened = !this.globalVars.TicketOpened;
    if (this.globalVars.TicketOpened) {
      this.trackingService.trackEvent(['openBetSlip', '', window.location.hash, 'Abre ticket apuestas', 'event']);
    } else {
      this.trackingService.trackEvent(['closeBetSlip', '', window.location.hash, 'Cierra ticket apuestas', 'event']);
    }
  }
  noTicketonPage() {
    return this.route?.component.name === 'SlotsGamePage' || this.route?.component.name == 'SlotsPage';
  }

  openDirectos() {
    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    this.router.navigate([SPORTS_PATHS.DirectosPage], {
      state: {}
    });
    this.trackingService.trackEvent(['Directos', '', 'Directos', 'Directos', 'pageView']);

    this.trackingService.trackEvent([
      'GoToDirectosPage',
      '',
      window.location.hash,
      'Ir a pagina de directos desde ',
      'event'
    ]);
    if (this.isMobile) this.toggleSidebarMenu('left-menu');
  }
  btSeccionUser_Click(indice: number) {
    if (this.opcionSideBarUserActive === indice) {
      this.opcionSideBarUserActive = -1;
    } else {
      this.opcionSideBarUserActive = indice;
    }
  }
  openSpecialTeam(team: string) {
    // Reset the content nav to have just this page
    // nav to special team (real Madrid, river plate) search page
    this.router.navigate([SPORTS_PATHS.SpecialTeamPage], {
      state: {
        params: {
          team
        }
      }
    });
    this.trackingService.trackEvent(['AccessToSpecialTeamPage', '', 'sportmenu', 'Pagina de ' + team, 'event']);
    if (this.isMobile) this.menu.close('left-menu');
  }

  openMisterunderdog() {
    // Reset the content nav to have just this page
    // nav to MisterunderdogPage search page
    this.trackingService.trackEvent([
      'AccessToMisterunderdogPage',
      '',
      'sportmenu',
      'Pagina de Misterunderdog',
      'event'
    ]);
  }

  openSpecialSport(sport: any, menu: string) {
    this.trackingService.trackEvent(['AccessToRighMenuSpecialSport', '', 'sportmenu', sport.sport, 'event']);
    this.searchQuery = sport.sport;
    const multiplesIds = sport.idLeagues.indexOf('-') !== -1;
    const page = multiplesIds ? 'EventoSpecialPage' : 'EventoPage';
    this.router.navigate([page], {
      state: {
        params: {
          Name: sport.sport,
          ...(multiplesIds && { idLeagues: sport.idLeagues }),
          ...(!multiplesIds && { NodeId: sport.idLeagues }),
          SportHandle: sport.sportHandle,
          SportName: sport.sportName,
          CountryCode: sport.countryCode,
          hasBackButton: true
        }
      }
    });

    if (this.isMobile) this.menu.close(menu);
  }

  openSpecialGame(game: any) {
    this.trackingService.trackEvent(['AccessToRighMenuSpecialGame', '', 'gamemenu', game.gameName, 'event']);
    //TODO routerService to be reviewed by PAYMENTS team
    // this.routerService.gotoPage(this.globalVars.gNav, LobbyCasinoPage, {
    //   playgame: game.gameId
    // });
  }

  openLigaAguila() {
    // functionality disabled by SPORTS team
    // this.routerService.gotoPage(this.globalVars.gNav, EventoPage, {
    //   item: { Name: 'Liga Águila', NodeId: '115163653', SportHandle: 'soccer' }
    // });
    this.trackingService.trackEvent(['AccessToLigaÁguila', '', 'sportmenu', 'Pagina de Liga Águila', 'event']);
  }
  openOlympics() {
    // functionality disabled by SPORTS team
    // this.globalVars.gNav.setRoot(SportCountriesPage, {
    //   item: { Name: 'JJ.OO. Tokyo 2020', NodeId: '751537550', SportHandle: 'olimpiadas' }
    // });
    this.trackingService.trackEvent(['AccessToOlympicGames', '', 'sportmenu', 'Pagina de Juegos Olimpicos', 'event']);
  }
  openTournament(section: string) {
    if (section === 'sportradar') {
      // functionality disabled by SPORTS team
      // this.routerService.gotoPage(this.globalVars.gNav, EuroCupCenterPage, {});
      this.router.navigate(['/'], {
        state: {}
      });
      this.trackingService.trackEvent(['AccessToEuroCupCenter', '', 'sportmenu', 'Pagina de Eurocopa Center', 'event']);
    } else if (section === 'events') {
      const name: string =
        this.globalVars.licenseType == LicenseTypes.Nacional ? Constants.UEFAEuroGamesLiteral : 'Copa América 2021';
      const nodeId: string =
        this.globalVars.licenseType == LicenseTypes.Nacional ? Constants.UEFAEuroNodeId : '444229735';
      const eventPage: string =
        this.globalVars.licenseType == LicenseTypes.Nacional
          ? 'AccessToEuroCupEventPage'
          : 'AccessToCopaAmericaEventPage';
      const eventName: string =
        this.globalVars.licenseType == LicenseTypes.Nacional
          ? 'Pagina de eventos de Eurocopa 2020'
          : 'Pagina de eventos de Copa America';
      this.router.navigate([SPORTS_PATHS.EventoPage], {
        state: {
          params: { Name: name, NodeId: nodeId, SportHandle: 'soccer', SportName: 'Fútbol' },
          isLeftMenuAZ: true
        }
      });
      this.toggleSidebarMenu('left-menu');
      this.trackingService.trackEvent([eventPage, '', 'sportmenu', eventName, 'event']);
    }
  }

  loginPropBets(e: any) {
    if (this.globalVars.user.logged) {
      this.newUserService.getAuthToken().subscribe(
        (data) => {
          const ibetToken = data.token;
          const name = data.nodeId.toString();
          const resp = [
            'response',
            'ds::loginAndPlace',
            'authorization',
            { username: name, password: ibetToken, requester: 'quickBet' }
          ];
          e.source.postMessage(resp, e.origin);
        },
        (err) => {
          this.utils.showError(this.translate.instant('m_menu_serverFail'));
        }
      );
    } else {
      // do nothing
      this.globalVars.eventProps = e;
      this.nativeService.checkTouchId();
    }
  }

  firstloginPropBets(e: any) {
    if (this.globalVars.user.logged) {
      this.newUserService.getAuthToken().subscribe(
        (data) => {
          const ibetToken = data.token;
          const name = data.nodeId.toString();
          const resp = [
            'response',
            'ds::login',
            'authorization',
            { username: name, password: ibetToken, requester: 'quickBet' }
          ];
          e.source.postMessage(resp, e.origin);
        },
        (err) => {
          this.utils.showError(this.translate.instant('m_menu_serverFail'));
        }
      );
    }
  }

  openToday() {
    this.codereRouterService.openToday();
    this.toggleSidebarMenu('left-menu');
  }

  openBetbuilder() {
    this.router.navigate([SPORTS_PATHS.CreaTuApuestaPage], {});
    if (this.isMobile) this.menu.close('left-menu');
    this.trackingService.trackEvent(['AccessBetbuilder', '', 'sportmenu', 'Crea tu apuesta', 'event']);
  }

  openNba() {
    // nav to NBA evento page
    this.globalVars.SportSelected = 'Basketball';
    const itemLeague: C_League = new C_League([], 'NBA', '128693343', '', 1, 'Basketball', false);
    this.router.navigate([SPORTS_PATHS.EventoPage], {
      state: { item: itemLeague, isLeftMenuAZ: true }
    });
    this.trackingService.trackEvent(['AccessToNBAEventoPage', '', 'sportmenu', 'Pagina de NBA', 'event']);
  }
  refreshBalance(hideLoader = false) {
    this.balancesService.refreshBalance(hideLoader);
  }

  openSlotsGame(url: any, backButton: boolean) {
    this.globalVars.navigateToSlotsGame = true;
    const lobbyType = this.utils.getLobbyFromUrl();
    const quicklaunch = this.globalVars.gameFromQuickLaunch;
    this.router.navigate([`/${CASINO_PATHS.SlotsGamePage}`], {
      queryParams: {
        url: url,
        buttonenabled: backButton,
        animate: !this.isDesktop,
        lobbyType: lobbyType,
        quicklaunch: quicklaunch
      },
      state: { prevPage: this.router.url }
    });
    this.globalVars.gameFromQuickLaunch = false;
  }

  // eslint-disable-next-line no-shadow
  openLobby(lobby, playgame?: string, category?: string, filter?: string) {
    const lobbyUrl = lobby?.lobbyUrl + 'Page';
    if (lobby?.SingleGameLobby) {
      if (this.isDesktop) {
        this.router.navigate(['/HomePage']).then(() => {
          this.router.navigate([`/${CASINO_PATHS.BASE}/${lobbyUrl}`], {
            queryParams: {
              lobby: lobby?.LobbyName,
              singleGame: lobby?.SingleGameLobby,
              playgame,
              category,
              filter
            }
          });
        });
      } else {
        this.router.navigate([`/${CASINO_PATHS.BASE}/${lobbyUrl}`], {
          queryParams: {
            lobby: lobby?.LobbyName,
            singleGame: lobby?.SingleGameLobby,
            playgame,
            category,
            filter
          }
        });
      }
    } else {
      if (this.isDesktop) {
        this.router.navigate(['/HomePage']).then(() => {
          this.router.navigate([`/${CASINO_PATHS.BASE}/${lobbyUrl}`], {
            queryParams: { lobby: lobby?.LobbyName, playgame, category, filter }
          });
        });
      } else {
        this.router.navigate([`/${CASINO_PATHS.BASE}/${lobbyUrl}`], {
          queryParams: { lobby: lobby?.LobbyName, playgame, category, filter }
        });
      }
    }

    if (this.isMobile) this.menu.close('left-menu');
  }

  openJackpots() {
    this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.JackpotPage}`], { queryParams: { lobby: 'jackpot' } });

    if (this.isMobile) this.menu.close('left-menu');
  }

  /**
   * Opens LobbyCalendarPage in an ion-modal. Since is not required by the user to have visited casino,
   * a preload of casino is needed in order to display games in the component.
   */
  async openCalendarGames() {
    await this.menu.close();
    await this.casinoService.setLobbyData('Playtech');
    await this.casinoService.setFilter(this.globalVars.FEATURES.casinoCalendarName, LobbyCalendarPage);
  }

  openPromotions() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PromotionsPage}`]);
    if (this.isMobile) this.menu.close('left-menu');
  }

  openPromotionsTest() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PromotionsTestPage}`]);
  }

  openHome() {
    // Reset the content nav to have just this page
    // we wouldn't want the ack button to show in this scenario
    // functionality disabled by SPORTS team
    // this.routerService.gotoPage(this.globalVars.gNav, this.globalVars.mainPageApp, {});
  }

  openContact(event) {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.ContactPage}`], {});
    this.menu.close();
  }

  openMessages() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.UserMessagesPage}`]);
    this.menu.close();
  }

  saveDeviceId(tag) {
    // TODO this.globalVars.deviceType
    // this.userService.saveDeviceId(tag, this.globalVars.deviceType).subscribe(
    //   () => {
    //     console.info(tag + ':::registrado correctamente');
    //   },
    //   (err) => {
    //     console.info('err al registrar ID');
    //   }
    // );
  }

  createCookieNative(value: string) {
    switch (value) {
      case 'ANDROID':
      case 'IOS':
      case 'cordova':
        this.cookiesService.setCookie(CookieNames.NativeDeviceType, value, 60 * 60 * 1000);
        break;
    }
  }

  menuIsOpen() {
    // eslint-disable-next-line no-unneeded-ternary
    return this.menu.isOpen('right') || this.menu.isOpen('left') ? true : false;
  }

  closeGame(value: boolean) {
    if (!this.toggleMiniGame$) {
      this.toggleMiniGame$ = !this.toggleMiniGame$;
      setTimeout(() => {
        this.showMiniGame = !value;
      }, 400);
    } else {
      this.showMiniGame = !value;
    }
  }

  toggleMiniGame(collapse: boolean) {
    if (this.toggleMiniGame$ && !this.showMiniGame) {
      this.showMiniGame = true;
      setTimeout(() => {
        this.toggleMiniGame$ = false;
      }, 300);
    } else {
      this.toggleMiniGame$ = collapse;
    }
  }

  setHeight(height: string) {
    if (!this.miniGameHeight) {
      this.miniGameHeight = height;
    }
  }

  forbiddenPages() {
    //LobbyPlaygroundPage
    return (
      (this.route?.component.name == 'SlotsPage' ||
        this.route?.component.name == 'LobbyPage' ||
        this.route?.component.name == 'LobbyCasinoPage' ||
        this.route?.component.name == 'CasinoPage' ||
        this.route?.component.name == 'SlotsGamePage' ||
        this.route?.component.name == 'SlotsPage' ||
        this.route?.component.name == 'SlotsRankingPage') &&
      !this.globalVars.playingMiniGame
    );
  }

  callbackcancelEVO() {
    this.userService.cancelPaymentEvoPayments(this.globalVars.evoPaymentsidTransaccion).subscribe((data) => {
      if (data.success) {
        if (data.errMessage == 'EVOPAYMENTS') {
          this.globalVars.evoPaymentsNewButton = true;
        }
      }
    });
    this.trackingService.trackEvent(['cancelEVOPayments', this.globalVars.evoPaymentsidTransaccion, '', '', 'event']);
  }
  callbackcompleteEVO(resultIndicator: any, sessionVersion: any) {
    this.userService.forcedPaymentEvoPayments(this.globalVars.evoPaymentsidTransaccion).subscribe(() => {});
    this.trackingService.trackEvent([
      'completeEVOPayments',
      this.globalVars.evoPaymentsidTransaccion,
      this.globalVars.evoPaymentsAmount,
      '',
      'event'
    ]);
    // TODO to be reviewed by PAYMENTS team
    // this.routerService.gotoPage(this.globalVars.gNav, DepositPaymentOkPage, {
    //   idTransiction: this.globalVars.evoPaymentsidTransaccion,
    //   amount: this.globalVars.evoPaymentsAmount,
    //   paymentStatus: 'PENDING',
    // });
  }

  /* Betslip */
  public changeTicketType(typeValue): void {
    if (typeValue === 0 && !this.globalVars.user.logged) {
      this.openLoginModal();
      return;
    }

    if (this.selectedFilterType$.value.value === 0 && this.selectedFilterType$.value.value === typeValue) {
      return;
    }

    this.filtersType$.value.map((type, index) => {
      typeValue !== index ? (type.selected = true) : (type.selected = false);
    });

    this.selectedFilterType$.next(this.filtersType$.value.find((filterSelected) => filterSelected.selected));

    if (typeValue === 0) {
      this.utilsMyBetsService.counterTicketBets$.next(0);
      this.changeMyBetsType({ value: 1 });
    }
  }

  async openLoginModal() {
    if (!this.globalVars.user.logged) {
      const login = await this.modalController.create({
        component: Login
      });
      login.present();
      return;
    }
  }

  public changeMyBetsType({ value }): void {
    this.utilsMyBetsService.counterTicketBets$.next(0);

    this.utilsMyBetsService.setBetSlipFilters(value);

    this.myBetsFiltersType$.value.map((type, index) => {
      value !== index ? (type.selected = true) : (type.selected = false);
    });

    this.myBetsSelectedFilterType$.next(
      this.myBetsFiltersType$.value.find((filterSelected) => filterSelected.selected)
    );
  }

  /**
   UI  Betslip
  **/
  private setSections() {
    // TODO REVIEW by SPORTS team
    // this.section$ = this.routerService.currentSection$.pipe(
    //   filter((section) => Boolean(section) && this.isDesktop),
    //   map((section) => ({
    //     name: section,
    //     left: section === "sportbook" || section === "user",
    //     right: section === "sportbook",
    //   }))
    // );
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event) => {
        this.sectionName = event['section'] || undefined;
      });
  }

  isSportbook(): boolean {
    return this.sectionName === 'sportbook';
  }

  isLeftPanel(): boolean {
    return (this.sectionName === 'sportbook' || this.sectionName === 'user') && this.isDesktop;
  }

  isRightPanel(): boolean {
    return this.sectionName === 'sportbook' && this.isDesktop;
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  async toggleSidebarMenu(menu: string) {
    await this.menu.enable(true, menu);
    await this.menu.toggle(menu);
  }

  openModalCookies() {
    this.openModalOrPage(CookiesConsentPage);
  }

  /**
   *  Lee de sessionStorage los datos correspondientes a un registro recien hecho
   *  Borra los datos de sessionStorage para que no afecten a futuros logins
   */
  setFirstLogin() {
    if (sessionStorage.getItem('afterLoginParams')) {
      this.globalVars.afterLoginParams = JSON.parse(sessionStorage.getItem('afterLoginParams'));
      this.globalVars.afterLoginParamsUrl = this.globalVars.afterLoginParams;
      sessionStorage.removeItem('afterLoginParams');
    }
    if (sessionStorage.getItem('afterLoginUrl')) {
      if (sessionStorage.getItem('afterLoginUrl') == 'CashierPage') {
        // TODO to review by PAYMENTS team
        // this.globalVars.afterLoginUrl = CashierPage
      }
      sessionStorage.removeItem('afterLoginUrl');
    }
  }

  private _filtersGenerator(config): any[] {
    const filters = [
      {
        name: this.translate.instant(config[0]),
        value: 1,
        selected: true,
        hidden: false,
        disabled: false
      },
      {
        name: this.translate.instant(config[1]),
        value: 0,
        selected: false,
        hidden: false,
        disabled: false
      }
    ];

    return filters.filter((filterSelected) => !filterSelected.hidden);
  }

  public getFilterStatus(value: number) {
    if (this.selectedFilterType$.value) {
      const filterTypeValue = this.selectedFilterType$.value.value;
      return filterTypeValue !== value;
    } else {
      return false;
    }
  }

  public showMoreBets(value: any) {
    let filterParam;
    switch (value) {
      case 0:
        filterParam = { dateValue: 0, typeValue: 0 };
        break;
      case 1:
        filterParam = { dateValue: 0, typeValue: 1 };
        break;
    }
    this.router.navigate([SPORTS_PATHS.MisApuestasPage], {
      state: { params: filterParam }
    });
  }

  async openCookiesConsent() {
    // TODO to be reviewed by MSO team
    //this.openModalOrPage(`${MSO_PATHS.BASE}/${MSO_PATHS.CookiesConsentPage}`, {}, false, 'modalh800 modalcookieconsent');
    const componente = this.getComponentForRoute(`${MSO_PATHS.BASE}/${MSO_PATHS.CookiesConsentPage}`);
    const modal = await this.modalController.create({
      component: componente,
      cssClass: this.deviceService.isDesktop() ? 'modalh800 modalcookieconsent' : '',
      backdropDismiss: false
    });
    await modal.present();
    if (this.isDesktop) {
      this.router.navigate(['/']);
    }
  }

  /**
   * Balance Popover Behaviour
   * Configuration of events to show or hide the popover
   */

  addClickAndScrollListeners() {
    const scrollElements = Array.from(document.getElementsByClassName('scroll-content'));
    const hideBalance = () => {
      if (this.balancesService.hideBalanceDetails()) {
        // TODO REVIEW deviceType to be reviewed by APPS team
        // if(this.globalVars.deviceType == 'cordova' || (this.globalVars.deviceType != 'cordova' && window.navigator.platform == 'iPhone')){
        //   document.removeEventListener('touchstart', hideBalance);
        // }else{
        //   window.removeEventListener('click', hideBalance);
        // }
        window.removeEventListener('scroll', hideBalance);
        scrollElements.forEach((element) => {
          element.removeEventListener('scroll', hideBalance);
        });
      }
    };
    // TODO REVIEW deviceType to be reviewed by APPS team
    // if(this.globalVars.deviceType == 'cordova' || (this.globalVars.deviceType != 'cordova' && window.navigator.platform == 'iPhone')){
    //   document.addEventListener('touchstart', hideBalance);
    // }else{
    //   window.addEventListener('click', hideBalance);
    // }

    window.addEventListener('scroll', hideBalance);
    scrollElements.forEach((element) => {
      element.addEventListener('scroll', hideBalance);
    });
  }

  getBetsConfigData() {
    this.sportService.getBetsConfigData().subscribe({
      next: (data: C_BetsData[]) => {
        this.globalVars.betsData = data;
      },
      error: () => {}
    });
  }

  redirectAfterLoginUrl(dataParam, namePageUrl) {
    this.nativeService.checkTouchId();
    // Set the redirect path after login
    if (dataParam) this.globalVars.afterLoginParamsUrl = dataParam;
    this.globalVars.forceToOpenLogin = false;
  }

  checkEventsLenght(league: C_League) {
    let component: any;
    let options = {};
    this.sportService.getEvents({ parentId: league.ParentNodeId }).subscribe((data) => {
      if (data.length === 1) {
        component = MercadosPage;
        options = { market: data[0] };
      } else {
        component = EventoPage;
        options = { item: league, hasBackButton: true };
      }
    });
  }

  subscribeLoadEvent() {
    this.events.subscribe('videoplayer:loadEvent', (event) => {
      this.globalVars.SportSelected = this.utils.getSportNameBySportHandle(event.SportHandle);
      this.events.publish('videoplayer:close');
      this.router.navigate(['NowLiveDetailPage/'], {
        state: { params: event },
        queryParams: { openVideo: true }
      });
    });
  }

  setIosScroll(event) {
    this.globalVars.iosScrollHide = event;
  }

  @init
  async getFeatures(firstLaunch = false) {
    let region = this.globalVars.LICENSE_TYPE.toString();
    // TODO to be reviewe by SPORTS team.
    // At this point, when the page is reloaded, the value set by the filter is lost. It is necessary to look for alternatives such as stores/cookies so that this scenario does not occur.
    if (this.isTranslate) {
      region = this.globalVars.user.userRegion?.toString();
    }
    await Promise.resolve(
      this.userService
        .getFeatures(region)
        .pipe(
          map((value) => {
            //convert the string values to boolean
            for (const x in value) {
              if (value[x] == 'false') {
                value[x] = false;
              } else if (value[x] == 'true') {
                value[x] = true;
              }
            }
            return value;
          })
        )
        .subscribe({
          next: (data) => {
            this.setFeatures(data, firstLaunch);
          },
          error: (error) => {
            throw Error(error);
          }
        })
    );
  }

  setFeatures(data: any, firstLaunch: boolean) {
    let dataLb = [];
    let dataLbSg = [];
    if (!!data.CasinoLobbiesMobile && !!data.CasinoLobbiesPC) {
      try {
        dataLb = JSON.parse(this.isMobile ? data.CasinoLobbiesMobile : data.CasinoLobbiesPC);
        dataLbSg = JSON.parse(this.isMobile ? data.CasinoSingleLbMobile : data.CasinoSingleLbPC);
        dataLbSg.forEach((ele) => {
          ele.SingleGameLobby = true;
        });
        data.Lobbies = dataLb.concat(dataLbSg);
      } catch (error) {}

      const arrayActualLobbies = [
        CASINO_PATHS.CasinoPage,
        CASINO_PATHS.SlotsPage,
        CASINO_PATHS.OmnichannelPage,
        CASINO_PATHS.JackpotPage,
        CASINO_PATHS.CasinoEnVivoPage,
        CASINO_PATHS.LobbyCasinoPage,
        CASINO_PATHS.BlackjackPage
      ];
      data.Lobbies.forEach((ele) => {
        ele.lobbyUrl = ele.LobbyTitle.replace(/ /g, '');
        if (ele.SingleGameLobby) {
          ele.lobbyUrl = CONSTANTS.casinoLobbyName;
        }
        if (ele.LobbyName === CONSTANTS.omnichannel) {
          ele.lobbyUrl = CONSTANTS.omnichannel;
        }
        const findLobby = arrayActualLobbies.find((item) => item === ele.lobbyUrl + 'Page');
        if (!findLobby) {
          ele.lobbyUrl = CONSTANTS.casinoLobbyName;
        }
      });

      this.lobbies = data.Lobbies;
    }
    //si en partnerconfigutarions no nos viene nada (ej. no está creada la feature) coge el valor que esté puesto en el archivo c_features.ts
    //Object.keys(data).forEach(c=>{if(this.globalVars.FEATURES.hasOwnProperty(c) && data[c] !=null){this.globalVars.FEATURES[c]=data[c]}})
    this.globalVars.FEATURES = data;

    this.lobbies.forEach((ele) => {
      if (ele.SingleGameLobby) this.singleLobbies.push(ele);
    });

    if (this.globalVars.FEATURES.ForbiddenSports) {
      if (this.globalVars.FEATURES.ForbiddenSports.indexOf('mejora_la_linea') == -1) {
        this.MejoraTuPremioForbbiden = false;
      } else {
        this.MejoraTuPremioForbbiden = true;
      }
    }

    if (this.utils.isArgentina()) {
      if (this.globalVars.FEATURES.RegistroArRenaper) {
        this.globalVars.registry = 'Renaper';
      } else {
        this.globalVars.registry = 'noRenaper';
      }
    }

    if (this.globalVars.FEATURES.newChatEnabled) {
      this.utils.loadContactChat(true);
    }

    this.sportService.getGameTypesConfigData().subscribe({ error: () => {} });

    firstLaunch ? this.initializeApp() : this.navService.initRightMenu();

    if (document.location.href.includes(data.mirrorCheck) || document.location.href.includes(data.mirrorCheckNewFE)) {
      this.globalVars.isMirror = true;
    }
    this.checkHaveCalendar();
    this.navService.init();

    this.isCompleted = true;
  }

  async checkHaveCalendar() {
    if (this.globalVars.FEATURES.CasinoCalendarEnabled && !this.isDesktop) {
      this.haveCalendarGames = await this.checkCalendar();
    }
  }
  private async checkCalendar() {
    const filtered = [];
    const dateNow = new Date().getTime();

    const CalendarSections = await this.slotsService.getCalendarInfo();

    if (CalendarSections && !CalendarSections.HasError) {
      if (CalendarSections.Data) {
        CalendarSections.Data.forEach((element) => {
          const dateIni = new Date(element.startDate).getTime();
          const dateFin = new Date(element.endDate).getTime();
          if (dateIni <= dateNow && dateFin >= dateNow && element.games) {
            filtered.push(element);
          }
        });

        //Order
        filtered.sort(function (a, b) {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          return 0;
        });
      }
    }

    return filtered;
  }

  private async launchAppsflyer() {
    // this.appsFlyerTrackingService.appsFlyer = window['plugins'].appsFlyer;
    await this.appsFlyerTrackingService.init();
  }

  private async launchXtremePushPopup() {
    try {
      await this.nativeService.initExtremePush();
    } catch (error) {
      console.error('App.component constructor: Failed initializing xtremePush plugin -> ', error);
    }
  }

  deleteCookiesOnLogout() {
    if (this.cookiesService.getCookie(CookieNames.LoggedInApplicationSite)) {
      this.cookiesService.removeCookie(CookieNames.LoggedInApplicationSite, true);
    }
    if (this.cookiesService.getCookie(CookieNames.UserInfo)) {
      this.cookiesService.removeCookie(CookieNames.UserInfo, true);
    }
    if (this.globalVars.FEATURES.NewCarouselActivated) {
      setTimeout(() => {
        this.events.publish('refreshBanners');
      }, 300);
    }
  }

  setLoginCookies() {
    this.userServiceMSO.getUserInfo().subscribe(async (data) => {
      const vipStatus = data.vipstats ? 'true,' + data.vipLevel : 'false';
      this.cookiesService.setCookie(CookieNames.VipStats, vipStatus, null, true);

      const userdata: I_UserInfo = data;

      if (data.ispromotable == null || data.ispromotablevip == null) {
        try {
          const promoresponse = await firstValueFrom(this.userServiceMSO.getIsFullPromotionable());
          userdata.ispromotable = promoresponse.isPromotable;
          userdata.ispromotablevip = promoresponse.isPromotableVip;
        } catch (error) {}
      }

      /**
       * Set ispromotablevip cookie.
       */
      this.cookiesService.setCookie(
        CookieNames.IsPromotable,
        new Boolean(userdata.ispromotablevip).toString(),
        null,
        true
      );

      this.cookiesService.setCookie(CookieNames.UserInfo, JSON.stringify(userdata), null, true);
      if (this.globalVars.FEATURES.targetedOptinPopup) {
        if (userdata && !userdata.selfExcluded) {
          this.showPopupOptIn();
        }
      }

      if (this.globalVars.FEATURES.NewCarouselActivated) {
        this.events.publish('refreshBanners');
      }
      this.events.publish('userInfoDone', userdata);
    });
  }
  updateLimits(accept: boolean) {
    this.userServiceMSO.updateLimits(accept).subscribe((data) => {
      if (data.success) this.logService.log('Cambio de límites efectuado');
      else this.logService.error('Cambio de límites fallido');
    });
  }
  async showAlertLimitsUpdate() {
    const msg = this.translate.instant('pendinglimitsconfirm');
    const alert = await this.alertCtrl.create({
      header: 'Importante',
      message: msg,
      buttons: [
        {
          text: 'NO',
          handler: () => {
            this.updateLimits(false);
          },
          role: 'cancel'
        },
        {
          text: this.translate.instant('yesconfirm'),
          handler: () => {
            this.updateLimits(true);
          }
        }
      ]
    });
    alert.present();
  }
  async updateComunicatios() {
    const update: boolean = await this.utils.showAlertAcceptComunications(
      this.translate.instant('acceptedcommunications') + ` <a>${this.translate.instant('editpreferences')}</a>`,
      this.translate.instant('tAccept')
    );
    let comunications = { phone: false, sms: false, mail: false, alerts: false };
    if (update) {
      comunications = { phone: true, sms: true, mail: true, alerts: true };

      this.userServiceMSO
        .updateComunications(comunications)
        .pipe(
          filter((data) => data.success && data.status === 'ok'),
          switchMap((_) => this.userServiceMSO.SetAcceptanceComunication())
        )
        .subscribe({
          next: (respose) => {
            console.info(respose);
          },
          error: (err) => console.error(err)
        });
    }
  }
  updateshowAlertComunicationsPrefences() {
    this.utils.showAlertAcceptComunicationsSecond(
      this.translate.instant('acceptedcommunicationssecondtime'),
      this.translate.instant('editpreferences')
    );
  }

  userWelcomeBonus() {
    if (this.globalVars.FEATURES.ModalBonusSportCasino) {
      if (this.globalVars.FEATURES.MSO_GraceTimeLogicEnabled) {
        if (!this.globalVars.extraUserData.graceTime && !this.globalVars.extraUserData.documentationVerification) {
          return;
        }
      }
      this.userServiceMSO.getUserWelcomeBonus().subscribe((data) => {
        if (data.count === 0) {
          this.events.publish(
            'cashier:open',
            this.cookiesService.getCookie(CookieNames.IforiumPromo) == null
              ? { name: 'PAYU', cashierEmpty: false }
              : { cashierEmpty: false }
          );
          this.events.publish('modalBonusWcCo');
        }
      });
    }
  }
  async showRevocationAlert() {
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    const title: string = `${this.translate.instant('revocation_se_title')}`;
    const contededoruser = `<div class="bold">${this.globalVars.user.username},</div>`;
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    const msg: string = `${contededoruser}<br> ${this.translate.instant('revocation_se_txtwantrevoca')}`;
    const alert = await this.utils.alert(
      false,
      title,
      msg,
      this.translate.instant('revocation_se_txtwantrevocabtn'),
      () => {
        this.router.navigate([`${MSO_PATHS.BASE}/${MSO_PATHS.SelfExclusionPage}`], {
          queryParams: { revocation: true }
        });
      }
    );

    const nodeAlert = alert.querySelector('.alert-wrapper');
    const icono = document.createElement('i');
    icono.setAttribute('id', 'btnclose');
    icono.classList.add('color-dark', 'codere-icon', 'icon-close', 'p-right-1');
    nodeAlert.classList.add('alertrevocationselogin');
    icono.addEventListener('click', () => {
      alert.dismiss();
    });
    nodeAlert.querySelector('.alert-head').appendChild(icono);
  }

  async trackingPermissions() {
    const request = await idfa.AdvertisingId.requestTracking();
    if (request.value !== 'Authorized') {
      const result = await idfa.AdvertisingId.getAdvertisingId();
      console.info(JSON.stringify(result));
    }
  }

  fromToRedirectJWT() {
    const path: string = this.location.path();
    let jwt = '';
    const params: string[] = path.split('?');
    if (params.length > 1) {
      const paramsArray = params[1].split('&');
      const arrayValuesParams = [];
      paramsArray.forEach((v) => {
        const aux = v.split('=');
        arrayValuesParams[aux[0]] = aux[1];
      });
      if (arrayValuesParams['JWT']) {
        jwt = arrayValuesParams['JWT'];
      }
    }
    return jwt;
  }

  requestComplianceCO() {
    firstValueFrom(this.userServiceMSO.registerCOComplianceRecords()).then(async (data) => {
      this.globalVars.extraUserData.pptNotVerification = data.presentaRiesgo;
      if (data.presentaRiesgo) {
        this.events.publish('closeWelcomeBonus');
        const alertCompliance = await this.alertCtrl.create({
          header: this.translate.instant('warning').toUpperCase(),
          message: this.translate.instant('accountadditionalvalidation'),
          buttons: [
            {
              text: 'OK',
              handler: () => {
                this.events.publish('closeWelcomeBonus');
                this.events.publish('user:logout');
              }
            }
          ],
          backdropDismiss: false
        });
        alertCompliance.present();
      }
    });
  }

  alertPrpProfesion(typePrpProf: ProfPrp) {
    let optionsAlert: AlertOptions;
    let alertprpPro: AlertController;
    switch (typePrpProf) {
      case 'PROFESSION':
        optionsAlert = {
          header: this.translate.instant('Attention'),
          message: "<span class='alertMessageCustom'>" + this.translate.instant('V2_profesionRequest') + '</span>',
          cssClass: 'sb-alert alertprof',
          backdropDismiss: false,
          buttons: [
            {
              text: this.translate.instant('tCont'),
              handler: () => {
                alertprpPro.dismiss();
                this.tryToGoPage('PersonalInfoPage');
              }
            }
          ]
        };
        break;
      case 'PROFESSION;PRP':
        optionsAlert = {
          header: this.translate.instant('Attention'),
          message: "<span class='alertMessageCustom'>" + this.translate.instant('personprpprof') + '</span>',
          cssClass: 'sb-alert alertprof',
          backdropDismiss: false,
          buttons: [
            {
              text: this.translate.instant('tCont'),
              handler: () => {
                alertprpPro.dismiss();
                this.tryToGoPage('PersonalInfoPage');
              }
            }
          ]
        };
        break;
      case 'PRP':
        optionsAlert = {
          header: this.translate.instant('Attention'),
          message: "<span class='alertMessageCustom'>" + this.translate.instant('personprp') + '</span>',
          cssClass: 'sb-alert alertprof',
          backdropDismiss: false,
          buttons: [
            {
              text: this.translate.instant('rY'),
              handler: () => {
                this.userServiceMSO.updatePrp(true).subscribe(
                  (_) => {},
                  (err) => {
                    console.error(err);
                  }
                );
                alertprpPro.dismiss();
              }
            },
            {
              text: 'No',
              handler: () => {
                this.userServiceMSO.updatePrp(false).subscribe(
                  (_) => {},
                  (err) => {
                    console.error(err);
                  }
                );
                alertprpPro.dismiss();
              }
            }
          ]
        };
        break;
    }

    if (optionsAlert) {
      // TODO to be reviewed by PAYMENTS or MSO team. AML related.
      // alertprpPro = this.alertCtrl.create(optionsAlert);
      // alertprpPro.present();
      // alertprpPro.onDidDismiss((_)=>{
      //   this.globalVars.extraUserData.professionOrPrpMissing = '';
      // });
    }
  }

  showAlertPPT() {
    this.utils.alert(
      false,
      this.translate.instant('warning').toUpperCase(),
      this.translate.instant('pptnotverification'),
      'OK',
      () => {
        this.events.publish('documentverification:open');
      }
    );
  }
  checkDNIExpired() {
    return new Promise<any>((resolve, reyect) => {
      this.userServiceMSO.verifyDNIexpiration().subscribe(
        async ({ data }) => {
          let dataAlert = null;
          if (data.isDniExpired) {
            this.globalVars.extraUserData.dniExpired = true;
            this.globalVars.extraUserData.daysSinceFirstCheck = data.daysSinceFirstCheck;
            dataAlert = await this.verificationAlertsService.showPopupDNIExpired(data.daysSinceFirstCheck);
          }
          resolve({ data, dataAlert });
        },
        (err) => {
          reyect(err);
        }
      );
    });
  }

  alertProhibitedLoginMZA() {
    return new Promise<any>((resolve, reyect) => {
      const alertprohibited: Promise<HTMLIonAlertElement> = this.alertCtrl.create({
        message: `${this.translate.instant('prohibitedloginmza')} <span><a href="${this.translate.instant(
          'prohibitedloginmza021'
        )}" target="_blank">${this.translate.instant('prohibitedloginmza02')}</a></span> ${this.translate.instant(
          'prohibitedloginmza03'
        )} `,
        buttons: [{ text: 'OK', role: 'confirm' }]
      });
      alertprohibited.then(async (_alert) => {
        await _alert.present();
        _alert.onDidDismiss().then(({ data, role }) => {
          resolve({ data, role });
        });
      });
    });
  }

  checkProfesion() {
    return new Promise<any>((resolve, reyect) => {
      this.userServiceMSO.verifyProfesion().subscribe(
        async ({ data }) => {
          this.globalVars.extraUserData.haveProfesion = data.isEnabled;
          this.globalVars.extraUserData.daysSinceFirstCheckProf = data.daysSinceFirstCheck;
          let dataAlert = null;
          if (!data.isEnabled) {
            dataAlert = await this.verificationAlertsService.showPopupProfesion(data.daysSinceFirstCheck);
          }
          resolve({ data, dataAlert });
        },
        (err) => {
          reyect(err);
        }
      );
    });
  }

  checkDocumentationVerificationStatus() {
    return new Promise<void>((resolve) => {
      let showalert = false;
      let alertOptions: AlertOptions;
      switch (this.globalVars.extraUserData.documentationVerificationStatus) {
        case 'Rechazado':
          showalert = true;
          alertOptions = {
            backdropDismiss: false,
            header: this.translate.instant('verificationstatusnodocumentationtitle'),
            message: this.translate.instant('verificationstatusnodocumentation'),
            buttons: [
              {
                text: 'OK',
                role: 'confirm',
                handler: () => {
                  this.events.publish('documentverification:open');
                }
              }
            ]
          };
          break;
        case 'NoDocumentacion':
          showalert = true;
          alertOptions = {
            backdropDismiss: false,
            header: this.translate.instant('verificationstatusrequestedtitle'),
            message: this.translate.instant('verificationstatusrequested'),
            buttons: [
              {
                text: 'OK',
                role: 'confirm',
                handler: () => {
                  this.events.publish('documentverification:open');
                }
              }
            ]
          };
          break;
        case 'Pendiente':
          showalert = true;
          alertOptions = {
            backdropDismiss: false,
            header: this.translate.instant('verificationstatuspendingtitle'),
            message: this.translate.instant('verificationstatuspending'),
            buttons: [
              {
                text: 'OK',
                role: 'confirm'
              }
            ]
          };
          break;
      }
      if (
        this.globalVars.FEATURES.MSO_dateRegisterVerificationStatus &&
        this.globalVars.FEATURES.MSO_dateRegisterVerificationStatus.length > 0
      ) {
        try {
          const dateregisterverificationstatus = new Date(this.globalVars.FEATURES.MSO_dateRegisterVerificationStatus);
          if (this.globalVars.user.registratedDate.getTime() >= dateregisterverificationstatus.getTime()) {
            if (showalert) {
              const alertverificationstatus = this.alertCtrl.create(alertOptions);
              alertverificationstatus.then((alert) => {
                alert.present().then(() => {
                  alert.onDidDismiss().then(() => {
                    resolve();
                  });
                });
              });
            } else {
              resolve();
            }
          } else {
            resolve();
          }
        } catch (error) {
          resolve();
        }
      }
    });
  }

  showAlert(alertFuction: () => any) {
    return new Promise((resolve) => {
      alertFuction
        .bind(this)()
        .then((data) => {
          resolve(data);
        });
    });
  }
  //cuando hay varios alert salen de uno en uno y no todos a la vez
  async additionalAlertLogin() {
    const additionalRequest: (() => any)[] = [];
    if (
      this.globalVars.extraUserData.documentationVerificationStatus &&
      this.globalVars.FEATURES.MSO_DocumentationVerificationStatus
    ) {
      additionalRequest.push(this.checkDocumentationVerificationStatus);
    }
    if (this.globalVars.FEATURES.MSO_CheckDNIExpired) {
      additionalRequest.push(this.checkDNIExpired);
    }
    if (this.globalVars.FEATURES.MSO_CheckProfesion) {
      additionalRequest.push(this.checkProfesion);
    }
    if (this.globalVars.extraUserData.isForbiddenInMendoza) {
      additionalRequest.push(this.alertProhibitedLoginMZA);
    }
    if (additionalRequest.length > 0) {
      try {
        for (const alert of additionalRequest) {
          const result = await this.showAlert(alert);
          console.log(result);
        }
        this.events.publish('badges:refresh');
      } catch (error) {
        console.log(error);
      }
    }
  }
  getItemsSearch(search) {
    this.searchQuery = search.detail.value;
    this.iniciarBusqueda = true;
    if (this.searchQuery.trim() == '') {
      this.resetSearch();
      return;
    }
    this.search(this.searchQuery);
  }

  onCancel(search) {
    this.resetSearch();
    this.searchQuery = search.detail.value;
    this.getItemsSearch(this.searchQuery);
  }

  search(searchQuery: string) {
    this.searchService.getResultSearch(searchQuery).subscribe((data: any) => {
      this.searchItems = data;
      this.showEmptyState = data.length == 0;
      this.trackingService.trackEvent([
        'AccessToSearchResult',
        searchQuery,
        data.length,
        'busqueda y muestra de resultados (caracteres=' + searchQuery.length + ')',
        'pageView'
      ]);
      return true;
    });
  }

  onItemSearch_Click(event, item) {
    this.toggleSidebarMenu('search-menu');
    this.searchService.navigateToSearchItemPageMobile(event, item, this.isMobile);
  }

  resetSearch() {
    this.searchItems = [];
    this.searchQuery = '';
    this.iniciarBusqueda = false;
    this.showEmptyState = false;
  }

  async subscribeToRoyalDegreeSignalR(): Promise<void> {
    if (this.globalVars.FEATURES.SignalRRoyalDegree) {
      await this.signalRService.startConnection(CONSTANTS.royalDegree);
      this.signalRService.addDataListener(CONSTANTS.royalDegreeNotification, CONSTANTS.royalDegree);
    }

    this.RDSubscription = this.signalRService.broadcaster.subscribe((data) => {
      if (data.type === CONSTANTS.royalDegreeNotification) {
        this.signalRService.showPopupRoyalDegree(data.payload);
      }
    });
  }

  async destroySub() {
    if (this.RDSubscription) {
      this.RDSubscription.unsubscribe();
    }
  }

  async getInstallReferrer() {
    try {
      const referrer = await Referrer.getReferrerDetails();
      console.log('Install Referrer:');
      console.log(referrer);
      console.log(JSON.stringify(referrer, null, 2));
    } catch (error) {
      console.error('Error retrieving install referrer:', error);
    }
  }
}
