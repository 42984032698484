import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnChanges,
  ViewChildren,
  ViewChild,
  QueryList,
  ChangeDetectorRef,
  inject,
  CUSTOM_ELEMENTS_SCHEMA
} from '@angular/core';
import { Utils } from '../../../utils/Utils';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TitleCasePipe } from '../../../pipes/titlecase/title-case.pipe';
import { Swiper, SwiperOptions } from 'swiper';
import { DirectivesModule } from '../../../directives/directives.module';

import { Constants } from '../../../utils/constants';

@Component({
  selector: 'sb-filter',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './sb-filter.html',
  standalone: true,
  imports: [IonicModule, TitleCasePipe, CommonModule, FormsModule, TranslateModule, DirectivesModule],
  styleUrls: ['./sb-filter.scss']
})
export class SbFilterComponent implements OnChanges, AfterViewInit {
  @ViewChildren('list') list: QueryList<any>;
  @ViewChild('line') line: ElementRef;
  cd = inject(ChangeDetectorRef);
  @ViewChild('swiper') swiperContainer:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;

  swiper?: Swiper;
  @Input() mode = 'grey';
  @Input() colorsOptions: any = {
    text: 'color-muted',
    background: 'background-color-regular',
    line: 'background-color-dark'
  };
  @Input() setFilterAvailable = true;
  @Input() filters: any[];
  @Input() switch: boolean;
  @Input() selected: any;
  @Input() keyboard = false;
  @Input() currentPage: string;
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Output() resizeEvent: EventEmitter<any> = new EventEmitter();
  private list$: Subscription;
  private ref: HTMLElement;
  private lineRef: HTMLElement;
  private listRef: HTMLElement;

  private _element = inject(ElementRef);
  private _renderer = inject(Renderer2);
  public utils = inject(Utils);

  public betbuilderCatId = Constants.betbuilderCatId;
  public lineThroughMode = 'grey';

  ngOnChanges() {
    if (this.filters && this.selected) {
      let index = this.filters.findIndex((filter) => filter.name === this.selected.name);
      index = index === -1 ? 0 : index;
      this._select(this.selected, index);
    }

    if (!this.swiper) return;
    this.swiper.update();
  }

  ngAfterViewInit() {
    this.ref = this._element.nativeElement;
    this.lineRef = this.line.nativeElement;
    this.listRef = this.list.first.nativeElement;

    this.setColorMode();

    if (this.selected && this.filters) {
      let index = this.filters.findIndex((filter) => filter?.name === this.selected?.name);
      index = index === -1 ? 0 : index;
      setTimeout(() => {
        this._select(this.selected, index);
      }, 50);
    }

    this.list$ = this.list.changes.subscribe(() => {
      this.display();
    });

    this.display().then((_) => this.resizeEvent.emit());

    if (this.currentPage) {
      this._renderer.addClass(this.swiperContainer.nativeElement, this.currentPage);
    }

    if (this.switch) return;

    const config: SwiperOptions = {
      slidesPerView: 'auto',
      spaceBetween: 0,
      freeMode: true,
      direction: 'horizontal',
      grabCursor: true,
      observer: false,
      loop: false,
      noSwiping: this.switch,
      followFinger: !this.switch,
      simulateTouch: !this.switch,
      allowSlidePrev: !this.switch,
      allowSlideNext: !this.switch
    };

    if (this.swiperContainer) {
      const swiperEl = new Swiper(this.swiperContainer?.nativeElement, config);
      swiperEl.init();
      this.swiper = this.swiperContainer?.nativeElement.swiper;
    }
  }

  ngOnDestroy() {
    if (this.list$) {
      this.list$.unsubscribe();
    }
  }

  _select(filter: any, index: number) {
    this.markAsSelected(filter);
    this.display().then((_) => this.moveLine(index));
  }

  select(filter: any, index: number): void {
    // todo desconectado el loader temporalmente
    // if (filter.CategoryId == '111') this.utils.loader();
    if (this.keyboard) return;
    this.filter.emit(filter);
    if (this.setFilterAvailable) this._select(filter, index);
  }

  private setColorMode() {
    this._renderer.setAttribute(this.ref, 'mode', this.mode);
    this._renderer.addClass(this.ref, this.colorsOptions.background);
    this._renderer.addClass(this.lineRef, this.colorsOptions.line);
  }

  private markAsSelected(currentFilter: any) {
    this.filters.forEach((filter) => {
      filter.selected = filter?.name === currentFilter?.name;
    });
  }

  private moveLine(i: number) {
    const item = this.listRef.children[i] as any;
    const sizes = item.getBoundingClientRect();
    if (sizes.width == 0) {
      sizes.width = 199.46;
    }
    this._renderer.setStyle(this.lineRef, 'transform', `translateX(${item.offsetLeft}px)`);
    this._renderer.setStyle(this.lineRef, 'width', sizes.width + 'px');
  }

  private display() {
    return new Promise((res, rej) => {
      let display;
      if (Array.isArray(this.filters)) {
        if (this.switch) {
          display = this.filters.length <= 1 ? 'none' : 'block';
        } else {
          // eslint-disable-next-line no-extra-boolean-cast
          display = !!this.filters.length ? 'block' : 'none';
        }
      } else {
        display = 'none';
      }

      this._renderer.setStyle(this._element.nativeElement, 'display', display);
      this.resizeEvent.emit();

      if (this.swiper) {
        this.swiper.update();
      }
      this.utils.closeLoader();
      res(null);
    });
  }
}
