/* eslint-disable */
import { Injectable } from '@angular/core';
import { BetSenseData } from '../../models/sports/BetSenseData';

@Injectable({ providedIn: 'root' })
export class BetSenseService {
  betSelections: any[] = [];

  /**
   * Event listener method for betsense page post messages
   * Should be binded to a BetSenseData object, which can be accessed through the 'this' object
   * @param event Event data
   */
  listenEvents(market: any, event: any) {
    // console.log(event.data);
    // Trick to get the 'this' binded object typed
    let bindedObject: any = this;
    let betSenseData: BetSenseData = <BetSenseData>bindedObject;
    let betsenseEventName: string;

    // WidgetEnabled_PreGame - Once the betsense widget enabled pre game start
    // WidgetEnabled_Live - Once the betsense widget enabled when game is live
    // BetAdded_PreGame - Once the user adds a bet to the betslip from the Betsense Widget before the game started
    // BetAdded_Live - Once the user adds a bet to the betslip from the Betsense Widget when the game is live
    // BetPlaced_PreGame - Once the user completes the bet placed to the betslip from the Betsense Widget before the game started
    // BetPlaced_Live - Once the user completes the bet placed to the betslip from the Betsense Widget when the game is live

    switch (event.data.issue) {
      case 'addBetExternal':
        market.isLive ? (betsenseEventName = 'BetAdded_Live') : (betsenseEventName = 'BetAdded_PreGame');
        betSenseData.newTicketFacade.addBetByLink(event, event.data.id);
        betSenseData.newTicketFacade.addBetsenseSelection(event.data.id);
        betSenseData.trackingService.trackEvent([betsenseEventName, event.data.id, '', '', 'event']);
        break;
      case 'enableBetsenseWidget':
        market.isLive ? (betsenseEventName = 'WidgetEnabled_Live') : (betsenseEventName = 'WidgetEnabled_PreGame');
        betSenseData.iframeVisible = betSenseData.iframeEnabled;
        betSenseData.trackingService.trackEvent([betsenseEventName, betSenseData.eventId, '', '', 'event']);
        break;
      // case 'disableBetsenseWidget':
      //     betSenseData.iframeVisible = false;
      //     betSenseData.trackingService.trackEvent(["BetsenseWidgetDisabled", betSenseData.eventId, "", liveEvent, "event"]);
      //     break;
      default:
        return;
    }
  }
}
