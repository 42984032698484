/* eslint-disable */
import { IonContent, IonHeader, IonicModule } from '@ionic/angular';
import {
  Component,
  ViewChild,
  ElementRef,
  NgZone,
  inject,
  CUSTOM_ELEMENTS_SCHEMA,
  OnInit,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, BehaviorSubject, Subscription, of, combineLatest } from 'rxjs';
import { switchMap, map, filter } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

//Services
import { SportService } from '../../../providers/SportService';
import { SportBookService } from '../../../providers/SportBookService';
import { TicketService } from '../../../providers/TicketService';
import { MenuSportService } from '../../../providers/menuSportService';
import { ScriptService } from '../../../providers/ScriptService';
import { StyleService } from '../../../providers/StyleService';
import { TrackingService } from '../../../providers/TrackingService';

//Models
import { SixPack } from '../../../models/sportbook/sixpack.model';
import { C_LeagueCategory } from '../../../models/C_LeagueCategory';
import { C_League } from '../../../models/C_League';
import { C_CategoryInfo } from '../../../models/C_CategoryInfo';
import { C_BetsData, C_BetsDataFilter } from '../../../models/C_BetsData';
import { C_GameGroupByName } from '../../../models/C_GameGroupByName';

//Others
import { intersection } from '../../../utils/utils.functions';
import { Utils } from '../../../utils/Utils';
import { Constants } from '../../../utils/constants';
import { SportHandleList } from '../../../utils/sportHandleList';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SbButtonComponent } from '../../../components/sports/sb-button/sb-button';
import { SbGridContentExpandedComponent } from '../../../components/sports/sb-grid-content-expanded/sb-grid-content-expanded';
import { SbGridContentSingleComponent } from '../../../components/sports/sb-grid-content-single/sb-grid-content-single';
import { SbGridContentTeamsComponent } from '../../../components/sports/sb-grid-content-teams/sb-grid-content-teams';
import { SbGridHeaderComponent } from '../../../components/sports/sb-grid-header/sb-grid-header';
import { SbOverviewScoreRegularComponent } from '../../../components/sports/sb-overview-score-regular/sb-overview-score-regular';
import { TrackingInfo, BasePage } from '../../base/BasePage';
import { SbNavbarComponent } from '../../../components/common/sb-navbar/sb-navbar';
import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { SbFilterComponent } from '../../../components/sports/sb-filter/sb-filter';
import { SbGridItemComponent } from '../../../components/sports/sb-grid-item/sb-grid-item';
import { SbOverviewScoreSetsComponent } from '../../../components/sports/sb-overview-score-sets/sb-overview-score-sets';
import { NowLiveDetailPage } from '../../../pages/sports/live-market/live-market';
import { MercadosPage } from '../../../pages/sports/market/market';
import { SbDropdownComponent } from '../../../components/sports/sb-dropdown/sb-dropdown';

import { ExtractDatePipe } from '../../../pipes/extractDate.pipe';
import { NewTicketFacade } from '../../../models/sports/store/facades/ticket.facade';
import { C_Event } from '../../../models/C_Event';
import { MobileBehaviorService } from '../../../services/sports/mobileBehavior.service';

import { NewBaseService } from '../../../providers/newBase.service';
import { EventsService } from '../../../providers/EventsService';
import { BufferFacade } from '../../../providers/facades';
import { ActivatedRoute, NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { BetDisplayDirective } from '../../../directives/bet-display/bet-display';
import { CodereNavbar } from '../../../components/common/codere-navbar/codere-navbar';
import { DeviceService } from '../../../services/device.service';
import { DropdownMode } from '../../../models/sportbook';
import { StaticStoreService } from '../../../providers/static-store.service';
import { SbTabsComponent } from '../../../../../sports/src/lib/components/';

declare const SIR;
@Component({
  selector: 'event-page',
  templateUrl: './event.html',
  styleUrls: ['./event.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    SbNavbarComponent,
    SbFilterComponent,
    SbGridItemComponent,
    SbOverviewScoreSetsComponent,
    SbOverviewScoreRegularComponent,
    SbButtonComponent,
    SbDropdownComponent,
    SbGridHeaderComponent,
    SbGridContentSingleComponent,
    CodereFooterComponent,
    SbGridContentTeamsComponent,
    SbGridContentExpandedComponent,
    ExtractDatePipe,
    TranslateModule,
    BetDisplayDirective,
    CodereNavbar,
    NowLiveDetailPage,
    SbTabsComponent
  ]
})
export class EventoPage extends BasePage implements OnInit, OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonHeader) header: IonHeader;
  @ViewChild('standardWidgetSIR') standardWidgetSIR: ElementRef;
  @ViewChild('standardWidgetSIR2') standardWidgetSIR2: ElementRef;
  @ViewChild('stickyWidgetSIR') stickyWidgetSIR: ElementRef;

  league: C_League;
  sportHandleEvent: string;
  events$: Observable<any>;
  eventsAux$: Observable<any>;
  categories$: Observable<C_LeagueCategory[]>;
  currentCategory$: BehaviorSubject<C_LeagueCategory> = new BehaviorSubject(null);
  bet$: Subscription;
  canGoBack: boolean = true;
  gameTypes: any[] = []; // BAU: Todo: maybe this is no longer needed.
  sixPackMarket: C_GameGroupByName[]; // BAU: Todo no longer neeeded
  hasSixPack: boolean;
  polling$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  hasMoreBets: boolean;
  initialFilters: C_BetsDataFilter[] = [];
  betsFilters$: BehaviorSubject<C_BetsDataFilter[]> = new BehaviorSubject(null);
  currentBetsFilter$: BehaviorSubject<C_BetsDataFilter> = new BehaviorSubject(null);
  currentNodeId$: BehaviorSubject<string> = new BehaviorSubject(null);
  currentParentNodeId$: BehaviorSubject<string> = new BehaviorSubject(null);

  //inside filters swipper

  public gametypes: number[] = [284, 114, 37, 82, 153, 192];
  betsCategories: any[] = [];
  showingMoreBets: boolean = false;
  hideCategories: boolean = false;
  market: any;
  markets$: Observable<any>;

  currentAddedCategory$: BehaviorSubject<C_LeagueCategory> = new BehaviorSubject(null);

  marketDesktopMode = DropdownMode.MARKET_DESKTOP;

  globalVars!: GlobalVarsModel;
  ngZone = inject(NgZone);
  override trackingService = inject(TrackingService);
  sportBookService = inject(SportBookService);
  newTicketFacade = inject(NewTicketFacade);
  sportService = inject(SportService);
  ticketService = inject(TicketService);
  translate = inject(TranslateService);
  menuSportService = inject(MenuSportService);
  mobileBehavior = inject(MobileBehaviorService);
  styleService = inject(StyleService);
  scriptService = inject(ScriptService);
  utils = inject(Utils);
  extractDate = inject(ExtractDatePipe);
  bufferFacade = inject(BufferFacade);
  eventService = inject(EventsService);
  newBaseService = inject(NewBaseService);
  activatedRoute = inject(ActivatedRoute);
  deviceService = inject(DeviceService);
  route = inject(ActivatedRoute);
  staticStoreService = inject(StaticStoreService);
  renderer = inject(Renderer2);

  isMobile = false;
  isDesktop = false;
  mySubscription: Subscription;
  private subscription: Subscription;
  router = inject(Router);
  private fromLeftMenuAZ: boolean = false;
  widgetActive: string;

  constructor() {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globalVars = data;
          return of(null);
        })
      )
      .subscribe();

    this.league = history.state.params;
    this.fromLeftMenuAZ = !!history.state.isLeftMenuAZ;

    this.hasMoreBets = false;
    this.currentNodeId$.next(this.league.NodeId);
    this.currentParentNodeId$.next(this.league.ParentNodeId);

    this.league.SportHandle = this.league.SportHandle || this.league.SportsHandle;

    if (this.league.SportName) {
      this.globalVars.SportSelected = this.league.SportName;
    } else {
      this.league.SportName = this.globalVars.SportSelected;
    }
    this.checkTypeLeague();
  }

  ionViewWillEnter() {
    this.staticStoreService.callPolling = false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationSkipped) {
        this.router.navigated = false;
        this.sportService.showSportradar$.next(false);
        this.loadSportRadarWidget();

        if (history?.state?.params || this.staticStoreService.params != null) {
          this.league = history.state.params ? history.state.params : this.staticStoreService.params;
          this.loadSportRadarWidget();
          this.fromLeftMenuAZ = !!history.state.isLeftMenuAZ;
          this.hasMoreBets = false;
          this.currentNodeId$.next(this.league.NodeId);
          this.currentParentNodeId$.next(this.league.ParentNodeId);

          this.league.SportHandle = this.league.SportHandle || this.league.SportsHandle;

          if (this.league.SportName) {
            this.globalVars.SportSelected = this.league.SportName;
          } else {
            this.league.SportName = this.globalVars.SportSelected;
          }
          this.checkTypeLeague();

          this.getData();
        }
      }
    });
    this.league = history.state.params ? history.state.params : this.staticStoreService.params;
    this.fromLeftMenuAZ = !!history.state.isLeftMenuAZ;
    this.hasMoreBets = false;
    this.currentNodeId$.next(this.league.NodeId);
    this.currentParentNodeId$.next(this.league.ParentNodeId);

    this.league.SportHandle = this.league.SportHandle || this.league.SportsHandle;

    if (this.league.SportName) {
      this.globalVars.SportSelected = this.league.SportName;
    } else {
      this.league.SportName = this.globalVars.SportSelected;
    }
    this.checkTypeLeague();

    this.getData();

    this.hasSixPack = SixPack.validate(this.league.SportHandle, this.league.CountryCode);
    this.polling$.next(true);

    this.globalVars.hideBetFooterBar = false;
    this.content.scrollToTop();

    this.trackingService.trackEvent([
      'SelectEvent',
      this.league.NodeId,
      this.globalVars.SportHandleSelected || this.globalVars.SportSelected,
      this.league.Name,
      'pageView'
    ]);
  }

  private getData() {
    this.subscription = this.eventService.currentCanGoBack.subscribe((mensaje) => {
      this.canGoBack = mensaje;
    });
    this.events$ = this.currentCategory$.pipe(
      filter((_) => !!this.currentCategory$.value),
      switchMap((category) => {
        if (!category) {
          category = new C_LeagueCategory(-1, '', false, true);
        }
        if (!this.currentBetsFilter$.value || this.currentBetsFilter$.value.index === 0) {
          this.polling$.next(true);
        }
        this.market = undefined;
        this.markets$ = undefined;
        this.showingMoreBets = false;
        return this.getEvents(category.CategoryId);
      }),
      tap((events) => {
        if (this.currentCategory$.value.CategoryId) {
          this.trackingService.trackEvent([
            'ChangeEventFilter',
            this.league.NodeId,
            '',
            this.currentCategory$.value.CategoryId.toString(),
            'event'
          ]);
        }
      })
    );

    this.categories$ = this.currentBetsFilter$.pipe(
      switchMap((_) => {
        if (!this.currentBetsFilter$.value || this.currentBetsFilter$.value.index === 0) {
          return this.getCategories();
        } else {
          let setCategories = [new C_LeagueCategory(-1, 'Principal', false)];
          return of(setCategories);
        }
      })
    );
  }

  // ionViewDidLoad is deprecated: https://ionicframework.com/docs/updating/4-0#lifecycle-events
  override ngOnInit() {
    if (!this.fromLeftMenuAZ) {
      this.getData();
    }
  }

  ionViewDidEnter() {
    this.staticStoreService.callPolling = false;
    // for mobile use
    if (this.league.Name.toLowerCase() != 'fórmula 1' && this.showTournament()) {
      Promise.all([this.scriptService.load('sportradar-euro'), this.styleService.load('sportradar')]).then(() => {
        this.loadSportRadarWidget();
      });
    }

    this.eventService.publish('footer:refresh', this.content);

    setTimeout(() => this.content.scrollToTop(), 100);
  }

  private loadSportRadarWidget() {
    let uniqueTournamentId: number = this.league.NodeId == Constants.UEFAEuroNodeId ? 1 : 133;
    let disableMoreStats = false;
    let isExpanded = false;
    let disableEventTicker = true;
    let disableCompare = true;

    if (!this.showTournament()) return;
    try {
      SIR('addWidget', '.sr-widget-22', 'tournament.preview', {
        branding: {
          tabs: {
            option: 'iconText',
            iconPosition: 'start',
            variant: 'standard'
          }
        },
        uniqueTournamentId: uniqueTournamentId,
        disableMoreStats: disableMoreStats,
        isExpanded: isExpanded,
        disableEventTicker: disableEventTicker,
        disableCompare: disableCompare,
        isMobile: this.isMobile
      });
    } catch (err) {}
  }

  ionViewDidLeave() {
    this.polling$.next(false);

    if (this.bet$) {
      this.bet$.unsubscribe();
    }
    // SIR('removeWidget', document.querySelector('.sr-widget-22'));
  }

  getTrackingInfo(): TrackingInfo {
    let sportName: string = this.globalVars.SportHandleSelected;
    if (sportName == '') sportName = this.globalVars.SportSelected;

    return {
      uri: encodeURI(`/${sportName}/${this.league.Name}`),
      description: `Evento page - ${this.league.Name}`,
      additionalData: {
        nodeId: this.league.NodeId,
        nodeName: this.league.Name,
        sportHandle: sportName
      }
    };
  }

  private getCategories(): Observable<C_LeagueCategory[]> {
    const params: any = {
      idLeague: this.currentNodeId$.value,
      sportHandle: this.league.SportHandle,
      countryCode: this.league.CountryCode
    };
    return this.sportService.GetCategoriesByLeague(params).pipe(
      map((categories) => {
        categories = Array.isArray(categories) ? categories : [];
        categories = categories.map((cat) => new C_LeagueCategory(cat.CategoryId, cat.CategoryName, cat.IsRelevant));
        const gameTypes = this.globalVars.gameTypes.getSport(this.league.SportHandle);

        if (this.hasSixPack) {
          categories.unshift(new C_LeagueCategory(-1, 'Principal', false));
          categories.filter((category) => gameTypes.indexOf(parseInt(category.CategoryId)) === -1);
        }
        if (
          (!this.isMobile && !this.hasSixPack) ||
          (!!this.currentBetsFilter$.value &&
            this.currentBetsFilter$.value.index === 1 &&
            this.isMobile &&
            !this.hasSixPack)
        ) {
          categories.unshift(new C_LeagueCategory(-1, 'Principal', false));
        }

        if (categories.length <= 0) {
          categories.unshift(new C_LeagueCategory(-1, 'Principal', false));
        }
        this.currentCategory$.next(categories[0]);

        setTimeout(() => this.content.scrollToTop(), 1);
        return categories;
      })
    );
  }

  private getEvents(category?: any): Observable<C_Event[]> {
    let params = {
      parentId: this.currentNodeId$.value,
      category: category,
      sportHandle: this.league.SportHandle
    };

    if (this.currentBetsFilter$.value == null || this.currentBetsFilter$.value.index === 0) {
      return this.bufferFacade
        .newAuxBuffer(this.sportService, 'getEventsCountryBuffer', params, 'regular', this.polling$)
        .pipe(
          map(([prev, current]) => {
            return this.mapEvents(prev, current, category);
          }),
          tap((current) => {
            this.eventService.publish('loading:finish');
            return current;
          })
        );
    } else {
      return this.eventsAux$.pipe(
        tap((events) => {
          this.getMarketNames(category, events);
          this.eventService.publish('loading:finish');
        })
      );
    }
  }

  private mapEvents(prev, current, category) {
    this.getMarketNames(category, current);

    if (!prev) {
      return current;
    }
    current.forEach((event) => {
      const [prevEvent, currentEvent, property] = intersection(prev, event, 'NodeId');
      currentEvent.Games.forEach((game) => {
        let g = prevEvent.Games.find((gItem) => gItem[property] === game[property]);
        this.sportBookService.updateOddChanges(g, game);
      });
    });
    return current;
  }

  private getMarketNames(category: string, current: any) {
    const isSpecialMarket = current.some((sport) => sport.isSpecial);
    if (parseInt(category) < 0 && !isSpecialMarket) {
      this.league.marketNames = C_League.getMarketNames(
        current,
        this.globalVars.gameTypes.getSport(this.league.SportHandle)
      );
      return;
    }
    this.league.marketNames = [];
  }

  public changeCategory(value: any): void {
    this.currentCategory$.next(value);
    this.content.scrollToTop();
  }

  public navigate(market, gameType?): void {
    let componentName = market.isLive ? 'NowLiveDetailPage' : 'MercadosPage';
    let eventType = market.isLive ? 'pushEventLive' : 'pushEvent';
    market.countryCode = this.league.CountryCode;

    const urlComponent = '/' + componentName;
    this.staticStoreService.params = history.state.params;
    this.router.navigate([urlComponent], { state: { params: market } });

    this.trackingService.trackEvent([eventType, market.NodeId, window.location.hash, market.Name, 'event']);
  }

  public bet(game: any, event: any, gameName: string, type: string) {
    const data = {
      ...game,
      EventDate: this.extractDate.transform(event.StartDate),
      EventName: !!this.market && this.market.LeagueName.includes('Anticipadas') ? this.market.Name : event.Name,
      GameName: this.isMobile && event.isSixPack ? event.Games.find((i) => i.NodeId == game.GameId).Name : gameName
    };

    this.newTicketFacade.checkSelection(data, data.NodeId);
  }

  setBetsFilters(filter: C_BetsDataFilter) {
    if (filter.selected) return;
    this.eventService.publish('loading:start');
    this.currentNodeId$.next(filter.nodeId);
    this.currentParentNodeId$.next(filter.parentId);
    this.initialFilters.map((filter, index) => {
      this.initialFilters[index].selected = !this.initialFilters[index].selected;
    });
    this.betsFilters$.next(this.initialFilters);
    this.markets$ = undefined;
    this.currentBetsFilter$.next(this.initialFilters[filter.index]);
    if (filter.index !== 0) {
      this.betsCategories = [new C_LeagueCategory(-1, 'Principal', false)];
      this.hideCategories = true;
      this.getBetsEvent();
    } else {
      this.hideCategories = false;
    }
  }

  checkTypeLeague() {
    const league = this.globalVars.betsData.find(
      (item: C_BetsData) => this.currentNodeId$.value === item.nameLeagueNodeId
    );
    if (league) {
      this.initialFilters = [
        new C_BetsDataFilter(0, 'Partidos', true, this.currentParentNodeId$.value, this.currentNodeId$.value)
      ];
      if (league.earlyBetsNodeId) {
        this.hasMoreBets = true;
        this.initialFilters.push(
          new C_BetsDataFilter(
            this.initialFilters.length,
            'Anticipadas',
            false,
            league.earlyBetsParentId,
            league.earlyBetsNodeId
          )
        );
      }
      if (league.specialBetsNodeId) {
        this.hasMoreBets = true;
        this.initialFilters.push(
          new C_BetsDataFilter(
            this.initialFilters.length,
            'Especiales',
            false,
            league.specialBetsParentId,
            league.specialBetsNodeId
          )
        );
      }
      if (league.groupsBetsNodeId) {
        this.hasMoreBets = true;
        this.initialFilters.push(
          new C_BetsDataFilter(
            this.initialFilters.length,
            'Grupos',
            false,
            league.groupsBetsParentId,
            league.groupsBetsNodeId
          )
        );
      }
      this.betsFilters$.next(this.initialFilters);
      this.currentBetsFilter$.next(this.initialFilters[0]);
    }
  }

  // //ANTICIPADAS DEVELOP

  getBetsEvent() {
    const params = {
      parentId: this.currentNodeId$.value,
      sportHandle: this.league.SportHandle
    };
    this.sportService
      .getEvents(params)
      .toPromise()
      .then((events) => {
        this.eventsAux$ = of(events);
        switch (events.length) {
          case 0:
            this.market = [];
            this.showingMoreBets = true;
            this.eventService.publish('loading:finish');
            break;
          case 1:
            this.market = events[0];
            this.loadBets();
            break;
          default:
            this.currentCategory$.next(new C_LeagueCategory(-1, 'Principal', false));
            break;
        }
      });
  }

  loadBets() {
    this.polling$.next(false);
    this.currentAddedCategory$.next(new C_LeagueCategory(-1, 'Principal', false));
    this.eventService.publish('loading:finish');
    this.markets$ = combineLatest(this.currentAddedCategory$, this.getMarkets(), (current, markets) => {
      this.showingMoreBets = true;
      if (current.CategoryId === -1) {
        return markets;
      }

      return markets.filter((market) => {
        if (!market.CategoryInfos) {
          return false;
        } else if (market.CategoryInfos.length > 0) {
          return market.CategoryInfos.find((category) => category.CategoryId === current.CategoryId);
        }

        return current.CategoryId === 99;
      });
    });
  }

  getMarkets() {
    return this.sportService.getGamesNoLiveEvent(this.market.NodeId, null).pipe(
      map((markets: any[]) => {
        markets.forEach((market, index) => {
          market.nameLeague = this.league.SportHandle;
          this.sportBookService.getAllSpovesInMarket(markets, market);
        });

        this.sportBookService.getCombinedMarkets(markets, this.market);
        markets = this.sportBookService.getMarketGoalScorer(markets, this.market);
        this.getBetsCategories(markets, this.betsCategories);
        markets = this.orderThreeColumns(markets);
        return markets;
      })
    );
  }

  getBetsCategories(games: any[], previousCategories: C_CategoryInfo[]) {
    let indexTemp;
    for (let i: number = 0; i < games.length; i++) {
      if (Array.isArray(games[i].CategoryInfos) && games[i].CategoryInfos.length > 0) {
        games[i].CategoryInfos.forEach((myCategory) => {
          if (myCategory.CategoryName) {
            indexTemp = previousCategories.findIndex((myItem) => myItem.CategoryId == myCategory.CategoryId);
            if (indexTemp == -1) {
              var newCat: C_CategoryInfo = new C_CategoryInfo(
                myCategory.CategoryId,
                myCategory.IsRelevant,
                myCategory.CategoryName
              );
              newCat.numGames = 1;
              this.betsCategories.push(newCat);
            } else {
              this.betsCategories[indexTemp].numGames++;
            }
          }
        });
      }
    }
  }

  private orderThreeColumns(markets) {
    markets.forEach((item) => {
      var isFinal = this.gametypes.indexOf(item.GameType) != -1;
      if (isFinal) {
        let local = [];
        let tie = [];
        let visitant = [];
        let a;
        let b;
        let tmp = '';
        item.GroupResults.forEach((it) => {
          tmp = it.Name.split(':');
          a = parseInt(tmp[0]);
          b = parseInt(tmp[1]);
          if (a > b) local.push(it);
          else if (b > a) visitant.push(it);
          else tie.push(it);
        });
        item.GroupResults = [local, tie, visitant];
      }
    });
    return markets;
  }

  public showTournament() {
    return (
      this.league &&
      this.league.SportHandle == SportHandleList.soccer &&
      (this.league.NodeId == Constants.UEFAEuroNodeId ||
        this.league.NodeId == Constants.CopaAmericaNodeId ||
        this.league.NodeId == Constants.MundialNodeId) &&
      this.globalVars.FEATURES.euroCopaWidgetEnable
    );
  }

  onScroll(e) {
    this.mobileBehavior.scrolling$.next(true);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  resize() {
    if (!this.content) {
      return;
    }
    setTimeout(() => this.content.scrollToTop(), 200);
  }
}
