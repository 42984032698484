import { SafeResourceUrl } from '@angular/platform-browser';
import { PlayerPropsService } from '../../services/sports/PlayerPropsService';

export class C_PlayerPropsIframeData {
  iframeVisible: boolean;
  playerPropsLoader: boolean;
  iframeUrl: SafeResourceUrl;
  iframeHeight: number;
  widgetId: string;
  playerPropsService: PlayerPropsService;
}
