import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ChangeDetectionStrategy,
  Inject,
  ElementRef,
  inject,
  AfterViewInit,
  Renderer2
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { GetNameResultPipe } from '../../../pipes/getNameResult.pipe';
import { FormatOdd } from '../../../pipes/formatOdd.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sb-button',
  templateUrl: './sb-button.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, GetNameResultPipe, FormatOdd],
  styleUrls: ['./sb-button.scss'],
  host: {
    class: 'background-color-button'
  }
})
export class SbButtonComponent implements AfterViewInit {
  @Input() selected: boolean;
  @Input() locked: boolean;
  @Input() type: number;
  @Input() typeName: string;
  @Input() results: any[];
  @Input() index: number;
  @Input() game: any;
  @Input() mode: string;
  @Input() isCombined = false;
  @Input() home: string;
  @Input() away: string;
  @Input() sport: string;
  @Input() sportHandler: string;
  @Output() bet: EventEmitter<any> = new EventEmitter();

  private cuotaEspecial = inject(ElementRef);
  private _renderer = inject(Renderer2);

  bet$: Observable<any>;
  _bet$: Subscription;
  _previousOdd;

  disabled(): boolean {
    if (!this.game) {
      return true;
    }
    return this.game.Odd === '-';
  }
  ngAfterViewInit(): void {
    if (this.sportHandler == 'apuestas_especiales') {
      // verificamos si tiene hermanos, es decir, si hay más botones
      if (this.cuotaEspecial.nativeElement.nextElementSibling != null) {
        this._renderer.addClass(this.cuotaEspecial.nativeElement, 'cuota-especial-25');
      } else {
        // no tiene hermanos
        this._renderer.addClass(this.cuotaEspecial.nativeElement, 'cuota-especial-100');
      }
    }
  }

  isLocked(): boolean {
    return !!this.locked;
  }

  @HostListener('click')
  onBet() {
    if (this.locked || this.game.Odd === '-') {
      return;
    }
    this.bet.emit(this.game);
  }
}
