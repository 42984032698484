export class C_Features {
  AddFriends: boolean;
  amountChargeValues: string;
  amountDepositsValues: string;
  APMNetellerChargeEnabled: boolean;
  APMSKrillChargeEnabled: boolean;
  APMCyberSourceChargeEnabled: boolean;
  appInsightsId: string;
  AppsFlyerAppId: string;
  AppsFlyerDevKey: string;
  AppVersionPopup: boolean;
  AstropayBankDeposits: string;
  AstropayBankDepositsEnabled: boolean;
  AstropayCreditCardEnabled: boolean;
  AstropaySpeiEnabled: boolean;
  AstropayWithdrawalEnabled: boolean;
  ASXDaviPlataChargeEnabled: boolean;
  ASXNequiChargeEnabled: boolean;
  balanceCasinoDetails: boolean;
  balanceFBDetails: boolean;
  bancomerEnabled: boolean;
  bannerCountdownMundial2022_dateTime: string;
  bannerCountdownMundial2022: boolean;
  BBVAIframeUrl: string;
  BetbuilderEnabled: boolean;
  SPO_BetbuilderEnabled: boolean;
  betsenseLiveEnabled: boolean;
  betsenseNonLiveEnabled: boolean;
  betsenseUrlTemplate: string;
  BetSlipLegMinValue: string;
  BlockByCountryAvailable: boolean;
  BonusSlotsMenuEnabled: boolean;
  callmebackEnabled: boolean;
  CancelCount: boolean;
  CardAssociationAllowed: boolean;
  CardSelectionWithdrawal: boolean;
  CAS_BonusBalanceBDRedirectMOB: any;
  CAS_BonusBalanceBDRedirectPC: any;
  Casino_OmnichannelBackgroundMobile: string;
  Casino_OmnichannelBackgroundPC: string;
  Casino_OmnichannelHeaderMobile: string;
  Casino_OmnichannelHeaderPC: string;
  Casino_URLResponsibleGaming: string;
  CasinoBetslipGames: string;
  CasinoBonusEnabled: boolean;
  Casino_EnabledMultiProviderBonuses: boolean;
  Casino_EnabledMultiCallBonuses: boolean;
  CasinoCalendarEnabled: boolean;
  casinoCalendarName: string;
  CAS_MoreFilters: boolean;
  CAS_GameSessionImprovements: boolean;
  CAS_NewCalendar: boolean;
  casinoCostLimit: number;
  casinoCostLimitRegulator: number;
  casinoDemoEnabled: boolean;
  casinoEnabled: boolean;
  CasinoLiveGameImageTypeUrl: string;
  casinoLostLimit: number;
  casinoLostLimitRegulator: number;
  CasinoQuickLaunchGames: string;
  casinoTimeLimit: number;
  casinoTimeLimitRegulator: number;
  changeLangSPEnabled: boolean;
  chatEnabedToFailureDeposit: boolean;
  chatEnabedToFailureRegister: boolean;
  checkTicket: boolean;
  checkTicketFromAzureEnabled: boolean;
  CobHalCashEnabled: boolean;
  CobLocalEnabled: boolean;
  CobMenuEnabled: boolean;
  CobOnlineEnabled: boolean;
  CobPayPalEnabled: boolean;
  CobTarjCodere: boolean;
  CobTransfBancEnabled: boolean;
  CodereCardEnabled: boolean;
  CodereMillion: boolean;
  CommunicationsOptions: boolean;
  contactEnabled: boolean;
  contactServiceEnabled: boolean;
  CopaAmerica_IframeURL: string;
  CopaAmerica: boolean;
  CopaAmericaAPI: string;
  copaAmericaWidgetEnable: boolean;
  CrowCasinoDepositEnabled: boolean;
  CrowCasinoWDEnabled: boolean;
  csrfControlFront: boolean;
  DaviPlataWithDrawalEnabled: boolean;
  declaracionJuradaPep: string;
  defaultDepositOptionSelected: string;
  DepCredidCardEnabled: boolean;
  DepLocalEnabled: boolean;
  DepOnlineEnabled: boolean;
  depositByTransf: boolean;
  depositByTransfValue: string;
  depositDayLimit: string;
  depositDayLimitRegulator: string;
  depositLimitRegulatorEndDate: string;
  depositLimitRegulatorStartDate: string;
  depositMonthLimit: string;
  depositMonthLimitRegulator: string;
  depositWeekLimit: string;
  depositWeekLimitRegulator: string;
  DepPaypalEnabled: boolean;
  DepPaysSafeCardEnabled: boolean;
  DepTarjCodere: boolean;
  DepUkashEnabled: boolean;
  DevEnvironment: boolean;
  DocVerification: boolean;
  doLoginwithLoginServices: boolean;
  DynamicYieldClientApiKey: string;
  DynamicYieldClientEngagementUrl: string;
  DynamicYieldRecommendationEnabled: boolean;
  DynamicYieldSportsBetSlipEnabled: boolean;
  EarlyCashoutEnabled: boolean;
  EnabledSelfDiagnosisTest: boolean;
  EnabledSolitics: boolean;
  EnableTrueLayerForDevices: boolean;
  enableVerificationMessage: boolean;
  EpgCreditCardChargeEnabled: boolean;
  EpgCreditCardEnabled: boolean;
  EPGDepositMinimumAmount: number;
  EPGMuchBetterWithDrawalEnabled: boolean;
  EpgSkrillRapidTransferDepositEnabled: boolean;
  eurocenterEnabled: string;
  euroCopaWidgetEnable: boolean;
  EventBusApiUrl: string;
  EvoPaymentsEnabled: boolean;
  FiveLoginPopup: boolean;
  footerContent: boolean;
  ForbiddenSports: any;
  FreeBetAccountVerification: boolean;
  FreeBetEnabled: boolean;
  FreebetFraudFix: boolean;
  FreeBetSMSVerification: boolean;
  GeolocationDistance: number;
  GeolocationTillAllowed: boolean;
  GeolocationTimespan: number;
  getPendingOnlinePaymentsEnabled: boolean;
  Payments_GracePeriodActive: boolean;
  Payments_GracePeriodDays: number;
  Payments_GracePeriodDepositLimit: number;
  Payments_GracePeriodStart: string;
  GreyhoundsEnabled: boolean;
  GreyhoundSISPlayerEnabled: boolean;
  GreyhoundSISPlayerUrl: string;
  HalCashMaxAmount: string;
  HalCashMinAmount: string;
  helpContent: boolean;
  hideBetFooterBar: boolean;
  historyEnabled: boolean;
  HorsesEnabled: boolean;
  HorsesSISPlayerEnabled: boolean;
  HorsesSISPlayerUrl: string;
  IMGOperatorId: string;
  IMGUrlBase: string;
  IosTouchIdEnabled: boolean;
  isOrdenCongelamientoFondos: boolean;
  JackpotCallServiceActive: boolean;
  JackpotLobbyEnabled: boolean;
  JackpotLobbyHeader: string;
  LigaAguilaEnabled: boolean;
  linkInfo: boolean;
  linkNativeDownload: string;
  linkOpenDownload: string;
  ListOfGameTypesInCombinedMarket: string;
  ListOfProfesions: string;
  loadTicket: boolean;
  Lobbies: any;
  LobbyAmountStepIncrement: number;
  LobbyMinimumAmount: number;
  LoginAccessEnabled: boolean;
  loginerrorCode: any;
  loginURLredirect: string;
  logoCodereHeader: string;
  LotbaWithdrawalEnabled: boolean;
  MadridMigrationEnabled: boolean;
  maxNumberOfVeridasfail: number;
  MaxTarjetaDepositAmount: number;
  MercadoPagoChargeEnabled: boolean;
  messageServiceEnabled: boolean;
  MexCobLocalEnabled: boolean;
  MexCobTransfBancEnabled: boolean;
  MinBankAccountCharge: number;
  MiniGameGuid: string;
  MiniGameProviderGuid: string;
  MiniGamesEnabled: boolean;
  miniRouletteEnabled: boolean;
  misterUnderdogEnabled: boolean;
  ModalBonusSportCasino: boolean;
  MSO_alertCookiesBtnDecline: boolean;
  MSO_alertCookiesNoBlock: boolean;
  MSO_BannersEndpointURL: string;
  MSO_cardCodereCP: boolean;
  MSO_CheckDNIExpired: boolean;
  MSO_checkElegibleBanners: boolean;
  MSO_CheckProfesion: boolean;
  MSO_EnabledComplianceRegistroCO: boolean;
  MSO_limitsSessionCasino: string;
  MSO_ModalWelcomeBonusContent: string;
  MSO_newEndpointCodereCard: boolean;
  MSO_rsa_publickey: string;
  MSO_UrlNewUserExtractNative: string;
  MSO_UrlNewUserSummaryAuthorize: string;
  MSO_UrlNewUserSummaryNative: string;
  msoNewSelfExclusionAR: boolean;
  msoSelfexclusionURLPBA: string;
  MSO_SelfexclusionURLMZA: string;
  MSO_PersonalInfoPageEnabled: boolean;
  MSO_DocumentationVerificationStatus: boolean;
  MSO_dateRegisterVerificationStatus: string;
  MSO_ShowBankTransferFooterAr: boolean;
  MSO_GraceTimeLogicEnabled: boolean;
  MSO_URLTargetedOptInPopupPage: string;
  MSO_URLPromotionsAPIOptIn: string;
  MSO_URLPromotionsAPIOptOut: string;
  MSO_URLPromotionsAPIWBUser: string;
  MSO_URLPromotionsAPIWBInfo: string;
  MSO_URLPromotionsAPIWBOut: string;
  MSO_URLPromotionsAPIisPromotable: string;
  MSO_URLPromotionsAPIisPromotableVip: string;
  MSO_URLPromotionsAPIisFullPromotable: string;
  MSO_EnableGeolocation: boolean;
  MSO_URLPromotionsAPIList: string;
  MSO_NewCtaPromoPopup: boolean;
  MSO_NewCheckCookieConsent: boolean;
  MSO_newCardCodere_Splash: boolean;
  MSO_TimerGeolocation: boolean;
  MSO_WelcomeBonusCasinoSportsInOne: boolean;
  MSO_NewAlertsPromotionsPage: boolean;
  MSO_SkipErrorMessagePromotions: string;
  MSO_CommunicationsOptionsLeft: boolean;
  MSO_WelcomeBonusDateLimit: string;
  MSO_WelcomeBonusIDCasino: string;
  MSO_WelcomeBonusIDSports: string;
  MSO_WelcomeBonusImgCasino: string;
  MSO_WelcomeBonusImgSports: string;
  MSO_WelcomeBonusTitleCasino: string;
  MSO_WelcomeBonusTitleSports: string;
  MSO_WelcomeBonusTxtCasino: string;
  MSO_WelcomeBonusTxtSports: string;
  MSO_WelcomeBonusTycCasino: string;
  MSO_WelcomeBonusTycSports: string;
  MSO_WelcomeBonusTycSportsCasino: string;
  MSO_SesionTimer: boolean;
  MSO_LocalDateTimer: boolean;
  MSO_NewReBranding: boolean;
  MVPCodere_IframeURL: string;
  MVPCodere: boolean;
  MVPCodereAPI: string;
  MVPCodereWidgetEnable: boolean;
  myactivity: boolean;
  nationalLimits: boolean;
  nationalReport: boolean;
  nationalUserPoints: boolean;
  nativeAndroidVersion: string;
  NcaaIdLeague: string;
  NearestLocals: boolean;
  NequiWithDrawalEnabled: boolean;
  NewCarouselActivated: boolean;
  NewCarouselApi: string;
  NewCashierActivated: boolean;
  newChatData: string;
  newChatEnabled: boolean;
  NewLoginServices: boolean;
  NewSports: string;
  notificationEnabled: boolean;
  NotifyBalanceChange: boolean;
  ObjectPromoPreBet: string;
  olympicGamesEnabled: boolean;
  ourLocals: boolean;
  oxxoEnabled: boolean;
  Payments_deposits_URL: string;
  Payments_withdrawals_URL: string;
  //PaymentsV2 Method España
  Payments_Skrill_UsePaymentsv2: boolean;
  Payments_Neteller_UsePaymentsv2: boolean;
  Payments_Cybersource_UsePaymentsv2: boolean;
  Payments_CreditCards_UsePaymentsv2: boolean;
  Payments_Astropay_UsePaymentsv2: boolean;
  Payments_PaySafe_UsePaymentsv2: boolean;
  Payments_Bizum_UsePaymentsv2: boolean;
  Payments_TrueLayer_UsePaymentsv2: boolean;
  Payments_RapidTransfer_UsePaymentsv2: boolean;
  Payments_LocalCodere_UsePaymentsv2: boolean;
  Payments_Paypal_UsePaymentsv2: boolean;
  Payments_BankTransfer_UsePaymentsv2: boolean;
  Payments_HalCash_UsePaymentsv2: boolean;
  //PaymentsV2 Method Argentina
  Payments_Tarjeta_UsePaymentsv2: boolean;
  Payments_Debin_UsePaymentsv2: boolean;
  Payments_MercadoPago_UsePaymentsv2: boolean;
  //PaymentsV2 Method Colombia
  Payments_Pse_UsePaymentsv2: boolean;
  Payments_PayU_UsePaymentsv2: boolean;
  Payments_Daviplata_UsePaymentsv2: boolean;
  Payments_Nequi_UsePaymentsv2: boolean;
  Payments_AlmacenesExito_UsePaymentsv2: boolean;
  Payments_Carulla_UsePaymentsv2: boolean;
  Payments_GanaCerca_UsePaymentsv2: boolean;
  Payments_PagoTodo_UsePaymentsv2: boolean;
  Payments_GanaBank_UsePaymentsv2: boolean;
  Payments_Efecty_UsePaymentsv2: boolean;
  Payments_Dimonex_UsePaymentsv2: boolean;
  Payments_PuntoRed_UsePaymentsv2: boolean;
  Payments_Tpaga_UsePaymentsv2: boolean;
  Payments_VeridasCOActive: boolean;
  paynetEnabled: boolean;
  PaypalClientEnviorement: string;
  paysafecardEnabled: boolean;
  PayValidaCashEnabled: boolean;
  PayValidaCreditCardEnabled: boolean;
  PayValidaPSEEnabled: boolean;
  phoneRegister: boolean;
  playerPropsEnabled: boolean;
  playerPropsEventUrl: string;
  playerPropsUrl: string;
  playsstenabled: boolean;
  predictNumberEvents: string;
  Prom10x30Enabled: boolean;
  Promolink: boolean;
  propsBetsEnabled: boolean;
  providersGamingBB: string;
  PSEWithDrawalEnabled: boolean;
  pticket: boolean;
  PtoPCashEnabled: boolean;
  PtoPCreditCardEnabled: boolean;
  PtoPPseEnabled: boolean;
  PtoPPseIframeEnabled: boolean;
  pvLimits: boolean;
  quickExitPageEnabled: boolean;
  rankingMundialEnabled: boolean;
  RealMadridEnabled: boolean;
  RealMadridId: string;
  RealMadridQuery: string;
  RecargaCodereEnabled: boolean;
  redsysFuc: string;
  redsysIdioma: string;
  redsysTerminal: string;
  RegistrationEnabled: boolean;
  RegistroArRenaper: boolean;
  RegistroCODesktop: string;
  RegistroCOMobile: string;
  RegistroCONative: string;
  registroRedirect: string;
  righMenuSpecialSport: string;
  rightMenuSpecialGame: string;
  RiverPlateEnabled: boolean;
  RiverPlateId: string;
  RiverPlateQuery: string;
  searcherView: boolean;
  selfExclusionPageEnabled: boolean;
  selfieDocEnabled: boolean;
  SendFrontErrors: boolean;
  SenToPlaytechTriggerBonus: boolean;
  ShowCardRequest: boolean;
  showDocumentVerificationQrCode: boolean;
  SignalRCasino: string;
  SignalRCasinoJackpots: string;
  SignalRCasinoLiveGames: string;
  SignalRRoyalDegree: boolean;
  cas_uniqueGamesessionEnabled: boolean;
  slotReport: boolean;
  slotsEnabled: boolean;
  SlotsPromotion: boolean;
  SlotsTournament: boolean;
  snapengageChannel: string;
  SPO_CodereCardPayout_SportsMisc: boolean;
  SPO_DefaultOverallStake: number;
  SPO_HideHeaderSearchFunctionality: boolean;
  SPO_LiveBetbuilderEnabled: boolean;
  SPO_LiveMarketTabByTabEnabled: boolean;
  SPO_LMTFormula1: boolean;
  SPO_MarqueeBetbuilderHidden: boolean;
  SPO_MarqueeHidden: boolean;
  SPO_MarqueeNodes: string;
  SPO_MisApuestasNewModelFeatureFlagEnabled: boolean;
  SPO_ShowSystemTab: boolean;
  SPO_SportHandlerNames: string;
  SPO_totalOddsFeatureFlagEnabled: boolean;
  SPO_totalOddsMobileFeatureFlagEnabled: boolean;
  SPO_UseSportsMiscForCodereCardBets: boolean;
  SPO_UseSportsMiscForPlayerBets: boolean;
  SPO_UseSportsMiscForPlayerInfo: boolean;
  sportPanelContent: boolean;
  SummaryPlayerForceExtract: boolean;
  targetedOptinPopup: boolean;
  TarjetaCodereEnabled: boolean;
  TeleIngresoEnabled: boolean;
  TemporaryPromoEnabled: boolean;
  TemporaryPromoUrl: string;
  timeAlertAr: boolean;
  timeOutPageEnabled: boolean;
  titleCodere: boolean;
  TpagaPaymentEnabled: boolean;
  TrueLayerWithdrawalEnabled: boolean;
  UrlNewUserExtract: string;
  UrlNewUserMyActivity: string;
  UrlNewUserMyActivityExtractable: string;
  UrlNewUserSummary: string;
  urlRegisterLite: string;
  UseBetConfigDataBackEndpoint: boolean;
  userPanelContent: boolean;
  UserServiceLoginActivate: boolean;
  userSErviceStandAlone: boolean;
  usertimelogout: any;
  VeridasDocumentVerificationEnabled: boolean;
  VeridasXpressIdVerificationEnabled: boolean;
  VerticalRouletteGameGuid: string;
  VerticalRouletteGameProviderGuid: string;
  videobingoEnabled: boolean;
  WelcomeMessage: string;
  WitdrawTaxRetentionInformationEnabled: boolean;
  worldCupCalendar: boolean;
  worldCupCenterEnabled: boolean;
  XPInboxEnabled: boolean;
  XPMessagingEnabled: boolean;
  showOriginOfFunds: boolean;
  payValidaPaymentCash: boolean;
  mfaActive: boolean;
  showLocalCodereInfoDelete: boolean;
  depositsJSONApiActive: boolean;
  withdrawalsJSONApiActive: boolean;
  netellerIframeActive: boolean;
  VeridasAutoVerificationEnabled: boolean;

  constructor(APPSFLYER_APPID?: string) {
    this.AddFriends = false;
    this.amountChargeValues = '';
    this.amountDepositsValues = '';
    this.amountDepositsValues = '';
    this.APMNetellerChargeEnabled = false;
    this.APMSKrillChargeEnabled = false;
    this.APMCyberSourceChargeEnabled = false;
    this.appInsightsId = '';
    this.AppsFlyerAppId = APPSFLYER_APPID || '917178660';
    this.AppsFlyerDevKey = '35oCwDbdGrCHzD9kajJMTk';
    this.AppVersionPopup = false;
    this.AstropayBankDeposits = '';
    this.AstropayBankDepositsEnabled = false;
    this.AstropayCreditCardEnabled = false;
    this.AstropaySpeiEnabled = false;
    this.AstropayWithdrawalEnabled = false;
    this.ASXDaviPlataChargeEnabled = false;
    this.ASXNequiChargeEnabled = false;
    this.balanceCasinoDetails = false;
    this.balanceFBDetails = false;
    this.bancomerEnabled = false;
    this.bannerCountdownMundial2022 = false;
    this.bannerCountdownMundial2022_dateTime = '';
    this.BBVAIframeUrl = '';
    this.BetbuilderEnabled = true;
    this.SPO_BetbuilderEnabled = true;
    this.BetSlipLegMinValue = '';
    this.BlockByCountryAvailable = true;
    this.BonusSlotsMenuEnabled = true;
    this.callmebackEnabled = false;
    this.CancelCount = true;
    this.CardAssociationAllowed = false;
    this.CardSelectionWithdrawal = true;
    this.CAS_BonusBalanceBDRedirectMOB = '';
    this.CAS_BonusBalanceBDRedirectPC = '';
    this.Casino_OmnichannelBackgroundMobile = '';
    this.Casino_OmnichannelBackgroundPC = '';
    this.Casino_OmnichannelHeaderMobile = '';
    this.Casino_OmnichannelHeaderPC = '';
    this.Casino_URLResponsibleGaming = '';
    this.CasinoBetslipGames = '';
    this.CasinoBonusEnabled = false;
    this.Casino_EnabledMultiProviderBonuses = false;
    this.Casino_EnabledMultiCallBonuses = false;
    this.CasinoCalendarEnabled = false;
    this.casinoCalendarName = 'Calendario de Novedades';
    this.CAS_MoreFilters = false;
    this.CAS_GameSessionImprovements = false;
    this.CAS_NewCalendar = false;
    this.casinoCostLimit = 0;
    this.casinoCostLimitRegulator = 0;
    this.casinoDemoEnabled = true;
    this.CasinoLiveGameImageTypeUrl = null;
    this.casinoLostLimit = 0;
    this.casinoLostLimitRegulator = 0;
    this.CasinoQuickLaunchGames = '';
    this.casinoTimeLimit = 0;
    this.casinoTimeLimitRegulator = 0;
    this.changeLangSPEnabled = true;
    this.chatEnabedToFailureDeposit = false;
    this.chatEnabedToFailureRegister = false;
    this.checkTicket = true;
    this.checkTicketFromAzureEnabled = false;
    this.CobHalCashEnabled = true;
    this.CobLocalEnabled = true;
    this.CobMenuEnabled = true;
    this.CobOnlineEnabled = true;
    this.CobPayPalEnabled = true;
    this.CobTarjCodere = true;
    this.CobTransfBancEnabled = true;
    this.CodereCardEnabled = true;
    this.CodereMillion = false;
    this.CommunicationsOptions = false;
    this.contactEnabled = true;
    this.contactServiceEnabled = false;
    this.CopaAmerica = false;
    this.CopaAmerica_IframeURL = '';
    this.CopaAmericaAPI = '';
    this.copaAmericaWidgetEnable = false;
    this.CrowCasinoDepositEnabled = false;
    this.CrowCasinoWDEnabled = false;
    this.csrfControlFront = false;
    this.DaviPlataWithDrawalEnabled = false;
    this.declaracionJuradaPep = '';
    this.defaultDepositOptionSelected = '';
    this.DepCredidCardEnabled = true;
    this.DepLocalEnabled = true;
    this.DepOnlineEnabled = true;
    this.depositByTransf = false;
    this.depositByTransfValue = '';
    this.depositDayLimit = '0';
    this.depositDayLimitRegulator = '0';
    this.depositLimitRegulatorEndDate = '';
    this.depositLimitRegulatorStartDate = '';
    this.depositMonthLimit = '0';
    this.depositMonthLimitRegulator = '0';
    this.depositWeekLimit = '0';
    this.depositWeekLimitRegulator = '0';
    this.DepPaypalEnabled = true;
    this.DepPaysSafeCardEnabled = true;
    this.DepTarjCodere = true;
    this.DepUkashEnabled = true;
    this.DevEnvironment = true;
    this.DocVerification = true;
    this.doLoginwithLoginServices = false;
    this.DynamicYieldClientApiKey = '';
    this.DynamicYieldClientEngagementUrl = '';
    this.DynamicYieldRecommendationEnabled = false;
    this.DynamicYieldSportsBetSlipEnabled = false;
    this.EarlyCashoutEnabled = true;
    this.EnabledSelfDiagnosisTest = false;
    this.EnabledSolitics = false;
    this.EnableTrueLayerForDevices = false;
    this.enableVerificationMessage = false;
    this.EpgCreditCardChargeEnabled = false;
    this.EpgCreditCardEnabled = false;
    this.EPGDepositMinimumAmount = 0;
    this.EPGMuchBetterWithDrawalEnabled = false;
    this.EpgSkrillRapidTransferDepositEnabled = false;
    this.eurocenterEnabled = null;
    this.euroCopaWidgetEnable = false;
    this.EventBusApiUrl = '';
    this.EvoPaymentsEnabled = false;
    this.FiveLoginPopup = false;
    this.footerContent = true;
    this.ForbiddenSports = [];
    this.FreeBetAccountVerification = false;
    this.FreeBetEnabled = true;
    this.FreebetFraudFix = false;
    this.FreeBetSMSVerification = false;
    this.GeolocationDistance = 0;
    this.GeolocationTillAllowed = true;
    this.GeolocationTimespan = 0;
    this.GreyhoundsEnabled = true;
    this.GreyhoundSISPlayerEnabled = false;
    this.GreyhoundSISPlayerUrl = '';
    this.HalCashMaxAmount = '600';
    this.HalCashMinAmount = '20';
    this.helpContent = true;
    this.hideBetFooterBar = true;
    this.historyEnabled = true;
    this.HorsesEnabled = true;
    this.HorsesSISPlayerEnabled = false;
    this.HorsesSISPlayerUrl = '';
    this.IMGOperatorId = '';
    this.IMGUrlBase = '';
    this.IosTouchIdEnabled = false;
    this.isOrdenCongelamientoFondos = false;
    this.JackpotCallServiceActive = false;
    this.JackpotCallServiceActive = false;
    this.JackpotLobbyHeader = '';
    this.LigaAguilaEnabled = false;
    this.linkInfo = true;
    this.linkNativeDownload = '';
    this.linkOpenDownload = '';
    this.ListOfGameTypesInCombinedMarket =
      '[4,7,39,148,155,175,988,990,991,993,1064,1065,1330,1391,1392,1393,1394,1606,1647,1648,1649,1650,1667,1668,1669,1804,1805,1806,1807,1808,1809]';
    this.ListOfProfesions = '';
    this.loadTicket = true;
    this.Lobbies = '[]';
    this.LobbyAmountStepIncrement = 1;
    this.LobbyMinimumAmount = 1;
    this.LoginAccessEnabled = true;
    this.loginerrorCode = [];
    this.loginURLredirect = '';
    this.logoCodereHeader = '';
    this.LotbaWithdrawalEnabled = false;
    this.maxNumberOfVeridasfail = 3;
    this.MaxTarjetaDepositAmount = 10000;
    this.MercadoPagoChargeEnabled = false;
    this.messageServiceEnabled = false;
    this.MexCobLocalEnabled = false;
    this.MexCobTransfBancEnabled = false;
    this.MinBankAccountCharge = 0;
    this.MiniGameGuid = '';
    this.MiniGameProviderGuid = '';
    this.MiniGamesEnabled = false;
    this.miniRouletteEnabled = false;
    this.misterUnderdogEnabled = false;
    this.ModalBonusSportCasino = false;
    this.MSO_alertCookiesBtnDecline = false;
    this.MSO_alertCookiesNoBlock = false;
    this.MSO_alertCookiesNoBlock = false;
    this.MSO_BannersEndpointURL = '';
    this.MSO_BannersEndpointURL = '';
    this.MSO_cardCodereCP = false;
    this.MSO_CheckDNIExpired = false;
    this.MSO_checkElegibleBanners = false;
    this.MSO_CheckProfesion = false;
    this.MSO_EnabledComplianceRegistroCO = false;
    this.MSO_limitsSessionCasino = '';
    this.MSO_ModalWelcomeBonusContent = 'ALL';
    this.MSO_PersonalInfoPageEnabled = false;
    this.MSO_SelfexclusionURLMZA = '';
    this.MSO_ShowBankTransferFooterAr = false;
    this.MSO_newEndpointCodereCard = false;
    this.MSO_rsa_publickey = '';
    this.MSO_UrlNewUserExtractNative = '';
    this.MSO_UrlNewUserSummaryAuthorize = '';
    this.MSO_UrlNewUserSummaryNative = '';
    this.MSO_SelfexclusionURLMZA = '';
    this.MSO_PersonalInfoPageEnabled = false;
    this.msoNewSelfExclusionAR = false;
    this.msoSelfexclusionURLPBA = '';
    this.MSO_DocumentationVerificationStatus = false;
    this.MSO_dateRegisterVerificationStatus = '';
    this.MSO_ShowBankTransferFooterAr = false;
    this.MSO_GraceTimeLogicEnabled = false;
    this.MSO_URLTargetedOptInPopupPage = '';
    this.MSO_URLPromotionsAPIOptIn = '';
    this.MSO_URLPromotionsAPIOptOut = '';
    this.MSO_URLPromotionsAPIWBUser = '';
    this.MSO_URLPromotionsAPIWBInfo = '';
    this.MSO_URLPromotionsAPIWBOut = '';
    this.MSO_URLPromotionsAPIisPromotable = '';
    this.MSO_URLPromotionsAPIisPromotableVip = '';
    this.MSO_URLPromotionsAPIisFullPromotable = '';
    this.MSO_EnableGeolocation = false;
    this.MSO_URLPromotionsAPIList = '';
    this.MSO_NewCtaPromoPopup = false;
    this.MSO_newCardCodere_Splash = false;
    this.MSO_TimerGeolocation = false;
    this.MSO_WelcomeBonusCasinoSportsInOne = false;
    this.MSO_NewAlertsPromotionsPage = false;
    this.MSO_SkipErrorMessagePromotions = '';
    this.MSO_CommunicationsOptionsLeft = false;
    this.MSO_WelcomeBonusDateLimit = '';
    this.MSO_WelcomeBonusIDCasino = '';
    this.MSO_WelcomeBonusIDSports = '';
    this.MSO_WelcomeBonusImgCasino = '';
    this.MSO_WelcomeBonusImgSports = '';
    this.MSO_WelcomeBonusTitleCasino = '';
    this.MSO_WelcomeBonusTitleSports = '';
    this.MSO_WelcomeBonusTxtCasino = '';
    this.MSO_WelcomeBonusTxtSports = '';
    this.MSO_WelcomeBonusTycCasino = '';
    this.MSO_WelcomeBonusTycSports = '';
    this.MSO_WelcomeBonusTycSportsCasino = '';
    this.MSO_SesionTimer = false;
    this.MSO_LocalDateTimer = false;
    this.MSO_NewReBranding = false;
    this.MVPCodere = false;
    this.MVPCodere_IframeURL = '';
    this.MVPCodereAPI = '';
    this.MVPCodereWidgetEnable = false;
    this.myactivity = false;
    this.nationalLimits = true;
    this.nationalReport = true;
    this.nationalUserPoints = true;
    this.nativeAndroidVersion = '1.0.4';
    this.NcaaIdLeague = '';
    this.NequiWithDrawalEnabled = false;
    this.NewCarouselActivated = false;
    this.NewCarouselApi = '';
    this.NewCashierActivated = false;
    this.newChatData = '';
    this.newChatEnabled = false;
    this.NewLoginServices = false;
    this.NewSports = '';
    this.notificationEnabled = false;
    this.NotifyBalanceChange = true;
    this.ObjectPromoPreBet = '';
    this.olympicGamesEnabled = false;
    this.ourLocals = false;
    this.oxxoEnabled = false;
    this.Payments_Neteller_UsePaymentsv2 = false;
    this.Payments_Neteller_UsePaymentsv2 = false;
    this.Payments_Skrill_UsePaymentsv2 = false;
    this.Payments_Cybersource_UsePaymentsv2 = false;
    this.paynetEnabled = false;
    this.PaypalClientEnviorement = 'sandbox';
    this.paysafecardEnabled = false;
    this.PayValidaCashEnabled = false;
    this.PayValidaCreditCardEnabled = false;
    this.PayValidaPSEEnabled = false;
    this.phoneRegister = true;
    this.playerPropsEnabled = false;
    this.playerPropsEventUrl = '';
    this.playerPropsUrl = '';
    this.playsstenabled = false;
    this.predictNumberEvents = '0';
    this.Prom10x30Enabled = false;
    this.Promolink = false;
    this.propsBetsEnabled = false;
    this.PSEWithDrawalEnabled = false;
    this.pticket = true;
    this.PtoPCashEnabled = false;
    this.PtoPCreditCardEnabled = false;
    this.PtoPPseEnabled = false;
    this.PtoPPseIframeEnabled = false;
    this.pvLimits = true;
    this.quickExitPageEnabled = false;
    this.rankingMundialEnabled = false;
    this.RealMadridEnabled = false;
    this.RealMadridId = '';
    this.RealMadridQuery = '';
    this.RecargaCodereEnabled = true;
    this.redsysFuc = '348638271';
    this.redsysIdioma = 'ES';
    this.redsysTerminal = '1';
    this.RegistrationEnabled = true;
    this.RegistroArRenaper = true;
    this.RegistroCODesktop = 'RegistroCOPage';
    this.RegistroCOMobile = 'RegistroCONew';
    this.RegistroCONative = 'RegistroCONew';
    this.registroRedirect = '';
    this.righMenuSpecialSport = '';
    this.rightMenuSpecialGame = '';
    this.RiverPlateEnabled = false;
    this.RiverPlateId = '';
    this.RiverPlateQuery = '';
    this.searcherView = true;
    this.selfExclusionPageEnabled = false;
    this.selfieDocEnabled = false;
    this.SendFrontErrors = false;
    this.SenToPlaytechTriggerBonus = false;
    this.ShowCardRequest = true;
    this.showDocumentVerificationQrCode = false;
    this.SignalRCasino = '';
    this.SignalRCasinoJackpots = '';
    this.SignalRCasinoLiveGames = '';
    this.SignalRRoyalDegree = false;
    this.cas_uniqueGamesessionEnabled = false;
    this.slotReport = true;
    this.SlotsPromotion = false;
    this.SlotsTournament = false;
    this.snapengageChannel = '';
    this.SPO_CodereCardPayout_SportsMisc = true;
    this.SPO_DefaultOverallStake = 10;
    this.SPO_HideHeaderSearchFunctionality = true;
    this.SPO_LiveBetbuilderEnabled = true;
    this.SPO_LiveMarketTabByTabEnabled = true;
    this.SPO_LMTFormula1 = true;
    this.SPO_MarqueeBetbuilderHidden = false;
    this.SPO_MarqueeHidden = false;
    this.SPO_MarqueeNodes = '';
    this.SPO_MisApuestasNewModelFeatureFlagEnabled = false;
    this.SPO_ShowSystemTab = true;
    this.SPO_SportHandlerNames =
      '{"billard": "Billar","esports": "eSports","golf": "Golf","handball": "Balonmano","motor": "Motor","snooker": "Snooker","ice_hockey": "Hockey sobre hielo","field_hockey": "Hockey hierba","Field_hockey": "Hockey hierba","darts": "Dardos","rugby": "Rugby","baseball": "Béisbol","soccer_indoor": "Futbol Sala","soccer": "Fútbol","basketball": "Baloncesto","american_football": "Fútbol Americano","badminton": "Bádminton","beach_soccer": "Fútbol Playa","beach_volleyball": "Voley Playa","tennis": "Tenis","table_tennis": "Tenis de mesa","volleyball": "Voleibol","Esports_sports":"eFútbol","eSports_sports":"eFútbol"}';
    this.SPO_totalOddsFeatureFlagEnabled = false;
    this.SPO_totalOddsMobileFeatureFlagEnabled = false;
    this.SPO_UseSportsMiscForCodereCardBets = false;
    this.SPO_UseSportsMiscForPlayerBets = true;
    this.SPO_UseSportsMiscForPlayerInfo = false;
    this.JackpotCallServiceActive = false;
    this.MSO_BannersEndpointURL = '';
    this.CAS_BonusBalanceBDRedirectMOB = '';
    this.CAS_BonusBalanceBDRedirectPC = '';
    this.Payments_deposits_URL = '';
    this.Payments_withdrawals_URL = '';
    this.Payments_Skrill_UsePaymentsv2 = false;
    this.Payments_Neteller_UsePaymentsv2 = false;
    this.Payments_Cybersource_UsePaymentsv2 = false;
    this.Payments_CreditCards_UsePaymentsv2 = false;
    this.Payments_Astropay_UsePaymentsv2 = false;
    this.Payments_PaySafe_UsePaymentsv2 = false;
    this.Payments_Bizum_UsePaymentsv2 = false;
    this.Payments_TrueLayer_UsePaymentsv2 = false;
    this.Payments_RapidTransfer_UsePaymentsv2 = false;
    this.Payments_LocalCodere_UsePaymentsv2 = false;
    this.Payments_Paypal_UsePaymentsv2 = false;
    this.Payments_BankTransfer_UsePaymentsv2 = false;
    this.Payments_HalCash_UsePaymentsv2 = false;
    this.Payments_Tarjeta_UsePaymentsv2 = false;
    this.Payments_Debin_UsePaymentsv2 = false;
    this.Payments_MercadoPago_UsePaymentsv2 = false;
    this.Payments_Pse_UsePaymentsv2 = false;
    this.Payments_PayU_UsePaymentsv2 = false;
    this.Payments_Daviplata_UsePaymentsv2 = false;
    this.Payments_AlmacenesExito_UsePaymentsv2 = false;
    this.Payments_Carulla_UsePaymentsv2 = false;
    this.Payments_GanaCerca_UsePaymentsv2 = false;
    this.Payments_PagoTodo_UsePaymentsv2 = false;
    this.Payments_GanaBank_UsePaymentsv2 = false;
    this.Payments_Nequi_UsePaymentsv2 = false;
    this.Payments_Efecty_UsePaymentsv2 = false;
    this.Payments_Dimonex_UsePaymentsv2 = false;
    this.Payments_Tpaga_UsePaymentsv2 = false;
    this.Payments_VeridasCOActive = false;
    this.Payments_PuntoRed_UsePaymentsv2 = false;
    this.MSO_newEndpointCodereCard = false;
    this.MSO_alertCookiesNoBlock = false;
    this.MSO_ModalWelcomeBonusContent = 'ALL';
    this.MSO_NewCheckCookieConsent = false;
    this.Casino_OmnichannelHeaderPC = '';
    this.Casino_OmnichannelHeaderMobile = '';
    this.Casino_OmnichannelBackgroundPC = '';
    this.Casino_OmnichannelBackgroundMobile = '';
    this.MSO_rsa_publickey = '';
    this.sportPanelContent = true;
    this.SummaryPlayerForceExtract = false;
    this.targetedOptinPopup = false;
    this.TarjetaCodereEnabled = true;
    this.TeleIngresoEnabled = true;
    this.TemporaryPromoEnabled = false;
    this.TemporaryPromoUrl = '';
    this.timeAlertAr = false;
    this.timeOutPageEnabled = false;
    this.titleCodere = true;
    this.TrueLayerWithdrawalEnabled = false;
    this.UrlNewUserExtract = '';
    this.UrlNewUserMyActivity = '';
    this.UrlNewUserMyActivityExtractable = '';
    this.UrlNewUserSummary = '';
    this.urlRegisterLite = '';
    this.UseBetConfigDataBackEndpoint = true;
    this.userPanelContent = true;
    this.UserServiceLoginActivate = false;
    this.userSErviceStandAlone = false;
    this.usertimelogout = 0;
    this.VeridasDocumentVerificationEnabled = false;
    this.VeridasXpressIdVerificationEnabled = false;
    this.VerticalRouletteGameGuid = '';
    this.VerticalRouletteGameProviderGuid = '';
    this.WelcomeMessage = '';
    this.WitdrawTaxRetentionInformationEnabled = false;
    this.worldCupCalendar = false;
    this.worldCupCenterEnabled = false;
    this.XPInboxEnabled = false;
    this.XPMessagingEnabled = false;
    this.showOriginOfFunds = false;
    this.payValidaPaymentCash = false;
    this.mfaActive = false;
    this.showLocalCodereInfoDelete = false;
    this.depositsJSONApiActive = false;
    this.withdrawalsJSONApiActive = false;
    this.netellerIframeActive = false;
  }
}
