/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../utils/Utils';
import { GlobalVarsModel } from '../../models/ModelGlobalsVar';
import { C_PlayerPropsIframeData } from '../../models/sports/C_PlayerPropsIframeData';
import { C_PlayerPropsLeagues } from '../../models/index';
import { SportService } from '../../providers/SportService';
import { NewUserService } from '../../providers/NewUserService';
import { EventsService } from '../../providers/EventsService';
import { NewBaseService } from '../../providers/newBase.service';
import { UserService } from '../../providers/UserService';

enum EventIds {
  Login = 'ds::login',
  Ready = 'ds::ready',
  BetPlaced = 'ds::bet::placed',
  WidgetHeight = 'ds::widgets::height'
}

class Event {
  id: EventIds;
  eventData: any;

  constructor(data: any[]) {
    this.id = data[1];
    this.eventData = data[3];
  }
}

@Injectable({ providedIn: 'root' })
export class PlayerPropsService {
  playerPropsLoader = false;
  playerPropsLeagues: C_PlayerPropsLeagues[] = [];
  globalVars!: GlobalVarsModel;
  //#region Inject Service
  utils = inject(Utils);
  userService = inject(UserService);
  sportService = inject(SportService);
  translate = inject(TranslateService);
  events = inject(EventsService);
  newBaseService = inject(NewBaseService);
  //#endregion
  newUserService = inject(NewUserService);

  constructor() {
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });

    this.sportService.getPlayerPropsLeagues().subscribe((leagues) => {
      this.playerPropsLeagues = leagues;
    });
  }

  firstlogin2 = (e: any): void => {
    if (this.globalVars.user.logged) {
      this.newUserService.getAuthToken().subscribe(
        (data) => {
          const ibetToken = data.token;
          const name = data.nodeId.toString();
          const resp = [
            'response',
            'ds::widgets::login',
            'authorization',
            { username: name, password: ibetToken, requester: 'quickBet' }
          ];
          e.source.postMessage(resp, e.origin);
        },
        () => {
          this.utils.showError(this.translate.instant('m_menu_serverFail'));
        }
      );
    }
  };
  firstlogin = (e: any): void => {
    if (this.globalVars.user.logged) {
      this.newUserService.getAuthToken().subscribe(
        (data) => {
          const ibetToken = data.token;
          const name = data.nodeId.toString();
          const resp = [
            'response',
            'ds::widgets::login',
            'authorization',
            { username: name, password: ibetToken, requester: 'quickBet' }
          ];
          e.source.postMessage(resp, e.origin);
        },
        () => {
          this.utils.showError(this.translate.instant('m_menu_serverFail'));
        }
      );
    }
  };

  login = (e: any): void => {
    if (this.globalVars.user.logged) {
      this.newUserService.getAuthToken().subscribe(
        (data) => {
          const ibetToken = data.token;
          const name = data.nodeId.toString();
          const resp = [
            'response',
            'ds::widgets::loginAndPlace',
            'authorization',
            { username: name, password: ibetToken, requester: 'quickBet' }
          ];
          e.source.postMessage(resp, e.origin);
        },
        () => {
          this.utils.showError(this.translate.instant('m_menu_serverFail'));
        }
      );
    } else {
      this.globalVars.eventProps = e;
      this.globalVars.rootScope.openLogin();
    }
  };

  handleEvents(e: any) {
    const event = new Event(e.data);

    const playerPropsIframeData = new C_PlayerPropsIframeData();
    playerPropsIframeData.playerPropsLoader = false;
    if (event.id) {
      // Trick to get the 'this' binded object typed

      switch (event.id) {
        case this.getEventId(EventIds.Login, playerPropsIframeData.widgetId):
          playerPropsIframeData.playerPropsService.login(e);
          break;

        case this.getEventId(EventIds.Ready, playerPropsIframeData.widgetId):
          playerPropsIframeData.playerPropsService.firstlogin(e);
          break;

        case this.getEventId(EventIds.BetPlaced, playerPropsIframeData.widgetId):
          playerPropsIframeData.playerPropsService.globalVars.rootScope.refreshBalance(true);
          break;

        case this.getEventId(EventIds.WidgetHeight, playerPropsIframeData.widgetId):
          if (playerPropsIframeData) playerPropsIframeData.iframeHeight = event.eventData;
          break;

        default:
          break;
      }
    }
  }
  getEventId(eventId: EventIds, widgetId: string) {
    return widgetId ? eventId.replace('{0}', `${widgetId}::`) : eventId.replace('{0}', '');
  }
}
