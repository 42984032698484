import { SportHandleList } from '../../../../utils/sportHandleList';

export class SidebarUtils {
  public static showDropdownIcon(sportHandle): boolean {
    let showContent = true;
    switch (sportHandle) {
      case SportHandleList.soccer:
      case SportHandleList.tennis:
      case SportHandleList.basketball:
      case SportHandleList.olimpiadas:
      case SportHandleList.greyhound_racing:
      case SportHandleList.horse_racing:
        showContent = false;
        break;
    }
    return showContent;
  }
}
