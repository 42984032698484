import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormatOdd } from '../../../pipes/formatOdd.pipe';

@Component({
  selector: 'sb-betbuilder',
  templateUrl: './sb-betbuilder.html',
  styleUrls: ['./sb-betbuilder.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, FormatOdd]
})
export class BetbuilderComponent {
  @Input() cuotaText: string = '';
  @Input() MASelections: any[];
  @Input() errorMessage: string = '';
  @Input() messageText: string = 'Añadir al menos X selecciones.';
  @Output() placeBetbuilderBet: EventEmitter<any> = new EventEmitter();
  @Output() removeBetbuilderBet: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  placeBet() {
    this.placeBetbuilderBet.emit();
  }

  removeSelection() {
    this.removeBetbuilderBet.emit();
  }
}
