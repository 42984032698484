<ng-container *ngIf="!isMobile; else mobileView">
  <ion-grid class="tablet-common-grid" *ngIf="!singleGame && !omnichannel && lobbyName !== 'jackpot'">
    <ion-row no-padding no-margin>
      <ion-col [class.leftMenuOpen]="globalVars?.leftMenuOpen" no-padding>
        <ion-content class="has-titleBar background-color-dark" scrollEvents="true" (ionScroll)="scrolling($event)">
          <codere-slider
            *ngIf="!!lobbyFacadeService?.slotsBanners?.length && sliderVisible && !globalVars?.FEATURES.NewCarouselActivated"
            [slides]="lobbyFacadeService?.slotsBanners"
            [controls]="isDesktop"
            (clickedSlide)="clickBanner($event)"
            [hidden]="!sliderVisible"
          >
          </codere-slider>
          <codere-slider-csv
            *ngIf="!!lobbyFacadeService?.slotsBanners?.length && showCarousel && sliderVisible && globalVars?.FEATURES.NewCarouselActivated"
            [banners]="lobbyFacadeService?.slotsBanners"
            [hidden]="!sliderVisible"
          >
          </codere-slider-csv>

          <div id="lobbyRef"></div>

          <div class="sb-navbar-container">
            <sb-navbar class="sb-navbar" theme="lobby" [bar]="true" [active]="activeFilterIndex">
              <lb-search
                navbar-left
                (searchTerm)="selectSearch($event)"
                [active]="activeFilterIndex"
                class="lb-search"
              ></lb-search>
              <sb-navbar-item
                theme="lobby"
                *ngFor="let filter of filters; let i = index"
                [item]="filter"
                tappable
                (click)="setFilter(filter.label)"
                [ngClass]="{ 'borderNavbarItem': i === 0, 'addMarginCalendar': filter?.label == globalVars?.FEATURES.casinoCalendarName }"
              >
              </sb-navbar-item>
            </sb-navbar>
            <ng-container *ngIf="moreFiltersApplied$ | async as moreFilters">
              <div *ngIf="moreFilters.length" class="moreFiltersApplied">
                <div class="delteAll moreFilter">
                  <span class="moreFilterText">Borrar todo</span>
                  <i class="codere-icon icon-close" (click)="unapplyMoreFilters()"></i>
                </div>
                <div *ngFor="let moreFilter of moreFilters" class="moreFilter">
                  <span class="moreFilterText"
                    >{{moreFilter.filterGroupTitle | translate}} {{moreFilter.text | translate}}</span
                  >
                  <i class="codere-icon icon-close" (click)="unapplyMoreFilter(moreFilter)"></i>
                </div>
              </div>
            </ng-container>
          </div>
          <div>
            <div class="balance" [hidden]="!globalVars?.user?.playingSlots">
              {{'balanceSession' | translate}}
              <!--Balance sesión-->: <b>{{slotsService?.scores?.balance | parseMoney:[1]}}</b> - {{'v2_apuestas' |
              translate}}
              <!--#Apuestas-->: <b>{{slotsService?.scores?.bets | parseMoney:[1]}}</b> - {{'prizes' | translate}}
              <!--Premios-->:
              <b>{{slotsService?.scores?.wins | parseMoney:[1]}}</b>
            </div>

            <div class="lobby">
              <ng-container *ngIf="lobby$ | async as lobby">
                <div *ngIf="lobby?.grid !== 'detail'">
                  <ion-list
                    #regularview
                    class="filter-container"
                    no-margin
                    no-padding
                    *ngFor="let category of lobby;let i = index"
                    [class.first]="i === 0"
                  >
                    <ng-container
                      *ngIf="category.mainCategory && category.Games.length >= lobbyConstants.minGames && !category.hide && category?.typeCategory !== lobbyConstants.banner"
                    >
                      <div class="lobby-main is-featured" padding-top>
                        <lb-header
                          [title]="category?.Name"
                          [cta]="'seeall'"
                          [category]="category"
                          (toggle)="setCategory($event)"
                        >
                        </lb-header>
                        <div
                          class="lobby-main--item"
                          [class.is-featured]="i === 0"
                          [class.is-regular]="i >= 1"
                          *ngFor="let game of category?.Games.slice(0, 11); let i = index"
                        >
                          <casino-card-new
                            tappable
                            [isJpLobby]="!!category?.JackpotId ? true : false"
                            class="smallsquare"
                            [outstanding]="true"
                            (turnPlay)="playGame($event)"
                            [game]="game"
                            [showGameTicker]="false"
                          >
                          </casino-card-new>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container
                      *ngIf="!category.mainCategory && !category.hide && category?.typeCategory !== lobbyConstants.banner || category.mainCategory && category.Games.length < lobbyConstants.minGames && !category.hide && category?.typeCategory !== lobbyConstants.banner"
                    >
                      <div class="lobby-main">
                        <div class="is-narrow-content">
                          <lb-header
                            [title]="category?.Name"
                            [cta]="'seeall'"
                            [category]="category"
                            (toggle)="setCategory($event)"
                          >
                          </lb-header>
                        </div>
                        <div [ngClass]="{'is-narrow-content' : category?.BackgroundImageUrl === ''}">
                          <casino-category-slider-new [id]="i" [category]="category" (turnPlay)="playGame($event)">
                          </casino-category-slider-new>
                        </div>
                      </div>
                    </ng-container>

                    <div class="is-narrow-content" *ngIf="category?.typeCategory === lobbyConstants.banner">
                      <casino-banner-card [game]="category.Games[0]" (turnPlay)="playGame($event)"></casino-banner-card>
                    </div>
                  </ion-list>
                </div>

                <div *ngIf="lobby.grid === 'detail'" class="is-narrow-content">
                  <ion-list class="all-slots" no-margin padding-top>
                    <lb-header [title]="lobby?.Name" [cta]="'hide'" [category]="lobby" (toggle)="setCategory($event)">
                    </lb-header>
                    <div class="lobby-list has-scroll-vertical" #detailDesktop>
                      <ng-container *ngFor="let game of lobby?.Games;let i =index">
                        <casino-card-new
                          *ngIf="!game?.live"
                          tappable
                          class="swiper-slide"
                          [game]="game"
                          [grid]="true"
                          [showGameTicker]="false"
                          (turnPlay)="playGame($event)"
                          [isJpLobby]="!!lobby?.JackpotId ? true : false"
                          [setStreaming]="game.streamUrl ? true : false"
                        >
                        </casino-card-new>
                        <casino-live-card-new
                          *ngIf="game?.live"
                          tappable
                          class="swiper-slide"
                          [grid]="true"
                          [game]="game"
                          (turnPlay)="playGame($event)"
                          [setStreaming]="game.streamUrl ? true : false"
                        >
                        </casino-live-card-new>
                      </ng-container>
                      <empty-state
                        [message]="'No hay juegos disponibles'"
                        theme="lobby"
                        [class.hidden]="lobby?.Games?.length > 0"
                      >
                      </empty-state>
                    </div>
                  </ion-list>
                </div>
              </ng-container>
            </div>
          </div>
          <codere-footer></codere-footer>
        </ion-content>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ng-container #jackpotview *ngIf="!singleGame && !omnichannel && lobbyName === 'jackpot'">
    <ion-content class="background-color-dark" #content scrollEvents="true" (ionScroll)="scrolling($event)">
      <lb-jackpot-header [headerImage]="'./assets/global/img/jackpot/jackpot-header-animation.gif'"></lb-jackpot-header>
      <div class="is-narrow-content">
        <div class="balance" [hidden]="!globalVars?.user?.playingSlots">
          {{'balanceSession' | translate}}
          <!--Balance sesión-->: <b>{{slotsService?.scores?.balance | parseMoney:[1]}}</b> - {{'v2_apuestas' |
          translate}}
          <!--#Apuestas-->: <b>{{slotsService?.scores?.bets | parseMoney:[1]}}</b> - {{'prizes' | translate}}
          <!--Premios-->:
          <b>{{slotsService?.scores?.wins | parseMoney:[1]}}</b>
        </div>
      </div>
      <ng-container *ngIf="lobby$ | async as jackpots else empty">
        <div *ngFor="let jackpot of jackpots; let i = index">
          <lb-jackpot
            *ngIf="jackpot?.jackpotInfo?.games.length > 0 && jackpot?.uiInfo"
            [jackpot]="jackpot"
            (join)="openLogin($event)"
            (info)="openInfo($event)"
          >
            <lb-slider
              class="slider-container"
              [id]="i"
              [jackpot]="jackpot"
              [controls]="false"
              (turnPlay)="playGame($event)"
            >
            </lb-slider>
          </lb-jackpot>
        </div>
      </ng-container>

      <ng-template #empty></ng-template>
      <codere-footer></codere-footer>
    </ion-content>
  </ng-container>

  <ng-container #SingleGame *ngIf="singleGame">
    <ion-content class="background-color-dark lobbySingle" #content>
      <ng-container *ngIf="lobby$ | async as oneGameinfo else empty">
        <lb-oneGameLobby *ngIf="singleGame" [oneGameinfo]="oneGameinfo"></lb-oneGameLobby>
      </ng-container>
      <ng-template #empty></ng-template>
      <codere-footer></codere-footer>
    </ion-content>
  </ng-container>

  <ng-container #Omnichannel *ngIf="omnichannel">
    <ion-content class="background-color-dark" #content scrollEvents="true" (ionScroll)="scrolling($event)">
      <ng-container *ngIf="lobby$ | async as omnichannellobby else empty">
        <lb-omnichanel [omnichannellobby]="omnichannellobby"></lb-omnichanel>
      </ng-container>
      <ng-template #empty></ng-template>
      <codere-footer></codere-footer>
    </ion-content>
  </ng-container>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <codere-navbar></codere-navbar>
  </ion-header>
  <ng-container *ngIf="!singleGame && !omnichannel && lobbyName !== 'jackpot'">
    <ion-content class="ion-padding background-color-dark" scrollEvents="true" (ionScroll)="scrolling($event)">
      <div id="lobbyRef"></div>
      <div *ngIf="globalVars?.user.logged && !!lobbyFacadeService?.slotsBanners?.length" class="cont-banner">
        <ion-button class="banner mvl">
          <span>
            <ion-icon
              class="arrow codere-icon codere-icon icon-arrow-top"
              [ngClass]="showBanner ? 'toggle-down' : 'toggle-up'"
              (click)="hideBanner()"
            ></ion-icon>
          </span>
        </ion-button>
      </div>
      <div [ngClass]="showBanner === false ? 'hideB' : 'showB'">
        <codere-slider
          *ngIf="!!lobbyFacadeService?.slotsBanners?.length && sliderVisible && !globalVars?.FEATURES.NewCarouselActivated"
          [slides]="lobbyFacadeService?.slotsBanners"
          [controls]="isDesktop"
          (clickedSlide)="clickBanner($event)"
        >
        </codere-slider>
        <codere-slider-csv
          *ngIf="!!lobbyFacadeService?.slotsBanners?.length && showCarousel && sliderVisible && globalVars?.FEATURES.NewCarouselActivated"
          [banners]="lobbyFacadeService?.slotsBanners"
        >
        </codere-slider-csv>
      </div>
      <div id="lobbyRef"></div>
      <div class="sb-navbar--container">
        <sb-navbar theme="lobby" [bar]="true" [active]="activeFilterIndex">
          <lb-search navbar-left (searchTerm)="selectSearch($event)" [active]="activeFilterIndex"> </lb-search>
          <sb-navbar-item
            theme="lobby"
            *ngFor="let filter of filters; let isLast = last"
            [item]="filter"
            tappable
            (click)="setFilter(filter?.label)"
            [ngClass]="{ 'addMarginCalendar': filter?.label === globalVars?.FEATURES?.casinoCalendarName, 'lastElement': isLast }"
          >
          </sb-navbar-item>
        </sb-navbar>
        <ng-container *ngIf="moreFiltersApplied$ | async as moreFilters">
          <div *ngIf="moreFilters.length" class="moreFiltersApplied">
            <div class="delteAll moreFilter">
              <span class="moreFilterText">Borrar todo</span>
              <i class="codere-icon icon-close" (click)="unapplyMoreFilters()"></i>
            </div>
            <div *ngFor="let moreFilter of moreFilters" class="moreFilter">
              <span class="moreFilterText"
                >{{moreFilter.filterGroupTitle | translate}} {{moreFilter.text | translate}}</span
              >
              <i class="codere-icon icon-close" (click)="unapplyMoreFilter(moreFilter)"></i>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="balance" [hidden]="!globalVars?.user?.playingSlots">
        {{'balanceSession' | translate}}
        <!--Balance sesión-->: <b>{{slotsService?.scores?.balance | parseMoney:[1]}}</b> - {{'v2_apuestas' | translate}}
        <!--#Apuestas-->: <b>{{slotsService?.scores?.bets | parseMoney:[1]}}</b> - {{'prizes' | translate}}
        <!--Premios-->:
        <b>{{slotsService?.scores?.wins | parseMoney:[1]}}</b>
      </div>
      <div class="lobby" padding-horizontal>
        <ng-container *ngIf="lobby$ | async as lobby">
          <div *ngIf="lobby?.grid !== 'detail'">
            <!-- prerendred 4 lines-->
            <ion-list
              class="filter-container slider-container"
              no-margin
              no-padding
              *ngFor="let category of lobby.slice(0, 4); let i = index"
            >
              <div
                *ngIf="!category.hide && category?.typeCategory !== lobbyConstants.banner"
                [ngClass]="{'lobby-category' : category?.BackgroundImageUrl === ''}"
              >
                <lb-header
                  [title]="category?.Name"
                  [cta]="'seeall'"
                  [category]="category"
                  (toggle)="setCategory($event)"
                ></lb-header>
                <casino-category-slider-new [controls]="false" [category]="category" (turnPlay)="playGame($event)">
                </casino-category-slider-new>
              </div>
              <ng-container *ngIf="!category.hide && category?.typeCategory === lobbyConstants.banner">
                <casino-banner-card [game]="category.Games[0]" (turnPlay)="playGame($event)"></casino-banner-card>
              </ng-container>
            </ion-list>

            <!-- rendred when scrooll-->
            <ng-container *ngIf="isScrolled">
              <ion-list
                class="filter-container slider-container"
                no-margin
                no-padding
                *ngFor="let category of lobby.slice(4); let i = index"
              >
                <div
                  *ngIf="!category.hide && category?.typeCategory !== lobbyConstants.banner"
                  [ngClass]="{'lobby-category' : category?.BackgroundImageUrl === ''}"
                >
                  <lb-header
                    [title]="category?.Name"
                    [cta]="'seeall'"
                    [category]="category"
                    (toggle)="setCategory($event)"
                  ></lb-header>
                  <casino-category-slider-new [controls]="false" [category]="category" (turnPlay)="playGame($event)">
                  </casino-category-slider-new>
                </div>
                <ng-container *ngIf="!category.hide && category?.typeCategory === lobbyConstants.banner">
                  <casino-banner-card [game]="category.Games[0]" (turnPlay)="playGame($event)"></casino-banner-card>
                </ng-container>
              </ion-list>
            </ng-container>
          </div>

          <div *ngIf="lobby.grid === 'detail'" class="is-narrow-content">
            <ion-list class="filter-container" no-margin no-paddin>
              <lb-header
                [title]="lobby?.Name | translate"
                [cta]="'hide'"
                [category]="lobby"
                [mobileDetail]="true"
                (toggle)="setCategory($event)"
              >
              </lb-header>
              <div class="lobby-list has-scroll-vertical" #detailMobile>
                <ng-container *ngFor="let game of lobby?.Games;let i =index">
                  <casino-card-new
                    *ngIf="!game?.live"
                    tappable
                    [grid]="true"
                    [game]="game"
                    [isJpLobby]="!!lobby?.JackpotId ? true : false"
                    (turnPlay)="playGame($event)"
                    [setStreaming]="game.streamUrl ? true : false"
                  >
                  </casino-card-new>
                  <casino-live-card-new
                    *ngIf="game?.live"
                    tappable
                    class="swiper-slide"
                    [grid]="true"
                    [game]="game"
                    (turnPlay)="playGame($event)"
                    [setStreaming]="game.streamUrl ? true : false"
                  >
                  </casino-live-card-new>
                </ng-container>
                <empty-state
                  [message]="'No hay juegos disponibles'"
                  theme="lobby"
                  [class.hidden]="lobby?.Games?.length > 0"
                >
                </empty-state>
              </div>
            </ion-list>
          </div>
        </ng-container>
      </div>

      <codere-footer></codere-footer>
    </ion-content>
  </ng-container>

  <ng-container *ngIf="!singleGame &&  !omnichannel && lobbyName === 'jackpot'">
    <ion-content class="background-color-dark" scrollEvents="true" (ionScroll)="scrolling($event)">
      <lb-jackpot-header
        [headerImage]="'./assets/global/img/jackpot/jackpot-header-animation-desktop.gif'"
      ></lb-jackpot-header>

      <ng-container *ngIf="lobby$ | async as jackpots else empty">
        <div *ngFor="let jackpot of jackpots; let i = index">
          <lb-jackpot
            *ngIf="jackpot?.jackpotInfo?.games.length > 0 && jackpot?.uiInfo"
            [jackpot]="jackpot"
            (join)="openLogin($event)"
            (info)="openInfo($event)"
          >
            <lb-slider
              class="slider-container"
              [id]="i"
              [jackpot]="jackpot"
              [controls]="false"
              (turnPlay)="playGame($event)"
            >
            </lb-slider>
          </lb-jackpot>
        </div>
      </ng-container>

      <ng-template #empty></ng-template>
      <codere-footer></codere-footer>
    </ion-content>
  </ng-container>

  <ng-container *ngIf="singleGame">
    <ion-content class="background-color-dark lobbyOneGame" scrollEvents="true" (ionScroll)="scrolling($event)">
      <ng-container *ngIf="lobby$ | async as oneGameinfo else empty">
        <lb-oneGameLobby *ngIf="singleGame" [oneGameinfo]="oneGameinfo"></lb-oneGameLobby>
      </ng-container>
      <ng-template #empty></ng-template>
      <codere-footer></codere-footer>
    </ion-content>
  </ng-container>
  <ng-container #Omnichannel *ngIf="omnichannel">
    <ion-content class="background-color-dark" #content scrollEvents="true" (ionScroll)="scrolling($event)">
      <ng-container *ngIf="lobby$ | async as omnichannellobby else empty">
        <lb-omnichanel [omnichannellobby]="omnichannellobby"></lb-omnichanel>
      </ng-container>
      <ng-template #empty></ng-template>
      <codere-footer></codere-footer>
    </ion-content>
  </ng-container>
</ng-template>
