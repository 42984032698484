export class SportHandleList {
  static american_football = 'american_football';
  static american_footballLiteral = 'Fútbol Americano';
  static badminton = 'badminton';
  static badmintonLiteral = 'Bádminton';
  static baseball = 'baseball';
  static baseballLiteral = 'Béisbol';
  static basketball = 'basketball';
  static basketballLiteral = 'Baloncesto';
  static beach_soccer = 'beach_soccer';
  static beach_soccerLiteral = 'Fútbol Playa';
  static beach_volleyball = 'beach_volleyball';
  static beach_volleyballLiteral = 'Voley Playa';
  static billard = 'billard';
  static billardLiteral = 'Billar';
  static darts = 'darts';
  static dartsLiteral = 'Dardos';
  static esports = 'esports';
  static eSports_sports = 'eSports_sports';
  static Esports_sports = 'Esports_sports';
  static eSports_sportsLiteral = 'eFútbol';
  static Esports_sportsLiteral = 'eFútbol';
  static esportsLiteral = 'eSports';
  static field_hockey = 'field_hockey';
  static Field_hockey = 'Field_hockey';
  static field_hockeyLiteral = 'Hockey hierba';
  static Field_hockeyLiteral = 'Hockey hierba';
  static golf = 'golf';
  static golfLiteral = 'Golf';
  static greyhound_racing = 'greyhound_racing';
  static greyhound_racingLiteral = 'Carreras de galgos';
  static handball = 'handball';
  static handballLiteral = 'Balonmano';
  static horse_racing = 'horse_racing';
  static horse_racingLiteral = 'Carreras de caballos';
  static ice_hockey = 'ice_hockey';
  static ice_hockeyLiteral = 'Hockey sobre hielo';
  static motor = 'motor';
  static motorsport = 'motorsport';
  static motorLiteral = 'Motor';
  static olimpiadas = 'olimpiadas';
  static olimpiadasLiteral = 'Olimpiadas';
  static rugby = 'rugby';
  static rugbyLiteral = 'Rugby';
  static snooker = 'snooker';
  static snookerLiteral = 'Snooker';
  static soccer = 'soccer';
  static soccer_indoor = 'soccer_indoor';
  static soccer_indoorLiteral = 'Futbol Sala';
  static soccerLiteral = 'Fútbol';
  static table_tennis = 'table_tennis';
  static table_tennisLiteral = 'Tenis de mesa';
  static tennis = 'tennis';
  static tennisLiteral = 'Tenis';
  static volleyball = 'volleyball';
  static volleyballLiteral = 'Voleibol';
}
