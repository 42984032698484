import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';
interface iAlert {
  title?: string;
  message?: string;
  cssClass?: string | string[];
  inputs?: any;
  buttons?: any;
}

@Component({
  selector: 'codere-ionic-alert-modal',
  standalone: true,
  template: ''
})
export class AlertModalComponent {
  constructor(private alertController: AlertController) {}
  // eslint-disable-next-line no-shadow
  async innerHTML(iAlert: iAlert) {
    const modal = await this.alertController.create({
      message: iAlert.message,
      inputs: iAlert.inputs,
      buttons: iAlert.buttons,
      cssClass: iAlert.cssClass
    });
    if (iAlert.title) {
      const doc = document.getElementsByClassName('customModal');
      if (doc[0]?.childNodes[2]) {
        const value = doc[0].childNodes[2].firstChild as Element;
        value.innerHTML = '<h6 class="sb-alert--title color-secondary">' + iAlert.title + '</h6>';
      }
    }
    await modal.present();
  }
}
