<section
  *ngIf="item"
  [ngClass]="{
  'lobby': theme === 'lobby',
  'sportbook': theme === 'sportbook',
  }"
>
  <div
    class="content"
    [class.color-light]="!item?.selected"
    [class.color-primary]="item?.selected"
    tappable
    (click)="select?.emit(item?.navigate)"
  >
    <div class="image">
      <img class="img" *ngIf="imageSrc" [src]="imageSrc" [alt]="item?.label" />
      <ion-icon *ngIf="icon" class="icon codere-icon" name="{{name}}" [ngClass]="icon"></ion-icon>
    </div>
    <p class="subtitle">{{ item?.label }}</p>
  </div>
</section>
