/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { C_CategoryInfo } from '@models/C_CategoryInfo';
import { C_GameGroupByName } from '@models/C_GameGroupByName';
import { C_Result } from '@models/C_Result';
import { C_Game } from '@models/C_Game';
import { C_Event } from '@models/C_Event';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { Constants } from '@constants';

@Injectable({ providedIn: 'root' })
export class SportBookService {
  public listOfGameTypesInCombinedMarket = [
    4, 7, 39, 148, 155, 175, 988, 990, 991, 993, 1064, 1065,
    // 1300, // This is a three axis market manually done.
    1330, 1391, 1392, 1393, 1394, 1606, 1647, 1648, 1649, 1650, 1667, 1668, 1669, 1804, 1805, 1806, 1807, 1808, 1809,
    1853
  ];
  //! WHY?
  // sixPackMarketKeys: C_GameTypes = new C_GameTypes();
  // listOfSixPackHandicaps = [259, 874];
  markets;
  regexTitles = /[a-zA-Z]+/;
  regexValuesLongResult = /[0-9-+.]+\d/g;
  regexValues = /[0-9-.+-]+/;
  regexHandicap = /[+-.0-9]+/g;
  globalVars!: GlobalVarsModel;
  constructor(private newBaseService: NewBaseService) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  public getMatrixBySportInSixPakMarket(sportHandle: string, CountryCode: string, category?): [any[], string] {
    const gameTypes = this.globalVars.gameTypes.getOne(sportHandle, CountryCode, false);
    return [gameTypes, sportHandle];
  }

  public getCombinedMarkets(markets, actualMarket, skipCall = false) {
    this.markets = markets;
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!this.globalVars.FEATURES.ListOfGameTypesInCombinedMarket) {
      this.listOfGameTypesInCombinedMarket = JSON.parse(this.globalVars.FEATURES.ListOfGameTypesInCombinedMarket);
    }
    markets.forEach((market) => {
      if (!market.GroupResults) {
        return;
      }
      const idCombinedMarket = this.listOfGameTypesInCombinedMarket.find((idMarket) => idMarket === market.GameType);
      this.getGameNameIfNoExist(market, actualMarket.teamAway, actualMarket.teamHome);
      if (idCombinedMarket) {
        market.isCombined = true;
        market.displayMode = 'single';
        market.headerClass = 'sb-grid-header--single';
        market.DisplayTypeName = '3buttonlist';
        market.Locked = actualMarket.Locked ? actualMarket.Locked : market.Locked;

        this.getCombinedMarketMatrix(idCombinedMarket, market, actualMarket, skipCall);
      } else {
        market.isCombined = false;
        market.displayMode = 'expanded';
      }
    });
  }

  private getGameNameIfNoExist(market: C_Game, teamAway: string, teamHome: string): void {
    market.teamAway = market.teamAway ? market.teamAway : teamAway;
    market.teamHome = market.teamHome ? market.teamHome : teamHome;
  }

  public getAllSpovesInMarket(groupedMarkets: any[], market: any) {
    if (!groupedMarkets[groupedMarkets.length - 1]) {
      return;
    }
    groupedMarkets[groupedMarkets.length - 1].AllSpov.push(market.Spov);
  }

  public getSixPackMarket(event: C_Event, gameTypes): C_GameGroupByName {
    if (!gameTypes) {
      event.isSixPack = false;
      return;
    }
    if (gameTypes.length === 0) {
      event.isSixPack = false;
      return;
    }
    if (event.isLive) {
      event.isSixPack = false;
      return;
    }
    const teamHome = event.teamHome;
    const teamAway = event.teamAway;
    const pitcherHome = event.pitcherHome;
    const pitcherAway = event.pitcherAway;
    let winners = event.Games.filter((m) => m.GameType === gameTypes[0]);

    const resOverUnder = event.Games.filter((m) => m.GameType === gameTypes[1]);
    let mostBalancedOverUnder = this.getMostBalancedMarkets(resOverUnder);

    const resBalancedTotals = event.Games.filter((m) => m.GameType === gameTypes[2]);
    let mostBalancedTotals = this.getMostBalancedMarkets(resBalancedTotals);
    [winners, mostBalancedOverUnder, mostBalancedTotals] = this.checkResultsSixPackMarket(
      winners,
      mostBalancedOverUnder,
      mostBalancedTotals
    );
    const homeResults = [
      this.getGameTypeResultInSixPack(winners[0].Results[0], winners[0].GameType),
      this.getGameTypeResultInSixPack(mostBalancedOverUnder.Results[0], mostBalancedOverUnder.GameType),
      this.getGameTypeResultInSixPack(mostBalancedTotals.Results[0], mostBalancedTotals.GameType)
    ];
    const awayResults = [
      this.getGameTypeResultInSixPack(winners[0].Results[1], winners[0].GameType),
      this.getGameTypeResultInSixPack(mostBalancedOverUnder.Results[1], mostBalancedOverUnder.GameType),
      this.getGameTypeResultInSixPack(mostBalancedTotals.Results[1], mostBalancedTotals.GameType)
    ];

    return this.getMarketSixPackData(homeResults, awayResults, teamHome, teamAway, pitcherHome, pitcherAway, event);
  }
  private getGameTypeResultInSixPack(result: any, gameType: any) {
    result.GameType = gameType;
    return result;
  }
  public getSixPackMarketInCalendar(market: C_Event, listOfMarketsComposeSixPack): C_GameGroupByName {
    let sixPackMarket = null;
    if (listOfMarketsComposeSixPack.length === 0) {
      return;
    }
    const teamHome = market.teamHome;
    const teamAway = market.teamAway;
    const pitcherHome = market.pitcherHome;
    const pitcherAway = market.pitcherAway;
    let winners = market.Games.filter((m) => m.GameType === listOfMarketsComposeSixPack[0]);

    const resOverUnder = market.Games.filter((m) => m.GameType === listOfMarketsComposeSixPack[1]);
    let mostBalancedOverUnder = this.getMostBalancedMarkets(resOverUnder);

    const resBalancedTotals = market.Games.filter((m) => m.GameType === listOfMarketsComposeSixPack[2]);
    let mostBalancedTotals = this.getMostBalancedMarkets(resBalancedTotals);
    [winners, mostBalancedOverUnder, mostBalancedTotals] = this.checkResultsSixPackMarket(
      winners,
      mostBalancedOverUnder,
      mostBalancedTotals
    );
    const homeResults = [winners[0].Results[0], mostBalancedOverUnder.Results[0], mostBalancedTotals.Results[0]];
    const awayResults = [winners[0].Results[1], mostBalancedOverUnder.Results[1], mostBalancedTotals.Results[1]];

    sixPackMarket = this.getMarketSixPackData(
      homeResults,
      awayResults,
      teamHome,
      teamAway,
      pitcherHome,
      pitcherAway,
      market
    );

    return sixPackMarket;
  }
  private checkResultsSixPackMarket(winners: any[], mostBalancedOverUnder: any, mostBalancedTotals: any) {
    if (!winners || winners.length === 0) {
      winners = [
        {
          Results: [this.declareNewResultMissing(), this.declareNewResultMissing()]
        }
      ];
    }
    winners[0].Results[0] = winners[0].Results[0] ? winners[0].Results[0] : this.declareNewResultMissing();
    winners[0].Results[1] = winners[0].Results[1] ? winners[0].Results[1] : this.declareNewResultMissing();

    if (!mostBalancedOverUnder || mostBalancedOverUnder.Results.length === 0) {
      mostBalancedOverUnder = {
        Results: [this.declareNewResultMissing(), this.declareNewResultMissing()]
      };
    }
    mostBalancedOverUnder.Results[0] = mostBalancedOverUnder.Results[0]
      ? mostBalancedOverUnder.Results[0]
      : this.declareNewResultMissing();
    mostBalancedOverUnder.Results[1] = mostBalancedOverUnder.Results[1]
      ? mostBalancedOverUnder.Results[1]
      : this.declareNewResultMissing();

    if (!mostBalancedTotals || mostBalancedTotals.Results.length === 0) {
      mostBalancedTotals = {
        Results: [this.declareNewResultMissing(), this.declareNewResultMissing()]
      };
    }
    mostBalancedTotals.Results[0] = mostBalancedTotals.Results[0]
      ? mostBalancedTotals.Results[0]
      : this.declareNewResultMissing();
    mostBalancedTotals.Results[1] = mostBalancedTotals.Results[1]
      ? mostBalancedTotals.Results[1]
      : this.declareNewResultMissing();
    return [winners, mostBalancedOverUnder, mostBalancedTotals];
  }

  public getMarketGoalScorer(
    groupedMarkets: C_GameGroupByName[],
    actualMarket: C_GameGroupByName,
    skipCall = false
  ): C_GameGroupByName[] {
    if (actualMarket.SportHandle !== 'soccer' || skipCall) {
      return groupedMarkets;
    }
    const listOfIdsGoalMarkets = [74, 75, 76];
    const listOfMarketsAvailable = [];
    let listOfScorerMarkets: any = [];
    listOfIdsGoalMarkets.forEach((idMarket) => {
      const scorerMarket = groupedMarkets.find((market) => market.GameType === idMarket);
      if (scorerMarket) {
        //this.getGoalScorersOrder(idMarket, scorerMarket);
        scorerMarket.position = idMarket;
        listOfScorerMarkets.push(scorerMarket);
        listOfMarketsAvailable.push(idMarket);
      } else {
        const aux = C_GameGroupByName.parse({ GameType: idMarket });
        aux.position = idMarket;
        listOfScorerMarkets.push(aux);
      }
    });

    if (!listOfMarketsAvailable.length) {
      return groupedMarkets;
    }

    if (listOfMarketsAvailable.length < 3) {
      listOfScorerMarkets = this.getMissingScoreMarkets(listOfScorerMarkets);
    }

    groupedMarkets = this.deleteMarketsUseless(groupedMarkets, listOfIdsGoalMarkets);

    const newMarketScore: C_GameGroupByName = this.getGoalScoreMarket(listOfScorerMarkets, actualMarket);
    this.getNewScoreMarket(groupedMarkets, newMarketScore);

    return groupedMarkets;
  }
  /* creamos una lista de goleadores y limpiamos la lista de valores repetidos,
   *  asignamos los resultados a la lista de goleadores ,
   *  construimos el mercado en su totalidad y lo devolvemos*/
  private getGoalScoreMarket(listOfScorerMarkets: any[], actualMarket: any): C_GameGroupByName {
    const listOfPlayerNames = this.getListOfPlayers(listOfScorerMarkets);
    const ListOfPlayersWithResults = this.getGoalScorersData(listOfPlayerNames, listOfScorerMarkets);
    const { categoryInfoScoreMarket, groupResults } = this.getCategoryAndGroupResults(listOfScorerMarkets);
    return this.prepareGoalScoreMarket(ListOfPlayersWithResults, actualMarket, categoryInfoScoreMarket, groupResults);
  }
  /*recorremos la lista de jugadores y la cruzamos con la lista de mercados y extraemos los resultados cuando coincidan con el nombre del jugador,
   * construimos el objeto que vamos autilizar en la vista y lo añadimos a la lista que vamos a devolver con los datos del mercado,
   * devolvemos el la lista con los jugadores y sus resultados asociados */
  /* si el resultado no ha sido encontrado declaramos uno vacio y le asignamos la posicion en funcion del gameType*/
  private getGoalScorersData(listOfPlayerNames: any[], listOfScorerMarkets: any[]) {
    const listOfPlayersWithResults: any = [];
    listOfPlayerNames.forEach((player: string) => {
      const resultsListByPlayerName: any = [];
      listOfScorerMarkets.forEach((market) => {
        const gameType = market.GameType;
        const resultByPlayerName = market.GroupResults.filter((result: any) => result.Name === player);
        if (!resultByPlayerName.length) {
          resultsListByPlayerName.push(this.getMissingResultInGoalScorers(gameType));
          return;
        }
        resultsListByPlayerName.push(resultByPlayerName[0]);
      });

      const newPlayerWithResultsList = {
        spov: player,
        results: resultsListByPlayerName
      };
      newPlayerWithResultsList.results = this.getOrderResultsInGoalScorers(newPlayerWithResultsList);
      listOfPlayersWithResults.push(newPlayerWithResultsList);
    });

    return listOfPlayersWithResults;
  }
  /* buscamos todos los nombres de los jugadores en los tres mercados,
   * quitamos lo valores repetidos y devolvemos la lista */
  private getListOfPlayers(listOfScorerMarkets: any[]) {
    const ListOfPlayersInMarkets: any = [];
    listOfScorerMarkets.forEach((game) => {
      game.GroupResults.forEach((result: any) => {
        ListOfPlayersInMarkets.push(result.Name);
      });
    });

    const listOfPlayerNames = Array.from(new Set(ListOfPlayersInMarkets));
    return listOfPlayerNames;
  }

  /* devolvemos el resultado ausente y le asignamos una posicion en funcion del id */
  private getMissingResultInGoalScorers(gameType: any): any {
    return { ...this.declareNewResultMissing(), ...{ position: this.getResultPositionInGoalScorer(gameType) } };
  }

  private getCategoryAndGroupResults(listOfScorerMarkets: any[]) {
    const groupResults = [
      ...listOfScorerMarkets[0].GroupResults,
      ...listOfScorerMarkets[1].GroupResults,
      ...listOfScorerMarkets[2].GroupResults
    ];
    const categoryInfoScoreMarket = {
      CategoryInfos: listOfScorerMarkets[0].CategoryInfos,
      CategoryInfo: listOfScorerMarkets[0].CategoryInfo
    };
    return { categoryInfoScoreMarket, groupResults };
  }
  /*asignamos una posicion al mercado ausente*/
  private getResultPositionInGoalScorer(gameType): number {
    if (gameType === 74) {
      return 0;
      // eslint-disable-next-line no-dupe-else-if
    } else if (gameType === 74) {
      return 1;
    }
    return 2;
  }

  private setPositionInResult(market: any, position: number) {
    market.GroupResults.forEach((el: any) => {
      el.position = position;
    });
  }

  private deleteMarketsUseless(
    groupedMarkets: C_GameGroupByName[],
    listOfIdsGoalMarkets: number[]
  ): C_GameGroupByName[] {
    const listOfNoScorerMarkets: C_GameGroupByName[] = [];
    groupedMarkets.forEach((market) => {
      if (
        market.GameType !== listOfIdsGoalMarkets[0] &&
        market.GameType !== listOfIdsGoalMarkets[1] &&
        market.GameType !== listOfIdsGoalMarkets[2]
      ) {
        listOfNoScorerMarkets.push(market);
      }
    });
    return listOfNoScorerMarkets;
  }

  private getNewScoreMarket(groupedMarkets: C_GameGroupByName[], newMarketScore: C_GameGroupByName) {
    let positionHatTrickMarket;
    groupedMarkets.forEach((market, index) => {
      if (market.GameType === 149) {
        positionHatTrickMarket = index;
      }
    });
    if (positionHatTrickMarket) {
      groupedMarkets.splice(positionHatTrickMarket, 0, newMarketScore);
    } else {
      groupedMarkets.push(newMarketScore);
    }
  }

  private getMissingScoreMarkets(listOfScorerMarkets: C_GameGroupByName[]) {
    const ghostMarket = C_GameGroupByName.parse({
      Name: 'Goleadores',
      GroupResults: []
    });
    const newMarket: any = [];

    listOfScorerMarkets.map((market) => {
      if (market === null) {
        newMarket.push(ghostMarket);
      } else {
        newMarket.push(market);
      }
    });
    return newMarket;
  }

  private getOrderResultsInGoalScorers(newPlayerWithResultsList: { spov: string; results: any[] }): any[] {
    /* ordered the results*/
    return newPlayerWithResultsList.results.sort((a, b) => a.position - b.position);
  }
  private prepareGoalScoreMarket(
    playerWithResultsList: any[],
    actualMarket: any,
    categoryInfoScoreMarket: any,
    groupResults: any[]
  ): C_GameGroupByName {
    return C_GameGroupByName.parse({
      Name: 'Goleadores',
      nameLeague: actualMarket.LeagueName,
      Priority: actualMarket.Priority,
      CategoryInfos: categoryInfoScoreMarket.CategoryInfos,
      CategoryInfo: categoryInfoScoreMarket.CategoryInfo,
      DisplayTypeName: '3buttonlist',
      teamAway: 'Ultimo',
      teamHome: 'Primer',
      titleCenter: 'Durante',
      isSoftCombined: true,
      isHardCombined: false,
      isSpecialMarket: false,
      isCombined: true,
      headerClass: 'sb-grid-header--single',
      displayMode: 'single',
      GroupResults: groupResults,
      combinedResults: playerWithResultsList
    });
  }
  private getMatrixBySpoort(SportHandle: any) {
    let matrix: number[];
    switch (SportHandle) {
      case 'basketball':
        matrix = [97, 259, 393];
        break;
      case 'baseball':
        matrix = [184, 874, 959];
        break;
      case 'american_football':
        matrix = [97, 259, 18];
        break;
      case 'tennis':
        matrix = [];
        break;
      case 'football':
        matrix = [];
        break;
      default:
        matrix = [];
        break;
    }
    return matrix;
  }

  private getMostBalancedMarkets(markets: any[]) {
    let balanced: number = Number.MAX_SAFE_INTEGER;
    let iterator = -1;
    markets.forEach((market, index) => {
      if (!market.Results[1]) {
        market.Results[1] = { odd: 0 };
      }
      if (!market.Results[0]) {
        market.Results[0] = { odd: 0 };
      }
      const res = Math.abs(market.Results[0].Odd - market.Results[1].Odd);
      if (res < balanced) {
        balanced = res;
        iterator = index;
      }
    });
    if (!markets[iterator]) {
      return this.getMissingMarket();
    }
    return markets[iterator];
  }

  public getMissingMarket() {
    return {
      CategoryInfo: null,
      CategoryInfos: null,
      ChildrenCount: null,
      DisplayTypeName: '',
      EventNodeTypeId: null,
      GameType: null,
      Name: '',
      NodeId: '',
      ParentNodeId: '',
      Priority: null,
      Results: [this.declareNewResultMissing(), this.declareNewResultMissing()],
      SmartMarketAvailable: false,
      SportHandle: null,
      Spov: null
    };
  }

  private getMarketSixPackData(
    homeResults: any[],
    awayResults: any[],
    teamHome: string,
    teamAway: string,
    pitcherHome: string,
    pitcherAway: string,
    market?: any
  ): C_GameGroupByName {
    const newHome = JSON.parse(JSON.stringify(homeResults));
    const newAwayResults = JSON.parse(JSON.stringify(awayResults));
    return C_GameGroupByName.parse({
      Name: market.Name,
      teamAway: teamAway,
      teamHome: teamHome,
      AllSpov: [],
      Games: [...homeResults, ...awayResults],
      Spov: market.Spov,
      LeagueName: market.LeagueName,
      Collapsed: false,
      SportHandle: market.SportHandle,
      ChildrenCount: market.ChildrenCount,
      Priority: market.Priority,
      ParentNodeId: market.ParentNodeId,
      NodeId: market.NodeId,
      isLive: market.isLive,
      StreamingEnabled: market.StreamingEnabled,
      CategoryInfo: market.CategoryInfo,
      StatisticsId: market.StatisticsId,
      mode: market.mode,
      CategoryInfos: market.CategoryInfos,
      EventNodeTypeId: market.EventNodeTypeId,
      StartDate: market.StartDate,
      pitcherHome: pitcherHome ? pitcherHome : false,
      pitcherAway: pitcherAway ? pitcherAway : false,
      GameType: 0,
      titleCenter: 'Totales',
      isSpecialMarket: false,
      isCombined: false,
      headerClass: 'sb-grid-header--single',
      displayMode: 'single',
      DisplayTypeName: '6buttonlist',
      GroupResults: this.getNameOfResults(newHome, newAwayResults),
      isSixPack: true,
      Locked: market.Locked,
      LTMEnabled: market.LTMEnabled,
      combinedResults: [
        {
          results: homeResults,
          spov: teamHome
        },
        {
          results: awayResults,
          spov: teamAway
        }
      ]
    });
  }

  private getNameOfResults(homeResults: any, awayResults: any) {
    if (!homeResults) {
      homeResults = [];
    } else {
      this.getResultsHomeData(homeResults);
    }
    if (!awayResults) {
      awayResults = [];
    } else {
      this.getResultAwayData(awayResults);
    }
    return [...homeResults, ...awayResults];
  }

  private getResultAwayData(awayResults: any) {
    awayResults.forEach((result: any, index: number) => {
      if (index === 0) {
        result.Name = '2';
        result.SortOrder = 3;
      }
      if (index === 1) {
        if (!result.Name) {
          result.Name = '';
        } else {
          try {
            result.Name = result.Name.match(this.regexValues)[0];
            result.SortOrder = 4;
          } catch {
            //eslint-disable-next-line
          }
        }
      }
      if (index === 2) {
        //eslint-disable-next-line
        result.Name = result.Name;
        result.SortOrder = 5;
      }
    });
  }

  private getResultsHomeData(homeResults: any) {
    homeResults.forEach((result: any, index: number) => {
      if (index === 0) {
        result.Name = '1';
        result.SortOrder = 0;
      }
      if (index === 1) {
        if (!result.Name) {
          result.Name = '';
        } else {
          try {
            result.Name = result.Name.match(this.regexValues)[0];
            result.SortOrder = 1;
          } catch {
            //eslint-disable-next-line
          }
        }
      }
      if (index === 2) {
        //eslint-disable-next-line
        result.Name = result.Name;
        result.SortOrder = 2;
      }
    });
  }

  private getCombinedMarketMatrix(idCombinedMarket, market, actualMarket, skipCall: boolean = false) {
    let matrixGrid: number[] = [];
    switch (idCombinedMarket) {
      case 39:
      case 175:
      case 148:
      case 1065:
      case 991:
      case 1667:
      case 1668:
      case 1669:
      case 1805:
      case 1806:
      case 1804:
      case 1394:
        matrixGrid = [0, 2, 4, 1, 3, 5];
        this.getCombinedMarketMatchResultAndTotalOverUnder(market, matrixGrid, skipCall);
        break;
      case 7:
        matrixGrid = [0, 1, 2, 3, 4, 5, 7, 6, 8];
        this.getCombinedMarketMatchResultAndFirstTeamScore(market, matrixGrid);
        break;
      case 988:
      case 990:
        matrixGrid = [0, 2, 4, 1, 3, 5];
        this.getCombinedMarketMatchResultAndFirstTeamScore(market, matrixGrid);
        break;
      case 1064:
        matrixGrid = [0, 2, 4, 1, 3, 5];
        this.getCombinedMarketMatchResultAndFirstTeamScore(market, matrixGrid);
        break;
      case 1330:
      case 1809:
      case 1807:
      case 1808:
        matrixGrid = [0, 2, 1, 3];
        this.getCombinedMarketLineMatchAndTotal(market, matrixGrid, actualMarket);
        break;
      case 1391:
      case 1392:
      case 1393:
        matrixGrid = [0, 1, 2, 3];
        this.getCombinedMarketLineMatchAndTotal(market, matrixGrid, actualMarket);
        break;
      case 1647:
      case 1648:
      case 1649:
      case 1650:
      case 1606 /* especial basket case */:
        /*handicap*/
        matrixGrid = [0, 2, 1, 3];
        this.getCombinedMarketLineMatchAndTotal(market, matrixGrid, actualMarket);
        break;
      case 993:
        matrixGrid = [0, 2, 1, 3];
        this.getCombinedMarketHandicap(market, matrixGrid, actualMarket);
        break;
      case 4:
      case 155:
        matrixGrid = [0, 1, 2, 3];
        this.getCombinedMarketOneXTwoHandicap(market, matrixGrid, actualMarket);
        break;
      case 1853:
        matrixGrid = [0, 2, 1, 3];
        this.getCombinedMarketHandicapBasket(market, matrixGrid, actualMarket);
        break;
      default:
        return;
    }
  }

  private getCombinedMarketHandicapBasket(market, matrix: number[], actualMarket) {
    market.isHardCombined = false;
    market.isSoftCombined = true;
    market.titleCenter = '';
    market.DisplayTypeName = '2buttonline';
    market.teamAway = actualMarket.teamAway;
    market.teamHome = actualMarket.teamHome;
    this.getTotalsAndHandicaps(market);
    this.getGridWithFourPositions(market, matrix);
  }

  private getCombinedMarketOneXTwoHandicap(market: any, matrix: number[], actualMarket: any) {
    market.isHardCombined = false;
    market.isCombined = false;
    market.isSoftCombined = false;
    market.isHandicap = true;
    market.titleCenter = `X(${this.getShortName(market.teamAway)})`;
    market.DisplayTypeName = '3buttonlist';
    market.displayMode = 'expanded';
    market.headerClass = 'sb-grid-header';
    market.teamHome = this.getShortName(market.teamHome);
    market.teamAway = this.getShortName(market.teamAway);
    market.GroupResults.forEach((result: any) => {
      if (!result.Name || !result.Name.match(this.regexValuesLongResult)) {
        return;
      }
      const [namesOne, nameTwo] = result.Name.match(this.regexValuesLongResult);
      result.Name = nameTwo ? nameTwo : namesOne;
    });
  }

  private getCombinedMarketHandicap(market: any, matrix: number[], actualMarket: any) {
    market.isHardCombined = false;
    market.isSoftCombined = true;
    market.titleCenter = '';
    market.DisplayTypeName = '2buttonline';
    market.teamAway = actualMarket.teamAway;
    market.teamHome = actualMarket.teamHome;
    this.getTotalsAndHandicaps(market);
    this.getGridWithFourPositions(market, matrix);
  }

  private getCombinedMarketMatchResultAndTotalOverUnder(market: any, matrix?: number[], skipCall: boolean = false) {
    market.titleCenter = 'X';
    market.teamHome = '1';
    market.teamAway = '2';
    market.isHardCombined = true;
    market.isSoftCombined = false;
    //eslint-disable-next-line
    market.Locked = market.Locked;
    if (market.GameType === 1065) {
      market.titleCenter = '1/2';
      market.teamHome = '1/X';
      market.teamAway = '2/X';
    }
    if (!skipCall) {
      this.getTotalSpovsList(market);
      this.getGridWithSixPositions(market, matrix);
    }
  }

  private getCombinedMarketLineMatchAndTotal(market, matrix: number[], actualMarket) {
    market.isHardCombined = false;
    market.isSoftCombined = true;
    market.titleCenter = '';
    market.DisplayTypeName = '2buttonline';
    // eslint-disable-next-line no-self-assign
    market.Locked = market.Locked;

    if (market.GameType === 1330 || market.GameType === 1853) {
      this.getTotalSpovsList(market);
      market.teamHome = `${this.getShortName(actualMarket.teamHome)}`;
      market.teamAway = `${this.getShortName(actualMarket.teamAway)}`;
    } else {
      this.getTotalsAndHandicaps(market);
    }
    this.getGridWithFourPositions(market, matrix);
  }

  private getCombinedMarketMatchResultAndFirstTeamScore(market: any, matrix: number[]) {
    market.titleCenter = '1/2';
    market.teamHome = '1/X';
    market.teamAway = '2/X';
    //eslint-disable-next-line
    market.Locked = market.Locked;
    if (market.GameType === 7 || market.GameType === 988 || market.GameType === 990) {
      market.titleCenter = 'X';
      market.teamHome = '1';
      market.teamAway = '2';
      market.DisplayTypeName = '3buttonlist';
    }
    market.isHardCombined = false;
    market.isSoftCombined = true;
    market.AllSpov = this.getAllSpovsWithOutSpovs(market);
    this.getGridWithSevenPositions(market, matrix);
  }

  private getGridWithFourPositions(market: any, matrix: any) {
    market.combinedResults = [];
    market.AllSpov.forEach((spov: any, indexSpov: any) => {
      const row: any = [];
      matrix.forEach((matrixOrder: any, indexMatix: any) => {
        let current = market.GroupResults.find((m: any) => m.SortOrder === matrixOrder);
        if (!current) {
          current = this.declareNewResultMissing();
        }
        if (row.length < 2 && indexSpov === 0 && indexMatix < 2) {
          row.push(current);
        }
        if (row.length < 2 && indexSpov === 1 && indexMatix < 4 && indexMatix >= 2) {
          row.push(current);
        }
      });
      if (row.length > 0) market.combinedResults.push({ results: row, spov: spov });
    });
  }
  private getGridWithSevenPositions(market: any, matrix: any) {
    market.combinedResults = [];
    market.AllSpov.forEach((spov: any, indexSpov: any) => {
      const row: any = [];
      this.getRowsOfGridInSevenPositions(matrix, market, row, indexSpov);
      if (row.length > 0) market.combinedResults.push({ results: row, spov: spov });
    });
  }
  private getRowsOfGridInSevenPositions(matrix: any, market: any, row: any[], indexSpov: any) {
    matrix.forEach((matrixOrder: any, indexMatix: any) => {
      let current = market.GroupResults.find((m: any) => m.SortOrder === matrixOrder);
      if (!current) {
        current = this.declareNewResultMissing();
      }
      if (row.length < 3 && indexSpov === 0 && indexMatix < 3) {
        row.push(current);
      }
      if (row.length < 3 && indexSpov === 1 && indexMatix < 6 && indexMatix >= 3) {
        row.push(current);
      }
      if (row.length < 3 && indexSpov === 2 && indexMatix >= 6 && matrix.length > 7) {
        row.push(current);
      }
    });
  }

  private getGridWithSixPositions(market: any, matrix: Array<number>) {
    market.combinedResults = [];

    market.AllSpov.forEach((spov: any) => {
      const filteredResults = market.GroupResults.filter(
        (result: any) => result.Spov.substr(result.Spov.indexOf('>') + 1) == Math.abs(parseFloat(spov)).toString()
      );

      const rowInFirstPositionGrid: any = [];
      const rowInSecondPositionGrid: any = [];
      matrix.forEach((matrixOrder) => {
        let current = filteredResults.find((m: any) => m.SortOrder === matrixOrder);
        if (!current) {
          current = this.declareNewResultMissing();
        }
        //current.title = spov;
        if (matrixOrder % 2 == 0) {
          rowInFirstPositionGrid.push(current);
        } else {
          rowInSecondPositionGrid.push(current);
        }
      });
      const arrOfRows = [rowInFirstPositionGrid, rowInSecondPositionGrid];
      market.combinedResults.push({
        results: arrOfRows,
        spov: this.getFinalSpovsByMarketName(market.Name, spov, market.GameType)
      });
    });
  }
  private getFinalSpovsByMarketName(name: any, spov: any, gameType: number): string[] {
    const expressionPrefix = /([A-Za-z/á]+[/]+[A-Za-z]+)/g;
    let spovPrefixList = name.match(expressionPrefix) ? name.match(expressionPrefix)[0].split('/') : '';
    if (gameType == 39 && name == '1X2 y Total de Goles') {
      spovPrefixList = ['Más', 'Menos'];
    }
    return [
      `${spovPrefixList[0]} ${spov.substr(spov.indexOf('>') + 1)}`,
      `${spovPrefixList[1]}  ${spov.substr(spov.indexOf('>') + 1)}`
    ];
  }
  private getAllSpovsWithOutSpovs(market: any) {
    if (market.GameType === 988 || market.GameType === 990 || market.GameType === 1064) {
      return ['Si', 'No'];
    }
    //Todo : this crash
    try {
      return [market.GroupResults[2].Name.split(' y ')[0], market.GroupResults[2].Name.split(' y ')[1], 'Sin Goles'];
    } catch {
      return ['1', '2', 'Sin Goles'];
    }
  }

  public declareNewResultMissing(): C_Result {
    return new C_Result('-', null, null, null, null, null, null, null, null, null, null, null);
  }

  private getTotalSpovsList(market: any) {
    const newArraySpovs: any = [];
    market.AllSpov = [];
    let actualSpov: any;
    market.GroupResults.forEach((result: any) => {
      if (actualSpov != result.Spov) {
        market.AllSpov.push(result.Spov);
        actualSpov = result.Spov;
      }
    });

    market.AllSpov.forEach((spov: any) => {
      const otherSpoves = spov.split('|');
      otherSpoves.forEach((key: any) => {
        if (market.GameType === 1330) {
          newArraySpovs.push(`Más ${key.match(this.regexValues)[0]}`);
          newArraySpovs.push(`Menos ${key.match(this.regexValues)[0]}`);
        } else {
          newArraySpovs.push(key.match(this.regexValues)[0]);
        }
      });
    });
    market.AllSpov = newArraySpovs;
  }
  getSymbol(hCap: number) {
    return Math.sign(hCap) > 0 ? '+' : '-';
  }
  private getTotalsAndHandicaps(market) {
    const keysObject = {};
    const newArraySpovs = [];
    market.spovListOfKeys = {};

    const otherSpoves = market.Spov.split('|');
    otherSpoves.forEach((key) => {
      if (key === '') {
        return;
      }
      keysObject[key.match(this.regexTitles)[0]] = key.match(this.regexValues)[0];
    });
    market.spovListOfKeys = keysObject;
    market.spovListOfKeys.realHcap = market.spovListOfKeys.Hcap;
    if (Math.sign(parseFloat(market.spovListOfKeys.Hcap)) === -1) {
      market.spovListOfKeys.Hcap = parseFloat(market.spovListOfKeys.Hcap) * -1;
    }

    if (
      market.GameType === 993 ||
      market.GameType === 1606 ||
      market.GameType === 1648 ||
      market.GameType === 1649 ||
      market.GameType === 1650 ||
      market.GameType === 1853
    ) {
      if (market.GameType == 1853) {
        market.teamHome = `${this.getShortName(market.teamHome)} `;
        market.teamAway = `${this.getShortName(market.teamAway)} `;
        newArraySpovs.push(`Más ${market.spovListOfKeys.Spov}`);
        newArraySpovs.push(`Menos ${market.spovListOfKeys.Spov}`);
      } else {
        market.teamHome = `${this.getShortName(market.teamHome)} ${this.getSymbol(
          parseFloat(market.spovListOfKeys.realHcap)
        )} ${parseFloat(market.spovListOfKeys.Hcap)}`;
        market.teamAway = `${this.getShortName(market.teamAway)} ${this.getSymbol(
          -1 * parseFloat(market.spovListOfKeys.realHcap)
        )} ${parseFloat(market.spovListOfKeys.Hcap)}`;
        newArraySpovs.push(`Más ${market.spovListOfKeys.Totals}`);
        newArraySpovs.push(`Menos ${market.spovListOfKeys.Totals}`);
      }
    } else if (market.GameType === 1647) {
      newArraySpovs.push(
        `${this.getShortName(market.teamHome)} ${this.getSymbol(
          parseFloat(market.spovListOfKeys.realHcap)
        )} ${parseFloat(market.spovListOfKeys.Hcap)}`
      );
      newArraySpovs.push(
        `${this.getShortName(market.teamAway)} ${this.getSymbol(
          -1 * parseFloat(market.spovListOfKeys.realHcap)
        )} ${parseFloat(market.spovListOfKeys.Hcap)}`
      );
    } else {
      newArraySpovs.push(`Más ${parseFloat(market.spovListOfKeys.Hcap)}`);
      newArraySpovs.push(`Menos ${parseFloat(market.spovListOfKeys.Hcap)}`);
    }

    market.AllSpov = newArraySpovs;
  }

  public createFastMarketFilterTennis(filters: C_CategoryInfo[]) {
    const fastMarketFilter = new C_CategoryInfo(0, false, 'MERCADOS RAPIDOS');
    filters.push(fastMarketFilter);
    return filters;
  }

  public createBetbuilderFilterSoccer(categories: C_CategoryInfo[]) {
    let newCategoriesArr: C_CategoryInfo[] = Array.from(categories);
    const principalesMarketsCat = categories.find((i) => i.CategoryId == Constants.principalesCat);
    const betbuilderBetsCat = categories.find((i) => i.CategoryId == Constants.betbuilderCatId);
    // const betbuilderBetsCat = new C_CategoryInfo(Constants.betbuilderCatId, false, Constants.betbuilderCatName);
    newCategoriesArr = newCategoriesArr.filter(
      (i) => i.CategoryId !== Constants.principalesCat && i.CategoryId !== Constants.betbuilderCatId
    );
    if (betbuilderBetsCat) newCategoriesArr.unshift(betbuilderBetsCat);
    if (principalesMarketsCat) newCategoriesArr.unshift(principalesMarketsCat);
    return newCategoriesArr;
  }

  groupMarkets(games: any[], league: string = '') {
    let marketGameType: any;
    const groupedMarkets: any = [];

    games.forEach((market, index) => {
      market.Results.forEach((r: any) => {
        r.Spov = market.Spov;
      });

      if (!marketGameType || marketGameType !== market.Name) {
        const newMarket = new C_GameGroupByName();
        newMarket.copyGame(market);
        newMarket.AllSpov = [market.Spov];
        newMarket.nameLeague = league;
        //eslint-disable-next-line
        newMarket.Locked = market.Locked;
        groupedMarkets.push(newMarket);
        marketGameType = market.Name;
      } else {
        if (marketGameType === market.Name) {
          groupedMarkets[groupedMarkets.length - 1].GroupResults = [
            ...groupedMarkets[groupedMarkets.length - 1].GroupResults,
            ...market.Results
          ];
        }
      }
    });

    return groupedMarkets;
  }

  arrangeCategories(categories: any[], previousCategories: C_CategoryInfo[]): C_CategoryInfo[] {
    previousCategories = categories.map((i) => {
      const newCat: C_CategoryInfo = new C_CategoryInfo(i.CategoryId, i.IsRelevant, i.CategoryName);
      newCat.numGames = 1;
      previousCategories.push(newCat);
      return newCat;
    });
    return previousCategories;
  }

  private getShortName(name: string) {
    return name.replace(/\s/g, '').slice(0, 3).toUpperCase();
  }

  updateOddChanges(prev: any, current: any, field: string = 'Results') {
    if (!prev || !current) {
      return;
    }
    field = current[field] ? field : 'GroupResults';

    for (const i in current[field]) {
      if (!prev[field] || !prev[field][i] || !current[field][i]) {
        return;
      }
      current[field][i].upOdd = current[field][i].Odd > prev[field][i].Odd;
      current[field][i].downOdd = current[field][i].Odd < prev[field][i].Odd;
    }

    return current;
  }

  doAlternatives(markets: any[], grouped: any[]) {
    const alternatives = {
      handicaps: [],
      totals: []
    };

    markets.forEach((market) => {
      if (this.globalVars.gameTypes.alternativeTotals.indexOf(market.GameType) !== -1) {
        alternatives.totals.push(market);
      }

      if (this.globalVars.gameTypes.alternativeHandicaps.indexOf(market.GameType) !== -1) {
        alternatives.handicaps.push(market);
      }
    });

    const mostBalancedTotals = this.getMostBalancedMarkets(alternatives.totals);
    mostBalancedTotals.Priority += 1;
    const mostBalancedHandicap = this.getMostBalancedMarkets(alternatives.handicaps);
    mostBalancedHandicap.Priority += 1;

    grouped = grouped.map((market) => {
      switch (market.GameType) {
        case mostBalancedTotals.GameType:
          market.GroupResults = market.GroupResults.filter((result) => result.Spov !== mostBalancedTotals.Spov);
          if (!market.GroupResults.length) return;
          market.Name = market.Name.includes('Alternativo') ? market.Name : market.Name + ' Alternativo';
          market.Collapsed = true;
          break;
        case mostBalancedHandicap.GameType:
          market.GroupResults = market.GroupResults.filter((result) => result.Spov !== mostBalancedHandicap.Spov);
          if (!market.GroupResults.length) return;
          market.Name = market.Name.includes('Alternativo') ? market.Name : market.Name + ' Alternativo';
          market.Collapsed = true;
          break;
      }
      return market;
    });
    //eliminate undefineds @a.e.m
    const g = [];
    for (const i of grouped) {
      if (i != null) g.push(i);
    }
    let result: any[] = [];
    result = [...g];
    if (mostBalancedTotals.Name != '') result.push(C_GameGroupByName.parse(mostBalancedTotals));
    if (mostBalancedHandicap.Name != '') result.push(C_GameGroupByName.parse(mostBalancedHandicap));
    return result;
  }
  isSpecialMarket(leagueName: string) {
    return leagueName.toLowerCase().search('especiales') != -1 || leagueName.toLowerCase().search('mejoradas') != -1;
  }
}
