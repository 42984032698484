/* eslint-disable eqeqeq */
import { Pipe, PipeTransform } from '@angular/core';

/*
  Generated class for the CountryMarker pipe.

  See https://angular.io/docs/ts/latest/guide/pipes.html for more info on
  Angular 2 Pipes.
*/
@Pipe({
  name: 'getCountryMarker',
  standalone: true
})
export class countryMarkerPipe implements PipeTransform {
  transform(value: string) {
    value = value + ''; // make sure it's a string
    const valueAuxWithoutNumbers = value.replace(/\d+/g, '');
    return valueAuxWithoutNumbers;
  }
}
