/* eslint-disable */
import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sb-overview-score-sets',
  templateUrl: './sb-overview-score-sets.html',
  standalone: true,
  imports: [CommonModule, FormsModule],
  styleUrls: ['./sb-overview-score-sets.scss']
})
export class SbOverviewScoreSetsComponent implements OnChanges {
  @Input() scoreboard;
  @Input() sportHandle = '';
  @Input() isMarquee = false;
  shortSet = false;

  constructor() {}

  ngOnChanges() {
    if (this.scoreboard.Sets && this.scoreboard.Sets.length >= 1) {
      this.scoreboard.Sets = this.scoreboard.Sets.reverse()
        .filter((_, i) => i === 0)
        .reverse();
    }

    this.setService();
    this.isShorter();
    this.parseTennisAD();
  }

  private isShorter() {
    switch (this.sportHandle) {
      case 'badminton':
      case 'volleyball':
      case 'beach_volleyball':
      case 'table_tennis':
        this.shortSet = true;
        break;
      default:
        return;
    }
  }

  private setService() {
    if (this.scoreboard.hasOwnProperty('HomeAttacking')) {
      this.scoreboard.HomeService = this.scoreboard.HomeAttacking;
    }
  }

  private parseTennisAD() {
    if (this.sportHandle !== 'tennis') {
      return;
    }

    this.scoreboard.ResultHome = this.scoreboard.ResultHome === 50 ? 'AD' : this.scoreboard.ResultHome;
    this.scoreboard.ResultAway = this.scoreboard.ResultAway === 50 ? 'AD' : this.scoreboard.ResultAway;
  }
  public getScoreboardInMarquee(): boolean {
    return this.isMarquee;
  }
}
