<ion-content>
  <ng-container *ngIf="isDesktop; then desktopTemplate; else mobileTemplate;"></ng-container>

  <ng-template #desktopTemplate>
    <ion-grid class="ion-grid-content tablet-common-grid">
      <ion-row class="ion-justify-content-center" no-padding no-margin>
        <ion-col [class.leftMenuOpen]="globalVars.leftMenuOpen" no-padding>
          <ion-content [class.leftMenuOpen]="globalVars.leftMenuOpen">
            <ion-toolbar class="toolbarSectionName">
              <back-button tappable></back-button>
              {{'tChangePass' | translate }}
              <!--Cambio de password-->
            </ion-toolbar>
            <div class="introReg background-color-dark">
              {{'tChangPassIns' | translate}}
              <!-- New literal:
                        Introduce una contraseña de entre 5 y 20 caracteres.
                        Tiene que contener al menos un número y  una letra.-->
            </div>
            <form [formGroup]="myForm">
              <ion-list>
                <div class="">
                  <ion-item
                    class="cdr-item-input"
                    lines="none"
                    [class.error]="!myForm.controls['pass'].valid && myForm.controls['pass'].touched"
                  >
                    <ion-input
                      [label]="'loginPass' | translate"
                      type="password"
                      labelPlacement="floating"
                      formControlName="pass"
                      autocomplete="off"
                      autoCorrect="off"
                      spellcheck="off"
                      [class.ion-input-error]="!myForm.controls['pass'].valid && myForm.controls['pass'].touched"
                    ></ion-input>
                  </ion-item>

                  <div [hidden]="myForm.controls['pass'].valid || !myForm.controls['pass'].touched" class="labelError">
                    {{'v2_contraObl' | translate}}
                    <!--La password es incorrecta-->
                  </div>
                </div>
                <div class="">
                  <!--nueva password-->
                  <ion-item
                    class="cdr-item-input"
                    lines="none"
                    [class.error]="!myForm.controls['newPass'].valid && myForm.controls['newPass'].touched"
                  >
                    <ion-input
                      [class.ion-input-error]="!myForm.controls['newPass'].valid && myForm.controls['newPass'].touched"
                      [label]="'tChangePassNew' | translate"
                      type="password"
                      autocomplete="off"
                      autoCorrect="off"
                      spellcheck="off"
                      labelPlacement="floating"
                      formControlName="newPass"
                    ></ion-input>
                  </ion-item>
                  <div
                    [hidden]="myForm.controls['newPass'].valid || !myForm.controls['newPass'].touched"
                    class="labelError"
                  >
                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='required'"
                    >
                      {{'v2_contraObl' | translate}}
                      <!--# La contraseña es obligatoria--></span
                    >
                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='noMay'"
                    >
                      {{'v2_errorContra' | translate}}
                      <!--# La contraseña debe incluir una mayúscula--></span
                    >
                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='noMin'"
                      >{{'v2_errorContra6' | translate}}
                      <!--La contraseña debe incluir una minúscula--></span
                    >
                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='noNum'"
                      >{{'error_pass_min_num' | translate}}
                      <!--La contraseña debe incluir un número--></span
                    >
                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='tooShort'"
                    >
                      {{'v2_errorContra3' | translate}}
                      <!--# La contraseña debe tener al menos 8 caracteres--></span
                    >
                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='incUser'"
                      >{{'error_pass_no_user' | translate}}
                      <!--La contraseña no puede incluir el nombre de usuario--></span
                    >

                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='incName'"
                    >
                      {{'error_pass_no_name' | translate}}</span
                    >
                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='incSurname'"
                    >
                      {{'error_pass_no_surname1' | translate}}<br
                    /></span>
                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='incSurname2'"
                    >
                      {{'error_pass_no_surname2' | translate}}</span
                    >
                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='incBirthday'"
                    >
                      {{'error_pass_no_date' | translate}}</span
                    >
                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='tooLong'"
                    >
                      {{'error_form_max20' | translate}}</span
                    >

                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='tooShortMX'"
                      >{{'error_tar_min5' | translate}}</span
                    >
                    <!--# La contraseña debe tener al menos 5 caracteres-->
                    <span
                      *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='tooLongMX'"
                      >{{'error_form_max20' | translate}}</span
                    >
                    <!-- New literal : La contraseña debe tener máximo 20 caracteres -->
                  </div>
                </div>
                <div class="">
                  <!--repite la nueva password-->
                  <ion-item
                    class="cdr-item-input"
                    lines="none"
                    [class.error]="!myForm.controls['reNewPass'].valid && myForm.controls['reNewPass'].touched"
                  >
                    <ion-input
                      [class.ion-input-error]="!myForm.controls['reNewPass'].valid && myForm.controls['reNewPass'].touched"
                      [label]="'tChangePassConfNew' | translate"
                      type="password"
                      autocomplete="off"
                      autoCorrect="off"
                      spellcheck="off"
                      labelPlacement="floating"
                      formControlName="reNewPass"
                    ></ion-input>
                  </ion-item>
                  <div
                    [hidden]="myForm.controls['reNewPass'].valid || !myForm.controls['reNewPass'].touched"
                    class="labelError"
                  >
                    <span
                      *ngIf="myForm.controls['reNewPass'].hasError('cError') && myForm.controls['reNewPass'].errors['cError']=='required'"
                    >
                      {{'v2_contraObl' | translate}}
                      <!--# La contraseña es obligatoria--></span
                    >
                    <span
                      *ngIf="myForm.controls['reNewPass'].hasError('cError') && myForm.controls['reNewPass'].errors['cError']=='same'"
                    >
                      {{'v2_errorContra5' | translate}}
                      <!--# Las contraseñas no coinciden--></span
                    >
                  </div>
                </div>

                <div class="sendForm">
                  <ion-row>
                    <ion-col>
                      <ion-button expand="full" (click)="doChangePass(myForm)">
                        {{'tHelpSend' | translate}}
                        <!--Enviar-->
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </div>
              </ion-list>
            </form>
            <codere-footer></codere-footer>
          </ion-content>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-template>

  <ng-template #mobileTemplate>
    <ion-content>
      <div class="introReg">
        {{'tChangPassIns' | translate}}
        <!-- New literal
          Introduce una contraseña de entre 5 y 20 caracteres.
          Tiene que contener al menos un número y  una letra. -->
      </div>

      <form [formGroup]="myForm" novalidate>
        <ion-list>
          <div class="">
            <ion-item
              class="cdr-item-input"
              lines="none"
              [class.error]="!myForm.controls['pass'].valid && myForm.controls['pass'].touched"
            >
              <ion-input
                [class.ion-input-error]="!myForm.controls['pass'].valid && myForm.controls['pass'].touched"
                [label]="'loginPass' | translate"
                type="password"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
                labelPlacement="floating"
                formControlName="pass"
              ></ion-input>
            </ion-item>

            <div [hidden]="myForm.controls['pass'].valid || !myForm.controls['pass'].touched" class="labelError">
              {{'v2_contraObl' | translate}}
              <!--La password es incorrecta-->
            </div>
          </div>
          <div class="">
            <ion-item
              class="cdr-item-input"
              lines="none"
              [class.error]="!myForm.controls['newPass'].valid && myForm.controls['newPass'].touched"
            >
              <ion-input
                [class.ion-input-error]="!myForm.controls['newPass'].valid && myForm.controls['newPass'].touched"
                [label]="'tChangePassNew' | translate"
                type="password"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
                labelPlacement="floating"
                formControlName="newPass"
              ></ion-input>
            </ion-item>

            <div [hidden]="myForm.controls['newPass'].valid || !myForm.controls['newPass'].touched" class="labelError">
              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='required'"
              >
                {{'v2_contraObl' | translate}}
                <!--# La contraseña es obligatoria--></span
              >
              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='noMay'"
              >
                {{'v2_errorContra' | translate}}
                <!--# La contraseña debe incluir una mayúscula--></span
              >
              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='noMin'"
                >{{'v2_errorContra6' | translate}}
                <!--#{{'v2_errorContra6' | translate}} La contraseña debe incluir una minúscula--></span
              >
              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='noNum'"
              >
                {{'v2_errorContra1' | translate}}
                <!--# La contraseña debe incluir un número--></span
              >
              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='tooShort'"
              >
                {{'v2_errorContra3' | translate}}
                <!--# La contraseña debe tener al menos 8 caracteres--></span
              >
              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='incUser'"
              >
                {{'v2_errorContra4' | translate}}
                <!--# La contraseña no puede incluir el nombre de usuario--></span
              >

              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='incName'"
              >
                {{'error_pass_no_name' | translate}}</span
              >
              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='incSurname'"
              >
                {{'error_pass_no_surname1' | translate}}<br
              /></span>
              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='incSurname2'"
              >
                {{'error_pass_no_surname2' | translate}}</span
              >
              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='incBirthday'"
              >
                {{'error_pass_no_date' | translate}}</span
              >
              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='tooLong'"
              >
                {{'error_form_max20' | translate}}</span
              >

              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='tooShortMX'"
                >{{'error_tar_min5' | translate}}</span
              >
              <!--# La contraseña debe tener al menos 5 caracteres-->
              <span
                *ngIf="myForm.controls['newPass'].hasError('cError') && myForm.controls['newPass'].errors['cError']=='tooLongMX'"
                >{{'error_form_max20' | translate}}</span
              >
              <!-- New literal: La contraseña debe tener máximo 20 caracteres -->
            </div>
          </div>
          <div class="myWrapInput">
            <ion-item
              class="cdr-item-input"
              lines="none"
              [class.error]="!myForm.controls['reNewPass'].valid && myForm.controls['reNewPass'].touched"
            >
              <ion-input
                [class.ion-input-error]="!myForm.controls['reNewPass'].valid && myForm.controls['reNewPass'].touched"
                [label]="'tChangePassConfNew' | translate"
                type="password"
                labelPlacement="floating"
                formControlName="reNewPass"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
              ></ion-input>
            </ion-item>

            <div
              [hidden]="myForm.controls['reNewPass'].valid || !myForm.controls['reNewPass'].touched"
              class="labelError"
            >
              <span
                *ngIf="myForm.controls['reNewPass'].hasError('cError') && myForm.controls['reNewPass'].errors['cError']=='required'"
              >
                {{'v2_contraObl' | translate}}
                <!--# La contraseña es obligatoria--></span
              >
              <span
                *ngIf="myForm.controls['reNewPass'].hasError('cError') && myForm.controls['reNewPass'].errors['cError']=='same'"
              >
                {{'v2_errorContra5' | translate}}
                <!--# Las contraseñas no coinciden--></span
              >
            </div>
          </div>

          <div class="sendForm">
            <ion-row>
              <ion-col>
                <ion-button expand="full" (click)="doChangePass(myForm)">
                  {{'tHelpSend' | translate}}
                  <!--Enviar-->
                </ion-button>
              </ion-col>
            </ion-row>
          </div>
        </ion-list>
      </form>

      <codere-footer></codere-footer>
    </ion-content>
  </ng-template>
</ion-content>
