import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  SimpleChanges,
  inject
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { JackpotService } from '../../../../services/casino/jackpot.service';
import { CommonModule } from '@angular/common';
import { ParseMoney } from '../../../../pipes/parseMoney';
import { SlotName } from '../../../../pipes/casino/lobby/slot-name.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from '../../../../services/device.service';

@Component({
  selector: 'lb-header',
  templateUrl: './lobby-header.html',
  standalone: true,
  imports: [CommonModule, SlotName, ParseMoney, TranslateModule],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./lobby-header.scss']
})
export class LobbyHeaderComponent implements OnInit, OnChanges {
  deviceService = inject(DeviceService);
  @Input() title: string;
  @Input() cta: string;
  @Input() category: any;
  @Input() mobileDetail = false;
  @Output() toggle: EventEmitter<any> = new EventEmitter();
  isJackpotCounter = false;
  jp: '';
  jackpots$: BehaviorSubject<number> = new BehaviorSubject(0);
  subscription: any;
  isDesktop = false;

  jackpotService = inject(JackpotService);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  //eslint-disable-next-line
  ngOnInit() {
    this.jp = this.category.JackpotId;
    if (this.jp) {
      this.subscription = this.category.additionalData.subscribe((data) => this.changed(data));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.jp = changes['category'].currentValue.JackpotId;
  }

  select(event: any) {
    this.cta === 'seeall'
      ? this.toggle.emit({ name: this.title, type: 'Category' })
      : this.toggle.emit({ name: '', type: 'All' });
    event.preventDefault();
  }

  changed(data) {
    if (data && data.jp && data.timer) {
      this.isJackpotCounter = true;
      this.jackpots$ = this.jackpotService.getTicker(data.jp, data.timer, this.category.Name);
    }
  }
}
