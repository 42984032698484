/* eslint-disable eqeqeq */
import { Injectable, inject } from '@angular/core';
import { C_TicketApuestasNew, TicketMethods, systemData } from '../../../C_TicketApuestas';
import { mapSingleResult, SingleItemInput } from '../../singleItemInput.model';
import { StraightMulticastInput } from '../../straightMulticast.model';
import { StakeGroup, StakeModel } from '../../stake.model';
import { CombinationMulticastInput } from '../../combinationMulticast.model';
import { LineItem } from '../../B_LineItem.model';
import { filterItem } from '../../B_Filters.model';
import { singleResultItem } from '../../singleResult.model';
import { SmartMarketInput } from '../../smartMarket.model';
import { XStateService } from '../../../../services/sports/xstate.service';
import { Subject, catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { ticketState } from '../reducers/ticket.reducers';
import * as TicketActions from '../actions/ticket.actions';
import * as ticketSelector from '../selectors/ticket.selectors';
import { BetSlipService } from '../../../../services/sports/betSlip.service';
import { EventsService } from '../../../../providers/EventsService';
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../../providers/newBase.service';
import { DeviceService } from '../../../../services/device.service';
import { UserService } from '../../../../providers/UserService';
import { Utils } from '../../../../utils/Utils';
import { TicketService } from '../../../../providers/TicketService';
import { Store } from '@ngrx/store';
import { MobileBehaviorService } from '../../../../services/sports/mobileBehavior.service';
import { TrackingService } from '../../../../providers/TrackingService';
import { BalancesService } from '../../../../providers/BalancesService';
import { EventTypes } from '../../../TrackingEvents';
import { C_OveraskDataBetPlacement } from '../../C_Overask-Data';
import { AlertController } from '@ionic/angular';
import { removeTicket } from '../../B_RemoveTicket.model';

@Injectable({ providedIn: 'root' })
export class TicketEffects {
  auxCloseBet: any;
  auxCloseBetTicket: C_TicketApuestasNew;
  auxGroupKey: any = null;
  auxLineItem: SingleItemInput;
  auxRacingLineItem: StraightMulticastInput | CombinationMulticastInput;
  auxRemovedItem: any = null;
  auxRemovedItems: any = null;
  auxSmartLineItem: SmartMarketInput;
  canBeEachWayItems: any = null;
  defaultAmount: number[] = [];
  multiSingleCounter: number = 0;
  multiSingleStake: StakeModel;
  noEachWayItems: any = null;
  overaskData: C_OveraskDataBetPlacement = new C_OveraskDataBetPlacement();
  overaskStatus$: Subject<boolean> = new Subject<boolean>();
  singlesStaked: boolean = false;
  stopOveraskPolling: boolean = false;
  systemSetStake: systemData = null;
  systemStaking: boolean = false;
  globalVars!: GlobalVarsModel;

  actions$ = inject(Actions);
  betSlipService = inject(BetSlipService);
  xStateService = inject(XStateService);
  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  ticketService = inject(TicketService);
  store = inject(Store<ticketState>);
  utils = inject(Utils);
  events = inject(EventsService);
  mobileBehavior = inject(MobileBehaviorService);
  _trackingService = inject(TrackingService);
  _balanceService = inject(BalancesService);
  deviceService = inject(DeviceService);
  alertController = inject(AlertController);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  /**
   * initial Effect
   * triggers on Effects initialization
   */
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.NoAction | TicketActions.RecoverTicket>(ROOT_EFFECTS_INIT),
      map((action) => {
        sessionStorage.setItem('licenseType', this.globalVars.licenseType.toString());
        this.xStateService.startBetslip();

        const XTicketState = localStorage.getItem('X-Ticket-State');
        let ticketBetslip: ticketState;
        if (XTicketState) {
          ticketBetslip = JSON.parse(XTicketState);

          if (TicketMethods.getHasFreebet(ticketBetslip.ticket))
            return new TicketActions.RemoveTicket(new removeTicket());
          const ticketItemsAndAmount = {
            lineItems: [
              ...ticketBetslip.ticket.LineItems,
              ...ticketBetslip.ticket.SmartLineItems,
              ...ticketBetslip.ticket.StraightMulticast,
              ...ticketBetslip.ticket.CombinationMulticast
            ],
            amount: this.defaultOverallStake / 100
          };
          if (ticketItemsAndAmount.lineItems.length > 0)
            return new TicketActions.RecoverTicket(ticketBetslip, ticketItemsAndAmount);
        }
        return new TicketActions.NoAction();
      })
    )
  );

  /**
   * Add a single Item to ticket
   * @returns Stake | Error
   */
  addSingleResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ADD_SINGLE_RESULT),
      switchMap((data: TicketActions.AddSingleResult) => {
        this.auxLineItem = mapSingleResult(data.game);
        return this.betSlipService.addSingleResult(this.auxLineItem).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            const autoStake: StakeModel = this.autoSingleStake(response.body, this.auxLineItem);

            this._trackingService.trackEvent([
              '', // todo this.auxLineItem.Game.IsInPlay ? 'addBetLive' : 'addBet',
              '', // todo this.auxLineItem.ResultId.toString(),
              window.location.hash,
              '', // todo this.auxLineItem.Game.IsInPlay ? 'A\u00F1adir apuesta en directo' : 'A\u00F1adir apuesta anticipada',
              'event'
            ]);

            return new TicketActions.SetStake(autoStake);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred addSingleResult BetSlipService')))
        );
      })
    )
  );

  /**
   * Add a single Item to ticket By Id
   * @returns Stake | Error
   */

  addSingleResultById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ADD_SINGLE_RESULT_BY_ID),
      switchMap((data: TicketActions.AddSingleResultById) =>
        this.ticketService.getEventByNodeV2(data.betId).pipe(
          map((response: any) => {
            let lineItem: any = null;
            if (response.length != undefined && response.length > 0) {
              lineItem = LineItem.mapItemFromIdService(response[0].Value);
            } else {
              lineItem = LineItem.mapItemFromIdServiceV2(response);
            }
            this.auxLineItem = mapSingleResult(lineItem);
            return new TicketActions.AddSingleResult(lineItem);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred addSingleResultById ticketService')))
        )
      )
    )
  );

  /**
   * Add a SmartMarket Item to ticket
   * @returns Stake | Error
   */
  addSmartMarketResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ADD_SMARTMARKET_ITEM),
      switchMap((data: TicketActions.AddSmartMarketItem) => {
        this.auxSmartLineItem = data.smartMarketBet;
        return this.betSlipService.addSmartMarketItem(data.smartMarketRequest).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            const autoStake: StakeModel = this.autoSmartSingleStake(response.body);

            this._trackingService.trackEvent([
              // ! COMENTADO EN REPO CODERE
              'addSmartMarket', // todo this.auxLineItem.Game.IsInPlay ? 'addBetLive' : 'addBet',
              '', // todo this.auxLineItem.ResultId.toString(),
              window.location.hash,
              '', // todo this.auxLineItem.Game.IsInPlay ? 'A\u00F1adir apuesta en directo' : 'A\u00F1adir apuesta anticipada',
              'event'
            ]);

            return new TicketActions.SetStake(autoStake);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred addSmartMarketResult BetSlipService')))
        );
      })
    )
  );

  /**
   * Add a CombinationMulticast Item to ticket
   * @returns Stake | Error
   */
  addStraightMulticast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ADD_STRAIGHT_MULTICAST),
      switchMap((data: TicketActions.AddStraightMulticast) => {
        this.auxRacingLineItem = data.straightMulticastInput;
        return this.betSlipService.addStraightMulticast(data.straightMulticastInput).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            const autoStake: StakeModel = this.autoStraightMulticastStake(response.body);

            this._trackingService.trackEvent([
              'addStraightMulticast', // todo this.auxLineItem.Game.IsInPlay ? 'addBetLive' : 'addBet',
              '', // todo this.auxLineItem.ResultId.toString(),
              window.location.hash,
              this.auxRacingLineItem.Game.IsInPlay
                ? 'A\u00F1adir apuesta en directo'
                : 'A\u00F1adir apuesta anticipada',
              'event'
            ]);

            return new TicketActions.SetStake(autoStake);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred addStraightMulticast BetSlipService')))
        );
      })
    )
  );

  /**
   * Add a StraightMulticast Item to ticket
   * @returns Stake | Error
   */
  addCombinationMulticast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ADD_COMBINATION_MULTICAST),
      switchMap((data: TicketActions.AddCombinationMulticast) => {
        this.auxRacingLineItem = data.combinationMulticastInput;
        return this.betSlipService.addCombinationMulticast(data.combinationMulticastInput).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            const autoStake: StakeModel = this.autoCombinationMulticastStake(response.body);

            this._trackingService.trackEvent([
              'addCombinationMulticast', // todo this.auxLineItem.Game.IsInPlay ? 'addBetLive' : 'addBet',
              '', // todo this.auxLineItem.ResultId.toString(),
              window.location.hash,
              '', // this.auxLineItem.Game.IsInPlay ? 'A\u00F1adir apuesta en directo' : 'A\u00F1adir apuesta anticipada',
              'event'
            ]);

            return new TicketActions.SetStake(autoStake);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred addCombinationMulticast BetSlipService')))
        );
      })
    )
  );

  /**
   * Set Stake and Check Possible Systems and Change Filters
   * @returns TicketActions.SaveBetSlipAndEvaluateFlow | TicketActions.Error
   */
  setStake$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlipAndEvaluateFlow | TicketActions.Error>(TicketActions.SET_STAKE),
      withLatestFrom(this.store.select('ticket')),
      switchMap(([data, ticketS]) => {
        const dataStake = data['stake'];
        const stake: StakeModel = dataStake;
        this.systemStaking = dataStake.Group !== StakeGroup.SINGLES;
        return this.betSlipService.stake(stake).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            this._trackingService.trackEvent([
              'SetStake',
              '',
              '', // todo stake.OverallStake.toString(),
              'Cambia importe del ticket',
              'event'
            ]);
            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, true);
          }),
          catchError((errors) => this.handleError(errors))
        );
      })
    )
  );

  updateStake$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlip | TicketActions.Error>(TicketActions.UPDATE_STAKE),
      withLatestFrom(this.store.select('ticket')),
      switchMap(([data, ticketSt]) => {
        const dataStake = data['stake'];
        const stake: StakeModel = dataStake;
        this.systemStaking = dataStake.Group !== StakeGroup.SINGLES;
        return this.betSlipService.stake(stake).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            this._trackingService.trackEvent([
              'ChangeAmountTicket',
              '',
              '', // todo stake.OverallStake.toString(),
              'Cambia importe del ticket',
              'event'
            ]);
            return new TicketActions.SaveBetSlip(response.body);
          }),
          catchError((errors) => this.handleError(errors))
        );
      })
    )
  );

  setMultiStake$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlip | TicketActions.SetMultiStake | TicketActions.Error>(
        TicketActions.SET_MULTI_STAKE
      ),
      withLatestFrom(this.store.select('ticket')),
      switchMap(([data, ticketSt]) => {
        const multiStakeData = data['multiStake'];
        this.splitCanBeEachWay(multiStakeData, ticketSt);
        if (this.noEachWayItems !== null && this.noEachWayItems.ItemIds.length > 0) {
          return this.betSlipService.stake(this.noEachWayItems).pipe(
            map((response: Response) => {
              if (response != undefined && response != null)
                this.xStateService.saveXState(response.headers.get('X-State'));
              this.noEachWayItems = null;
              if (this.canBeEachWayItems.ItemIds.length > 0) {
                return new TicketActions.SetMultiStake(this.canBeEachWayItems);
              }
              if (response != undefined && response != null) return new TicketActions.SaveBetSlip(response.body);
              else return new TicketActions.NoAction();
            }),
            catchError((errors) => this.handleError(errors))
          );
        } else {
          return this.betSlipService.stake(this.canBeEachWayItems).pipe(
            map((response: Response) => {
              this.xStateService.saveXState(response.headers.get('X-State'));
              this.canBeEachWayItems = null;
              this.noEachWayItems = null;
              return new TicketActions.SaveBetSlip(response.body);
            }),
            catchError((errors) => this.handleError(errors))
          );
        }
      })
    )
  );

  /**
   * Set Stake and Check Possible Systems and Change Filters
   * @returns TicketActions.ChangeFilter | TicketActions.UpdateTicket | TicketActions.SetSystemBetType | TicketActions.UpdateAndChangeToSingles | TicketActions.NoAction
   */
  saveBetSlipAndEvaluateFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | TicketActions.ChangeFilter
        | TicketActions.UpdateTicket
        | TicketActions.SetSystemBetType
        | TicketActions.UpdateAndChangeToSingles
        | TicketActions.UpdateAndCheckForErrors
        | TicketActions.CheckForErrors
        | TicketActions.NoAction
      >(TicketActions.SAVE_BETSLIP_AND_EVALUATE_FLOW),
      withLatestFrom(this.store.select('ticket')),
      map(([data, tState]) => {
        const betSlip = data['betSlip'];
        const checkFilterChange = data['checkFilterChange'];
        const possibleSystems = betSlip['possibleSystems'];
        const filterSelected: filterItem = tState['filterSelected'];
        const numberOfSelections = TicketMethods.getNumberOfSelections(tState['ticket']);
        const existAccumulator = possibleSystems.find((system) => system.systemType === StakeGroup.ACCUMULATOR);
        const existMultiples = possibleSystems.find(
          (system) => system.systemType === StakeGroup.SYSTEM || system.systemType === StakeGroup.MULTI_WAY_SYSTEM
        );
        const globalsData = {
          cuotaAccept: this.globalVars.cuotaAccept,
          userLogged: this.globalVars.user.logged
        };

        this.ticketService.saveLocalStoregeTicket(tState);

        //? FilterSelected?
        if (filterSelected != undefined && filterSelected != null) {
          switch (filterSelected.betType) {
            case StakeGroup.SINGLES:
              if (numberOfSelections == 2 && existAccumulator && checkFilterChange) {
                return new TicketActions.ChangeFilter(1);
              } else {
                return new TicketActions.UpdateAndCheckForErrors(globalsData);
              }
            case StakeGroup.ACCUMULATOR:
              if (existAccumulator) {
                const stake: StakeModel = this.checkSystemsAmount(tState, filterSelected.value);
                return new TicketActions.SetSystemBetType(tState, stake);
              } else {
                if (numberOfSelections < 2) return new TicketActions.UpdateAndChangeToSingles(globalsData);
                return new TicketActions.UpdateAndCheckForErrors(globalsData);
              }
            case StakeGroup.SYSTEM:
            case StakeGroup.MULTI_WAY_SYSTEM:
              if (existMultiples) {
                const stake: StakeModel = this.checkSystemsAmount(tState, filterSelected.value);
                return new TicketActions.SetSystemBetType(tState, stake);
              } else {
                return new TicketActions.UpdateAndChangeToSingles(globalsData);
              }
            default:
              return new TicketActions.UpdateTicket(globalsData);
          }
        } else {
          return new TicketActions.ChangeFilter(1);
        }
      })
    )
  );

  /**
   * Set Multiple Selection to Ticket
   * @returns Stake | Error
   */
  setSystemBetType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.SET_SYSTEM_BET_TYPE),
      switchMap((data: TicketActions.SetSystemBetType) =>
        this.betSlipService.stake(data.systemStake).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            this._trackingService.trackEvent([
              'ChangeCombiTicket',
              '',
              '', // todo newBetSlip: numCombinations.toString(), ! (COMENTAOD POR REPO CODERE)
              'Cambia numero de combinaciones del ticket',
              'event'
            ]);
            return new TicketActions.SaveBetSlip(response.body);
          }),
          catchError((errors) => this.handleError(errors))
        )
      )
    )
  );

  updateOdds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.UPDATE_ODDS),
      switchMap((data: TicketActions.UpdateOdds) =>
        this.betSlipService.updateOdds(data.odds).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            return new TicketActions.SaveBetSlip(response.body);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred updateOdds BetSlipService')))
        )
      )
    )
  );

  marketLocked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.MARKET_LOCKED),
      map((data: TicketActions.MarketLocked) => {
        if (data.oddItems.length > 0) return new TicketActions.UpdateOdds(data.oddItems);
        return new TicketActions.NoAction();
      })
    )
  );

  keepSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.KEEP_SELECTION),
      map((data: TicketActions.KeepSelection) => new TicketActions.UpdateTicket(data.globalsData))
    )
  );

  removeStake$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlip | TicketActions.SetStake | TicketActions.Error>(TicketActions.REMOVE_STAKE),
      withLatestFrom(this.store.select('ticket')),
      switchMap(([data, tkState]) => {
        const systemSetStake = data['systemSetStake'];
        this.systemSetStake = systemSetStake;
        return this.betSlipService.removeStake(systemSetStake).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            if (this.systemSetStake) {
              return new TicketActions.SetStake(this.systemSetStake);
            } else {
              return new TicketActions.SaveBetSlip(response.body);
            }
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred removeStake BetSlipService')))
        );
      })
    )
  );

  SaveAndCloseBet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.SAVE_AND_CLOSE_BET),
      map((data: TicketActions.SaveAndCloseBet) => new TicketActions.CloseBet(data.betSlip))
    )
  );

  recoverTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.RECOVER_TICKET),
      map((data: TicketActions.RecoverTicket) => {
        const stake = this.generateMultiStake(data.ticketItemsAndAmount);
        if (!this.deviceService.isDesktop) this.mobileBehavior.openSmallFooter();
        return new TicketActions.SetMultiStake(stake);
      })
    )
  );

  removeItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlipAndEvaluateFlow | TicketActions.Error>(TicketActions.REMOVE_ITEM),
      withLatestFrom(this.store.select('ticket')),
      switchMap(([data, tckState]) => {
        const itemId = data['id'];
        this.auxRemovedItem = TicketMethods.getItemByPBSId(tckState['ticket'], itemId);
        return this.betSlipService.removeItem(itemId).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));

            this._trackingService.trackEvent([
              // ! comentado en su repositorio
              'removeItem', // todo this.auxRemovedItem.IsLive ? 'removeAddBetLive' : 'removeAddBet',
              '', // todo this.auxRemovedItem.ItemId,
              window.location.hash,
              '', // todo this.auxRemovedItem.IsLive ? 'Quitar apuesta en directo' : 'Quitar apuesta anticipada',
              'event'
            ]);

            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, false);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred removeItem BetSlipService')))
        );
      })
    )
  );

  removeItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlipAndEvaluateFlow | TicketActions.Error>(TicketActions.REMOVE_ITEMS),
      withLatestFrom(this.store.select('ticket')),
      switchMap(([data, ticktState]) => {
        const itemIds = data['ids'];
        this.auxRemovedItems = TicketMethods.getItemByPBSIds(ticktState['ticket'], itemIds);
        return this.betSlipService.removeItem(itemIds).pipe(
          map((response: Response) => {
            this._trackingService.trackEvent([
              // ! Comentado en su repositorio
              'removeItems', // todo this.auxRemovedItem.IsLive ? 'removeAddBetLive' : 'removeAddBet',
              '', // todo this.auxRemovedItem.ResultsNr,
              window.location.hash,
              '', // todo this.auxRemovedItem.IsLive ? 'Quitar apuesta en directo' : 'Quitar apuesta anticipada',
              'event'
            ]);

            this.xStateService.saveXState(response.headers.get('X-State'));
            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, false);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred removeItem BetSlipService')))
        );
      })
    )
  );

  removeMulticastItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.REMOVE_MULTICAST_ITEM),
      switchMap((data: TicketActions.RemoveMulticastItem) =>
        this.betSlipService.removeItem(data.id).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, false);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred removeMulticastItem BetSlipService')))
        )
      )
    )
  );

  removeCombination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.REMOVE_COMBINATION_ITEM),
      switchMap((data: TicketActions.RemoveCombinationItem) =>
        this.betSlipService.removeItem(data['id']).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, false);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred removeCombination BetSlipService')))
        )
      )
    )
  );

  removeSmartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.REMOVE_SMARTMARKET_ITEM),
      switchMap((data: TicketActions.RemoveSmartMarketItem) =>
        this.betSlipService.removeItem(data['id']).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, false);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred removeCombination BetSlipService')))
        )
      )
    )
  );

  placeBet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.CloseBet | TicketActions.Error>(TicketActions.PLACE_BET),
      withLatestFrom(this.store.select('ticket')),
      switchMap(([data, ticketStat]) => {
        const groupKey = data['groupKey'];
        const hasFreeBets = ticketStat['ticket'].FreeBetVoucherId;
        this.auxCloseBetTicket = ticketStat['ticket'];
        this.auxGroupKey = groupKey;
        this.utils.loader(null, 'is-loader transparent-backdrop');
        return of(new TicketActions.CloseBet(groupKey));
      })
    )
  );

  closeBet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | TicketActions.CloseTicketResult
        | TicketActions.CloseTicketResultAndOddsUpdate
        | TicketActions.OveraskStart
        | TicketActions.BetbuilderOddsChange
        | TicketActions.NoAction
        | TicketActions.Error
      >(TicketActions.CLOSE_BET),
      withLatestFrom(this.store.select('ticket')),
      switchMap(([data, ticketSte]) => {
        this.auxCloseBet = data['placeBet'];
        return this.betSlipService.closeBet(this.auxCloseBet).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            const ticketResponse = response.body;
            const singleTicketItem = ticketResponse['SingleTicketItem'];
            const ticket = ticketSte['ticket'];
            let betbuilderError = false;
            this.events.publish('loading:finish');

            if (ticketResponse['GeneralError']) {
              return new TicketActions.Error('Error Ocurred closeBet BetSlipService', { ticketResponse });
            }

            const responseData: any = {
              isSingleItem: !response.body['TicketItems'] ? false : true,
              data: !response.body['TicketItems'] ? response.body['SingleTicketItem'] : response.body['TicketItems']
            };

            if (responseData.data) this.processTrackEventCloseTicket(this.auxCloseBetTicket, responseData);

            if (singleTicketItem) {
              if (singleTicketItem.OveraskRejectId != null) {
                this.utils.closeLoader();
                return new TicketActions.OveraskStart(singleTicketItem, singleTicketItem['Stake']);
              }

              if (singleTicketItem.ErrorCode.includes('ErrorOddsChange')) {
                const newOdds = [];
                TicketMethods.getAllItems(ticket).map((lineItem, index) => {
                  if (lineItem.SmartLineItems && lineItem.SmartLineItems.length > 0 && lineItem.IsLive) {
                    betbuilderError = true;
                  } else {
                    singleTicketItem['NewOdds'].map((newOddsItem) => {
                      if (newOddsItem.ResultId === lineItem.ResultsNr) {
                        newOdds.push({
                          ItemId: lineItem.ItemId,
                          Odds: newOddsItem.Odd
                        });
                      }
                    });
                  }
                });
                if (newOdds.length < 1 && this.globalVars.FEATURES.SPO_LiveBetbuilderEnabled && betbuilderError) {
                  // ?? to test with the PO
                  return new TicketActions.BetbuilderOddsChange();
                }
                this.utils.closeLoader();
                return new TicketActions.CloseTicketResultAndOddsUpdate(singleTicketItem, newOdds);
              }

              if (singleTicketItem.ErrorCode.includes('InvalidOdd')) {
                return new TicketActions.Error('Error: InvalidOdd');
              }

              if (singleTicketItem.ErrorCode.includes('EventNodeNotActive')) {
                this.someMarketIsClosedOnBBL();
              }
            }

            this.auxCloseBet = null;

            this._balanceService.refreshBalance();
            this.utils.closeLoader();
            return new TicketActions.CloseTicketResult(ticketResponse, []);
          }),
          catchError((error) => {
            this._trackEventCloseTicket(this.auxCloseBetTicket, error);
            return of(new TicketActions.Error('Error Ocurred closeBet BetSlipService'));
          })
        );
      })
    )
  );

  closeTicketResultAndOddsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.CLOSED_TICKET_RESULT_AND_ODDS_UPDATE),
      map((data: TicketActions.CloseTicketResultAndOddsUpdate) => new TicketActions.UpdateOdds(data.newOdds))
    )
  );

  overaskMaxStake$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SetStake>(TicketActions.OVERASK_MAX_STAKE),
      map((data: TicketActions.SetStake) => new TicketActions.SetStake(data.stake))
    )
  );

  overaskCloseBet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.CloseBet>(TicketActions.OVERASK_CLOSE_BET),
      map(() => {
        this.utils.closeLoader();
        return new TicketActions.CloseBet(this.auxCloseBet);
      })
    )
  );

  // FREEBETS

  freeBetsUpdateVouchers$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.FreeBetsSetVouchers | TicketActions.Error>(TicketActions.FREEBETS_UPDATE_VOUCHERS),
      switchMap(() =>
        this.ticketService.GetUserActiveVouchers(true).pipe(
          map((response) => {
            const data = response['data'];
            let lstFreebets = [];
            if (data) {
              lstFreebets = data.filter((item) => item.IsTerminalVoucher === false);
            } else {
              lstFreebets = [];
            }
            return new TicketActions.FreeBetsSetVouchers(lstFreebets);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred freeBetsUpdateVouchers TicketService')))
        )
      )
    )
  );

  freeBetsAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | TicketActions.FreeBetsUpdateValidations
        | TicketActions.FreeBetsCancel
        | TicketActions.FreeBetsUpdateStakeAndPlaceFB
        | TicketActions.Error
      >(TicketActions.FREEBETS_ADD),
      withLatestFrom(this.store.select(ticketSelector.getFilters)),
      switchMap(([data, filter]) =>
        this.betSlipService.freebetAdd(data['voucher']).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            const freebetStake: StakeModel = this.autoFreeBetsStake(response.body);
            const betSlip: any = response.body;
            let validationCode = '';

            switch (filter.value) {
              case 0:
                validationCode = betSlip.stakeGroups.singles.validation.bonus.singles[0].vouchers[0].validationCode;
                if (validationCode !== 'Validated' && validationCode !== 'GeneralConditionCheckFailed') {
                  this.utils.showError('Voucher No Compatible. <br>Por favor, selecione otro.');
                  return new TicketActions.FreeBetsCancel(freebetStake.VoucherCode);
                }
                if (validationCode !== 'Validated' && validationCode == 'GeneralConditionCheckFailed') {
                  freebetStake.VoucherCode = '';
                  freebetStake.OverallStake = betSlip.bonus.freeBetVouchers[0].freeBetStake;
                  return new TicketActions.FreeBetsUpdateStakeAndPlaceFB(freebetStake);
                }
                break;
              case 1:
                validationCode =
                  betSlip.stakeGroups.accumulator.validation.bonus.systems[
                    betSlip.stakeGroups.accumulator.validation.bonus.systems.length - 1
                  ].vouchers[0].validationCode;
                if (validationCode !== 'Validated' && validationCode !== 'GeneralConditionCheckFailed') {
                  this.utils.showError('Voucher No Compatible. <br>Por favor, selecione otro.');
                  return new TicketActions.FreeBetsCancel(freebetStake.VoucherCode);
                }
                if (validationCode !== 'Validated' && validationCode == 'GeneralConditionCheckFailed') {
                  freebetStake.VoucherCode = '';
                  freebetStake.OverallStake = betSlip.bonus.freeBetVouchers[0].freeBetStake;
                  return new TicketActions.FreeBetsUpdateStakeAndPlaceFB(freebetStake);
                }
                break;
              default:
                break;
            }

            this._trackingService.trackEvent(['freeBetsAddsTicket', '', '', 'Ticket Freebet a\u00F1adido', 'event']);

            return new TicketActions.FreeBetsUpdateValidations('stake', betSlip, freebetStake);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred freeBetsAdd BetSlipService')))
        )
      )
    )
  );

  freeBetsUpdateStakeAndPlaceFB$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.FreeBetsUpdateValidations | TicketActions.Error>(
        TicketActions.FREEBETS_UPDATE_STAKE_AND_PLACE_FB
      ),
      withLatestFrom(this.store.select('ticket')),
      switchMap(([data, tiktState]) => {
        const dataStake = data['stake'];
        const stake: StakeModel = dataStake;
        return this.betSlipService.stake(stake).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            const freebetStake: StakeModel = this.autoFreeBetsStake(response.body);
            const betSlip: any = response.body;
            this._trackingService.trackEvent([
              'ChangeAmountTicket',
              '',
              '', // todo stake.OverallStake.toString(),
              'Cambia importe del ticket',
              'event'
            ]);
            return new TicketActions.FreeBetsUpdateValidations('stake', betSlip, freebetStake);
          }),
          catchError((errors) => this.handleError(errors))
        );
      })
    )
  );

  freeBetsCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.FreeBetsUpdateValidations | TicketActions.Error>(TicketActions.FREEBETS_CANCEL),
      switchMap((data: any) =>
        this.betSlipService.freebetCancel(data.voucherCode).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            const betSlip: any = response.body;

            this._trackingService.trackEvent(['cancelFreeBetsTicket', '', '', 'Ticket Freebet cancelado', 'event']);

            return new TicketActions.FreeBetsUpdateValidations('SaveBetSlip', betSlip);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred freeBetsCancel BetSlipService')))
        )
      )
    )
  );

  freeBetsUpdateValidations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.FREEBETS_UPDATE_VALIDATIONS),
      map((data: TicketActions.FreeBetsUpdateValidations) => {
        switch (data.nextStep) {
          case 'SaveBetSlip':
            return new TicketActions.SaveBetSlip(data.betSlip);
          default: // Stake
            return new TicketActions.SetStake(data.freebetStake);
        }
      })
    )
  );

  //INTERNAL EFFECTS
  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ERROR),
      map((state: TicketActions.Error) => {
        this.utils.closeLoader();
        const globalsData = {
          cuotaAccept: this.globalVars.cuotaAccept,
          userLogged: this.globalVars.user.logged
        };
        return new TicketActions.UpdateTicket(globalsData);
      })
    )
  );

  /**
   * Add a single Item to ticket
   * @returns Stake | Error
   */
  SetXStateAndAuthorization$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.NoAction>(TicketActions.SET_X_STATE_AND_AUTHORIZATION),
      switchMap(() => {
        this.xStateService.loadInitialXState();
        this.xStateService.loadAuthorization();
        return of(new TicketActions.NoAction());
      })
    )
  );

  updateAndChangeToSingles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.UPDATE_AND_CHANGE_TO_SINGLES),
      map((data: TicketActions.UpdateAndChangeToSingles) => new TicketActions.ChangeFilter(0))
    )
  );

  changeFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | TicketActions.SetSystemBetType
        | TicketActions.UpdateAndChangeToSingles
        | TicketActions.UpdateTicket
        | TicketActions.CheckForErrors
        | TicketActions.NoAction
      >(TicketActions.CHANGE_FILTER),
      withLatestFrom(this.store.select('ticket')),
      map(([data, state]) => {
        const betSlip = state['betSlip'];
        const possibleSystems = betSlip['possibleSystems'];
        const filterValue = data['filter'];
        const existAccumulator = possibleSystems.find((system) => system.systemType === StakeGroup.ACCUMULATOR);
        const existMultiples = possibleSystems.find(
          (system) => system.systemType === StakeGroup.SYSTEM || system.systemType === StakeGroup.MULTI_WAY_SYSTEM
        );
        let stake: StakeModel;
        const updateTicket = {
          cuotaAccept: this.globalVars.cuotaAccept,
          userLogged: this.globalVars.user.logged
        };

        switch (filterValue) {
          case 1:
            if (!existAccumulator) return new TicketActions.CheckForErrors();
            stake = this.checkSystemsAmount(state, filterValue);
            return new TicketActions.SetSystemBetType(state, stake);
          case 2:
            if (!existMultiples) return new TicketActions.UpdateAndChangeToSingles(updateTicket);
            stake = this.checkSystemsAmount(state, filterValue);
            return new TicketActions.SetSystemBetType(state, stake);
          default:
            return new TicketActions.CheckForErrors();
        }
      })
    )
  );

  saveBetslip$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.UpdateTicket | TicketActions.Error>(TicketActions.SAVE_BETSLIP),
      withLatestFrom(this.store.select('ticket')),
      map(([data, ticket]) => {
        const globalsData = {
          cuotaAccept: this.globalVars.cuotaAccept,
          userLogged: this.globalVars.user.logged
        };
        this.ticketService.saveLocalStoregeTicket(ticket);
        return new TicketActions.UpdateTicket(globalsData);
      })
    )
  );

  updateTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SetFilters | TicketActions.Error>(TicketActions.UPDATE_TICKET),
      map((state: any) => new TicketActions.SetFilters(state))
    )
  );

  setFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SetFilterSelected | TicketActions.Error>(TicketActions.SET_FILTERS),
      map((state: any) => new TicketActions.SetFilterSelected(state))
    )
  );

  removeTicket$ = createEffect(() =>
    // todo ofType<TicketActions.SaveBetSlip | TicketActions.Error>
    this.actions$.pipe(
      ofType(TicketActions.REMOVE_TICKET),
      switchMap((data: TicketActions.RemoveTicket) => {
        this.stopOveraskPolling = true;
        return this.betSlipService.removeTicket(data.removeTicket).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get('X-State'));
            this.ticketService.removeLocalStoregeTicket();
            return new TicketActions.SaveBetSlip(response.body);
          }),
          catchError((_) => of(new TicketActions.Error('Error Ocurred removeTicket BetSlipService')))
        );
      })
    )
  );

  setBetOddAcceptance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.NoAction | TicketActions.Error>(TicketActions.SET_BET_ODDS_ACCEPTANCE),
      switchMap(() =>
        this.userService.updateCustomizations(this.globalVars.user.customization).pipe(
          map((response: Response) => new TicketActions.NoAction()),
          catchError((_) => of(new TicketActions.Error('Error Ocurred setBetOddAcceptance setBetOddAcceptance')))
        )
      )
    )
  );

  restartBetslip$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.NoAction>(TicketActions.RESTART_BETSLIP),
      switchMap(() => {
        this.xStateService.restartAll();

        return of(new TicketActions.RemoveTicket(new removeTicket()));
      })
    )
  );

  autoSingleStake(betSlip: any, auxLineItem: SingleItemInput) {
    const item: singleResultItem = betSlip.items.singleResultItems.find((i) => i.resultId == +auxLineItem.ResultId);

    if (!item.canBeEachWay && item.canBeEachWay !== auxLineItem.CanBeEachWay) {
      console.error('The bet is not possible');
    }

    const tempStake: StakeModel = {
      ItemId: item.itemId,
      OverallStake: this.defaultOverallStake,
      IsEachWay: auxLineItem.CanBeEachWay,
      Group: StakeGroup.SINGLES
    };
    return tempStake;
  }

  mapSystemBet(systemDataStake: systemData, overallStake: number) {
    const tempStake: StakeModel = {
      SystemId: systemDataStake.systemId,
      OverallStake: overallStake * 100,
      IsEachWay: systemDataStake.canBeEachWay,
      Group: StakeGroup.SYSTEM
    };
    return tempStake;
  }

  autoSmartSingleStake(betSlip: any) {
    const itemId = betSlip.items.smartMarketItems[betSlip.items.smartMarketItems.length - 1].itemId;

    const tempStake: StakeModel = {
      ItemId: itemId,
      OverallStake: this.defaultOverallStake,
      IsEachWay: false,
      Group: StakeGroup.SINGLES
    };
    return tempStake;
  }

  autoStraightMulticastStake(betSlip: any) {
    const itemId = betSlip.items.straightMulticastItems[betSlip.items.straightMulticastItems.length - 1].itemId;
    const tempStake: StakeModel = {
      ItemId: itemId,
      OverallStake: this.defaultOverallStake,
      IsEachWay: false,
      Group: StakeGroup.SINGLES
    };
    return tempStake;
  }

  autoCombinationMulticastStake(betSlip: any) {
    const itemId = betSlip.items.combinationMulticastItems[betSlip.items.combinationMulticastItems.length - 1].itemId;
    const tempStake: StakeModel = {
      ItemId: itemId,
      OverallStake: this.defaultOverallStake,
      IsEachWay: false,
      Group: StakeGroup.SINGLES
    };
    return tempStake;
  }

  autoFreeBetsStake(betSlip: any) {
    const possibleSystemsLength = betSlip.possibleSystems.length;
    const voucher = betSlip.bonus.freeBetVouchers[0];

    const tempStake: StakeModel = {
      VoucherCode: voucher.voucherCode,
      OverallStake: 0,
      IsEachWay: false,
      Group: ''
    };

    if (possibleSystemsLength < 2) {
      tempStake.ItemId = 1;
      tempStake.Group = StakeGroup.SINGLES;
    } else {
      tempStake.SystemId = betSlip.possibleSystems.find(
        (system) => system.systemType == StakeGroup.ACCUMULATOR
      ).systemId;
      tempStake.Group = StakeGroup.ACCUMULATOR;
    }

    return tempStake;
  }

  getOveraskStatus(overaskData: C_OveraskDataBetPlacement) {
    let status: number | string = 0;
    const StakeCt = this.auxCloseBet.placeBetSlipRequest.BetTickets[0].Bets[0].StakeCt;

    if (overaskData.Finished) {
      if (overaskData.Rejected) {
        status = 3;
      } else {
        if (overaskData.Approved) {
          status = overaskData.MaxStake > 0 && overaskData.MaxStake < StakeCt ? 1 : 'OK';
        }
      }
    }

    this.stopOveraskPolling ? this.overaskStatus$.next(true) : this.overaskStatus$.next(overaskData.Finished);
    this.overaskData = overaskData;
    return status;
  }

  overaskNextStep(overaskData: C_OveraskDataBetPlacement) {
    const status = this.getOveraskStatus(overaskData);

    switch (status) {
      case 0: // Aprobación Requerida
        return new TicketActions.OveraskStartPolling(overaskData, status);
      case 1: // Apuesta Máxima Posible
        const stake = {
          IsEachWay: false,
          Group: this.auxGroupKey,
          ItemId: 1,
          OverallStake: overaskData.MaxStake
        };
        return new TicketActions.OveraskMaxStake(overaskData, status, stake);
      case 3: // No se ha podido realizar
        return new TicketActions.OveraskRejected(overaskData, status);
      default:
        return new TicketActions.CloseBet(this.auxCloseBet);
    }
  }

  checkSystemsAmount(ticketStte: any, filter: number): any {
    const freebets = !!ticketStte.ticket.FreeBetVoucherId;
    let OverallStake = this.defaultOverallStake;

    switch (filter) {
      case 2:
        let systemId = 2;
        if (ticketStte.ticket.StakeGroups.System.systemId) {
          const isActualSystemElectable = ticketStte.betSlip.possibleSystems.find(
            (i) =>
              (i.systemId == ticketStte.ticket.StakeGroups.System.systemId && i.systemType == StakeGroup.SYSTEM) ||
              i.systemType == StakeGroup.MULTI_WAY_SYSTEM
          );
          const possibleSystemSelection =
            isActualSystemElectable && isActualSystemElectable.length > 0
              ? isActualSystemElectable
              : ticketStte.betSlip.possibleSystems.find((i) => i.systemId == systemId);
          const minValue =
            ticketStte.ticket.SystemSelected.numberOfBetWays > possibleSystemSelection.numberOfBetWays
              ? ticketStte.ticket.SystemSelected.numberOfBetWays
              : possibleSystemSelection.numberOfBetWays;
          const totalAmount = ticketStte.ticket.StakeGroups.System.totalAmount;

          systemId = possibleSystemSelection.systemId;
          OverallStake = totalAmount && totalAmount >= minValue ? totalAmount * 100 : minValue * 100;
        } else {
          const system = ticketStte.betSlip.possibleSystems.find(
            (i) => i.systemType == StakeGroup.SYSTEM || i.systemType == StakeGroup.MULTI_WAY_SYSTEM
          );
          const minValue = system.numberOfBetWays;
          OverallStake = minValue * this.defaultOverallStake;
        }

        const systemStake: StakeModel = {
          SystemId: systemId,
          IsEachWay: false,
          Group: '',
          OverallStake: freebets ? 0 : OverallStake,
          VoucherCode: freebets ? ticketStte.ticket.FreeBetVoucherId : ''
        };

        switch (systemId) {
          case 2:
            systemStake.Group = StakeGroup.DOUBLES;
            break;
          case 3:
            systemStake.Group = StakeGroup.TRIPLES;
            break;
          case 4:
            systemStake.Group = StakeGroup.CUADRUPLES;
            break;
          default:
            systemStake.Group = `${StakeGroup.SYSTEM}${systemId}`;
            break;
        }
        return systemStake;

      default:
        const accumulator = ticketStte.betSlip.possibleSystems.find((i) => i.systemType == StakeGroup.ACCUMULATOR);
        OverallStake = ticketStte.ticket.StakeGroups.Accumulator.totalAmount
          ? ticketStte.ticket.StakeGroups.Accumulator.totalAmount * 100
          : OverallStake;
        const acumuladasStake: StakeModel = {
          SystemId: accumulator ? accumulator.systemId : 2,
          IsEachWay: false,
          Group: StakeGroup.ACCUMULATOR,
          OverallStake: freebets ? 0 : OverallStake,
          VoucherCode: freebets ? ticketStte.ticket.FreeBetVoucherId : ''
        };
        return acumuladasStake;
    }
  }

  betSlipNumberOfItems(betSlip: any): number {
    let selections = 0;

    if (betSlip.items.combinationMulticastItems.length > 0) {
      selections += betSlip.items.combinationMulticastItems.length;
    }
    if (betSlip.items.singleResultItems.length > 0) {
      selections += betSlip.items.singleResultItems.length;
    }
    if (betSlip.items.smartMarketItems.length > 0) {
      selections += betSlip.items.smartMarketItems.length;
    }
    if (betSlip.items.straightMulticastItems.length > 0) {
      selections += betSlip.items.straightMulticastItems.length;
    }
    return selections;
  }

  generateMultiStake(data: any) {
    const itemIds = [];
    const auxLineItems = data.lineItems as LineItem[];

    auxLineItems.forEach((i) => itemIds.push(i.ItemId));

    const multiStake: StakeModel = {
      IsEachWay: false,
      Group: StakeGroup.SINGLES,
      OverallStake: parseFloat(data.amount) * 100,
      VoucherCode: '',
      SystemId: 1,
      ItemIds: itemIds
    };
    return multiStake;
  }

  splitCanBeEachWay(data: any, ticket: any) {
    const lineItems = [
      ...ticket.ticket.LineItems,
      ...ticket.ticket.StraightMulticast,
      ...ticket.ticket.CombinationMulticast,
      ...ticket.ticket.SmartLineItems
    ];
    this.canBeEachWayItems = {
      IsEachWay: true,
      Group: data.Group,
      OverallStake: data.OverallStake,
      VoucherCode: '',
      SystemId: data.SystemId,
      ItemIds: []
    };
    this.noEachWayItems = {
      IsEachWay: false,
      Group: data.Group,
      OverallStake: data.OverallStake,
      VoucherCode: '',
      SystemId: data.SystemId,
      ItemIds: []
    };
    this.canBeEachWayItems.IsEachWay = true;
    this.canBeEachWayItems.ItemIds = [];
    this.noEachWayItems.ItemIds = [];
    data.ItemIds.forEach((itemId) => {
      const item = lineItems.find((j) => j.ItemId == itemId);
      if (item.CanBeEachWay) {
        this.canBeEachWayItems.ItemIds.push(item.ItemId);
      } else {
        this.noEachWayItems.ItemIds.push(item.ItemId);
      }
    });
  }

  get defaultOverallStake() {
    return +this.globalVars.FEATURES.SPO_DefaultOverallStake * 100 || 1000;
  }

  handleError(errors) {
    return of(new TicketActions.Error(errors.error));
  }

  private _trackEventCloseTicket(ticket: C_TicketApuestasNew, closeTicketResponse: any): void {
    if (ticket.OrderErrorMessages.length == 0 && closeTicketResponse.TicketId) {
      const betIds = ticket.LineItems ? ticket.LineItems.map((item) => item.ResultsNr).join(',') : null;
      this._trackingService.track({
        eventType: EventTypes.BetCompleted,
        id: closeTicketResponse.TicketId,
        secondParameter: ticket.BetType.toString(),
        description: ticket.Odd.toString(),
        additionalData: {
          userId: this.globalVars.user.username,
          currencyIsoCode: this.globalVars.currencyISOCode,
          betId: betIds,
          data: {
            amount: ticket.Total
          }
        }
      });
      return;
    }
    this._trackingService.trackEvent([
      EventTypes.ErrorMsgTicket as string,
      '',
      closeTicketResponse.ErrorDescription || 'General ErrorMsgTicket',
      'Mensaje de error en el ticket',
      'event'
    ]);
    return;
  }

  private processTrackEventCloseTicket(ticket: C_TicketApuestasNew, responseData: any): void {
    if (responseData.isSingleItem) {
      responseData.data.forEach((closeTicketResponse) => {
        this._trackEventCloseTicket(ticket, closeTicketResponse);
      });
      return;
    } else {
      this._trackEventCloseTicket(ticket, responseData.data);
    }
  }

  private async oddsAcceptanceAlert() {
    const alert = await this.alertController.create({
      header: 'Atención',
      message:
        'Has agregado al ticket un <b>Crea tu apuesta Live</b>.Te recomendamos que en las "Opciones de apuesta" selecciones la opción aceptar cualquier tipo de cambio de cuota para que puedas apostar rápidamente.',
      cssClass: '',
      buttons: [
        // {
        //   text: 'Opciones de apuesta',
        //   handler: () => {
        //     console.log({ aceptado: 'aceptado' });
        //     this.presentOddAcceptance();
        //   }
        // },
        {
          text: 'Aceptar',
          handler: null,
          role: 'cancel'
        }
      ],
      backdropDismiss: true
    });
    await alert.present();
  }

  private async presentOddAcceptance() {
    const auxCurrentOddAcceptance = this.globalVars.cuotaAccept;
    const alert = await this.ticketService.getConfigAlertOddsChange();
    alert.present();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    alert.onDidDismiss().then((alertResp: any) => {
      if (alertResp?.data?.accepted) {
        this.globalVars.setCuotaAccept(alertResp?.data?.value);
        this.store.dispatch(new TicketActions.SetBetOddAcceptance());
      } else {
        this.globalVars.cuotaAccept = auxCurrentOddAcceptance;
      }
    });
  }

  private someMarketIsClosedOnBBL() {
    this.utils.alert(
      true,
      'Atención',
      'Algún mercado está suspendido en este momento. Si el partido no ha terminado espere.',
      'Aceptar',
      () => {}
    );
  }
}
