import { LineItem } from '../../../C_LineItem';
import { oddsUpdateItem } from '../../../OddsUpdateItem';
import { StakeModel } from '../../stake.model';
import { removeTicket } from '../../B_RemoveTicket.model';
import { ticketBetSlip } from '../../ticketBetSlip.model';
import { C_FreeBetVoucher } from '../../../C_FreeBetTicket';
import { Action } from '@ngrx/store';
import { CombinationMulticastInput } from '../../combinationMulticast.model';
import { OddItem } from '../../oddsInput.model';

//BETSLIP ACTIONS
// ADD_REMOVE
export const ADD_SINGLE_RESULT = '[ticket] Add SingleResult';
export const ADD_SINGLE_RESULT_BY_ID = '[ticket] Add SingleResultById';
export const ADD_STRAIGHT_MULTICAST = '[ticket] Add StraightMulticast';
export const ADD_COMBINATION_MULTICAST = '[ticket] Add CombinationMulticast';
export const ADD_SMARTMARKET_ITEM = '[ticket] Add SmartMarket Bet';
export const ADD_SMARTMARKET_SELECTION = '[ticket] Add SmartMarket Selection';
export const REMOVE_ITEM = '[ticket] Remove Item';
export const REMOVE_ITEMS = '[ticket] Remove Items';
export const REMOVE_MULTICAST_ITEM = '[ticket] Remove Multicast Item';
export const REMOVE_COMBINATION_ITEM = '[ticket] Remove Combination Item';
export const REMOVE_SMARTMARKET_SELECTION = '[ticket] Remove SmartMarket Selection';
export const REMOVE_SMARTMARKET_ALL_SELECTIONS = '[ticket] Remove SmartMarket All Selections';
export const REMOVE_SMARTMARKET_ITEM = '[ticket] Remove SmartMarket Item';
export const BETBUILDER_ODDS_CHANGE = '[ticket] Betbuilder Odds Change';

// STAKE
export const SET_STAKE = '[ticket] Set Stake';
export const SET_MULTI_STAKE = '[ticket] Set Multi Stake';
export const UPDATE_STAKE = '[ticket] Update Stake';
export const REMOVE_STAKE = '[ticket] Remove Stake';

// PLACE BET
export const PLACE_BET = '[ticket] Place Bet';
export const CLOSE_BET = '[ticket] Close Bet';
export const CLOSED_TICKET_RESULT = '[ticket] Closed Ticket Result';
export const CLOSED_TICKET_RESULT_AND_ODDS_UPDATE = '[ticket] Closed Ticket Result And Odds Update';

// ERRROS
export const ERROR = '[ticket] Error';

// UPDATING POLLING
export const UPDATE_ODDS = '[ticket] Update Odds';
export const MARKET_LOCKED = '[ticket] Market Locked';
export const PLACING_BET = '[ticket] Set Placing Bet';
export const RECOVER_TICKET = '[ticket] Recover Ticket';
export const SAVE_AND_CLOSE_BET = '[ticket] Save And Close Bet';
export const SET_BET_ODDS_ACCEPTANCE = '[ticket] Set Bet Odds Aceptance';
export const SET_FILTER_SELECTED = '[ticket] Set Filter Selected';
export const SET_FILTERS = '[ticket] Set Filters';
export const CHANGE_FILTERS_AND_SET_ODD_ERRORS = '[ticket] Change Filters And Set Odd Errors';
export const SET_SYSTEM_BET_TYPE = '[ticket] Set System Bet Type';
export const SET_X_STATE_AND_AUTHORIZATION = '[ticket] Set XState And Authorization';

// OVERASK
export const OVERASK_START = '[ticket] Overask Start';
export const OVERASK_START_POLLING = '[ticket] Overask Start Polling';
export const OVERASK_MAX_STAKE = '[ticket] Overask Max Stake';
export const OVERASK_TIMEOUT = '[ticket] Overask TimeOut';
export const OVERASK_REJECTED = '[ticket] Overask Rejected';
export const OVERASK_CLOSE_BET = '[ticket] Overask Close Bet';

//INTERNAL ACTIONS
export const KEEP_SELECTION = '[ticket] Keep Selection';
export const REMOVE_TICKET = '[ticket] Remove Ticket';
export const SAVE_BETSLIP = '[ticket] Save BetSlip';
export const SAVE_BETSLIP_AND_EVALUATE_FLOW = '[ticket] Save BetSlip AndE valuate Flow';
export const UPDATE_TICKET = '[ticket] Update Ticket';
export const UPDATE_AND_CHANGE_TO_SINGLES = '[ticket] Update And Change To Singles';
export const UPDATE_AND_CHECK_FOR_ERRORS = '[ticket] Update And Check For Errors';
export const AMOUNT_LOCAL_LINE_ITEM_UPDATE = '[ticket] Amount Local LineItem Update';
export const AMOUNT_LOCAL_TOTAL_UPDATE = '[ticket] Amount Local Total Update';
export const SET_LINE_ITEM_SELECTED = '[ticket] Set Item Selected';
export const SET_LINE_ITEM_UNSELECTED = '[ticket] Set Item Unselected';
export const SET_ODD_ERRORS = '[ticket] Set Odd Errors';
export const CHECK_FOR_ERRORS = '[ticket] Check For Errors';

// BEHAVIOR
export const CHANGE_FILTER = '[ticket] Change Filter';
export const INIT_FILTERS = '[ticket] Init Filters';
export const KEYBOARD_CLOSE = '[ticket] Keyboard Close';
export const KEYBOARD_OPEN = '[ticket] Keyboard Open';
export const KEYBOARD_TOGGLE = '[ticket] Keyboard Toggle';

// FREEBETS
export const FREEBETS_TOGGLE = '[ticket] FreeBets Toggle';
export const FREEBETS_SET_VOUCHERS = '[ticket] FreeBets Set Vouchers';
export const FREEBETS_UPDATE_VOUCHERS = '[ticket] FreeBets Update Vouchers';
export const FREEBETS_ADD = '[ticket] FreeBets Add';
export const FREEBETS_UPDATE_VALIDATIONS = '[ticket] FreeBets Update Validations';
export const FREEBETS_STAKE = '[ticket] FreeBets Stake';
export const FREEBETS_CANCEL = '[ticket] FreeBets Cancel';
export const FREEBETS_UPDATE_STAKE_AND_PLACE_FB = '[ticket] FreeBets Update Stake And Place FB';

export const RESTART_BETSLIP = '[ticket] Restart Betslip';

export class SetXStateAndAuthorization implements Action {
  readonly type = SET_X_STATE_AND_AUTHORIZATION;
}

//BETSLIP ACTIONS
export class AddSingleResult implements Action {
  readonly type = ADD_SINGLE_RESULT;
  constructor(public game: any) {}
}

export class AddSingleResultById implements Action {
  readonly type = ADD_SINGLE_RESULT_BY_ID;
  constructor(public betId: any) {}
}

export class AddStraightMulticast implements Action {
  readonly type = ADD_STRAIGHT_MULTICAST;
  constructor(
    public straightMulticastInput: any,
    public straightMulticastItem
  ) {}
}

export class AddCombinationMulticast implements Action {
  readonly type = ADD_COMBINATION_MULTICAST;
  constructor(
    public combinationMulticastInput: CombinationMulticastInput,
    public combinationMulticastItem: LineItem
  ) {}
}

export class AddSmartMarketSelection implements Action {
  readonly type = ADD_SMARTMARKET_SELECTION;
  constructor(public selection: any) {}
}

export class AddSmartMarketItem implements Action {
  readonly type = ADD_SMARTMARKET_ITEM;
  constructor(
    public smartMarketRequest: any,
    public smartMarketBet: any
  ) {}
}

export class BetbuilderOddsChange implements Action {
  readonly type = BETBUILDER_ODDS_CHANGE;
}

export class KeepSelection implements Action {
  readonly type = KEEP_SELECTION;
  constructor(public globalsData: { cuotaAccept: string; userLogged: boolean }) {}
}

export class RemoveItem implements Action {
  readonly type = REMOVE_ITEM;
  constructor(public id: number) {}
}

export class RemoveItems implements Action {
  readonly type = REMOVE_ITEMS;
  constructor(public ids: number[]) {}
}
export class RemoveMulticastItem implements Action {
  readonly type = REMOVE_MULTICAST_ITEM;
  constructor(public id: number) {}
}

export class RemoveCombinationItem implements Action {
  readonly type = REMOVE_COMBINATION_ITEM;
  constructor(public id: number) {}
}

export class RemoveSmartMarketSelection implements Action {
  readonly type = REMOVE_SMARTMARKET_SELECTION;
  constructor(public id: number) {}
}

export class RemoveSmartMarketAllSelections implements Action {
  readonly type = REMOVE_SMARTMARKET_ALL_SELECTIONS;
  constructor() {}
}

export class RemoveSmartMarketItem implements Action {
  readonly type = REMOVE_SMARTMARKET_ITEM;
  constructor(public id: number) {}
}

export class SaveBetSlipAndEvaluateFlow implements Action {
  readonly type = SAVE_BETSLIP_AND_EVALUATE_FLOW;
  constructor(
    public betSlip: any,
    public checkFilterChange: boolean
  ) {}
}

export class SaveBetSlip implements Action {
  readonly type = SAVE_BETSLIP;
  constructor(public betSlip: any) {}
}

export class SetStake implements Action {
  readonly type = SET_STAKE;
  constructor(public stake: any) {}
}

export class UpdateStake implements Action {
  readonly type = UPDATE_STAKE;
  constructor(public stake: any) {}
}

export class SetMultiStake implements Action {
  readonly type = SET_MULTI_STAKE;
  constructor(public multiStake: any) {}
}

export class RemoveStake implements Action {
  readonly type = REMOVE_STAKE;
  constructor(
    public stakeGroup: string,
    public systemSetStake?: any
  ) {}
}

export class UpdateOdds implements Action {
  readonly type = UPDATE_ODDS;
  constructor(public odds: OddItem[]) {}
}
export class PlaceBet implements Action {
  readonly type = PLACE_BET;
  constructor(public groupKey: string) {}
}

export class CloseBet implements Action {
  readonly type = CLOSE_BET;
  constructor(public placeBet: any) {}
}

// OVERASK POLLING
export class OveraskStart implements Action {
  readonly type = OVERASK_START;
  constructor(
    public overaskData: any,
    public closeBetStake: any
  ) {}
}

export class OveraskStartPolling implements Action {
  readonly type = OVERASK_START_POLLING;
  constructor(
    public overaskData: any,
    public status: any
  ) {}
}

export class OveraskMaxStake implements Action {
  readonly type = OVERASK_MAX_STAKE;
  constructor(
    public overaskData: any,
    public status: any,
    public stake: any
  ) {}
}

export class OveraskTimeOut implements Action {
  readonly type = OVERASK_TIMEOUT;
  constructor(
    public overaskData: any,
    public status = 2
  ) {}
}

export class OveraskRejected implements Action {
  readonly type = OVERASK_REJECTED;
  constructor(
    public overaskData: any,
    public status: any
  ) {}
}

export class OveraskCloseBet implements Action {
  readonly type = OVERASK_CLOSE_BET;
}

export class CloseTicketResult implements Action {
  readonly type = CLOSED_TICKET_RESULT;
  constructor(
    public closeTicketResult: any,
    public newOdds: OddItem[]
  ) {}
}

export class CloseTicketResultAndOddsUpdate implements Action {
  readonly type = CLOSED_TICKET_RESULT_AND_ODDS_UPDATE;
  constructor(
    public closeTicketResult: any,
    public newOdds: OddItem[]
  ) {}
}

export class RemoveTicket implements Action {
  readonly type = REMOVE_TICKET;
  //eslint-disable-next-line
  constructor(public removeTicket: removeTicket) {}
}

//INTERNAL ACTIONS

export class InitFilters implements Action {
  readonly type = INIT_FILTERS;
  constructor(public filters: any) {}
}

export class ChangeFilter implements Action {
  readonly type = CHANGE_FILTER;
  constructor(public filter: number) {}
}

export class SetFilters implements Action {
  readonly type = SET_FILTERS;
  constructor(public state: any) {}
}

export class SetFilterSelected implements Action {
  readonly type = SET_FILTER_SELECTED;
  constructor(public state: any) {}
}

export class SetSystemBetType implements Action {
  readonly type = SET_SYSTEM_BET_TYPE;
  constructor(
    public newTicketState: any,
    public systemStake: StakeModel
  ) {}
}

export class UpdateAndChangeToSingles implements Action {
  readonly type = UPDATE_AND_CHANGE_TO_SINGLES;
  constructor(public globalsData: { cuotaAccept: string; userLogged: boolean }) {}
}

export class UpdateAndCheckForErrors implements Action {
  readonly type = UPDATE_AND_CHECK_FOR_ERRORS;
  constructor(public globalsData: { cuotaAccept: string; userLogged: boolean }) {}
}

export class RecoverTicket implements Action {
  readonly type = RECOVER_TICKET;
  constructor(
    public localStorageBetSlip: any,
    public ticketItemsAndAmount: any
  ) {}
}

export class SaveAndCloseBet implements Action {
  readonly type = SAVE_AND_CLOSE_BET;
  constructor(public betSlip: any) {}
}

export class MarketLocked implements Action {
  readonly type = MARKET_LOCKED;
  constructor(
    public odds: oddsUpdateItem[],
    public oddItems: OddItem[]
  ) {}
}
export class UpdateTicket implements Action {
  readonly type = UPDATE_TICKET;
  constructor(public globalsData: { cuotaAccept: string; userLogged: boolean }) {}
}

export class ChangeFilterAndSetOddErrors implements Action {
  readonly type = CHANGE_FILTERS_AND_SET_ODD_ERRORS;
  constructor(public filter: number) {}
}

export class CheckForErrors implements Action {
  readonly type = CHECK_FOR_ERRORS;
}

export class SetOddErrors implements Action {
  readonly type = SET_ODD_ERRORS;
}

export class SetLineItemSelected implements Action {
  readonly type = SET_LINE_ITEM_SELECTED;
  constructor(public lineItemSelection: any) {}
}

export class SetLineItemUnselected implements Action {
  readonly type = SET_LINE_ITEM_UNSELECTED;
}

export class PlacingBet implements Action {
  readonly type = PLACING_BET;
  constructor(public placeStatus: boolean) {}
}

export class SetBetOddAcceptance implements Action {
  readonly type = SET_BET_ODDS_ACCEPTANCE;
  constructor() {}
}

export class OpenKeyboard implements Action {
  readonly type = KEYBOARD_OPEN;
  constructor() {}
}

export class ToggleKeyboard implements Action {
  readonly type = KEYBOARD_TOGGLE;
  constructor() {}
}

export class CloseKeyboard implements Action {
  readonly type = KEYBOARD_CLOSE;
  constructor() {}
}

export class AmountLocalLineItemUpdate implements Action {
  readonly type = AMOUNT_LOCAL_LINE_ITEM_UPDATE;
  constructor(public payload) {}
}

export class AmountLocalTotalUpdate implements Action {
  readonly type = AMOUNT_LOCAL_TOTAL_UPDATE;
  constructor(public value) {}
}

// FREEBETS
export class FreeBetsSetVouchers implements Action {
  readonly type = FREEBETS_SET_VOUCHERS;
  constructor(public vouchers: any) {}
}

export class FreeBetsUpdateVouchers implements Action {
  readonly type = FREEBETS_UPDATE_VOUCHERS;
  constructor() {}
}

export class FreeBetsAdd implements Action {
  readonly type = FREEBETS_ADD;
  constructor(
    public voucher: C_FreeBetVoucher,
    public personalized: boolean,
    public filterSelected: any
  ) {}
}

export class FreeBetsUpdateValidations implements Action {
  readonly type = FREEBETS_UPDATE_VALIDATIONS;
  constructor(
    public nextStep: string,
    public betSlip: ticketBetSlip,
    public freebetStake?: StakeModel
  ) {}
}

export class FreeBetsCancel implements Action {
  readonly type = FREEBETS_CANCEL;
  constructor(public voucherCode: string) {}
}

export class FreeBetsUpdateStakeAndPlaceFB implements Action {
  readonly type = FREEBETS_UPDATE_STAKE_AND_PLACE_FB;
  constructor(public stake: any) {}
}

export class NoAction implements Action {
  readonly type = 'NoAction';
  constructor() {}
}

export class Error implements Action {
  readonly type = ERROR;
  constructor(
    public error: string,
    public code?: any
  ) {}
}

export class RestartBetslip implements Action {
  readonly type = RESTART_BETSLIP;
  constructor() {}
}

export type Types =
  | AddCombinationMulticast
  | AddSingleResult
  | AddSingleResultById
  | AddSmartMarketItem
  | AddSmartMarketSelection
  | AddStraightMulticast
  | AmountLocalLineItemUpdate
  | AmountLocalTotalUpdate
  | BetbuilderOddsChange
  | ChangeFilter
  | ChangeFilterAndSetOddErrors
  | CheckForErrors
  | CloseBet
  | CloseKeyboard
  | CloseTicketResult
  | CloseTicketResultAndOddsUpdate
  | Error
  | FreeBetsAdd
  | FreeBetsCancel
  | FreeBetsSetVouchers
  | FreeBetsUpdateStakeAndPlaceFB
  | FreeBetsUpdateValidations
  | FreeBetsUpdateVouchers
  | InitFilters
  | KeepSelection
  | MarketLocked
  | NoAction
  | OpenKeyboard
  | OveraskCloseBet
  | OveraskMaxStake
  | OveraskRejected
  | OveraskStart
  | OveraskStartPolling
  | OveraskTimeOut
  | PlaceBet
  | PlacingBet
  | RecoverTicket
  | RemoveCombinationItem
  | RemoveItem
  | RemoveItems
  | RemoveMulticastItem
  | RemoveSmartMarketAllSelections
  | RemoveSmartMarketItem
  | RemoveSmartMarketSelection
  | RemoveStake
  | RemoveTicket
  | RestartBetslip
  | SaveAndCloseBet
  | SaveBetSlip
  | SaveBetSlipAndEvaluateFlow
  | SetBetOddAcceptance
  | SetFilters
  | SetFilterSelected
  | SetLineItemSelected
  | SetLineItemUnselected
  | SetMultiStake
  | SetOddErrors
  | SetStake
  | SetSystemBetType
  | ToggleKeyboard
  | UpdateAndChangeToSingles
  | UpdateAndCheckForErrors
  | UpdateOdds
  | UpdateTicket;
