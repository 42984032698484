import { inject, Injectable, Provider } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import {
  CapacitorHttp as Http,
  HttpParams as CapacitorHttpParams,
  HttpHeaders as CapacitorHttpHeaders,
  Capacitor,
  CapacitorHttp
} from '@capacitor/core';
import { Platform } from '@ionic/angular';

import { defer, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CapacitorHttpInterceptor implements HttpInterceptor {
  constructor(private platform: Platform) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*
    La condicion del https es para revisar que tenga una URL valida
    Porque cuando queremos hacer una peticion de assets/... , CapacitorHttp
    pide un hostname y genera un error de MalformationURL
    */
    if (this.platform.is('capacitor') && !req.url.includes('assets')) {
      return this.interceptNativeRequest(req, next);
    } else if (Capacitor.getPlatform() === 'ios' && req.url.includes('assets')) {
      /* Redirect a usar fech en iOS porque HttpClient y CapacitorHttp
      No estan devolviendo los accesos locales */
      return this.interceptFetchRequest(req, next);
    }

    return this.interceptWebRequest(req, next);
  }

  private interceptNativeRequest(req: HttpRequest<any>, _next: HttpHandler): Observable<HttpEvent<any>> {
    const { method, body, url, headers, params } = req;
    /**
     * Transforms the type signature of Angular http headers
     * to Capacitor's type signature for http headers.
     *
     * Sanitizes invalid header values from the output.
     */
    const sanitizeHeaders = (_headers: HttpHeaders) => {
      const res: CapacitorHttpHeaders = {};
      for (const key of _headers.keys()) {
        res[key] = _headers.get(key) || '';
      }
      return res;
    };

    const sanitizeParams = (_params: HttpParams) => {
      const res: CapacitorHttpParams = {};
      for (const key of _params.keys()) {
        res[key] = decodeURIComponent(_params.get(key) || '');
      }
      return res;
    };

    return defer(async () => {
      const options = {
        url,
        method,
        headers: {
          ...sanitizeHeaders(headers),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': sanitizeHeaders(headers)['Content-Type'] || 'application/json'
        },
        data: body ? body : method === 'GET' ? undefined : {},
        params: sanitizeParams(params)
      };

      const response = await CapacitorHttp.request(options);

      if (response.status >= 400) {
        const errorResponse = new HttpErrorResponse({
          error: response.data,
          headers: new HttpHeaders(response.headers), // Incluye los encabezados en la respuesta de error
          url: response.url,
          status: response.status
        });
        throw this.handleRequestError(errorResponse);
      }

      const httpResponse = new HttpResponse({
        body: response.data,
        headers: new HttpHeaders(response.headers) // Incluye los encabezados en la respuesta exitosa
      });
      return httpResponse;
    }).pipe(
      catchError((e) => {
        console.error('Error during HTTP request:', e);
        return throwError(() => this.handleRequestError(e));
      })
    );
  }

  private interceptFetchRequest(req: HttpRequest<any>, _next: HttpHandler): Observable<HttpEvent<any>> {
    const { method, body, url, headers, params } = req;
    const baseHref = url;
    // const baseHref = 'https://dm.apuestas.codere.es/DeportesDevExs/' + url;

    const sanitizeHeaders = (_headers: HttpHeaders) => {
      const res: CapacitorHttpHeaders = {};
      for (const key of _headers.keys()) {
        res[key] = decodeURIComponent(params.get(key) || '');
      }
      return res;
    };

    const sanitizeParams = (_params: HttpParams) => {
      const res: CapacitorHttpParams = {};
      for (const key of _params.keys()) {
        res[key] = decodeURIComponent(_params.get(key) || '');
      }
      return res;
    };

    const requestOptions: RequestInit = {
      method,
      headers: {
        ...sanitizeHeaders(headers),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    if (method !== 'GET') {
      requestOptions.body = JSON.stringify(body || {});
    }

    return defer(() =>
      fetch(baseHref, requestOptions).then(async (res) => {
        const data = await res.json();
        return data;
      })
    ).pipe(
      catchError((e) => throwError(() => this.handleRequestError(e))),
      map((res) => {
        if (res.status >= 400) {
          const errorResponse = new HttpErrorResponse({
            error: res.body,
            headers: new HttpHeaders(res.headers),
            url: res.url,
            status: res.status
          });
          throw this.handleRequestError(errorResponse);
        }
        return new HttpResponse({ body: res });
      })
    );
  }

  private interceptWebRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
  }

  private handleRequestError(error: HttpErrorResponse) {
    return error;
  }
}

export const CapacitorHttpProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: CapacitorHttpInterceptor,
  multi: true
};
