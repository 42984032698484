import { Router, RouterModule } from '@angular/router';
import { CommonModule, Location, ViewportScroller } from '@angular/common';
import { IonAccordionGroup, IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  Component,
  Input,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  Inject,
  forwardRef,
  inject,
  OnChanges
} from '@angular/core';

//Services
import { SportService } from '../../../providers/SportService';
import { TrackingService } from '../../../providers/TrackingService';
import { EventsService } from '../../../providers/EventsService';

//Models
import { C_HighLight } from '../../../models/C_HighLight';
import { C_SpecialSports } from '../../../models/C_SpecialSports';
import { C_Sport, C_SportSidebar } from '../../../models/C_Sport';
import { C_League } from '../../../models/C_League';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { CodereRouterService } from '../../../services/codere-router.service';
import { countryMarkerPipe } from '../../../pipes/sports/countryMarker.pipe';

// Routes
import { SPORTS_PATHS } from '../../../lib/constants/routes';

// Store
import { sidebarState } from '../../../../../shared/src/models/sports/store/reducers/sidebar.reducers';
import * as SidebarActions from '../../../../../shared/src/models/sports/store/actions/sidebar.actions';
import { SidebarFacade } from '../../../../../shared/src/models/sports/store/facades/sidebar.facade';

import { SportHandleList } from '@utils/sportHandleList';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule, TranslateModule, countryMarkerPipe],
  selector: 'codere-sidebar-pc',
  templateUrl: './codere-sidebar-pc.html',
  styleUrls: ['./codere-sidebar-pc.scss']
})
export class CodereSidebarPcComponent implements OnChanges {
  @ViewChild('SportsSidebar') SportsSidebar: IonAccordionGroup;
  //es necesario hacer input de las variables y métodos de la app.
  @Input() highlights: Array<C_HighLight>;
  @Input() highlightsConfig: Array<C_SpecialSports> = [];
  @Input() sports: any;
  league: any;
  highLightSports: any = [];

  globalMenuAnimated: boolean = false;
  specialGames: Array<any> = [];

  sportsItems: C_SportSidebar[] = [];
  selectedSport: C_SpecialSports = null;

  @ViewChildren('subMenu') arrSubMenu: QueryList<ElementRef>;

  globalVars!: GlobalVarsModel;

  public router: Router = inject(Router);
  public sportService: SportService = inject(SportService);
  public events: EventsService = inject(EventsService);
  private newBaseService: NewBaseService = inject(NewBaseService);
  private codereRouterService: CodereRouterService = inject(CodereRouterService);
  private eventService = inject(EventsService);
  private location = inject(Location);
  public sidebarFacade = inject(SidebarFacade);

  public scroller = inject(ViewportScroller);

  constructor(@Inject(forwardRef(() => TrackingService)) private trackingService: TrackingService) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.location.onUrlChange((url) => {
      const namePageUrl = url.split('?')[0].substring(2);
      if (!SPORTS_PATHS[namePageUrl]) {
        this.globalVars.LeagueSelected = '';
        this.globalVars.SportHandleSelected = '';
      }
    });
  }

  ngOnChanges() {
    this.sportsItems = this.sports;
    this.highLightSports = this.highlights;
    if (this.sidebarFacade.selectedSport) this.navigateToSport(this.sidebarFacade.selectedSport);
  }

  openMvpCodere() {
    this.codereRouterService.openMvpCodere();
  }

  openToday() {
    this.codereRouterService.openToday();
  }

  openWorldCupCalendar() {
    this.codereRouterService.openWorldCupCalendar();
  }

  toggleSport(sport: any) {
    let collapsed = true;
    if (sport.isCollapsible) {
      collapsed = this.isCollapsed(sport);
      if (!collapsed) return (this.sidebarFacade.selectedSport = null);
    } else {
      collapsed = true;
    }
    this.toggleSportTrackingService(sport, collapsed);
    this.globalVars.SportHandleSelected = sport.SportHandle;
    this.globalVars.SportSelected = sport.Name;
    this.sidebarFacade.selectedSport = sport;

    if (!sport.isCollapsible) {
      this.navigateToNoDropdownSports(sport);
    } else {
      this.getSportItems(sport);
    }
  }

  isCollapsed(sport) {
    switch (true) {
      case this.sidebarFacade.selectedSport == null:
        return true;
      case sport.SportHandle == this.sidebarFacade.selectedSport.SportHandle:
        return false;
      case sport.SportHandle != this.sidebarFacade.selectedSport.SportHandle:
        return true;
    }
    return;
  }

  getSportItems(sport) {
    this.sidebarFacade.fetchCountries(sport);
  }

  navigateToSport(sport) {
    if (sport.highlight.length > 0) {
      this.league = sport.highlight[0];
      this.router.navigate(['SportEventsPage'], {
        state: { params: sport },
        queryParams: { animate: !this.globalVars.isNative }
      });
    } else {
      if (sport.countries.data.length > 0) {
        if (sport.countries.data[0].Leagues.length > 0) {
          this.league = sport.countries.data[0].Leagues[0];
          this.navigate(this.league);

          this.trackingService.trackEvent([
            'AccesstoLeagueFromCountryItemsportMenu',
            this.league.Name,
            this.league.SportHandle,
            this.league.NodeId,
            'event'
          ]);
        }
      }
    }
    setTimeout(() => {
      this.scroller.scrollToAnchor(sport.SportHandle);
    }, 500);
  }

  navigateToNoDropdownSports(sport) {
    this.globalVars.SportSelected = sport.Name;
    switch (sport.SportHandle) {
      case SportHandleList.horse_racing:
      case SportHandleList.greyhound_racing:
        this.navigateToRacePage(sport);
        break;
      default:
        this.router.navigate([SPORTS_PATHS.SportCountriesPage], {
          state: { params: { Name: sport.Name, NodeId: sport.NodeId, SportHandle: sport.SportHandle } }
        });
        break;
    }
  }

  toggleSportTrackingService(sport, collapsed) {
    this.trackingService.trackEvent([
      collapsed ? 'OpenItemSportMenu' : 'CollapsedItemSportMenu',
      sport.SportHandle,
      this.globalVars.currentPage.name,
      '',
      'event'
    ]);
  }

  toggleSection(sport: C_Sport) {
    this.sidebarFacade.toggleSection(sport);
    this.trackingService.trackEvent([
      'ViewMoreItemSports',
      sport.countries.collapsed,
      this.globalVars.currentPage.name,
      '',
      'event'
    ]);
  }

  itemLeagueTapped(event: Event, league: C_League, sport: C_Sport, isHL: boolean = false): void {
    this.globalVars.SportSelected = sport.Name;
    this.globalVars.LeagueSelected = league.NodeId;
    this.league = league;

    if (this.league.SportHandle !== 'greyhound_racing' && this.league.SportHandle !== 'horse_racing') {
      this.navigate(this.league);
    } else {
      this.navigateToRacePage(sport);
    }
    this.trackingService.trackEvent([
      isHL ? 'AccesstoLeagueFromHLItemsportMenu' : 'AccesstoLeagueFromCountryItemsportMenu',
      this.league.Name,
      this.league.SportHandle,
      this.league.NodeId,
      'event'
    ]);
  }

  async navigate(league: any) {
    let componentName: string = SPORTS_PATHS.EventoPage;
    let params = league;

    const leagueName = league.Name.toLowerCase();

    if (leagueName.search('anticipadas') >= 0 || leagueName.search('especiales') >= 0) {
      const data = await this.sportService.getEventsCountry(league.NodeId).toPromise();
      if (data.length === 1) {
        componentName = SPORTS_PATHS.MercadosPage;
        params = data[0];
      }
    }
    const hasBackButton: string = this.router.routerState.snapshot.url;
    this.eventService.sendCanGoBack(hasBackButton === '/HomePage' ? true : false);
    switch (componentName) {
      case SPORTS_PATHS.EventoPage:
        this.router.navigate([componentName], { state: { params: params, isLeftMenuAZ: true } });
        break;
      case SPORTS_PATHS.MercadosPage:
        this.router.navigate([componentName], { state: { params: params } });
        break;
    }
  }

  showSportHandleContent(sportHandle) {
    let showContent = true;

    switch (sportHandle) {
      case 'soccer':
      case 'tennis':
      case 'basketball':
      case 'olimpiadas':
      case 'greyhound_racing':
      case 'horse_racing':
        showContent = false;
        break;
    }
    return showContent;
  }

  navigateToRacePage(sport) {
    this.router.navigate([SPORTS_PATHS.HorsesPage], {
      state: { params: sport },
      queryParams: { animate: !this.globalVars.isNative }
    });
  }

  getLeaugueValue(sport) {
    return sport.highlight.length > 0 ? null : sport.countries.data[0];
  }
}
