import { Action, ActionReducer, ActionReducerMap } from '@ngrx/store';

import * as fromTicket from '@shared-sports-store/reducers/ticket.reducers';
import * as fromSidebar from '@shared-sports-store/reducers/sidebar.reducers';
import * as fromSportsData from '@shared-sports-store/reducers/sportsData.reducers';

export interface AppState {
  ticket: fromTicket.ticketState;
  sidebar: fromSidebar.sidebarState;
  sportsData: fromSportsData.sportsDataState;
}

export const appReducer: ActionReducerMap<AppState> = {
  ticket: fromTicket.ticketReducer as ActionReducer<fromTicket.ticketState, Action>,
  sidebar: fromSidebar.sidebarReducer as ActionReducer<fromSidebar.sidebarState, Action>,
  sportsData: fromSportsData.sportsDataReducer as ActionReducer<fromSportsData.sportsDataState, Action>
};
