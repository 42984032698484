import { C_Event } from './C_Event';
// models/C_Evento.ts
export class C_EventMostBet {
  // TODO <C_Event>
  Events: Array<C_Event>;
  Locked: boolean;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  Priority: number;
  SportHandle: string;
  SportId: string;
  SportOrder: number;
  SportName: string;
  key: any;

  constructor() {}
}
